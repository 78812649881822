import styles from './index.css';
import cerfa from '../../assets/cerfa_blue.svg'
import finance from '../../assets/finance.svg'
import support from '../../assets/support.svg'
import ContactForm from '../../Components/ContactForm'
import React, {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext";
import {isMobile} from 'react-device-detect';
import { useTranslation } from "react-i18next";
import img1 from '../../assets/assocs_img1.jpeg'
import img2 from '../../assets/assocs_img2.jpeg'
import Groupe1 from '../../assets/New/groupe1.png'
import Groupe2 from '../../assets/New/groupe2.png'
import Calc from '../../assets/New/calc.png'
import Cerfa from '../../assets/New/cerfa.png'
import Contact from '../../assets/New/contact.png'
import {useHistory} from 'react-router-dom'


const AssocBlock = ({title, desc,desc2=null,desc3=null, btn,img, reverse, noImgStyle, other, onClick}) => {

    const history = useHistory();

    return(
    <div className={('assoc-block' +( reverse ? ' reverse' : ''))}>
        {btn && 
            <div onClick={onClick} className={'assoc-btn mobile'}>{btn}</div>
        }
        {(!isMobile || !noImgStyle) && <img src={img} className={noImgStyle ? 'assoc-img-nostyle' : 'assoc-img'}></img>}
        
        <div className={other ? 'assoc-content2' : 'assoc-content'}>
            <div style={{width: '100%', flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
            {(isMobile && noImgStyle) && <img src={img} className={'assoc-img-mobile'}></img>}
                <div className={'assoc-title'}>{title}</div>
            </div>
            <div className={'assoc-desc'}>{desc}<span style={{fontWeight: 'bold',color:"#e00365",textDecoration:"underline",cursor: 'pointer'}} onClick={()=>history.push("/contact")}>{desc2}</span>{desc3}</div>
            {btn && 
                <div onClick={onClick} className={'assoc-btn desktop'}>{btn}</div>
            }
        </div>
    </div>)
}

export default function Login({history}) {
    const [userState, setUserState] = useContext(UserContext);
    const { t } = useTranslation()

    useEffect(() => {
        window.scrollTo(0,0)
    }, [history.location.pathname]);
    return <div className={'assocContainer'}>
        <AssocBlock onClick={() => {
            if (userState && userState.user) {
                history.push('/dashboard?menu=0')                
            } else {
                history.push('/login')
            }
            
        }}  img={Groupe1} title={t("AssociationPage.TitleAssociation")} desc={t("AssociationPage.TextAssociation")} btn={t("AssociationPage.BtnAssociation")} />
        <AssocBlock onClick={() => {
            if (userState && userState.user) {
                history.push('/dashboard?menu=1')                
            } else {
                history.push('/login')
            }
        }} reverse img={Groupe2} title={t("AssociationPage.TitleCampaign")} desc={t("AssociationPage.TextCampaign")} desc2={t("AssociationPage.TextCampaign2")} desc3={t("AssociationPage.TextCampaign3")} btn={t("AssociationPage.BtnCampaign")} />
        <AssocBlock other  noImgStyle img={Calc} title={t("AssociationPage.TitleManaging")} desc={t("AssociationPage.TextManaging")}  />
        <AssocBlock other reverse noImgStyle img={Cerfa} title={t("AssociationPage.TitleCerfa")} desc={t("AssociationPage.TextCerfa")} />
        <AssocBlock other  noImgStyle img={Contact} title={t("AssociationPage.TitleSurvices")} desc={t("AssociationPage.TextSurvices")} />

        <ContactForm title={"AssociationPage.FormQuestion"} subTitle={"AssociationPage.FormCampaign"} desc={"AssociationPage.FormContact"} />
    </div>
}