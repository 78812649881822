import styles from "./index.css";
import cerfa from "../../assets/cerfa_blue.svg";
import finance from "../../assets/finance.svg";
import support from "../../assets/support.svg";
import ContactForm from "../../Components/ContactForm";
import DonationBlock from "../../Components/DonationBlock";
import arrowDown from "../../assets/angle-down-solid.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
import whatsapp from "../../assets/whatsapp.svg";
import linkedin from "../../assets/linkedin.svg";
import iconDonate from "../../assets/icon_donate.svg";
import iconAssoc from "../../assets/icon_association.svg";
import iconSecu from "../../assets/icon_securite.svg";
import iconConfiance from "../../assets/icon_confiance.svg";
import iconCerfa from "../../assets/cerfa.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Assoc from "../../Components/Assoc";
import { useRef, useState, useEffect, useContext } from "react";
import Select from "react-select";
import {
  getPageByUrl,
  getAssocDonations,
  getAllCamps,
} from "../../Services/ws";
import { UserContext } from "../../Context/UserContext";
// import { Editor } from "react-draft-wysiwyg";
import dynamicLoader from "react-dynamic-loader";
import { useTranslation } from "react-i18next";
// import SEO from "../../Components/SEO/SEO";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { isMobile } from "react-device-detect";
import sharelink from "../../assets/share-link.png";
import { Helmet } from "react-helmet";
import roseFacebook from "../../assets/New/roseFacebook.png";
import roseTwitter from "../../assets/New/roseTwitter.png";
import roseWhatsapp from "../../assets/New/roseWhatsapp.png";
import roseMail from "../../assets/New/roseMail.png";
import CountUp from "react-countup";
import Hand from "../../assets/New/hand.png";
import Modal from "react-modal";
import { numberWithSpaces, calcPercentage } from "../../Services/utils";
import check from "../../assets/New/check-circle.svg";
import Countdown from "react-countdown";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import roseLink from "../../assets/New/roseLink.png";
import roseLinkedin from "../../assets/New/roseLinkedin.png";
import roseQr from "../../assets/New/roseQr.png";
import QrCode from "../../Components/QrCode/QrCode";

var fx = require("money");

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown
    return (
      <span>{`${days} J : ${hours} H : ${minutes} M : ${seconds} S`}</span>
    );
  }
};
const renderer2 = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown
    return (
      <span>{`${days} J : ${hours} H : ${minutes} M : ${seconds} S`}</span>
    );
  }
};

Modal.setAppElement("#root");
const options = [
  { label: "Euro €", value: "eur" },
  { label: "Usd $", value: "usd" },
  { label: "Ils ₪", value: "ils" },
  { label: "Gbp £", value: "gbp" },
];

const paymentOptions = [
  { label: "Sur 12 mois", value: "12" },
  { label: "Sur 11 mois", value: "11" },
  { label: "Sur 10 mois", value: "10" },
  { label: "Sur 9 mois", value: "9" },
  { label: "Sur 8 mois", value: "8" },
  { label: "Sur 7 mois", value: "7" },
  { label: "Sur 6 mois", value: "6" },
  { label: "Sur 5 mois", value: "5" },
  { label: "Sur 4 mois", value: "4" },
  { label: "Sur 3 mois", value: "3" },
  { label: "Sur 2 mois", value: "2" },
];

const TopDonateurLeft = ({
  firstName,
  lastName,
  message,
  amount,
  currency,
}) => {
  var cur = "€";
  switch (currency) {
    case "usd":
      cur = "$";
      break;
    case "ils":
      cur = "₪";
      break;
    case "gbp":
      cur = "£";
      break;
    default:
      break;
  }
  return (
    <div className={"TopDonateurContainer"}>
      <div className="handContainer">
        <img width="24px" src={Hand} />
      </div>
      <div>
        <div className="TopDonateurName">{`${firstName} ${lastName}`}</div>
        <div className="TopDonateurTxt">{message}</div>
      </div>
      <div className="TopDonateurDon">
        <CountUp separator=" " duration={5} end={amount} />
        {cur}
      </div>
    </div>
  );
};

const DonGeneral = ({
  campain,
  history,
  userState,
  setUserState,
  sponsor,
  donations,
}) => {
  const [amount, setAmount] = useState(null);
  const [currency, setCurrency] = useState(options[0].value);
  const [oneTime, setOneTime] = useState(true);
  const [intervals, setIntervals] = useState("12");
  const { t } = useTranslation();
  const [donFor, setDonFor] = useState("");
  const [donTemp, setDonTemp] = useState(null);

  const handleVal = (id, affects) => {
    affects.forEach((aff) => {
      document.getElementById(aff.idAffect).classList.remove("checked");
    });
    var checked = document.getElementById(id);
    checked.classList.add("checked");
    var don = affects.filter((aff) => {
      return aff.idAffect == id;
    });
    setDonFor(don[0].val);
    // console.log(document.getElementById(id))
  };

  //    }else{
  //        donateurTemp.push(donations[donations.length-1])
  //        donateurTemp.push(donations[donations.length-2])
  //        donateurTemp.push(donations[donations.length-3])
  //        donateurTemp.push(donations[donations.length-4])
  //    }
  return (
    <div className="donGeneralContainer">
      <div className="assoc-donation-block-amountLine">
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(26);
          }}
          className={
            amount == 26
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          26{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(52);
          }}
          className={
            amount == 52
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          52{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(126);
          }}
          className={
            amount == 126
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          126{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
      </div>
      <div
        style={{ justifyContent: "space-between" }}
        className="assoc-donation-block-amountLine"
      >
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(260);
          }}
          className={
            amount == 260
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          260{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(520);
          }}
          className={
            amount == 520
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          520{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(1040);
          }}
          className={
            amount == 1040
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          1040{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
      </div>
      <div
        style={{ marginTop: 10, display: "flex", alignItems: "center" }}
        className="assoc-donation-block-amountLine"
      >
        {/* <input
            style={{ width: "40%" }}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            onFocus={() => setAmount(null)}
            type="number"
            placeholder={t("Campaign.OtherAmount")}
            className="assoc-donation-block-amount-input"
            value={amount}
          ></input>
          <Select
            value={currency}
            onChange={(val) => {
              //console.log(val);
              setCurrency(val);
            }}
            placeholder="EUR €"
            classNamePrefix="assoc-select"
            className="react-select-container"
            options={options}
          /> */}
        <input
          style={{
            width: "130px",
            height: "22px",
            borderRadius: "10px 0px 0px 10px",
          }}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          onFocus={() => setAmount(null)}
          type="number"
          placeholder={t("Campaign.OtherAmount")}
          className="assoc-donation-block-amount-input"
          value={amount}
        ></input>
        <select
          className="select-donFor"
          value={currency}
          placeholder="EUR €"
          onChange={(e) => setCurrency(e.target.value)}
          style={{
            width: "100px",
            borderRadius: "0px 10px 10px 0px",
            marginTop: "4px",
            marginBottom: "4px",
            marginRight: "92px",
            marginLeft: "0px",
          }}
        >
          {options.map((opt, index) => {
            return (
              <option key={index} value={opt.value}>
                {opt.label}
              </option>
              // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
            );
          })}
        </select>
      </div>
      {campain && campain.affects.length > 1 && (
        <select
          style={{ marginLeft: "34px" }}
          className="select-donFor"
          value={donFor}
          onChange={(e) => setDonFor(e.target.value)}
        >
          {campain.affects.map((aff) => {
            return (
              <option value={aff.idAffect}>{aff.val}</option>
              // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
            );
          })}
        </select>
      )}
      <div
        className="assoc-donation-block-amount-checkbox-wrapper"
        style={{ width: "232px", margin: "auto", marginTop: "20px" }}
      >
        <div className="assoc-donation-block-amount-checkbox-line">
          <input
            onChange={() => setOneTime(true)}
            checked={oneTime}
            style={{ width: 25, height: 25, backgroundColor: "#f1f0ec" }}
            type="radio"
            className="radio-toolbar"
          />
          <div className="assoc-donation-block-amount-checkbox-text">
            {t("Campaign.DonatePeriod")}
          </div>
        </div>
        <div
          style={{ marginTop: 8 }}
          className="assoc-donation-block-amount-checkbox-line"
        >
          <input
            onChange={() => setOneTime(false)}
            checked={!oneTime}
            style={{ width: 25, height: 25 }}
            type="radio"
            className="radio-toolbar"
          />
          <div className="assoc-donation-block-amount-checkbox-text">
            {t("Campaign.DonateSeveral")}
          </div>
        </div>
        {!oneTime && (
          // <Select
          //   value={intervals}
          //   onChange={(val) => {
          //     //console.log(val);
          //     setIntervals(val);
          //   }}
          //   placeholder={paymentOptions[0].label}
          //   classNamePrefix="assoc-select"
          //   className="donation-months-container"
          //   options={paymentOptions}
          // />
          <select
            className="select-donFor"
            value={intervals}
            placeholder={paymentOptions[0].label}
            onChange={(e) => setIntervals(e.target.value)}
            style={{
              width: "218px",
              height: "50px",
              fontSize: "12px",
              borderRadius: "010px",
              marginTop: "4px",
              marginBottom: "4px",
              marginRight: "92px",
              marginLeft: "0px",
              marginTop: "20px",
            }}
          >
            {paymentOptions.map((opt, index) => {
              return (
                <option key={index} value={opt.value}>
                  {opt.label}
                </option>
                // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
              );
            })}
          </select>
        )}
      </div>
      {!oneTime && (
        <div
          className="yourAmountText"
          style={{ fontSize: "12px", width: "80%", margin: "auto" }}
        >
          {t("Dashboard.donate.Total")}
          {numberWithSpaces(Number(amount), currency.value)}{" "}
          {t("Donate.spread")} {Number(intervals)} {t("Donate.month")} Soit{" "}
          {numberWithSpaces(Number(amount) / Number(intervals), currency.value)}{" "}
          {t("Donate.setByMonth")} <br />
          {t("Donate.Tax.Reduction")}{" "}
          {numberWithSpaces(
            Number(Number(amount) - Number(amount) * 0.66),
            currency.value
          )}
          .
        </div>
      )}
      {/* {campain && campain.affects.length>1 && <div className="affects-container">{campain.affects.map((aff)=>{
                  return(
                      <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>    
                  )
              })}</div>} */}
      <div
        onClick={() => {
          setUserState({
            ...userState,
            donation: {
              amount: amount,
              currency: currency,
              times: oneTime,
              donateToId: campain.id,
              sponsorId: sponsor ? sponsor.id : null,
              returnUrl: window.location.href,
              intervals: intervals,
              donFor: donFor,
            },
          });
          history.push("/donate");
        }}
        className="assoc-donation-block-amount-donate-button"
      >
        {t("Campaign.MakeDonation")}
      </div>
      <div className="assoc-donation-block-amount-donate-txt">
        {t("Campaign.GetCerfa")}
      </div>
    </div>
  );
};

const SliderBlock = ({ history, campain }) => {
  var total = 0;
  var currency = "eur";
  var isFuture = new Date(campain.startingAt) > new Date();
  const { t } = useTranslation();

  if (campain.funding && isFuture == false) {
    for (let i = 0; i < campain.funding.length; i++) {
      const element = campain.funding[i];
      total += fx(element.amount)
        .from(element.currency.toUpperCase())
        .to("EUR");
    }
  }

  var percentage = calcPercentage(total, campain.goal.amount);
  var percentagePx = (percentage / 100) * 246;
  var percentagePxBonus = 0;
  // 0%   => 0px
  // 25%  => 61.5px
  // 50%  => 123px
  // 75%  => 184.5px
  // 100% => 246px
  if (percentage > 100) {
    percentagePxBonus = percentagePx - 246;
    percentagePx = 246 - percentagePxBonus - 15;
    percentagePxBonus = 246;
  }

  return (
    <div
      onClick={() => history.push(`/${campain.linkParam}`, { camp: campain })}
      className={"slider-block"}
    >
      <div className={"sb-container"}>
        {campain.poster[0].type == "PICTURE" ? (
          <img className={"headMedia"} src={campain.poster[0].url}></img>
        ) : (
          <video
            preload={"auto"}
            autoPlay={true}
            muted={true}
            className={"headMediaVideo"}
            controls
          >
            <source src={campain.poster[0].url} />
          </video>
          // <video className={"headMediaVideo"} poster={campain.sharingImage} src={campain.poster[0].url} controls>
          //   Your browser does not support the video tag.
          // </video>
        )}
        <div
          className={"home-slider-title"}
          style={{ marginTop: 10, textAlign: "left" }}
        >
          {" "}
          {campain.title[0].content}
        </div>
        <div className={"home-slider-sub-title"}>{campain.associationName}</div>
        <div style={{ flexDirection: "column", display: "flex", padding: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 10,
              opacity: isFuture ? 0 : 1,
            }}
          >
            <div className={"home-slider-price"}>
              {numberWithSpaces(campain.goal.amount, currency)}
            </div>
            <div className={"home-slider-price"}>{percentage}%</div>
          </div>
          <div
            style={{
              background: "#f8f8f8",
              border: "0.2px solid #471d30",
              width: "246px",
              height: 15,
              borderRadius: 4,
              opacity: isFuture ? 0 : 1,
            }}
          >
            {/* barre % */}
            <div
              style={{
                background:
                  new Date(campain.startingAt) > new Date()
                    ? "#471d30"
                    : new Date(campain.endingAt) <= new Date()
                    ? "linear-gradient(90deg, rgba(71,29,48,1) 0%, rgba(224,3,101,1) 100%)"
                    : "#471d30",
                width: `${percentage > 100 ? 100 : percentage}%`,
                height: "100%",
                borderRadius: 4,
              }}
            ></div>
            <div
              style={{
                position: "relative",
                top: "-15px",
                left: percentage > 100 ? `${percentagePx}px` : "0",
              }}
            >
              <img
                style={{ display: percentage > 100 ? "block" : "none" }}
                src={check}
              />
            </div>
            {/* barre % */}
          </div>
          <div
            style={{
              position: "relative",
              top: "-12px",
              left: percentage > 100 ? `${percentagePx - 40}px` : "0",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: 10,
              opacity: isFuture ? 0 : 1,
            }}
          >
            <div
              style={{ opacity: percentage > 100 ? "1" : "0" }}
              className={"home-slider-price"}
            >
              {numberWithSpaces(total, currency)}
            </div>
          </div>
          {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 5, alignItems: 'center'}}>
                <div  style={{fontSize: 14}} className={'home-slider-subtitle'}>{t("Campaign.Goal")}</div>
                <div style={{fontSize: 16}}  className={'home-slider-subtitle'}>{numberWithSpaces(campain.goal.amount, campain.goal.currency)}</div>
             </div> */}
        </div>

        <div
          className={"home-slider-subtitle"}
          style={{
            width: "85%",
            marginLeft: "auto",
            marginRight: "auto",
            minHeight: 16,
          }}
        >
          {new Date(campain.startingAt) > new Date()
            ? t("Campaign.Starts")
            : new Date(campain.endingAt) <= new Date()
            ? t("Campaign.Completed")
            : t("Campaign.Current")}
        </div>
        <div className="infoCampagneContainer">
          <div className="statusCampagne">
            {new Date(campain.startingAt) > new Date()
              ? null
              : new Date(campain.endingAt) <= new Date()
              ? t("Home.Merci")
              : t("Home.IlReste")}
          </div>

          <div className="tempsCampagne">
            {new Date(campain.startingAt) > new Date() ? (
              <Countdown
                date={new Date(campain.startingAt)}
                renderer={renderer}
              />
            ) : new Date(campain.endingAt) <= new Date() ? null : (
              <Countdown
                date={new Date(campain.endingAt)}
                renderer={renderer2}
              />
            )}
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: 5,
            marginBottom: 0,
            backgroundColor:
              new Date(campain.startingAt) > new Date()
                ? "#471d30"
                : new Date(campain.endingAt) <= new Date()
                ? "#471d30"
                : "#471d30",
            color: "white",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: "0px 0px 20px 20px",
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          {t("Campaign.ButtonSee")}
        </div>
      </div>
    </div>
  );
};

const TopAssoc = ({ assoc, userState, setUserState, history, donations }) => {
  const donateurTemp = [];
  if (donations && donations.length < 4) {
    donations[0] && donateurTemp.push(donations[0]);
    donations[1] && donateurTemp.push(donations[1]);
    donations[2] && donateurTemp.push(donations[2]);
    donations[3] && donateurTemp.push(donations[3]);
  } else if (donations) {
    donateurTemp.push(donations[Math.floor(Math.random() * donations.length)]);
    donateurTemp.push(donations[Math.floor(Math.random() * donations.length)]);
    donateurTemp.push(donations[Math.floor(Math.random() * donations.length)]);
    donateurTemp.push(donations[Math.floor(Math.random() * donations.length)]);
  }

  return (
    <div className={""}>
      <div className={"TopDonateur"}>
        {donateurTemp &&
          donateurTemp.map((don, index) => {
            return (
              <TopDonateurLeft
                key={index}
                firstName={don.donation.donor.firstName}
                lastName={don.donation.donor.lastName}
                message={don.donation.message}
                amount={don.donation.amount}
                currency={don.donation.currency}
              />
            );
          })}
        {!donateurTemp.length && <div style={{ width: "33%" }}></div>}
      </div>
      <div className={"TopAffiche"}>
        <div style={{ width: "10%" }}></div>
        {/* <div style={{ width: "33%" }}></div> */}
        <div className="topVideoStyleLater">
          {assoc.poster[0].type == "PICTURE" ? (
            <img className={"headMedia"} src={assoc.poster[0].url}></img>
          ) : (
            <video
              preload={"auto"}
              autoPlay={true}
              muted={true}
              className={"headMediaVideo"}
              controls
            >
              <source src={assoc.poster[0].url} />
            </video>
          )}
        </div>
      </div>
      <DonationBlock
        donateToId={assoc.id}
        userState={userState}
        setUserState={setUserState}
        history={history}
      />
    </div>
  );
};

const Carousel = ({
  pictures = [],
  videos = [],
  style = {},
  reverse = false,
}) => {
  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    autoplaySpeed: 1500,
    slidesToShow: isMobile
      ? 1
      : pictures.length + videos.length >= 5
      ? 5
      : pictures.length + videos.length,
    slidesToScroll: 1,
    autoplay: true,
    variableWidth: !isMobile,
  };

  return (
    <div style={{ marginTop: 30, position: "relative", ...style }}>
      <Slider ref={sliderRef} {...settings}>
        {!reverse &&
          pictures.map((picture) => (
            <div className="slider-block">
              <img className="assoc-slider-img" src={picture} />
            </div>
          ))}
        {!reverse &&
          videos.map((picture) => (
            <div className="slider-block">
              <video controls className="assoc-slider-img" src={picture} />
            </div>
          ))}
        {reverse &&
          videos.map((picture) => (
            <div className="slider-block">
              <video controls className="assoc-slider-img" src={picture} />
            </div>
          ))}
        {reverse &&
          pictures.map((picture) => (
            <div className="slider-block">
              <img className="assoc-slider-img" src={picture} />
            </div>
          ))}
      </Slider>
    </div>
  );
};
export default function Association({ history }) {
  const [assoc, setAssoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userState, setUserState] = useContext(UserContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [linkCopyed, setLinkCopyed] = useState(false);
  const [donations, setDonations] = useState(null);
  const [campains, setCampains] = useState(null);
  const sliderContainerRef = useRef(null);
  const sliderRef = useRef(null);
  const [editorTemp, setEditorTemp] = useState("");
  const [readMore, setReadMore] = useState(false);
  const [preview, setPreview] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (editorState) {
      const ediTemp = editorState
        .getCurrentContent()
        .getPlainText("\u0001")
        .split(" ");
      const arrTemp = [];
      for (let i = 0; i < 50; i++) {
        arrTemp.push(ediTemp[i]);
      }
      var str = arrTemp.join(" ");
      setEditorTemp(str + "...");
    }
  }, [editorState]);

  useEffect(() => {
    var params = history.location.pathname.split("/");

    if (
      history.location.pathname &&
      history.location.pathname.indexOf("/visualize") != -1
    ) {
      var res = userState.assocVisualize;

      setAssoc(userState.assocVisualize);
      setPreview(true);
      try {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(res.content[0].content))
          )
        );
      } catch (error) {}

      setLoading(false);
    } else {
      getPageByUrl(params[params.length - 1]).then((res) => {
        setAssoc(res);
        res &&
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(res.content[0].content))
            )
          );
        setLoading(false);
      });
    }
  }, [history.location.pathname]);

  useEffect(() => {
    // const getAllDonnations = async() => {
    //     var res = await getAssocDonations()
    //     var resFilter = res.filter(r=>r.donation.campaign.association===assoc.association._id)
    //     setDonations(resFilter)
    // }
    const getAllCampains = async () => {
      var res = await getAllCamps();
      var resFilter = res.filter(
        (r) => r.association === assoc.association._id
      );
      var resFilter2 = resFilter.filter((r) => r.goal != undefined);
      setCampains(resFilter2);
    };
    if (assoc && assoc.association) {
      // getAllDonnations()
      getAllCampains();
    }
  }, [assoc]);

  if (loading) {
    return <div style={{ height: "calc(100vh - 403px)" }} />;
  }
  if (!assoc) {
    return (
      <div
        style={{
          height: "calc(100vh - 403px)",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontSize: 45,
        }}
      >
        {t("Charity.PageNotFound")}
      </div>
    );
  }
  const pictures = assoc
    ? assoc.medias.filter((el) => el.type == "PICTURE").map((el) => el.url)
    : [];
  const videos = assoc.medias
    .filter((el) => el.type == "VIDEO")
    .map((el) => el.url);

  if (assoc.association && assoc.association.block) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "78%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 100,
          border: "5px solid rgb(169 86 86)",
          borderRadius: 15,
          padding: 30,
        }}
      >
        {t("Campaign.AssociationBlocked")}
      </div>
    );
  }

  if (!assoc.accepted) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "78%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 100,
          border: "5px solid rgb(169 86 86)",
          borderRadius: 15,
          padding: 30,
        }}
      >
        {t("Campaign.PageRejected")}
      </div>
    );
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const currentMonth = currentDate.getMonth().toString();
  const currentDay = currentDate.getDay().toString();
  const formattedDate = currentDay + currentMonth + currentYear;

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setLinkCopyed(true);
    setTimeout(() => setLinkCopyed(false), 3000);
  };

  const CarouselCam = ({ history }) => {
    var campainsTemp = [...campains];
    var resultCampains = [];
    campainsTemp.forEach((camp) => {
      if (
        (camp.draft == undefined || camp.draft == "no") &&
        camp.closed == false &&
        camp.accepted == true
      ) {
        resultCampains.push(camp);
      }
    });
    var calcSize =
      sliderContainerRef && sliderContainerRef.current
        ? Math.trunc(sliderContainerRef.current.offsetWidth / 350)
        : campains.length;
    calcSize =
      calcSize > resultCampains.length ? resultCampains.length : calcSize;
    var settings = {
      dots: true,
      infinite: true,
      arrows: true,
      speed: 500,
      // speed: 50000000,
      autoplaySpeed: 1500,
      slidesToShow: isMobile ? 1 : calcSize,
      slidesToScroll: 1,
      variableWidth: !isMobile,
      autoplay: true,
    };
    return (
      <div
        ref={sliderContainerRef}
        style={{ marginTop: 60, position: "relative" }}
      >
        <Slider ref={sliderRef} {...settings}>
          {resultCampains.map((campain) => {
            return (
              <div>
                <SliderBlock history={history} campain={campain} />
              </div>
            );
          })}
        </Slider>
        {/* <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 370, top: 0, display: 'flex'}}>
            <img onClick={() => {
                //console.log(sliderRef);
                sliderRef.current.slickPrev()
            }} style={{width: 35, marginLeft: 'auto', marginRight: 'auto', marginTop: 50,  zIndex: 30, transform: 'rotate(90deg)'}} src={arrowDown}></img>
            </div>
            <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 370, top: 0, right: 0, display: 'flex'}}>
            <img onClick={() => {
                //console.log(sliderRef);
                sliderRef.current.slickNext()
            }}  style={{width: 35, marginLeft: 'auto', marginRight: 'auto', marginTop: 50,  zIndex: 30, transform: 'rotate(270deg)'}} src={arrowDown}></img>
            </div> */}
      </div>
    );
  };

  return (
    <div className={"assocContainer"}>
      {/* <SEO
        title={`Association: ${assoc.associationName}`}
        description={assoc.title[0].content}
        name={`CharityClick`}
        type="website"
        image={assoc.sharingImage ? assoc.sharingImage : assoc.medias[0].url}
      /> */}
      <Helmet>
        <title>{`Association: ${assoc.associationName}`}</title>
        <meta
          property="og:title"
          content={`Association: ${assoc.associationName}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={assoc.title[0].content} />
        <meta property="og:site_name" content={"CharityClic"} />
        <meta
          property="og:image"
          itemProp={"image"}
          content={
            assoc.sharingImage ? assoc.sharingImage : assoc.medias[0].url
          }
        />
        <meta
          property="og:image:secure_url"
          itemProp={"image"}
          content={
            assoc.sharingImage ? assoc.sharingImage : assoc.medias[0].url
          }
        />
      </Helmet>
      {isMobile && (
        <Assoc
          customStyle={{ width: "90%" }}
          img={assoc.associationLogoUrl}
          title={assoc.associationName}
          desc={assoc.description[0].content}
        />
      )}
      {/* DEBUT TOP DE LA CAMPAGNE SI PAS MOBILE */}

      {!isMobile && (
        <div className="topCampainContainer">
          <div>
            <Assoc
              customStyle={{ width: "43%", marginRight: 0 }}
              campainName={assoc.title[0].content}
              img={assoc.associationLogoUrl}
              title={assoc.associationName}
              desc={assoc.description[0].content}
            />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                className={"share-block-top"}
                style={{
                  width: "32%",
                  marginRight: "auto",
                  marginLeft: "3%",
                  marginTop: 20,
                }}
              >
                <div
                  onClick={() => {
                    setUserState({
                      ...userState,
                      donation: {
                        amount: 0,
                        currency: "eur",
                        times: 1,
                        donateToId: assoc.id,
                        sponsorId: null,
                        returnUrl: window.location.href,
                        intervals: 1,
                        // donFor:donFor
                      },
                    });
                    history.push("/donate");
                  }}
                  className="assoc-donation-block-amount-donate"
                >
                  {t("Campaign.MakeDonation")}
                </div>
                <div
                  className="assoc-donation-block-amount-donate-txt"
                  style={{
                    fontSize: "10px",
                    marginBottom: "-5px",
                    marginLeft: "-100px",
                    width: "300px",
                  }}
                >
                  {t("Campaign.GetCerfa")}
                </div>
                {/* <span style={{textAlign: 'center', color: '#507cab', fontSize: 20, fontWeight: 'bold'}}>{t("Campaign.SocialShare")}</span> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <FacebookShareButton url={window.location.href}>
                    <img className={"h-socialIcon-top"} src={roseFacebook} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    title={`Soutenons : ${assoc.title[0].content}`}
                    url={window.location.href}
                  >
                    <img className={"h-socialIcon-top"} src={roseTwitter} />
                  </TwitterShareButton>
                  <WhatsappShareButton url={window.location.href}>
                    <img className={"h-socialIcon-top"} src={roseWhatsapp} />
                  </WhatsappShareButton>
                  <EmailShareButton url={window.location.href}>
                    <img className={"h-socialIcon-top"} src={roseMail} />
                    {/* <EmailIcon size={32} round /> */}
                  </EmailShareButton>
                  {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
                  <div style={{ paddingTop: "9px" }}>
                    <QrCode
                      className={"h-socialIcon-top"}
                      url={window.location.href}
                    />
                  </div>
                  <LinkedinShareButton url={window.location.href}>
                    <img className={"h-socialIcon-top"} src={roseLinkedin} />
                  </LinkedinShareButton>
                  <div onClick={() => copyToClipboard(window.location.href)}>
                    <img
                      className={"h-socialIcon-top"}
                      style={{ cursor: "pointer" }}
                      src={roseLink}
                    />
                    {linkCopyed && (
                      <p style={{ position: "absolute" }}>Link copied</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div style={{ margin: "auto", marginBottom: "-30px" }}>
          <div
            className={"share-block-top"}
            style={
              {
                // width: "32%",
                // marginRight: "auto",
                // marginLeft: "3%",
                // marginTop: 20,
              }
            }
          >
            <div
              onClick={() => {
                setUserState({
                  ...userState,
                  donation: {
                    amount: 0,
                    currency: "eur",
                    times: 1,
                    donateToId: assoc.id,
                    sponsorId: null,
                    returnUrl: window.location.href,
                    intervals: 1,
                    // donFor:donFor
                  },
                });
                history.push("/donate");
              }}
              className="assoc-donation-block-amount-donate"
            >
              {t("Campaign.MakeDonation")}
            </div>
            <div
              className="assoc-donation-block-amount-donate-txt"
              style={{ fontSize: "8px", marginBottom: "-5px" }}
            >
              {t("Campaign.GetCerfa")}
            </div>
            {/* <span style={{textAlign: 'center', color: '#507cab', fontSize: 20, fontWeight: 'bold'}}>{t("Campaign.SocialShare")}</span> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <FacebookShareButton url={window.location.href}>
                <img className={"h-socialIcon-top"} src={roseFacebook} />
              </FacebookShareButton>
              <TwitterShareButton
                title={`Soutenons : ${assoc.title[0].content}`}
                url={window.location.href}
              >
                <img className={"h-socialIcon-top"} src={roseTwitter} />
              </TwitterShareButton>
              <WhatsappShareButton url={window.location.href}>
                <img className={"h-socialIcon-top"} src={roseWhatsapp} />
              </WhatsappShareButton>
              <EmailShareButton url={window.location.href}>
                <img className={"h-socialIcon-top"} src={roseMail} />
                {/* <EmailIcon size={32} round /> */}
              </EmailShareButton>
              {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
              <div style={{ paddingTop: "9px" }}>
                <QrCode
                  className={"h-socialIcon-top"}
                  url={window.location.href}
                />
              </div>
              <LinkedinShareButton url={window.location.href}>
                <img className={"h-socialIcon-top"} src={roseLinkedin} />
              </LinkedinShareButton>
              <div onClick={() => copyToClipboard(window.location.href)}>
                <img
                  className={"h-socialIcon-top"}
                  style={{ cursor: "pointer" }}
                  src={roseLink}
                />
                {linkCopyed && (
                  <p style={{ position: "absolute" }}>Link copied</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* FIN TOP DE LA CAMPAGNE SI PAS MOBILE */}
      <div className="containerPrincipal">
        <TopAssoc
          assoc={assoc}
          userState={userState}
          setUserState={setUserState}
          history={history}
          donations={donations}
        />
      </div>
      {campains && (
        <div className={"carousel-cont"}>
          <CarouselCam
            history={history}
            style={{ width: "79%", marginLeft: "auto", marginRight: "auto" }}
          />
        </div>
      )}

      <div
        style={{ width: "79%", marginLeft: "auto", marginRight: "auto" }}
        className={"assocContainer-desc"}
      >
        <Editor readOnly toolbarHidden editorState={editorState} />
        {/* {assoc.content[0].content} */}
      </div>

      <Carousel
        pictures={pictures}
        videos={videos}
        style={{ width: "79%", marginLeft: "auto", marginRight: "auto" }}
      />
      {/* <div className={"assocContainer-donations-ranks"}>
            <div className={"assocContainer-donations-block"}>
                <span className={"assocContainer-donations-price"}>180 €</span>
                <span  className={"assocContainer-donations-title"}>Membre<br/> Bienfaiteur</span>
            </div>
            <div className={"assocContainer-donations-block"}>
                <span className={"assocContainer-donations-price"}>260 €</span>
                <span className={"assocContainer-donations-title"}>Membre<br/> d’honneur</span>
            </div>
            <div className={"assocContainer-donations-block"}>
                <span className={"assocContainer-donations-price"}>260 €</span>
                <span className={"assocContainer-donations-title"}>Membre<br/> VIP</span>
            </div>
            <div className={"assocContainer-donations-block"}>
                <span className={"assocContainer-donations-price"}>260 €</span>
                <span className={"assocContainer-donations-title"}>Membre<br/> Pilier</span>
            </div>
            <div className={"assocContainer-donations-block"}>
                <span className={"assocContainer-donations-price"}>260 €</span>
                <span className={"assocContainer-donations-title"}>Membre<br/> Fondateur</span>
            </div>
        </div> */}

      {/* <Carousel reverse pictures={pictures}  videos={videos} style={{width: '79%', marginLeft: 'auto', marginRight: 'auto'}} />
        <div style={{height: 100}}></div> */}
      {/* DEBUT DON GENERAL */}

      <div style={{ marginTop: "100px" }}>
        <div className="aboutCampain">{t("Campaign.Amount")}</div>
        {preview == false && (
          <DonGeneral
            history={history}
            campain={assoc}
            userState={userState}
            setUserState={setUserState}
            donations={donations}
            donateToId={assoc.id}
          />
        )}
        {preview == true && <div style={{ width: "33%" }}></div>}
      </div>

      {/* FIN DON GENERAL */}

      <div
        style={{ marginBottom: 30 }}
        className={"campain-container-donators"}
      >
        {/* <div  className={'campain-block1'}>
                <Carousel pictures={pictures} videos={videos}/>
            </div> */}
        {donations && donations.length > 0 && (
          <div className={""}>
            {/* {isFuture == false &&
                <> */}

            <div
              className={""}
              style={{
                fontSize: 25,
                width: "90%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 10,
                textAlign: "center",
                marginTop: "100px",
              }}
            >
              {t("Thanks")}
            </div>

            <div className="campain-subcontainer-donators">
              {donations.map((donation, index) => {
                //   if(sponsor){
                //     if(!donation.sponsor || sponsor.id!=donation.sponsor.id){
                //       return null
                //     }
                //   }
                return (
                  <div key={index} className="donation-block-donators">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div>
                          {" "}
                          <span className="fame-donator">
                            {donation.donation.anonymous == false
                              ? donation.donation.donor.firstName +
                                " " +
                                donation.donation.donor.lastName
                              : t("Campaign.SponsorAnonymous")}
                          </span>
                          {donation.donation.sponsor && (
                            <span className="fame-sponsor">
                              {" "}
                              via {donation.donation.sponsor.name}
                            </span>
                          )}
                        </div>
                        <div>{donation.donation.message}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className="fame-amount">
                          {numberWithSpaces(
                            donation.donation.amount ||
                              donation.donation.originalAmount,
                            donation.donation.currency ||
                              donation.donation.originalCurrency
                          )}
                        </div>
                        <div className="fame-date">
                          {formatDistanceToNow(
                            new Date(donation.donation.createdAt),
                            {
                              addSuffix: true,
                            }
                          )}
                          {/* {moment(new Date(donation.createdAt)).format('DD/MM/YYYY')} */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* </>
                } */}
          </div>
        )}
      </div>
    </div>
  );
}
