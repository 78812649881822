import React, {useState, createRef, useEffect} from 'react';
import styles from './index.css';
import add from '../../assets/add.png'
import {uploadFile} from '../../Services/s3'
import {createCollect, updateCollect, publishCollect, getLinkAvailability} from '../../Services/ws'
import { Store } from 'react-notifications-component';
import dynamicLoader from 'react-dynamic-loader'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Loader from "react-loader-spinner";
import draftToHtml from 'draftjs-to-html';
import { isMobile } from 'react-device-detect';
import { useTranslation} from "react-i18next";
import CreateCampagne from '../Dashboard/CreateCampagne'
import CreateBilletterie from '../Dashboard/CreateBilletterie'
import Plus2 from '../../assets/New/plus2.png'

export default function Don({userState, setUserState, history, setShowWarning}) {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [loadingAffiche, setLoadingAffiche] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [pic, setPic] = useState(null);
    const [logo, setLogo] = useState(null);
    const [sharingImage, setSharingImage] = useState(null);
    const [isBlocked, setBlockedStatus] = useState(false);
    const [videos, setVideos] = useState([]);
    const [pictures, setPictures] = useState([]);
    const inputOpenFileRefLogo = createRef();
    const inputOpenFileRefPic = createRef();
    const inputOpenFileRefSharingImage = createRef();
    const inputOpenFileRefPhotos = createRef();
    const inputOpenFileRefVideos = createRef();
    const [name, setName] = useState(userState.association.usageName);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [article, setArticle] = useState('');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [descEditorState, setDescEditorState] = useState(EditorState.createEmpty());
    const [subDiv,setSubDiv] = useState(1);
    const [allCampaign,setAllCampaign] = useState([])
    const [selectedCampaign,setSelectedCampaign] = useState("")
    const [allBilletterie,setAllBilletterie] = useState([])
    const [selectedBilletterie,setSelectedBilletterie] = useState("")

    const { t } = useTranslation();


    useEffect(() => {
        
        if (userState.collect && userState.collect.title) {
        //    const vid = userState.collect.medias.filter((el) => el.type == 'VIDEO')
           
            setTitle(userState.collect.title[0].content)
            setUrl(userState.collect.linkParam)
            setName(userState.collect.associationName)
            setLogo(userState.collect.associationLogoUrl)
            setBlockedStatus(userState.association.block);
            setPic(userState.collect.poster[0].url)
            setSharingImage(userState.collect.sharingImage)
            setDescription(userState.collect.description[0].content)
            setArticle(userState.collect.content[0].content)
            setPictures( userState.collect.medias.filter((el) => el.type == 'PICTURE').map((el) => el.url))
            setVideos( userState.collect.medias.filter((el) => el.type == 'VIDEO').map((el) => el.url))
            try {
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(userState.collect.content[0].content))))
                setDescEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(userState.collect.description[0].content))))
            } catch (error) {
                
            }
            // setVideo(vid[0]? vid[0].url : null)
        } else {
            setUrl(userState.association.linkParam);
            setName(userState.association.usageName);
            setLogo(userState.association.logoUrl);
            setBlockedStatus(userState.association.block);
        }
    }, [userState.collect])

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setArticle(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    };
    
    const onDescEditorStateChange = (editorState) => {
        setDescEditorState(editorState)
        setDescription(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    };
    
    // const handleBeforeInput = (input) => {
    //     if (draftToHtml(convertToRaw(editorState.getCurrentContent())).length >= 10) {
    //     return 'handled';
    //     }
    //   };

    console.log("userState",userState);

    useEffect(()=>{

        if(userState.campains){
            var campainTemp = []
            userState.campains.map((campaign)=>{
                if(campaign.accepted == true && campaign.closed == false){
                    campainTemp.push({title:campaign.title[0].content,link:campaign.linkParam})
                }
            })
            setAllCampaign(campainTemp)
        }
        if(userState.billetteries){
            var billetterieTemp = []
            userState.billetteries.map((billetteries)=>{
                if(billetteries.accepted == true && billetteries.closed == false){
                    billetterieTemp.push({title:billetteries.title[0].content,link:billetteries.linkParam})
                }
            })
            setAllBilletterie(billetterieTemp)
        }

    },[userState])

    const handleCampaign = () =>{
        if(selectedCampaign === ""){
            return
        }else{
            window.open(`/${selectedCampaign}`, "_blank");
        }
    }
    const handlebilletterie = () =>{
        if(selectedBilletterie === ""){
            return
        }else{
            window.open(`/${selectedBilletterie}`, "_blank");
        }
    }
    
    return (<>
    {subDiv == 1 && (
        <div>
            <p className="dashTitle">Dashboard</p>
            <div >
                <p className="dashTitle">Mes campagnes</p>
                <div className="designContainer">
                    <select onChange={(e)=>setSelectedCampaign(e.target.value)}>
                        <option value="">Choisissez une campagne</option>
                        {allCampaign.map((camp)=>{
                            return(
                                <option value={camp.link}>{camp.title}</option>
                            )
                        })}
                    </select>
                    <div className="newBtnNext2" onClick={handleCampaign}>Visiter</div>
                    <div className="row2" onClick={() => {
                    setSubDiv(3)
                    }}>
                    <img className="newDashAddNo" src={Plus2} alt="add"  />
                    <p style={{color:"#5c3748",fontWeight:"bold",fontSize: isMobile ? '14px' : '30px'}}>{t("Dashboard.CampaignPage.AddNew")}</p>
                    {/* <div className={'newBtnNext3'}>{t("Dashboard.CampaignPage.Access")}</div> */}
                </div>
                </div>
            </div>

            <div >
                <p className="dashTitle">Mes billetteries</p>
                    <div className="designContainer">
                        <select onChange={(e)=>setSelectedBilletterie(e.target.value)}>
                            <option value="">Choisissez une billetterie</option>
                            {allBilletterie.map((camp)=>{
                                return(
                                    <option value={camp.link}>{camp.title}</option>
                                )
                            })}
                        </select>
                        <div className="newBtnNext2" onClick={handlebilletterie}>Visiter</div>
                        <div className="row2" onClick={() => {
                    setSubDiv(4)
                    }}>
                    <img className="newDashAddNo" src={Plus2} alt="add"  />
                    <p style={{color:"#5c3748",fontWeight:"bold",fontSize: isMobile ? '14px' : '30px'}}>{t("Dashboard.BilletteriePage.AddNew")}</p>
                    {/* <div className={'newBtnNext2'}>{t("Dashboard.CampaignPage.Access")}</div> */}
                </div>
                    </div>
                </div>
                <div style={{marginTop:"60px",display: 'flex',justifyContent: 'center'}}>
                <div className="newBtnNext2" onClick={()=>setSubDiv(2)}>Ma page</div>
                </div>
        </div>
    )}
    {subDiv == 2 && (
         <>
         <div className="newBtnNext2" onClick={()=>setSubDiv(1)}>Retour</div>
        <div>
            <p className="dashTitle">{t("Dashboard.CharityPage.Title")}</p>

            {isBlocked &&
                <div className="dashBlocked">
                    You have been blocked! To change this status you need contact to website administrator.
                </div>
            }

            <div className="dashForm">
                <p className="dashFormTitle">{t("Dashboard.CharityPage.Information")}</p>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CharityPage.OfficialName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder={t("Dashboard.CharityPage.OfficialNamePlacehodler")} value={name} onChange={(evt) => {setName(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CharityPage.AssociationTitle")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder={t("Dashboard.CharityPage.AssociationTitlePlacehodler")} value={title} onChange={(evt) => {
                            setTitle(evt.target.value)
                            var link = evt.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "")
                            link = link.replace(' ', '-').replace(/ /g, '-');
                            setUrl(link)
                            }} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CharityPage.AssociationUrl")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" value={"https://charityclic.com/" + url}  placeholder={t("Dashboard.CharityPage.AssociationUrlPlaceholder")} onChange={(evt) => {
                            var val = evt.target.value.slice('https://charityclic.com/'.length, evt.target.value.length)
                            
                            setUrl(val)
                            }} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CharityPage.AssociationLogo")}</p>
                    </div>
                    {loadingLogo ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefLogo?.current.click()}} />
                            )}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingLogo(true)
                            var fileUrl = await uploadFile(e.target.files[0])
                            //console.log(fileUrl);
                            setLogo(fileUrl)
                            setLoadingLogo(false)
                        }
                    }} type="file"  accept="image/*" ref={inputOpenFileRefLogo} style={{ display: 'none' }}/>
                    {logo && 
                        <img className="filePreview" src={logo}></img>
                    }
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{pic ? t("Dashboard.CharityPage.AssociationPosterEdit") : t("Dashboard.CharityPage.AssociationPosterInsert")} affiche ou votre vidéo *</p>
                    </div>
                    {loadingAffiche ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefPic?.current.click()}} />
                            )}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingAffiche(true)
                            try {
                                var fileUrl = await uploadFile(e.target.files[0])
                                //console.log(fileUrl);
                                setPic(fileUrl)
                            } catch (error) {
                                Store.addNotification({
                                    title: t("SingIn.Errors"),
                                    message: t("Dashboard.CharityPage.DownloadFailed"),
                                    type: "danger",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                    }
                                    });
                            }
                            setLoadingAffiche(false)
                        }
                    }} type="file"  accept="image/*, video/mp4" ref={inputOpenFileRefPic} style={{ display: 'none' }}/>
                    {pic &&
                        pic.indexOf('.mp4') > 0 ?
                            <video controls className="picturePreview" src={pic}></video> :
                            <img className="picturePreview" src={pic}></img>
                    }
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{sharingImage ? t("Dashboard.CharityPage.AssociationShareImageEdit") : t("Dashboard.CharityPage.AssociationShareImageInsert")}</p>
                    </div>
                    {loadingAffiche ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                                type="ThreeDots"
                                color="#507cab"
                                height={10}
                                width={30}/>
                        </div>
                    ) :(
                        <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefSharingImage?.current.click()}} />
                    )}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingAffiche(true)
                            try {
                                var fileUrl = await uploadFile(e.target.files[0])
                                setSharingImage(fileUrl)
                            } catch (error) {
                                Store.addNotification({
                                    title: t("SingIn.Errors"),
                                    message: t("Dashboard.CharityPage.DownloadFailed"),
                                    type: "danger",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                    }
                                });
                            }
                            setLoadingAffiche(false)
                        }
                    }} type="file"  accept="image/*" ref={inputOpenFileRefSharingImage} style={{ display: 'none' }}/>
                    {sharingImage &&
                        <img className="picturePreview" src={sharingImage}></img>
                    }
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CharityPage.AssociationDescription")}</p>
                        <p className="smallTxt">{t("Dashboard.CharityPage.AssociationDescriptionLines")}</p>
                    </div>
                    <div className="dashColInput">
                    <Editor
                        editorState={descEditorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="dashInput2-new"
                        editorClassName="editorClassName"
                        onEditorStateChange={onDescEditorStateChange}
                        />
                        {/* <textarea className="dashInput" rows={3} placeholder="Description ou phrase explicative de la campagne de don"
                            value={description} onChange={(evt) => {setDescription(evt.target.value)}} style={{height: '170px'}} /> */}
                            {/*  */}
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CharityPage.DonationArticle")}</p>
                        <p className="smallTxt">{t("Dashboard.CharityPage.Explanation")}</p>
                    </div>
                    <div className="dashColInput">
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="dashInput2-new"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        />
                        {/* <textarea className="dashInput" rows={3} placeholder="Article sur votre association"
                            value={article} onChange={(evt) => {setArticle(evt.target.value)}} style={{height: '530px'}} /> */}
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CharityPage.Photos")}</p>
                    </div>
                    {loading ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                        <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefPhotos?.current.click()}} />
                        )}
                    <input multiple onChange={async (e) => {
                        if (e.target.files[0]) {
                            const newPics =[...pictures]
                            setLoading(true)
                            for (let i = 0; i < e.target.files.length; i++) {
                                const element = e.target.files[i];
                                var fileUrl = await uploadFile(element)
                                newPics.unshift(fileUrl)
                                setPictures(newPics)
                            }
                            setLoading(false)
                        }
                    }} type="file" accept="image/*" ref={inputOpenFileRefPhotos} style={{ display: 'none' }}/>
                    <div style={{maxWidth: isMobile ? '90%' : '65%'}} className="row">
                        {pictures.map((picture, idx) =>
                            <div>
                                <img className="picturePreview" src={picture}></img>
                                <div onClick={() => {
                                    const newPics =[...pictures]
                                    newPics.splice(idx, 1)
                                    setPictures(newPics)
                                }} className={'newBtnNext2'} style={{margin:"auto"}}>{t("Dashboard.CharityPage.PhotosDelete")}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CharityPage.Videos")}</p>
                    </div>
                    {loadingVideos ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefVideos?.current.click()}} />
                            )}
                    <input multiple type="file" onChange={async (e) => {
                        //console.log(videos);
                        
                        if (e.target.files[0]) {
                            setLoadingVideos(true)
                            const newVids =[...videos]
                            for (let i = 0; i < e.target.files.length; i++) {
                                const element = e.target.files[i];
                                //console.log(element);
                                
                                try {
                                    var fileUrl = await uploadFile(element)
                                    //console.log(fileUrl);
                                    
                                    newVids.unshift(fileUrl)
                                    setVideos(newVids)
                                } catch (error) {
                                    Store.addNotification({
                                        title: t("SingIn.Errors"),
                                        message: t("Dashboard.CharityPage.DownloadFailed"),
                                        type: "danger",
                                        insert: "top",
                                        container: "top-center",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                            duration: 10000,
                                            onScreen: true
                                        }
                                        });                                 
                                }
                            }
                            setLoadingVideos(false)
                        }
                    }} accept="video/mp4" ref={inputOpenFileRefVideos} style={{ display: 'none' }}/>
                     <div style={{maxWidth: '55%'}} className="row">
                    {videos.map((video, idx) =>
                        <div>
                            <video controls className="videoPreview"  src={video} />
                            <div onClick={() => {
                                const newVids =[...videos]
                                newVids.splice(idx, 1)
                                setVideos(newVids)
                            }} className={'delBtn'}>{t("Dashboard.CharityPage.PhotosDelete")}</div>
                        </div>
                        )}
                        </div>
                </div>
                <div className="dashBtns">
                    <div onClick={async () => {
                        // if (!description) {
                        //     Store.addNotification({
                        //         title: "Erreur",
                        //         message: "Merci de remplir la description.",
                        //         type: "danger",
                        //         insert: "top",
                        //         container: "top-center",
                        //         animationIn: ["animate__animated", "animate__fadeIn"],
                        //         animationOut: ["animate__animated", "animate__fadeOut"],
                        //         dismiss: {
                        //           duration: 10000,
                        //           onScreen: true
                        //         }
                        //       });
                        // }
                        var errorFields = []
                        !description && errorFields.push(`Description`)
                        !article && errorFields.push(`Article`)
                        !url && errorFields.push(`URL`)
                        !logo && errorFields.push(`Logo`)
                        !pic && errorFields.push(t("Dashboard.CharityPage.ErrorPoster"))
                        !title && errorFields.push(t("Dashboard.CharityPage.ErrorTitle"))

                        if (errorFields.length > 0) {
                            Store.addNotification({
                                title:  t("SingIn.Errors"),
                                message: t("Dashboard.CharityPage.FillFields") + errorFields.join(', '),
                                type: "danger",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                  duration: 10000,
                                  onScreen: true
                                }
                              });
                        }
                        else {
                            if (!userState.collect) {
                                var available = await getLinkAvailability(url)
                                if (!available.available) {
                                    Store.addNotification({
                                        title: t("SingIn.Errors"),
                                        message: t("Dashboard.CharityPage.LinkError"),
                                        type: "danger",
                                        insert: "top",
                                        container: "top-center",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                          duration: 10000,
                                          onScreen: true
                                        }
                                      });
                                    //console.log(available);
                                    return;
                                }
                                var collect = await createCollect(url, pic, sharingImage, title, description, article, pictures, videos, logo, name, userState.user.id)
                                setUserState({...userState, collect: collect})
                                Store.addNotification({
                                    title: t("Dashboard.CharityPage.Success"),
                                    message: t("Dashboard.CharityPage.NotPublished"),
                                    type: "success",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                      duration: 10000,
                                      onScreen: true
                                    }
                                  });
                            } else {
                                if (url != userState.collect.linkParam) {

                                    var available = await getLinkAvailability(url)
                                    if (!available.available) {
                                        Store.addNotification({
                                            title: t("SingIn.Errors"),
                                            message: t("Dashboard.CharityPage.LinkError"),
                                            type: "danger",
                                            insert: "top",
                                            container: "top-center",
                                            animationIn: ["animate__animated", "animate__fadeIn"],
                                            animationOut: ["animate__animated", "animate__fadeOut"],
                                            dismiss: {
                                            duration: 10000,
                                            onScreen: true
                                            }
                                        });
                                        //console.log(available);
                                        return;
                                    }
                                }
                                await updateCollect(url, pic, sharingImage, title, description, article, pictures, videos, logo, name, userState.user.id)
                                Store.addNotification({
                                    title: t("Dashboard.CharityPage.Success"),
                                    message: t("Dashboard.CharityPage.UpdatePage"),
                                    type: "success",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                      duration: 10000,
                                      onScreen: true
                                    }
                                  });
                            }
                            await publishCollect();
                        }
                    }} className={'newBtnNext2'}>{t("Dashboard.CharityPage.Enter")}</div>
                    <div className={'newBtnNext2'} onClick={() => {
                        var medias = []
                        for (let i = 0; i < pictures.length; i++) {
                            const element = pictures[i];
                            medias.push({type: 'PICTURE', url: element})
                        }
                        for (let i = 0; i < videos.length; i++) {
                            const element = videos[i];
                            medias.push({type: 'VIDEO', url: element})
                        }
                        var assoc = {
                            accepted: true,
                            block: false,
                            status: 'active',
                            associationLogoUrl: logo,
                            associationName: name,
                            content: [{content: article}],
                            description: [{content: description}],
                            funding: [],
                            linkParam: url,
                            medias: medias,
                            poster: [{url: pic, type: pic && pic.indexOf('.mp4') > 0 ? 'VIDEO' : 'PICTURE'}],
                            sharingImage: sharingImage,
                            published: true,
                            sponsors: [],
                            title: [{content: title}]
                        }
                        setUserState({...userState, assocVisualize: assoc})
                        window.open('/association/visualize', '_blank').focus();
                        //history.push('/association/visualize?assoc=' + JSON.stringify(assoc))
                    }}>{t("Dashboard.CharityPage.Visualize")}</div>
                </div>
            </div>
        </div>
        </>
        )}
        {subDiv == 3 &&(
            <>
            <div className="newBtnNext2" onClick={()=>setSubDiv(1)}>Retour</div>
                <CreateCampagne campain={null} userState={userState} setPart={1} setUserState={setUserState} />
                </>
        )}
        {subDiv == 4 &&(
             <>
             <div className="newBtnNext2" onClick={()=>setSubDiv(1)}>Retour</div>
                <CreateBilletterie campain={null} userState={userState} setPart={1} setUserState={setUserState} />
                </>
        )}
        </>
    )

}
