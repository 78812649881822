import styles from "./index.css";
import ContactForm from "../../Components/ContactForm";
import { useTranslation } from "react-i18next";
import { InlineWidget } from "react-calendly";

export default function Login({ history }) {
  const { t } = useTranslation();

  return (
    <div className={"contactContainer"}>
      <ContactForm
        title={t("AssociationPage.FormQuestion")}
        desc={t("Charity.Contact")}
        history={history}
      />
      <div style={{marginTop: "60px"}}>
        <InlineWidget url="https://calendly.com/charityclik/30min" />
      </div>
    </div>
  );
}
