import {useRef, useState, useEffect, useContext} from "react"
import { getPageByUrl } from '../Services/ws';
import { UserContext } from "../Context/UserContext";
import dynamicLoader from 'react-dynamic-loader'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import data from '../assets/cgu.json'

export default function CGU({history}) {
    const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(data)))

    return <div style={{width: '60%', marginLeft: 'auto', marginRight: 'auto', marginTop: 30}}>
        <Editor
            editorState={editorState}
            toolbarHidden
            readOnly
            onEditorStateChange={(editorState) => {
                setEditorState(editorState)
                //console.log(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
                
                
            }}

        />
    </div>
}
