import React, {useState, useEffect} from 'react';
import styles from './index.css';
import add from '../../assets/add.png'
import CreateCampagne from './CreateCampagne.js'
import AddParrains from './AddParrains.js'
import { numberWithSpaces } from '../../Services/utils';
import {getCollect, getMyCampains,getPaired} from '../../Services/ws'
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import Plus2 from '../../assets/New/plus2.png'

const moment = require('moment');

var fx = require("money");

fx.base = "EUR";
fx.rates = {
    "USD" : 1.19, // eg. 1 USD === 0.745101 EUR
    "GBP" : 0.86, // etc...
    "ILS" : 3.89,
    "EUR" : 1,        // always include the base rate (1:1)
    /* etc */
}

export default function Campagne({userState, history, setUserState, reload}) {
    const [part, setPart] = useState(0);
    const [selectCampain, setSelectCampain] = useState(null);
    const [pairedCampain,setPairedCampain] = useState(null);
    const [ locationKeys, setLocationKeys ] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        ////console.log(reload);
        setPart(0)
        getMyCampains().then((result) => {
            ////console.log('campains', result);
            setUserState({...userState, campains: result})
        })
        const getPairedCampains = async () =>{
            const pairedTemp = await getPaired(userState.association.id);
            setPairedCampain(pairedTemp.body)
        }
        getPairedCampains()
    }, [reload])
    
    useEffect(()=>{

    },[])


    return (
        <>
        {
            part === 0 &&
            <div>
                {/* <p className="dashTitle">{t("Dashboard.CampaignPage")}</p> */}
                <div className="row" onClick={() => {
                    setSelectCampain(null)
                    setPart(1)
                    }}>
                    <img className="newDashAddNo" src={Plus2} alt="add"  />
                    <p style={{color:"#5c3748",fontWeight:"bold",fontSize: isMobile ? '14px' : '30px'}}>{t("Dashboard.CampaignPage.AddNew")}</p>
                    <div className={'newBtnNext3'}>{t("Dashboard.CampaignPage.Access")}</div>
                </div>
                <div className="dashInput2-new">
                    
                    <p className="newDashFormTitle">{t("Dashboard.CampaignPage.IncomingCampaigns")}</p>
                    {/* <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.CampaignPage.Campaigns")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>
                        
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div> */}
                    {userState.campains && userState.campains.filter((camp) => new Date(camp.startingAt?camp.startingAt:"now") > new Date()).slice(0).reverse().map((camp,index) => {
                        var total = 0;
                        var currency = 'eur';
                        if (camp.funding) {
                            for (let i = 0; i < camp.funding.length; i++) {
                                const element = camp.funding[i];
                                total += fx(element.amount).from(element.currency.toUpperCase()).to('EUR');
                            }
                        }
                        return (
                            <div key={index} style={{borderTop:index!=0?"1px solid #5c3748":null, margin:index!=0?"50px 0px":"0px 0px"}}>
                            <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.CampaignPage.Campaigns")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>
                        
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div>
                                <div className="newRowCamp">
                                    <p className="newDashCamptxt">{camp.title[0].content} {camp.draft === "yes"?t("Dashboard.CampaignPage.Draft"):null}</p>
                                    <p className="newDashCamptxt">{numberWithSpaces(total)}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.startingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.endingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{new Date() >= new Date(camp.startingAt) ? t("Dashboard.CampaignPage.CampaignInProgress") : t("Dashboard.CampaignPage.CampaignFuture") }</p>
                                </div>
                                <div className="newRowBtn">
                                    <div className="newBtnNext3" onClick={() => {
                                        setPart(2)
                                        setSelectCampain(camp)
                                        }}>{t("Dashboard.CampaignPage.AddSponsors")}</div>
                                    <div className="newBtnNext3" onClick={() => {
                                        setSelectCampain(camp)
                                        setPart(1)
                                    }}>Modifier</div>
                                    <div onClick={() => {
                                        window.open(`/${camp.linkParam}`).focus();
                                    }} className="newBtnNext3">{t("Dashboard.CharityPage.Visualize")}</div>
                                </div>
                            </div>
                        );
                    })}
                    {pairedCampain && pairedCampain.filter((camp) => new Date(camp.endingAt) > new Date(camp.startingAt) > new Date()).slice(0).reverse().map((camp,index)=>{
                        var total = 0;
                        var currency = 'eur';
                        if (camp.funding) {
                            for (let i = 0; i < camp.funding.length; i++) {
                                const element = camp.funding[i];
                                total += fx(element.amount).from(element.currency.toUpperCase()).to('EUR');
                            }
                        }
                        return (
                            <div key={index} style={{borderTop:index!=0?"1px solid #5c3748":null, margin:index!=0?"50px 0px":"0px 0px"}}>
                                <div className="newRowCamp">
                                    <p className="newDashCamptxt">{camp.title[0].content} {camp.draft === "yes"?t("Dashboard.CampaignPage.Draft"):null}</p>
                                    <p className="newDashCamptxt">{numberWithSpaces(total)}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.startingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.endingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{new Date() >= new Date(camp.startingAt) ? t("Dashboard.CampaignPage.CampaignInProgress") : t("Dashboard.CampaignPage.CampaignFuture") }</p>
                                </div>
                                <div className="newRowBtn">
                                    <div className="newBtnNext3" onClick={() => {
                                        setPart(2)
                                        setSelectCampain(camp)
                                        }}>{t("Dashboard.CampaignPage.AddSponsors")}</div>
                                        {/* Partie jumelage */}
                                        <div className="newBtnNext3">Jumelage</div>
                                    {/* <div className="btnCenterDash btnGeneral" onClick={() => {
                                        setSelectCampain(camp)
                                        setPart(1)
                                    }}>Modifier</div> */}
                                    <div onClick={() => {
                                        window.open(`/${camp.linkParam}`).focus();
                                    }} className="newBtnNext3">{t("Dashboard.CharityPage.Visualize")}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="dashInput2-new">
                    
                    <p className="newDashFormTitle">{t("Dashboard.CampaignPage.CurrentCampaigns")}</p>
                    {/* <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.CampaignPage.Campaigns")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>
                        
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div> */}
                    {userState.campains && userState.campains.filter((camp) => new Date(camp.endingAt) > new Date() && new Date(camp.startingAt)<= new Date()).slice(0).reverse().map((camp,index) => {
                        var total = 0;
                        var currency = 'eur';
                        if (camp.funding) {
                            for (let i = 0; i < camp.funding.length; i++) {
                                const element = camp.funding[i];
                                total += fx(element.amount).from(element.currency.toUpperCase()).to('EUR');
                            }
                        }
                        return (
                            <div key={index} style={{borderTop:index!=0?"1px solid #5c3748":null, margin:index!=0?"50px 0px":"0px 0px"}}>
                            <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.CampaignPage.Campaigns")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>
                        
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div>
                                <div className="newRowCamp">
                                    <p className="newDashCamptxt">{camp.title[0].content} {camp.draft === "yes"?t("Dashboard.CampaignPage.Draft"):null}</p>
                                    <p className="newDashCamptxt">{numberWithSpaces(total)}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.startingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.endingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{new Date() >= new Date(camp.startingAt) ? t("Dashboard.CampaignPage.CampaignInProgress") : t("Dashboard.CampaignPage.CampaignFuture") }</p>
                                </div>
                                <div className="newRowBtn">
                                    <div className="newBtnNext3" onClick={() => {
                                        setPart(2)
                                        setSelectCampain(camp)
                                        }}>{t("Dashboard.CampaignPage.AddSponsors")}</div>
                                    <div className="newBtnNext3" onClick={() => {
                                        setSelectCampain(camp)
                                        setPart(1)
                                    }}>Modifier</div>
                                    <div onClick={() => {
                                        window.open(`/${camp.linkParam}`).focus();
                                    }} className="newBtnNext3">{t("Dashboard.CharityPage.Visualize")}</div>
                                </div>
                            </div>
                        );
                    })}
                    {pairedCampain && pairedCampain.filter((camp) => new Date(camp.endingAt) > new Date() && new Date(camp.startingAt)<= new Date()).slice(0).reverse().map((camp,index)=>{
                        var total = 0;
                        var currency = 'eur';
                        if (camp.funding) {
                            for (let i = 0; i < camp.funding.length; i++) {
                                const element = camp.funding[i];
                                total += fx(element.amount).from(element.currency.toUpperCase()).to('EUR');
                            }
                        }
                        return (
                            <div key={index} style={{borderTop:index!=0?"1px solid #5c3748":null, margin:index!=0?"50px 0px":"0px 0px"}}>
                                <div className="newRowCamp">
                                    <p className="newDashCamptxt">{camp.title[0].content} {camp.draft === "yes"?t("Dashboard.CampaignPage.Draft"):null}</p>
                                    <p className="newDashCamptxt">{numberWithSpaces(total)}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.startingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.endingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{new Date() >= new Date(camp.startingAt) ? t("Dashboard.CampaignPage.CampaignInProgress") : t("Dashboard.CampaignPage.CampaignFuture") }</p>
                                </div>
                                <div className="newRowBtn">
                                    <div className="newBtnNext3" onClick={() => {
                                        setPart(2)
                                        setSelectCampain(camp)
                                        }}>{t("Dashboard.CampaignPage.AddSponsors")}</div>
                                        {/* Partie jumelage */}
                                        <div className="newBtnNext3">Jumelage</div>
                                    {/* <div className="btnCenterDash btnGeneral" onClick={() => {
                                        setSelectCampain(camp)
                                        setPart(1)
                                    }}>Modifier</div> */}
                                    <div onClick={() => {
                                        window.open(`/${camp.linkParam}`).focus();
                                    }} className="newBtnNext3">{t("Dashboard.CharityPage.Visualize")}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="dashInput2-new">
                    <p className="newDashFormTitle">{t("Dashboard.CampaignPage.CompletedCampaigns")}</p>
                    {/* <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.CampaignPage.Campaigns")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>

                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div> */}
                    {userState.campains && userState.campains.filter((camp) => new Date(camp.endingAt) <= new Date()).slice(0).reverse().map((camp,index) => {
                        var total = 0;
                        var currency = 'eur';
                        if (camp.funding) {
                            for (let i = 0; i < camp.funding.length; i++) {
                                const element = camp.funding[i];
                                total += fx(element.amount).from(element.currency.toUpperCase()).to('EUR');
                            }
                        }
                        return (
                            <div key={index} style={{borderTop:index!=0?"1px solid #5c3748":null, margin:index!=0?"50px 0px":"0px 0px"}}>
                            <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.CampaignPage.Campaigns")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>

                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div>
                                <div className="newRowCamp">
                                    <p className="newDashCamptxt">{camp.title[0].content}</p>
                                    <p className="newDashCamptxt">{numberWithSpaces(total)}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.startingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.endingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{t("Dashboard.CampaignPage.CampaignEnded")}</p>
                                </div>
                                <div className="newRowBtn">
                                    {/* <div className="btnLeftDash btnGeneral" onClick={() => {
                                        setPart(2)
                                        setSelectCampain(camp)
                                        }}>Ajouter Parrains</div>
                                    <div className="btnCenterDash btnGeneral" onClick={() => {
                                        setSelectCampain(camp)
                                        setPart(1)
                                    }}>Modifier</div> */}
                                    <div onClick={() => {
                                        window.open(`/${camp.linkParam}`).focus();
                                    }} className="newBtnNext3">{t("Dashboard.CharityPage.Visualize")}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        }
        {
            part === 1 &&
            <CreateCampagne campain={selectCampain} userState={userState} setPart={setPart} setUserState={setUserState} />
        }
        {
            part === 2 &&
            <AddParrains campain={selectCampain} userState={userState} setUserState={setUserState} updateCampain={(camp) => setSelectCampain(camp)} />
        }
        </>
    )
}
