import React, {useState, createRef, useEffect} from 'react';
import styles from './index.css';
import add from '../../assets/add.png'
import { getSettings, updateAccountData } from '../../Services/ws';
import { useTranslation } from "react-i18next";
import Modal from "../../Components/Elements/Modal/Modal";
import DownloadButton from "../../Components/Elements/DownloadButton/DownloadButton";
import { useStripe } from '@stripe/react-stripe-js';

export default function Settings({userState}) {
    const [edit, setEdit] = useState(false);
    const [accountId, setAccountId] =  useState('');
    const [director, setDirector] = useState('');
    const [assoName, setAssoName] = useState('');
    const [usage, setUsage] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const inputOpenFileRef = createRef();
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [countryShort, setCountryShort] = useState('');
    const [postal, setPostal] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankIban, setBankIban] = useState('');
    const [bankBic, setBankBic] = useState('');
    const [password, setPassword] = useState('');
    const [conf, setConf] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [massage, setMessage] = useState('');
    let banks = [{name: '', address: '', iban: '', bic: ''}];
    const { t } = useTranslation();
    const stripe = useStripe();

    useEffect(() => {
        getSettings().then((settings) => {
            // console.log(settings);
            setAccountId(settings.gateway.accountId)
            setAssoName(settings.gateway.nonProfitName)
            setPhone(settings.gateway.phone)
            setDirector(settings.user.firstName + ' ' + settings.user.lastName)
            setEmail(settings.user.email)
            setAddress(settings.gateway.address.lineOne)
            setPostal(settings.gateway.address.zipCode)
            setCity(settings.gateway.address.city)
            setCountry('France')
            setBankName(settings.gateway.bankAccount.name)
            setBankIban('** **** **** **** **** ' + settings.gateway.bankAccount.accountNumber)
            setBankBic(settings.gateway.bankAccount.routingNumber)
        })
    }, [userState.collect])

    const updateData = async () => {
        const accountResult = await stripe.createToken('account', {
            company: {
                name: assoName,
                address: {
                    city: city,
                    country: countryShort,
                    line1: address,
                    postal_code: postal,
                },
                phone: phone,
            }
        });

        const dataChanges = {
            "company": {
                "name": assoName,
                "address": {
                    "city": city,
                    "country": countryShort,
                    "line1": address,
                    "postal_code": postal,
                },
                "phone": phone,
            },
            "email": email
        }

        const data = {
            token: accountResult,
            info: dataChanges
        }
        const updated = await updateAccountData(`/association/update-data/${accountId}`, data);
        if(updated) {
            if(updated.id) {
                setMessage('Information was updated successfully')
            } else {
                setMessage('Something went wrong')
            }
            setEdit(false)
            setModalOpen(true)
        } else {
            console.log('Error')
        }
    }

    const handleCountry = (value) => {
        setCountry(value);
        switch(value) {
            case 'France':
                setCountryShort('FR');
                break;
            default:
                setCountryShort('FR');
                break;
        }
    }

    const modalText = () => {
        return (
            <>
                <p>{massage}</p>
                <div style={{textAlign: 'center'}}>
                    <DownloadButton buttonStyle={'primary'} action={() => setModalOpen(false)} name={'OK'}/>
                </div>
            </>
        )
    }

    return (
        <div>
            <p className="dashTitle">{t("Dashboard.Settings.Charity")}</p>
            <div className="">
                <div className="dashTitleRow">
                    <p className="dashFormTitle">{t("Dashboard.Settings.Account")}</p>
                    <DownloadButton name={'Edit'} action={() => setEdit(true)} />
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Settings.OfficialName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled={edit ? false : true} className="dashInput disabled" type="text" placeholder={t("Dashboard.Settings.OfficialName")} value={assoName} onChange={(evt) => {setAssoName(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Settings.DirectorName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled={edit ? false : true} className="dashInput disabled" type="text" placeholder={t("Dashboard.Settings.DirectorName")} value={director} onChange={(evt) => {setDirector(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Settings.Phone")}</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled={edit ? false : true} className="dashInput disabled" type="text" placeholder="06 97 89 67 67" value={phone} onChange={(evt) => {setPhone(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">Email</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled={edit ? false : true} className="dashInput disabled" type="text" placeholder="monoassociation@gmail.com" value={email} onChange={(evt) => {setEmail(evt.target.value)}} />
                    </div>
                </div>
               
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Settings.CharityAddress")}</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled={edit ? false : true} className="dashInput disabled" type="text" placeholder="45 rue du petit" value={address} onChange={(evt) => {setAddress(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Settings.ZipCode")}</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled={edit ? false : true} className="dashInput disabled" type="text" placeholder="75008" value={postal} onChange={(evt) => {setPostal(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Settings.City")}</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled={edit ? false : true} className="dashInput disabled" type="text" placeholder="Paris" value={city} onChange={(evt) => {setCity(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Settings.Country")}</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled={edit ? false : true} className="dashInput disabled" type="text" placeholder="France" value={country} onChange={(evt) => {handleCountry(evt.target.value)}} />
                    </div>
                </div>

                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Settings.BankName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled className="dashInput disabled" type="text" placeholder={t("Dashboard.Settings.BankName")} value={bankName} />
                    </div>
                </div>

                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">IBAN</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled className="dashInput disabled" type="text" placeholder="** **** **** **** ****" value={bankIban}  />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">BIC</p>
                    </div>
                    <div className="dashColInput">
                        <input disabled className="dashInput disabled" type="text" placeholder="MIZTALLITGXXX" value={bankBic}/>
                    </div>
                </div>

                {edit &&
                    <DownloadButton name={'Send'} action={() => updateData()} />
                }
            </div>

            {modalOpen &&
                <Modal modalContent={modalText()} modalClose={() => setModalOpen(false)}/>
            }
        </div>
    )
}
