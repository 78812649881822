import React, {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext";

import styles from './index.css';
import Don from './Don.js'
import Campagne from './Campagne.js'
import Billeterie from './Billetterie.js'
import Donateur from './Donateur.js'
import Donations from './Donations.js'
import Settings from './Settings.js'
import Treasury from './Treasury.js'
import Facture from './Facture.js'
import Documents from './Documents'
import {getCollect, getMyCampains} from '../../Services/ws'
import Modal from 'react-modal';
import { isMobile } from 'react-device-detect';
import { useTranslation} from "react-i18next";

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius: 20
    }
  };
Modal.setAppElement('#root')

export default function Dashboard({history}) {
    const [menu, setMenu] = useState(0);
    const [userState, setUserState] = useContext(UserContext);
    const [reload, setReload] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [isBlocked, setBlockStatus] = useState(false);
    const { t } = useTranslation()

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
    }
  
    function closeModal(){
      setIsOpen(false);
    }

    useEffect(() => {
        getCollect().then((collect) => {
            // console.log(collect);
            // setUserState({...userState, collect: collect})
            getMyCampains().then((result) => {
                // console.log('campains', result);
                setUserState({...userState, campains: result, collect: collect})
                setBlockStatus(userState.association.block)
            })    
        })
    }, [])

    useEffect(() => {
        window.scrollTo(0,0)
        // console.log(history.location);
        const urlParams = new URLSearchParams(history.location.search);
        const myParam = urlParams.get('menu');
        if (myParam) {
            setMenu(parseInt(myParam))
            setReload(!reload)
        }
        // console.log(myParam);
        
    }, [history.location]);

    // console.log(userState.gatewayRequirements.requirements);

    // if (userState.gatewayRequirements.requirements.length > 0) {
    //     return (
    //     <div className="dashbooardContainer">
    //         <div className="dashbooardMenu">
    //             <img className="menuImg" src={userState.association.logoUrl || test} alt="logo" />
    //             <p className="imgSub">{userState.association.usageName}</p>
    //             <p style={{marginTop: 'auto', marginBottom: 150}} className={menu === 6 ? "newMenuSelected" : "newMenu"} onClick={() => {
    //                 setUserState({association: null, user: null, accessToken: null});
    //                 history.push('/login')
    //             }}>Déconnexion</p>
    //         </div>
    //         <div style={{fontSize: 30, justifyContent: 'center', alignItems: 'center', display: 'flex'}} className="menuDetail">
    //             Votre compte est en cours de validation
    //         </div>
    //     </div>
    //     )
    // }
    // console.log(localStorage.getItem('refreshToken'));
    
    if (!userState || !userState.user || !userState.association || localStorage.getItem('refreshToken') == 'null') {
        history.push('/login')
        return <div />
    }

    return (
        <div className="dashbooardContainer">
             <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            >

            <div className="dashFormTitle">{t('Dashboard.LogoutText')}</div>

                <div style={{marginRight: 0}} className="smllRow">
                    <div className={'smbtn btnGeneral'} onClick={async () => {
                        setUserState({association: null, user: null, accessToken: null});
                         history.push('/login')
                    }}>{t("Dashboard.LogoutConfirm")}</div>
                    <div onClick={async () => {setIsOpen(false)}} className={'smbtn btnGeneral'}>{t("Dashboard.LogoutCancel")}</div>
                </div>
            </Modal>
            {!isMobile &&
                <div className="newDashbooardMenu">
                    <img className="newMenuImg" src={userState.association.logoUrl} alt="logo" />
                    <div className="newImgSub">
                        {userState.association.usageName}
                        {isBlocked &&
                            <p className={"imgStatus"}>{t("Dashboard.Blocked")}</p>
                        }
                    </div>

                    <p className={menu === 0 ? "newMenuSelected" : "newMenu"} onClick={() => {
                            setMenu(0)
                        }}>{t("Dashboard.CharityPage")}</p>

                    {!isBlocked &&
                        <p className={menu === 1 ? "newMenuSelected" : "newMenu"} onClick={() => {
                            setReload(!reload);
                            setMenu(1)
                        }}>{t("Dashboard.CampaignPage")}</p>
                    }

                    {!isBlocked &&
                        <p className={menu === 2 ? "newMenuSelected" : "newMenu"} onClick={() => {
                            setReload(!reload);
                            setMenu(2)
                        }}>{t("Dashboard.Billetterie")}</p>
                    }

                    {!isBlocked &&
                        <p className={menu === 3 ? "newMenuSelected" : "newMenu"} onClick={() => {
                            setMenu(3)
                        }}>{t("Dashboard.Donors")}</p>
                    }

                    {!isBlocked &&
                        <p className={menu === 4 ? "newMenuSelected" : "newMenu"} onClick={() => {
                            setMenu(4)
                        }}>{t("Dashboard.Donations")}</p>
                    }

                    {!isBlocked &&
                        <p className={menu === 5 ? "newMenuSelected" : "newMenu"} onClick={() => {setMenu(5)}}>{t("Dashboard.Invoice")}</p>
                    }

                    {!isBlocked &&
                        <p className={menu === 6 ? "newMenuSelected" : "newMenu"} onClick={() => {
                            setMenu(6)
                        }}>{t("Dashboard.Treasury")}</p>
                    }

                    {!isBlocked &&
                        <p className={menu === 7 ? "newMenuSelected" : "newMenu"} onClick={() => {
                            setMenu(7)
                        }}>{t("Dashboard.Settings")}</p>
                    }

                    {!isBlocked &&
                    <p className={menu === 8 ? "newMenuSelected" : "newMenu"} onClick={() => {
                        setMenu(8)
                    }}>{t("Dashboard.Documents")}</p>
                    }

                    <p className={menu === 9 ? "newMenuSelected" : "newMenu"} onClick={() => {
                        openModal()
                    }}>{t("Dashboard.Disconnect")}</p>
                </div>
            }

            <div className="menuDetail">
                {
                    menu === 0 && <Don userState={userState} history={history} setUserState={setUserState} setShowWarning={setShowWarning} />
                }
                {
                    menu === 1 && !isBlocked && <Campagne userState={userState} setUserState={setUserState} history={history} reload={reload}/>
                }
                {
                    menu === 2 && !isBlocked && <Billeterie userState={userState} setUserState={setUserState} history={history} reload={reload}/>
                }
                {
                    menu === 3 && !isBlocked && <Donateur userState={userState} />
                }
                {
                    menu === 4 && !isBlocked && <Donations userState={userState} />
                }
                {
                    menu === 5 && !isBlocked && <Facture userState={userState} />
                }
                {
                    menu === 6 && !isBlocked && <Treasury userState={userState} />
                }
                {
                    menu === 7 && !isBlocked && <Settings userState={userState} />
                }
                {
                    menu === 8 && !isBlocked && <Documents userState={userState} />
                }
            </div>
        </div>
    )
}
