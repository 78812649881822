import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
    order: ['navigator'],
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: options,
        resources: {
            en: {
                translations: require('./locales/en/translation.json')
            },
            fr: {
                translations: require('./locales/fr/translation.json')
            }
        },
        ns: ['translations'],
        defaultNS: 'translations',
        fallbackLng: 'en',
        supportedLngs: ['fr', 'en'],
});

export default i18n;