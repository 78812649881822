import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import Association from './Association';
import Campaigns from "./Campaigns";
import Billetteries from "./Billetteries";
import Donations from "./Donations";
import Treasury from "./Treasury";
import MyBills from "./MyBills";
import Statistics from "./Statistics";
import Sidebar from './Sidebar';

import styles from './index.module.css'

export default function BackOffice() {
  //const history = useHistory();
  const { user } = JSON.parse(localStorage.getItem('userState'));
  const isAdmin = user.roles.find(r=> r.role === 'admin');

  useEffect(() => {
    if (!isAdmin) window.location.assign('/')
  }, [])

  return (
    <div>
      <div className={styles.mainContainer}>
        <Sidebar />
        <div className={styles.pageContent}>
          <Route path="/back-office/donations" component={Donations}/>
          <Route path="/back-office/campaigns" component={Campaigns} />
          <Route path="/back-office/billetteries" component={Billetteries} />
          <Route path="/back-office/association" component={Association}/>
          <Route path="/back-office/treasury" component={Treasury}/>
          <Route path="/back-office/my-bills" component={MyBills}/>
          <Route path="/back-office/statistics" component={Statistics}/>
        </div>
      </div>
    </div>
  )
}