import React, {useEffect, useState} from 'react';
import styles from './index.css';
import { getTreasury } from '../../Services/ws';
import { numberWithSpaces } from '../../Services/utils';
import { isMobile } from 'react-device-detect';

export default function Treasury() {
    const [treasury, setTreasury] = useState(null)
    useEffect(() => {
        getTreasury().then((res) => {
            setTreasury(res)
        })
    }, [])
    return (
            <div>
                <p className="dashTitle">Trésorerie</p>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{height: 50, marginTop: 'auto', marginBottom: 'auto'}} className="priceRow">
                        <p style={{marginLeft: 'auto'}} className="priceTxt">En caisse</p>
                        <div className="line"/>
                        <p className="priceTxt" style={{fontWeight: '600'}}>{numberWithSpaces(treasury ? treasury.available[0].amount : 0)}</p>
                        <p style={{fontSize: isMobile ? 14 : 20, marginLeft: 'auto'}} className="priceTxt">En attente</p>
                        <div style={{height: isMobile ? 14 : 22}} className="line"/>
                        <p  className="priceTxt" style={{fontWeight: '600', fontSize: isMobile ? 14 : 20}}>{numberWithSpaces(treasury ? treasury.pending[0].amount : 0)}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', width: 600}}>
                        <p style={{marginLeft: 'auto', marginRight: 'auto', color: 'black', textAlign: 'center'}} className="priceTxt">Effectuer un virement</p>
                        <input style={{backgroundColor: 'white', outline: 'none', borderRadius: 60, height: 64, fontSize: 20, textAlign: 'center', marginTop: 22}} type="text" placeholder="Inscrivez le montant à virer"onChange={(evt) => {
                        }} />
                        <div style={{marginTop: 12, marginLeft: 'auto', marginRight: 'auto', marginBottom: 20}} className="btn btnGeneral">Valider</div>
                    </div>
                </div>
                <div className="dashForm">
                    <p className="dashFormTitle">Versements reçus</p>
                    <div className="rowSub">
                        <p className="dashSub">Date</p>
                        <p className="dashSub">Montant</p>
                        <p className="dashSub">Banque</p>
                        <p className="dashSub">Coordonnéee bancairee</p>
                    </div>
                    <div className="rowCamp">
                        <p className="dashCamptxt">23/01/2021</p>
                        <p className="dashCamptxt">4000€</p>
                        <p className="dashCamptxt">Société générale</p>
                        <p className="dashCamptxt">FR 233 445 556 667</p>
                    </div>
                    <div className="rowCamp">
                        <p className="dashCamptxt">23/01/2021</p>
                        <p className="dashCamptxt">4000€</p>
                        <p className="dashCamptxt">Société générale</p>
                        <p className="dashCamptxt">FR 233 445 556 667</p>
                    </div>
                    <div className="rowCamp">
                        <p className="dashCamptxt">23/01/2021</p>
                        <p className="dashCamptxt">4000€</p>
                        <p className="dashCamptxt">Société générale</p>
                        <p className="dashCamptxt">FR 233 445 556 667</p>
                    </div>
                    <div className="rowCamp">
                        <p className="dashCamptxt">23/01/2021</p>
                        <p className="dashCamptxt">4000€</p>
                        <p className="dashCamptxt">Société générale</p>
                        <p className="dashCamptxt">FR 233 445 556 667</p>
                    </div>
                    <div className="rowCamp">
                        <p className="dashCamptxt">23/01/2021</p>
                        <p className="dashCamptxt">4000€</p>
                        <p className="dashCamptxt">Société générale</p>
                        <p className="dashCamptxt">FR 233 445 556 667</p>
                    </div>
                </div>
            </div>

    )
}
