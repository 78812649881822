import React, {useState, createRef, useEffect, useContext} from 'react';
import styles from './index.css';
import searchIcon from '../../assets/search-solid.svg'
import { Divider } from '@material-ui/core';
import { UserContext } from "../../Context/UserContext";
import { getAssocDonations, createManualPayment, getAssocDonationsByName, getExcel } from '../../Services/ws';
import Select from 'react-select'
import { store } from 'react-notifications-component';
import validator from 'validator';
import { numberWithSpaces } from '../../Services/utils';
import PhoneInput from 'react-phone-input-2'
import moment from 'moment';

const options = [
    { label: 'EUR €', value: 'eur' },
    { label: 'USD $', value: 'usd' },
    { label: 'ILS ₪', value: 'ils' },
    { label: 'GBP £', value: 'gbp' },
  ]

export default function Donateur({}) {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('');
    const [postal, setPostal] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [phone, setPhone] = useState('');
    const [search, setSearch] = useState('');
    const inputOpenFileRef = createRef();
    const [userState, setUserState] = useContext(UserContext);
    const [amount, setAmount] = useState(null)
    const [currency, setCurrency] = useState(options[0])
    const [campain, setCampain] = useState(null)
    const [isCash, setIsCash] = useState(true)
    const [isAnonymous, setIsAnonymous] = useState(null)

    const [campainOptions, setCampainOptions] = useState([])
    const [donations, setDonations] = useState([])
    const [pageNb, setPageNb] = useState(0)

    useEffect(() => {
        // //console.log(userState);
        // getAssocDonations(0).then((res) => {
        //     //console.log(res);
        //     setDonations(res || [])
        // })
        // var campOpts = []
        // if (userState.collect && userState.collect.id) {
        //     campOpts.push({label: userState.collect.title[0].content, value: userState.collect.id})
        // }
        // if (userState.campains) {
        //     for (let i = 0; i < userState.campains.length; i++) {
        //         const element = userState.campains[i];
        //         campOpts.push({label: element.title[0].content, value: element.id})
        //     }
        // }
        // setCampainOptions(campOpts)
    }, [])

    useEffect(() => {

        if (search.length >= 1) {
            getAssocDonationsByName(pageNb, search).then((res) => {
                setDonations(res || [])
            })
        } else {
            ////console.log('pageNb', pageNb*5);

            getAssocDonations(pageNb).then((res) => {
                setDonations(res || [])
            })
        }
    }, [search, pageNb])

    return (
        <div>
            <div className="row">
                <div className="DonSearchBar">
                    <img className="DonIcon" src={searchIcon} alt="search" />
                    <input className="DonInput" type="text" placeholder="Rechercher un donateur" value={search} onChange={(evt) => {
                        //onsole.log('setsearch', evt.target.value);

                        setSearch(evt.target.value)
                        }} />
                </div>
                <div onClick={() => {
                    ////console.log('getExcel');

                    getExcel()
                }} style={{borderRadius: 60}} className="btnLeftDash btnGeneral">Excel</div>
                {/* <div className="btnRightDash btnGeneral">PDF</div> */}
            </div>
            <div className="col">
            <table style={{width: '90%', overflowX: 'scroll', whiteSpace: 'nowrap', display: 'block'}}>
                <thead>
                    <tr>
                        <th>Origine</th>
                        <th>Nom de la page</th>
                        <th>Nº Cerfa</th>
                        <th>Montant</th>
                        <th>Nb</th>
                        <th>Type</th>
                        <th>Parrain</th>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>Email</th>
                        <th>Tel.</th>
                        <th>Adresse</th>
                        <th>Ville</th>
                        <th>CP</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {donations && donations.map((don) =>
                        <tr>
                            <td>{userState.collect && (don.donation.campaign.id == userState.collect.id) ? 'Association' : 'Campagne'}</td>
                            <td>{don.donation.campaign.title[0].content}</td>
                            <td>{don.internalReference}</td>
                            <td>{numberWithSpaces(don.donation.amount, don.donation.currency)}</td>
                            <td>{don.donation.intervals} fois</td>
                            <td>{don.type == 'STRIPE' ? 'CB' : 'Manuel'}</td>
                            <td>{don.donation.sponsor ? don.donation.sponsor.name : '-'}</td>
                            <td>{don.donation.donor.lastName}</td>
                            <td>{don.donation.donor.firstName}</td>
                            <td>{don.donation.donor.email}</td>
                            <td>{don.donation.donor.phone}</td>
                            <td>{don.donation.donor.address.lineOne}</td>
                            <td>{don.donation.donor.address.city}</td>
                            <td>{don.donation.donor.address.zipCode}</td>
                            <td>{moment(new Date(don.donation.createdAt)).format('DD/MM/YYYY')}</td>
                        </tr>
                    )} */}
                </tbody>
            </table>

            <div style={{justifyContent: 'center', width: '90%', display: 'flex', flexDirection: 'row'}}>
                {pageNb > 0 && <div onClick={() => setPageNb(pageNb - 1)} style={{fontSize: 30, cursor: 'pointer'}}>{"<"}</div>}
                <div style={{textAlign: 'center', fontSize: '1.25em', marginTop: 10, marginLeft: 10}}>Page {pageNb + 1}</div>
                {donations && donations.length == 15 && <div onClick={() => setPageNb(pageNb + 1)} style={{fontSize: 30, marginLeft: 10, cursor: 'pointer'}}>{">"}</div>}
            </div>
            </div>
        </div>
    )
}
