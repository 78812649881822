import React, {useState, createRef, useEffect} from 'react';
import styles from './index.css';
import add from '../../assets/add.png'
import { createCampain, updateCampain, publishCampain, getLinkAvailability, getBackOffice } from '../../Services/ws';
import {uploadFile} from '../../Services/s3'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Store } from 'react-notifications-component';
import Modal from 'react-modal';
import dynamicLoader from 'react-dynamic-loader'
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import Loader from "react-loader-spinner";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from 'uuid'
import Select from 'react-select'
import Autosuggest from 'react-autosuggest';
import {useHistory} from 'react-router-dom'


const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius: 20,
      zInd1ex: 20
    }
  };
Modal.setAppElement('#root')
export default function CreateCampagne({campain, setPart, userState, setUserState}) {
    const history = useHistory();
    const [url, setUrl] = useState(campain ? campain.linkParam : '');
    const [campainName, setCampainName] = useState(campain ? campain.associationName : userState.association.usageName);
    const [campTitle, setCampTitle] = useState(campain ? campain.title[0].content : '');
    const [description, setDescription] = useState(campain ? campain.description[0].content : '');
    const [obj, setObj] = useState(campain ? ('' + campain.goal.amount) : '');
    const [objBonus, setObjBonus] = useState(campain ? ('' + campain.goal.amountBonus) : '0');
    const [article, setArticle] = useState(campain ? campain.content[0].content : '');
    const [startDate, setStartDate] = useState(campain ? new Date(campain.startingAt) : '');
    const [endDate, setEndDate] = useState(campain ? new Date(campain.endingAt) : '');
    const inputOpenFileRef = createRef();
    const [pic, setPic] = useState(campain ? campain.poster[0].url : null);
    const [logo, setLogo] = useState(campain ? campain.associationLogoUrl : userState.association.logoUrl);
    const [sharingImage, setSharingImage] = useState(campain ? campain.sharingImage : null);
    const [videos, setVideos] = useState([]);
    const [pictures, setPictures] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPics, setLoadingPics] = useState(false);
    const [loadingAffiche, setLoadingAffiche] = useState(false);
    const [loadingSharingImage, setLoadingSharingImage] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [descEditorState, setDescEditorState] = useState(EditorState.createEmpty());
    const inputOpenFileRefLogo = createRef();
    const inputOpenFileRefPic = createRef();
    const inputOpenFileRefSharingImage = createRef();
    const inputOpenFileRefPhotos = createRef();
    const inputOpenFileRefVideos = createRef();
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [award1, setAward1] = useState({})
    const [award2, setAward2] = useState({})
    const [award3, setAward3] = useState({})
    const [award4, setAward4] = useState({})
    const [award5, setAward5] = useState({})
    const [award6, setAward6] = useState({})
    const [g1Img, setG1Img] = useState(null)
    const [g2Img, setG2Img] = useState(null)
    const [g3Img, setG3Img] = useState(null)
    const [g4Img, setG4Img] = useState(null)
    const [g5Img, setG5Img] = useState(null)
    const [g6Img, setG6Img] = useState(null)
    const gift1Ref = createRef();
    const gift2Ref = createRef();
    const gift3Ref = createRef();
    const gift4Ref = createRef();
    const gift5Ref = createRef();
    const gift6Ref = createRef();
    const { t } = useTranslation()

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
    }
  
    function closeModal(){
      setIsOpen(false);
    }
    useEffect(() => {
        if (userState.collect) {
            // var edState = 
            setDescEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(userState.collect.description[0].content))))
            setDescription(userState.collect.description[0].content)

        }
        if (campain) {
            setPictures( campain.medias.filter((el) => el.type == 'PICTURE').map((el) => el.url))
            setVideos( campain.medias.filter((el) => el.type == 'VIDEO').map((el) => el.url))
            setAward1(campain.awards[0] ? {name: campain.awards[0].title, amount: campain.awards[0].amount, gift: campain.awards[0].gift} : {})
            setG1Img(campain.awards[0]?campain.awards[0].logo:'')
            setAward2(campain.awards[1] ? {name: campain.awards[1].title, amount: campain.awards[1].amount, gift: campain.awards[1].gift} : {})
            setG2Img(campain.awards[1]?campain.awards[1].logo:'')
            setAward3(campain.awards[2] ? {name: campain.awards[2].title, amount: campain.awards[2].amount, gift: campain.awards[2].gift} : {})
            setG3Img(campain.awards[2]?campain.awards[2].logo:'')
            setAward4(campain.awards[3] ? {name: campain.awards[3].title, amount: campain.awards[3].amount, gift: campain.awards[3].gift} : {})
            setG4Img(campain.awards[3]?campain.awards[3].logo:'')
            setAward5(campain.awards[4] ? {name: campain.awards[4].title, amount: campain.awards[4].amount, gift: campain.awards[4].gift} : {})
            setG5Img(campain.awards[4]?campain.awards[4].logo:'')
            setAward6(campain.awards[5] ? {name: campain.awards[5].title, amount: campain.awards[5].amount, gift: campain.awards[5].gift} : {})
            setG6Img(campain.awards[5]?campain.awards[5].logo:'')
            try {
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(campain.content[0].content))))
                setDescEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(campain.description[0].content))))
            } catch (error) {
                
            }
        }
    }, [])
    
    const resetFields = () => {
        setUrl('')
        setCampainName('')
        setCampTitle('')
        setDescription('')
        setObj('')
        setArticle('')
        setStartDate('')
        setEndDate('')
        setPic(null)
        setSharingImage(null)
        setLogo(null)
        setVideos([])
        setPictures([])
        ////console.log('RESET');
        
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setArticle(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    };
    
    const onDescEditorStateChange = (editorState) => {
        setDescEditorState(editorState)
        setDescription(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    };


    const [selectAssoChoice,setSelectAssoChoice] = useState("")
    const [suggestions, setSuggestions] = useState([])


    const [affect,setAffect] = useState();
    const [affectDb,setAffectDb] = useState(null);
    const [checkPairing,setCheckPairing] = useState(false);
    const [pairingMail,setPairingMail] = useState("");
    const [pairing,setPairing] = useState("no");
    const [pairingId,setPairingId] = useState("");
    const [asso,setAsso] = useState();
    const [selectAsso,setSelectAsso] = useState();

    const [draft,setDraft] = useState("no");
    useEffect(()=>{
        const getData = async () => {
            const data = await getBackOffice('/association/back-office')
            const dataTemp = [];
            const selectTemp = [];
            data.forEach((d)=>{
                dataTemp.push({
                    id:d._id,
                    idAsso:d.association._id,
                    usageName:d.association.usageName,
                    email:d.email,
                    firstName:d.firstName,
                    lastName:d.lastName
                })
                selectTemp.push({
                    value: d._id,
                    label: d.association.usageName,
                })
            })
            setAsso(dataTemp)
            setSelectAsso(selectTemp)
        }
        getData()
        if(campain){
            setAffect(campain.affects)
            setDraft(campain.draft)
        }
        else{
            setAffect([{
                idAffect:uuidv4(),
                val:"Don général"
            }])
        }
    },[])

    const handleCheckPairing = (checkPairing)=>{
        setCheckPairing(!checkPairing)
        if(!checkPairing){
            setPairingMail("");
            setPairingId("");
            setPairing("no")
        }
    }
    
    const handlePairing = (id)=>{
        if(checkPairing){
            var assoTemp = (asso.find((as)=>{return as.id===id}))
            setPairingMail(assoTemp.email)
            setPairingId(assoTemp.idAsso)
            setPairing("pending")
        }
    }

    const [affectType,setAffectType]=useState("");

    const handleAddAffect = () =>{
        if(affectType.length){
            var affectTemp = [...affect];
            var valTemp = {
                idAffect:uuidv4(),
                val:affectType
            };
            affectTemp.push(valTemp);
            setAffect(affectTemp);
            setAffectType("")
        }   
    }

    const handleRemove=(id)=>{
        var affectTemp = [...affect];
        var temp = affectTemp.filter((aff)=>{
            return aff.idAffect!==id
        })
        setAffect(temp);
    }
    
    const handleDraft = ()=>{
        if((document.getElementById("draft").checked)){
            setDraft("yes")
        }else{
            setDraft("no")
        }
        
    }



    useEffect(()=>{
        if(affect && affect.length>1){
            setAffectDb([...affect])
        }else{
            setAffectDb(null)
        }
    
    },[affect])


    return (
        <div>
            <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            >

            <div className="dashFormTitle">{t("Dashboard.CreateCampaign.ConfirmChanges")}</div>

                <div style={{marginRight: 0}} className="smllRow">
                    <div className={'newBtnNext2'} onClick={async () => {
                      var errorFields = []
                      !description && errorFields.push(`Description`)
                      !article && errorFields.push(`Article`)
                      !url && errorFields.push(`URL`)
                      !logo && errorFields.push(`Logo`)
                      !sharingImage && errorFields.push(t("Dashboard.CreateCampaign.ErrorPoster"))
                      !pic && errorFields.push(t("Dashboard.CreateCampaign.ErrorPoster"))
                      !campTitle && errorFields.push(t("Dashboard.CreateCampaign.ErrorPageTitle"))
                      !startDate && errorFields.push(t("Dashboard.CreateCampaign.ErrorStartDate"))
                      !endDate && errorFields.push(t("Dashboard.CreateCampaign.ErrorEndDate"))
                      !obj && errorFields.push(t("Dashboard.CreateCampaign.ErrorGoal"))
                      !campainName && errorFields.push("Dashboard.CreateCampaign.ErrorName")
                      obj>=objBonus && objBonus !=0 && errorFields.push("Dashboard.CreateCampaign.ErrorBonusGoal")

                      if (errorFields.length > 0) {
                          Store.addNotification({
                              title: t("SingIn.Errors"),
                              message: t("Dashboard.CharityPage.FillFields") + errorFields.join(', '),
                              type: "danger",
                              insert: "top",
                              container: "top-center",
                              animationIn: ["animate__animated", "animate__fadeIn"],
                              animationOut: ["animate__animated", "animate__fadeOut"],
                              dismiss: {
                                duration: 10000,
                                onScreen: true
                              }
                            });
                      }
                      else {
                          if (loading == true) return ;
                          setLoading(true)
                          if (campain) {
                              if (url != campain.linkParam) {
                                  var available = await getLinkAvailability(url)
                                  if (!available.available) {
                                      Store.addNotification({
                                          title: t("SingIn.Errors"),
                                          message: t("SingIn.Errors.AssociationLinkError"),
                                          type: "danger",
                                          insert: "top",
                                          container: "top-center",
                                          animationIn: ["animate__animated", "animate__fadeIn"],
                                          animationOut: ["animate__animated", "animate__fadeOut"],
                                          dismiss: {
                                          duration: 10000,
                                          onScreen: true
                                          }
                                      });
                                      setLoading(false)
                                      return;
                                  }
                              }
                              try {
                                var awards = []
                                if (award1.name && award1.amount) {
                                    awards.push({amount: parseInt(award1.amount), title: award1.name, gift: award1.gift, currency: 'eur', logo: g1Img})
                                }
                                if (award2.name && award2.amount) {
                                  awards.push({amount: parseInt(award2.amount), title: award2.name, gift: award2.gift, currency: 'eur', logo: g2Img})
                              }
                              if (award3.name && award3.amount) {
                                  awards.push({amount: parseInt(award3.amount), title: award3.name, gift: award3.gift, currency: 'eur', logo: g3Img})
                              }
                              if (award4.name && award4.amount) {
                                  awards.push({amount: parseInt(award4.amount), title: award4.name, gift: award4.gift, currency: 'eur', logo: g4Img})
                              }
                              if (award5.name && award5.amount) {
                                  awards.push({amount: parseInt(award5.amount), title: award5.name, gift: award5.gift, currency: 'eur', logo: g5Img})
                              }
                              if (award6.name && award6.amount) {
                                  awards.push({amount: parseInt(award6.amount), title: award6.name, gift: award6.gift, currency: 'eur', logo: g6Img})
                              }
                                  var res = await updateCampain(campain.id, pic, sharingImage, campTitle, description, article, pictures, startDate, endDate, obj.replace(/\s+/g, ''), url, campainName, logo, videos, awards,affect,draft,pairingMail,pairing,pairingId,objBonus==undefined?0:isNaN(objBonus)?0:Number(objBonus))
                                //   publishCampain(res.id)
                                  var newCamp = []
                                  for (let index = 0; index < userState.campains.length; index++) {
                                      const element = userState.campains[index];
                                      if (res.id == element.id) {
                                          newCamp.push(res)
                                      } else {
                                        newCamp.push(element)
                                      }
                                      setUserState({...userState, campains: newCamp})
                                  }
                                  setPart(0)
                                  window.scrollTo(0, 0)

                                  Store.addNotification({
                                      title: t("Dashboard.CharityPage.Success"),
                                      message: t("Dashboard.CreateCampaign.UpdateCampaign"),
                                      type: "success",
                                      insert: "top",
                                      container: "top-center",
                                      animationIn: ["animate__animated", "animate__fadeIn"],
                                      animationOut: ["animate__animated", "animate__fadeOut"],
                                      dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                      }
                                    });
                                    setLoading(false)
                                    resetFields();
                              } catch (error) {
                                setLoading(false)

                              }
                          } else {
                              var available = await getLinkAvailability(url)
                              if (!available.available) {
                                  Store.addNotification({
                                      title: t("SingIn.Errors"),
                                      message: t("SingIn.Errors.AssociationLinkError"),
                                      type: "danger",
                                      insert: "top",
                                      container: "top-center",
                                      animationIn: ["animate__animated", "animate__fadeIn"],
                                      animationOut: ["animate__animated", "animate__fadeOut"],
                                      dismiss: {
                                      duration: 10000,
                                      onScreen: true
                                      }
                                  });
                                  setLoading(false)
                                  return;
                              }
                              try {
                                  var awards = []
                                  if (award1.name && award1.amount) {
                                      awards.push({amount: parseInt(award1.amount), title: award1.name, gift: award1.gift, currency: 'eur', logo: g1Img})
                                  }
                                  if (award2.name && award2.amount) {
                                    awards.push({amount: parseInt(award2.amount), title: award2.name, gift: award2.gift, currency: 'eur', logo: g2Img})
                                }
                                if (award3.name && award3.amount) {
                                    awards.push({amount: parseInt(award3.amount), title: award3.name, gift: award3.gift, currency: 'eur', logo: g3Img})
                                }
                                if (award4.name && award4.amount) {
                                    awards.push({amount: parseInt(award4.amount), title: award4.name, gift: award4.gift, currency: 'eur', logo: g4Img})
                                }
                                if (award5.name && award5.amount) {
                                    awards.push({amount: parseInt(award5.amount), title: award5.name, gift: award5.gift, currency: 'eur', logo: g5Img})
                                }
                                if (award6.name && award6.amount) {
                                    awards.push({amount: parseInt(award6.amount), title: award6.name, gift: award6.gift, currency: 'eur', logo: g6Img})
                                }
                        var res = await createCampain(pic, sharingImage, campTitle, description, article, pictures, startDate, endDate, obj.replace(/\s+/g, ''), url, campainName, logo, videos, awards, affect,draft,pairingMail,pairing,pairingId,objBonus)
                                  if (userState.campains) {
                                      userState.campains.push(res)
                                  } else {
                                    userState.campains = [res]
                                  }
                                  setUserState({...userState})
                                  publishCampain(res.id)
                                  setPart(0)
                                  setLoading(false)

                                  window.scrollTo(0, 0)
                                  Store.addNotification({
                                      title: t("Dashboard.CharityPage.Success"),
                                      message: t("Dashboard.CreateCampaign.UpdateCampaign"),
                                      type: "success",
                                      insert: "top",
                                      container: "top-center",
                                      animationIn: ["animate__animated", "animate__fadeIn"],
                                      animationOut: ["animate__animated", "animate__fadeOut"],
                                      dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                      }
                                    });
                                    resetFields();
                              } catch (error) {
                                setLoading(false)

                            }
                      }
                  }
                    }}>{t("Dashboard.LogoutConfirm")}</div>
                    <div onClick={async () => {setIsOpen(false)}} className={'newBtnNext2'}>{t("Dashboard.LogoutCancel")}</div>
                </div>
            </Modal>
            <p className="dashTitle">{t("Dashboard.CreateCampaign.CampaignCreation")}</p>
            <div className="dashInput2-new">
                <p className="dashFormTitle">{t("Dashboard.CreateCampaign.InformationAbout")}</p>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.OfficialName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput-new" type="text" placeholder={t("Dashboard.CreateCampaign.OfficialNamePlaceholder")} value={campainName} onChange={(evt) => {setCampainName(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.ModifyLogo")}</p>
                    </div>
                    {loadingLogo ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefLogo?.current.click()}} />
                            )}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingLogo(true)
                            var fileUrl = await uploadFile(e.target.files[0])
                            ////console.log(fileUrl);
                            setLogo(fileUrl)
                            setLoadingLogo(false)
                        }
                    }} type="file"  accept="image/*" ref={inputOpenFileRefLogo} style={{ display: 'none' }}/>
                    {logo && 
                        <img className="filePreview" src={logo}></img>
                    }
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.InsertPoster")}</p>
                    </div>
                    {loadingAffiche ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefPic?.current.click()}} />
                            )}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingAffiche(true)
                            try {
                                var fileUrl = await uploadFile(e.target.files[0])
                                ////console.log(fileUrl);
                                setPic(fileUrl)
                            } catch (error) {
                                Store.addNotification({
                                    title: t("SingIn.Errors"),
                                    message: t("Dashboard.CharityPage.DownloadFailed"),
                                    type: "danger",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                    }
                                    });
                            }
                            setLoadingAffiche(false)
                        }
                    }} type="file"  accept="image/*, video/mp4" ref={inputOpenFileRefPic} style={{ display: 'none' }}/>
                    {pic &&
                        pic.indexOf('.mp4') > 0 ?
                            <video controls className="picturePreview" src={pic}></video> :
                            <img className="picturePreview" src={pic}></img>
                    }
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.InsertSharingImage")}</p>
                    </div>
                    {loadingSharingImage ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                                type="ThreeDots"
                                color="#507cab"
                                height={10}
                                width={30}/>
                        </div>
                    ) :(
                        <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefSharingImage?.current.click()}} />
                    )}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingSharingImage(true)
                            try {
                                var fileUrl = await uploadFile(e.target.files[0])
                                ////console.log(fileUrl);
                                setSharingImage(fileUrl)
                            } catch (error) {
                                Store.addNotification({
                                    title: t("SingIn.Errors"),
                                    message: t("Dashboard.CharityPage.DownloadFailed"),
                                    type: "danger",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                    }
                                });
                            }
                            setLoadingSharingImage(false)
                        }
                    }} type="file"  accept="image/*" ref={inputOpenFileRefSharingImage} style={{ display: 'none' }}/>
                    {sharingImage &&
                        <img className="picturePreview" src={sharingImage}></img>
                    }
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignTitle")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput-new" type="text" placeholder={t("Dashboard.CreateCampaign.CampaignTitlePlaceholder")} value={campTitle} onChange={(evt) => {
                            setCampTitle(evt.target.value)
                            var link = evt.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "")
                            link = link.replace(' ', '-').replace(/ /g, '-');
                            setUrl(link)
                            }} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignURL")}</p>
                    </div>
                    <div className="dashColInput">
                    <input className="dashInput-new" type="text" value={"https://charityclic.com/" + url}  placeholder={t("Dashboard.CreateCampaign.CampaignURLPlaceholder")} onChange={(evt) => {
                            var val = evt.target.value.slice('https://charityclic.com/'.length, evt.target.value.length)
                            
                            setUrl(val)

                            }} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignStart")}</p>
                    </div>
                    <div className="dashColInput">
                        <DatePicker showTimeSelect dateFormat="dd/MM/yyyy HH:mm" timeFormat="HH:mm" className="dashInput-new" selected={startDate}  onChange={(date) => {setStartDate(date)}} />
                        {/* <input className="dashInput-new" type="date" placeholder="21/02/2021" value={startDate} onChange={(evt) => {setStartDate(evt.target.value)}} /> */}
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput-new" type="text" placeholder="345 000 euros" value={obj} onChange={(evt) => {setObj(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Campaign.BonusGoal")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput-new" type="text" placeholder="345 000 euros" value={objBonus} onChange={(evt) => {setObjBonus(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignEnd")}</p>
                    </div>
                    <div className="dashColInput">
                    <DatePicker showTimeSelect dateFormat="dd/MM/yyyy HH:mm" timeFormat="HH:mm" className="dashInput-new" selected={endDate}  onChange={(date) => {setEndDate(date)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.Affect")}</p>
                    </div>
                    <div className="dashColInput">
                        {affect && affect.map((aff)=>{ 
                            return(
                                <div style={{display: 'flex',  flexDirection: 'row', alignItems: 'center'}}>
                                <input className="dashInput2-new" type="text" value={aff.val} disabled/>
                                {aff.val==="Don général"?null:<img src={add} onClick={()=>handleRemove(aff.idAffect)} alt="add" style={{width:"16px",height:"16px",padding: "10px",width: "16px",height: "16px",background: "#ffffff 0% 0% no-repeat padding-box",border:"2px solid #491f32",borderRadius: "24px",cursor: "pointer", transform:'rotate(45deg)', marginLeft:"15px"}} />}
                                
                                </div>
                                )})}
                        <input onChange={(e)=>setAffectType(e.target.value)} value={affectType} className="dashInput2-new" type="text" placeholder={t("Dashboard.CreateCampaign.AffectFor")}/>
                    {/* <img src={add} onClick={handleAddAffect} alt="add" style={{width:"16px",height:"16px",padding: "10px",paddingLeft: "25px",paddingRight: "25px",width: "16px",height: "16px",background: "#ffffff 0% 0% no-repeat padding-box",boxShadow: "0px 3px 6px #00000029",borderRadius: "24px",marginRight: "20px",marginTop: "20px",cursor: "pointer"}} /> */}
                    <div onClick={handleAddAffect} className="newBtnNext2">{t("add")}</div>
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignPacks")}</p>
                    </div>
                    <div style={{width: '100%'}} className="dashCol">
                        <div className="dashRow" style={{justifyContent: 'space-evenly'}}>
                            <div className="dashInputPack">
                                <input value={award1.name} onChange={(e) => setAward1({...award1, name: e.target.value})} style={{border: 'none'}} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksName")} />
                                <input value={award1.amount} onChange={(e) => setAward1({...award1, amount: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksAmount")} />
                                <input value={award1.gift} onChange={(e) => setAward1({...award1, gift: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <input ref={gift1Ref} accept="image/*"  style={{display: 'none'}} type="file" onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        ////console.log(e.target.files);
                                        var fileUrl = await uploadFile(e.target.files[0])
                                        setG1Img(fileUrl)
                                    }
                                    
                                }} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <div className={"packInput"} >{t("Dashboard.CreateCampaign.CampaignPacksGiftPicture")}</div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <img className="dashAdd" src={add} alt="add" onClick={() => {gift1Ref?.current.click()}} />
                                    {g1Img && <img className="giftImg" src={g1Img} alt="add" />}
                                </div>
                            </div>
                            <div className="dashInputPack">
                                <input value={award2.name} onChange={(e) => setAward2({...award2, name: e.target.value})} style={{border: 'none'}} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksName")} />
                                <input value={award2.amount} onChange={(e) => setAward2({...award2, amount: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksAmount")} />
                                <input value={award2.gift} onChange={(e) => setAward2({...award2, gift: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <input ref={gift2Ref} accept="image/*"  style={{display: 'none'}} type="file" onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        ////console.log(e.target.files);
                                        var fileUrl = await uploadFile(e.target.files[0])
                                        setG2Img(fileUrl)
                                    }
                                    
                                }} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <div className={"packInput"} >{t("Dashboard.CreateCampaign.CampaignPacksGiftPicture")}</div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <img className="dashAdd" src={add} alt="add" onClick={() => {gift2Ref?.current.click()}} />
                                    {g2Img && <img className="giftImg" src={g2Img} alt="add" />}
                                </div>
                            </div>
                            <div  className="dashInputPack">
                                <input value={award3.name} onChange={(e) => setAward3({...award3, name: e.target.value})} style={{border: 'none'}} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksName")} />
                                <input value={award3.amount} onChange={(e) => setAward3({...award3, amount: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksAmount")} />
                                <input value={award3.gift} onChange={(e) => setAward3({...award3, gift: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <input ref={gift3Ref} accept="image/*"  style={{display: 'none'}} type="file" onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        ////console.log(e.target.files);
                                        var fileUrl = await uploadFile(e.target.files[0])
                                        setG3Img(fileUrl)
                                    }
                                    
                                }} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <div className={"packInput"} >{t("Dashboard.CreateCampaign.CampaignPacksGiftPicture")}</div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <img className="dashAdd" src={add} alt="add" onClick={() => {gift3Ref?.current.click()}} />
                                    {g3Img && <img className="giftImg" src={g3Img} alt="add" />}
                                </div>                            </div>
                        </div>
                        <div className="dashRow" style={{justifyContent: 'space-evenly'}}>
                            <div className="dashInputPack">
                                <input value={award4.name} onChange={(e) => setAward4({...award4, name: e.target.value})} style={{border: 'none'}} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksName")} />
                                <input value={award4.amount} onChange={(e) => setAward4({...award4, amount: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksAmount")} />
                                <input value={award4.gift} onChange={(e) => setAward4({...award4, gift: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <input ref={gift4Ref} accept="image/*"  style={{display: 'none'}} type="file" onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        ////console.log(e.target.files);
                                        var fileUrl = await uploadFile(e.target.files[0])
                                        setG4Img(fileUrl)
                                    }
                                    
                                }} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <div className={"packInput"} >{t("Dashboard.CreateCampaign.CampaignPacksGiftPicture")}</div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <img className="dashAdd" src={add} alt="add" onClick={() => {gift4Ref?.current.click()}} />
                                    {g4Img && <img className="giftImg" src={g4Img} alt="add" />}
                                </div>
                            </div>
                            <div className="dashInputPack">
                                <input value={award5.name} onChange={(e) => setAward5({...award5, name: e.target.value})} style={{border: 'none'}} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksName")} />
                                <input value={award5.amount} onChange={(e) => setAward5({...award5, amount: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksAmount")} />
                                <input value={award5.gift} onChange={(e) => setAward5({...award5, gift: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <input ref={gift5Ref} accept="image/*"  style={{display: 'none'}} type="file" onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        ////console.log(e.target.files);
                                        var fileUrl = await uploadFile(e.target.files[0])
                                        setG5Img(fileUrl)
                                    }
                                    
                                }} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <div className={"packInput"} >{t("Dashboard.CreateCampaign.CampaignPacksGiftPicture")}</div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <img className="dashAdd" src={add} alt="add" onClick={() => {gift5Ref?.current.click()}} />
                                    {g5Img && <img className="giftImg" src={g5Img} alt="add" />}
                                </div>
                            </div>
                            <div  className="dashInputPack">
                                <input value={award6.name} onChange={(e) => setAward6({...award6, name: e.target.value})} style={{border: 'none'}} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksName")} />
                                <input value={award6.amount} onChange={(e) => setAward6({...award6, amount: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksAmount")} />
                                <input value={award6.gift} onChange={(e) => setAward6({...award6, gift: e.target.value})} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <input ref={gift6Ref} accept="image/*"  style={{display: 'none'}} type="file" onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        ////console.log(e.target.files);
                                        var fileUrl = await uploadFile(e.target.files[0])
                                        setG6Img(fileUrl)
                                    }
                                    
                                }} className={"packInput"} placeholder={t("Dashboard.CreateCampaign.CampaignPacksGift")} />
                                <div className={"packInput"} >{t("Dashboard.CreateCampaign.CampaignPacksGiftPicture")}</div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <img className="dashAdd" src={add} alt="add" onClick={() => {gift6Ref?.current.click()}} />
                                    {g6Img && <img className="giftImg" src={g6Img} alt="add" />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignDescription")}</p>
                        <p className="smallTxt">{t("Dashboard.CreateCampaign.CampaignDescriptionLabel")}</p>
                    </div>
                    <div className="dashColInput">
                    <Editor
                        editorState={descEditorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="dashInput-new"
                        editorClassName="editorClassName"
                        onEditorStateChange={onDescEditorStateChange}
                        />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignArticle")}</p>
                        <p className="smallTxt">{t("Dashboard.CreateCampaign.CampaignExplanation")}</p>
                    </div>
                    <div className="dashColInput">
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="dashInput-new"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        />
                    {/* {draftToHtml(convertToRaw(editorState.getCurrentContent()))} */}
                        {/* <textarea className="dashInput-new" rows={3} placeholder="Article sur votre association"
                            value={article} onChange={(evt) => {setArticle(evt.target.value)}} style={{height: '530px'}} /> */}
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignPhotos")}</p>
                    </div>
                    {loadingPics ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                        <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefPhotos?.current.click()}} />
                        )}
                    <input multiple onChange={async (e) => {
                     if (e.target.files[0]) {
                        const newPics =[...pictures]
                        setLoadingPics(true)
                        for (let i = 0; i < e.target.files.length; i++) {
                            const element = e.target.files[i];
                            var fileUrl = await uploadFile(element)
                            newPics.push(fileUrl)
                            setPictures(newPics)
                        }
                        setLoadingPics(false)
                    }
                    }} type="file" accept="image/*" ref={inputOpenFileRefPhotos} style={{ display: 'none' }}/>
                    <div style={{maxWidth: '55%'}} className="row">
                    {pictures.map((picture, idx) =>
                            <div>
                                <img className="picturePreview" src={picture}></img>
                                <div onClick={() => {
                                    const newPics =[...pictures]
                                    newPics.splice(idx, 1)
                                    setPictures(newPics)
                                }} className={'delBtn'}>Supprimer</div>
                            </div>
                        )}
                    </div>

                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignVideos")}</p>
                    </div>
                    {loadingVideos ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefVideos?.current.click()}} />
                            )}
                    <input multiple type="file" onChange={async (e) => {
                        ////console.log(videos);
                        
                        if (e.target.files[0]) {
                            setLoadingVideos(true)
                            const newVids =[...videos]
                            for (let i = 0; i < e.target.files.length; i++) {
                                const element = e.target.files[i];
                                try {
                                    var fileUrl = await uploadFile(element)
                                    newVids.push(fileUrl)
                                    setVideos(newVids)
                                } catch (error) {
                                    Store.addNotification({
                                        title: t("SingIn.Errors"),
                                        message: t("Dashboard.CharityPage.DownloadFailed"),
                                        type: "danger",
                                        insert: "top",
                                        container: "top-center",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                            duration: 10000,
                                            onScreen: true
                                        }
                                        });                                 
                                }
                            }
                            setLoadingVideos(false)
                        }
                    }} accept="video/mp4" ref={inputOpenFileRefVideos} style={{ display: 'none' }}/>
                    <div style={{maxWidth: '55%'}} className="row">
                        {videos.map((video, idx) =>
                            <div>
                            <video controls className="videoPreview"  src={video} />
                            <div onClick={() => {
                                const newVids =[...videos]
                                newVids.splice(idx, 1)
                                setVideos(newVids)
                            }} className={'delBtn'}>Supprimer</div>
                        </div>
                        )}
                    </div>
                </div>
                {/* <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">Insérez vos vidéo</p>
                    </div>
                    <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefVideos?.current.click()}} />
                    <input type="file" onChange={async (e) => {
                        if (e.target.files[0]) {
                            var fileUrl = await uploadFile(e.target.files[0])
                            setVideo(fileUrl)
                        }
                    }} accept="video/*" ref={inputOpenFileRefVideos} style={{ display: 'none' }}/>
                    {video &&
                        <video controls className="videoPreview"  src={video} />
                    }
                </div> */}
                {/* <div className="dashRow" style={{display: 'flex', alignItems: 'center'}}>
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.Draft")}</p>
                    </div>
                    <div className="dashColInput">
                        <input checked={draft && draft==="yes"?"checked":null} onClick={handleDraft} type="checkbox" id="draft" style={{width: '18px',height: '18px'}}/>
                    </div>
                </div> */}
                <div className="dashRow" style={{display: 'flex', alignItems: 'center'}}>
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.Pairing")}</p>
                    </div>
                    <div className="dashColInput">
                        <input 
                        onChange={()=>handleCheckPairing(checkPairing)} 
                        type="checkbox" id="checkPairing" style={{width: '18px',height: '18px',position: 'relative'}}/>
                        {asso && checkPairing && <Autosuggest
                        inputProps={{
                            placeholder:"Choisissez une association",
                            autocomplete:"abcd",
                            name:"pairingAsso",
                            id:"pairingAsso",
                            value:selectAssoChoice,
                            onChange:(_event,{newValue})=>{
                                setSelectAssoChoice(newValue);
                            }
                        }}
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={async({value})=>{
                            if(!value || value.length<3){
                                setSuggestions([])
                                return
                            }
                            try {
                                const result = []
                                asso.forEach((as)=>{
                                    if(as.usageName.toLowerCase().includes(value)){
                                        result.push(as)
                                    }
                                })
                                setSuggestions(result.map((asso)=>({name:asso.usageName, id:asso.id})))
                                // //console.log(suggestions);
                            } catch (error) {
                                setSuggestions([])
                            }
                        }}
                        onSuggestionsClearRequested={()=>{
                            setSuggestions([])
                        }}
                        onSuggestionSelected={(e,{suggestion,method})=>{
                            if(method==="enter"){
                                e.preventDefault()    
                            }
                            // //console.log(suggestion.id);
                            handlePairing(suggestion.id)
                        }}
                        getSuggestionValue={(suggestion)=>suggestion.name}
                        renderSuggestion={suggestion=><div>{suggestion.name}</div>}
                        />}
                        {/* {asso && checkPairing?<Select value={asso.usageName} onChange={(val) => {
                            //console.log(val.value);
                            handlePairing(val.value)
                        }} placeholder={t("Dashboard.Donation.DonationDestinationPlaceholder")} classNamePrefix="assoc-select" className="selectCampain" options={selectAsso} />:null} */}
                    </div>
                </div>
                <div className="dashBtns">
                    <div onClick={async () => {
                        setDraft("no")
                       openModal()
                    }} className={'newBtnNext2'}>{t("Dashboard.CharityPage.Enter")}</div>
                    <div onClick={async () => {
                        setDraft("yes")
                       openModal()
                    }} className={'newBtnNext2'}>Brouillon</div>
                    <div className={'newBtnNext2'} onClick={() => {
                        var medias = []
                        for (let i = 0; i < pictures.length; i++) {
                            const element = pictures[i];
                            medias.push({type: 'PICTURE', url: element})
                        }
                        for (let i = 0; i < videos.length; i++) {
                            const element = videos[i];
                            medias.push({type: 'VIDEO', url: element})
                        }
                        var awards = []
                        if (award1.name && award1.amount) {
                            awards.push({amount: parseInt(award1.amount), title: award1.name, gift: award1.gift, currency: 'eur', logo: g1Img})
                        }
                        if (award2.name && award2.amount) {
                          awards.push({amount: parseInt(award2.amount), title: award2.name, gift: award2.gift, currency: 'eur', logo: g2Img})
                      }
                      if (award3.name && award3.amount) {
                          awards.push({amount: parseInt(award3.amount), title: award3.name, gift: award3.gift, currency: 'eur', logo: g3Img})
                      }
                      if (award4.name && award4.amount) {
                          awards.push({amount: parseInt(award4.amount), title: award4.name, gift: award4.gift, currency: 'eur', logo: g4Img})
                      }
                      if (award5.name && award5.amount) {
                          awards.push({amount: parseInt(award5.amount), title: award5.name, gift: award5.gift, currency: 'eur', logo: g5Img})
                      }
                      if (award6.name && award6.amount) {
                          awards.push({amount: parseInt(award6.amount), title: award6.name, gift: award6.gift, currency: 'eur', logo: g6Img})
                      }
                      if(draft==="yes"){
                          var acceptedVerif = true;
                      }else if(draft=="no"){
                        var acceptedVerif = false;
                      }
                        var assoc = {
                            // accepted: draft.acceptedVerif,
                            accepted: true,
                            closed: false,
                            associationLogoUrl: logo,
                            associationName: campainName,
                            sharingImage: sharingImage,
                            content: [{language: 'EN',content: article}],
                            description: [{language: 'EN',content: description}],
                            funding: [],
                            linkParam: "testassoc",
                            medias: medias ? medias : [],
                            poster: [{url: pic, type: pic && pic.indexOf('.mp4') > 0 ? 'VIDEO' : 'PICTURE'}],
                            published: true,
                            sponsors: [],
                            awards: awards,
                            affects: affectDb?affectDb:[],
                            pairingMail:pairingMail,
                            pairingId:pairingId,
                            // draft:draft.draft,
                            draft:draft?draft:[],
                            pairing:pairing,
                            title: [{language: 'EN',content: campTitle}],
                            "goal": {
                                "amount": parseInt(obj),
                                "currency": "eur",
                                "bonusMultiplier": 0
                              },
                              endingAt: endDate,
                            // association: {
                            //     block: false,
                            // }
                            association: userState.association.id
                        }
                        setUserState({...userState, campainVisualize:assoc})

                        window.open('/visualize', '_blank').focus();
                    //    history.push('/visualize?assoc=' + JSON.stringify(assoc))
                    }}>{t("Dashboard.CharityPage.Visualize")}</div>
                </div>
            </div>
        </div>
    )
}
