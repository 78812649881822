import React, { useState, useEffect } from 'react'
import { createClosingInvoice, donationsForCampaign, getAllPersons, getAssociationById, getBankAccountById, transferAmountsToBank, getBackOffice,getCollectFromCamp,getBilletterieDonations } from "../../Services/ws";
import Modal from '../Elements/Modal/Modal';
import { useTranslation } from "react-i18next"
import styles from './CampaignClosing.module.css'
import DownloadButton from "../Elements/DownloadButton/DownloadButton";

const BilletterieClosing = (props) => {
   const reasons = [
       {
           'motif': '',
           'rising': ''
       }
   ]
   const [reasonData, setReasonData] = useState(reasons)
   const [campaign, setCampaign] = useState(props.campaign)
   const [maam, setMaam] = useState(0)
   const [total, setTotal] = useState(0)
   const [totalRising, setTotalRising] = useState(0)
   const [risingCreditCard, setRisingCreditCard] = useState(0)
   const [risingCash, setRisingCash] = useState(0)
   const [risingStripe, setRisingStripe] = useState(0)
   const [amountTransfer, setAmountTransfer] = useState(0)
   const [bills, setBills] = useState([])
   const [donations, setDonations] = useState([])
   const [donationsOnline, setDonationsOnline] = useState(0)
   const [bankData, setBankData] = useState([])
   const [showModal, setModalShow] = useState(false)
   const [successTransfer, setTransferSuccess] = useState(false)
   const [errorTransfer, setErrorTransfer] = useState(false)
   const [invoiceCreated, setInvoiceCreated] = useState(props.campaign.invoice)
   const { t } = useTranslation()


   const [dataSource, setDataSource] = useState([]);
   const [search, setSearch] = useState('')
   const [currentData, setCurrentData] = useState([])
   const [currentCampain,setCurrentCampain] = useState([]);
   const [allDonCam,setAllDonCam] = useState([]);
   const [donations2,setDonations2] = useState([])
   const [id,setId] = useState([]);


   const addNewLine = () => {
       let newLine = {
           'motif': '',
           'rising': ''
       };
       setReasonData([...reasonData, newLine])
   }

   const updateMotif = index => e => {
       let newReasons = [...reasonData]
       newReasons[index].motif = e.target.value
       setReasonData(newReasons)
   }

    const updateRising = index => e => {
        let newReasons = [...reasonData]
        newReasons[index].rising = Number(e.target.value)
        setReasonData(newReasons)
        updateMaam()
    }

   const updateMaam = () => {
       let maam = 0;
       reasonData.map((reason) => {
           let rising = reason.rising > 0 ? reason.rising : 0
           let row = rising * 0.2;
           let rowConverted = Number(row.toFixed(2));
           maam = maam + rowConverted;
       });
    //    setMaam(Number(maam.toFixed(2)));
       setMaam(((maam*100)/100).toFixed(2));
       updateTotal(Number(maam.toFixed(2)));
   }

   const updateTotal = (maam) => {
       let total = 0;
       let rising = 0;
       reasonData.map((reason) => {
           rising = rising + reason.rising;
       });
       total = rising + maam;
    //    setTotal(Number(total.toFixed(2)));
       setTotal(((total*100)/100).toFixed(2));
       updateTransferAmount(Number(total.toFixed(2)));
   }

   const updateTransferAmount = (total) => {
       let amount = 0;
       amount = risingCreditCard - risingStripe - total;
    //    amount = Number(amount.toFixed(2));
       amount = (((amount*100)/100).toFixed(2));
       setAmountTransfer(amount);
   }

   const getDonations = async () => {
       let rising = 0
       let risingCreditCard = 0
       let risngStripeFee = 0
       let risingCash = 0
       let amount = 0;
       let onlineDonors = 0;
       const donations = await donationsForCampaign('/donation/billetterie', campaign)
       const temp2 = await getBilletterieDonations(campaign._id)
       const donationsArr = []
       let count = 0;
       for(let i=0;i<temp2.length;i++) {
           for(let j=0;j<donations.length;j++) {
            if(donations[j].donation.id == temp2[i].donor.id){
                let donTemp = donations[j];
                donTemp.donation.amount=temp2[i].amount;
                donationsArr.push(donTemp);
                break
            }
           }
       }
       for(let i=0;i<donationsArr.length;i++) {
           count = count+donationsArr[i].donation.amount
       }
       setDonations2(donationsArr)

       donationsArr.map(donation => {
           risngStripeFee = risngStripeFee + (donation.gatewayFee ? donation.gatewayFee : 0)
           if(donation.type === 'CASH' || donation.type === 'DRAFT') {
               rising = rising + donation.amount
               risingCash = risingCash + donation.amount
            }
            if(donation.type === 'STRIPE') {
               rising = rising + donation.donation.amount
               risingCreditCard = risingCreditCard + donation.donation.amount
               onlineDonors = onlineDonors + 1;
           }
       })
       setRisingStripe(Number(risngStripeFee.toFixed(2)))
       setRisingCash(risingCash)
       setRisingCreditCard(risingCreditCard)
       setTotalRising(rising)
       setDonations(donations)
       setDonationsOnline(onlineDonors)
       amount = risingCreditCard - risngStripeFee - total
       amount = Number(amount.toFixed(2))
       setAmountTransfer(amount)
   }

   const getBankAccount = async () => {
        let association = await getAssociationById(`/association/${campaign.association}`)
        if(association) {
            const bankAcc = await getBankAccountById(`/association/bank-account/${association.stripeGateway.accountId}`)
            setBankData(bankAcc)
        }
   }

   const makeTransferToAccount = async () => {
       let association = await getAssociationById(`/association/${campaign.association}`)

        const data = {
            balance: campaign.invoiceAmount ? campaign.invoiceAmount : amountTransfer,
            currency: 'eur',
            account: bankData.data[0].id,
            accountId: association.stripeGateway.accountId,
            campaignId: campaign._id,
            associationId: association.id,
            bankName: bankData.data ? bankData.data[0].bank_name : '',
        }

        const result = await transferAmountsToBank('/payouts/transfer-amount', data);
        if(result.id) {
            setTransferSuccess(true);
        } else {
            setErrorTransfer(true);
        }
       setModalShow(false);
       setAmountTransfer(0);
    }

   const transferModalContent = () => {
       return (
           <>
               <div className="text">
                   {t("CampaignClosing.ModalText")}
               </div>
               <div className="buttons">
                   <DownloadButton buttonStyle={'primary'} name={t("Charity.Accept")} action={() => makeTransferToAccount()}/>
                   <DownloadButton buttonStyle={'danger'} name={t("Charity.Reject")} action={() => setModalShow(false)}/>
               </div>
           </>
       )
   }



   const createInvoice = async () => {
       let number = '';
       let date = '';

       let currentDate = new Date();
       let currentDay = currentDate.getDate();
       let currentMonth = currentDate.getMonth() + 1;
       let currentYear = currentDate.getFullYear();

       number = currentYear + '/' + currentMonth + '/C' + 1;
       date = currentDay + '.' + currentMonth + '.' + currentYear;

       let association = await getAssociationById(`/association/${campaign.association}`)
       let person = await getAllPersons(`/association/all-persons/${association.stripeGateway.accountId}/1`)

       if(association && person) {
           const data = {
               campaign: campaign._id,
               number: number,
               date: date,
               association: association._id,
               associationName: campaign.associationName,
               assosiationAddress: person.data[0].address.line1,
               associationPostal: person.data[0].address.postal_code + ',' + person.data[0].address.city,
               associationCity: person.data[0].address.country,
               amount: Number(amountTransfer),
               charityFee: risingStripe,
               maamFee: Number(maam),
               amountByCash: risingCash,
               amountByCC: risingCreditCard,
               donationsOnline: donationsOnline,
               totalTVA: Number(maam),
               totalTTC: Number(total),
               totalCollectedAmount: totalRising,
               reasons: reasonData,
               invoiceMonth: currentMonth,
               invoiceYear: currentYear,
           }

           const invoice = await createClosingInvoice('/invoices/create-closing', data);
           if(invoice._id) {
               setInvoiceCreated(true)
           }
       }
   }


    useEffect(() => {
        getDonations();
        getBankAccount();
    }, []);

   

       const setData = async () => {
        const data = await getBackOffice('/campaign/back-office/');
        const allDon = await getCollectFromCamp(campaign._id)
        setDataSource(data)
        setCurrentData(data)
        setCurrentCampain(dataSource.filter(data => data.id==campaign._id));
        let count = 0
        const donationsTemp = [...donations]
        const temp2 = await getBilletterieDonations(campaign._id)
        for(let i=0;i<temp2.length;i++){
            count = count+temp2[i].amount
        }
        setAllDonCam(temp2);
        const arrTemp = []
        for(let i=0;i<allDonCam.length;i++) {
            for(let j=0;j<donations.length;j++){
                if(donations[j].donation.id==allDonCam[i].donor.id){
                    arrTemp.push(donations[j])
                    break;
                }
            }
            // let filt = (donations.filter(don=>don.donation.id==temp2[i].donor.id));
        }
        setDonations2(arrTemp);

        
    }

    const verif2 = () =>{
        
        for(let i=0;i<id.length;i++){
            let count = 0;
            for(let j=0;j<id.length;j++){
                if(id[i]==id[j] && i!=j){
                    // count++
                    // console.log(id[i]);

                }
            }
            // console.log(count);
        }
    }



   return(
       <>
           <div className={styles.closingPage}>
               {!invoiceCreated &&
                   <>
                   <div className={styles.closingPage__head}>
                        <h2>
                            {t("CampaignClosing.Title")}
                        </h2>
                       <div className={styles.closingPage__association}>
                           {campaign.associationName}
                       </div>
                   </div>
                   <div className={styles.closingPage__totalAmount}>
                       {t("CampaignClosing.AmountCollected")}
                       <span>{totalRising} €</span>
                   </div>
                   <div className={styles.closingPage__amounts}>
                       <div className={styles.closingPage__amount}>
                           {t("CampaignClosing.CreditCard")}
                           <span>{risingCreditCard}€</span>
                       </div>
                       <div className={styles.closingPage__amount}>
                           {t("CampaignClosing.Stripe")}
                           <span>{risingStripe} €</span>
                       </div>
                       <div className={styles.closingPage__amount}>
                           {t("CampaignClosing.Manual")}
                           <span>{risingCash} €</span>
                       </div>
                   </div>
                   <div className={styles.closingPage__facture}>
                       <h3>{t("CampaignClosing.Bill")}</h3>
                       {reasonData.map((reason, index) => {
                           return (
                               <div className={styles.closingPage__factureRow} key={'billLine' + index}>
                                   <div className={styles.closingPage__factureCell}>
                                       <label>{t("CampaignClosing.Reason")}</label>
                                       <input type={'text'}
                                              name={'motif'}
                                              value={reason.motif}
                                              onChange={updateMotif(index)}
                                       />
                                   </div>
                                   <div className={styles.closingPage__factureCell}>
                                       <label>{t("CampaignClosing.Rising")}</label>
                                       <input type={'number'}
                                              name={'rising'}
                                              value={reason.rising}
                                              onChange={updateRising(index)}
                                       />
                                   </div>
                               </div>
                           )
                       })}
                       <div className={styles.closingPage__addMore}>
                           <h5>{t("CampaignClosing.NewLine")}</h5>
                           <button type={'button'} onClick={() => addNewLine()}>+</button>
                       </div>
                       <div className={styles.closingPage__factureRow}>
                           <div className={styles.closingPage__factureCell}>
                               <label>Maam</label>
                           </div>
                           <div className={styles.closingPage__factureCell}>
                               <label>{t("CampaignClosing.Rising")}</label>
                               <span>{maam}</span>
                           </div>
                       </div>
                       <div className={styles.closingPage__factureRow}>
                           <div className={styles.closingPage__factureCell}>
                               <label>Total</label>
                           </div>
                           <div className={styles.closingPage__factureCell}>
                               <label>{t("CampaignClosing.Rising")}</label>
                               <span>{total}</span>
                           </div>
                       </div>
                       <div className={styles.closingPage__save}>
                           <button type={'button'} onClick={() => createInvoice()} >Validate</button>
                       </div>
                   </div>
                   </>
               }
               <div className={styles.closingPage__transfer}>
                   <h3>
                       {t("CampaignClosing.MakeTransfer")} {campaign.associationName} {t("CampaignClosing.AssociationBankAccount")}
                   </h3>
                   <div className={styles.closingPage__transferAmount}>
                       <span>{t("CampaignClosing.Rising")}</span>
                       <span>{campaign.invoiceAmount ? campaign.invoiceAmount : amountTransfer} €</span>
                   </div>
                   <div className={styles.closingPage__banks}>
                       <table>
                           <thead>
                               <tr>
                                   <th>{t("CampaignClosing.BankName")}</th>
                                   <th>{t("CampaignClosing.AssociationName")}</th>
                                   <th>{t("CampaignClosing.BankIban")}</th>
                               </tr>
                           </thead>
                           <tbody>
                                <tr>
                                    <td>{bankData.data ? bankData.data[0].bank_name : ''}</td>
                                    <td>{campaign.associationName}</td>
                                    <td>**** **** **** **** ***{bankData.data ? bankData.data[0].last4 : ''}</td>
                                </tr>
                           </tbody>
                       </table>
                   </div>
                   <div className={styles.closingPage__save}>
                       <button type={'button'} onClick={() => setModalShow(true)}>Validate</button>
                   </div>
               </div>

               {successTransfer &&
                   'Amount transferred successfully'
               }
           </div>

           {showModal &&
                <Modal
                    modalTitle={t("CampaignClosing.ModalTitle")}
                    modalContent={transferModalContent()}
                    modalClose={()=>setModalShow(false)}
                />
           }
       </>
   )
}

export default BilletterieClosing
