import React, {useState} from 'react';
import logoFull from '../../assets/website_logo.svg'
import styles from './Invoice.module.css'
import { useTranslation } from "react-i18next"
import DownloadButton from "../Elements/DownloadButton/DownloadButton";
import {downloadPDF} from "../../Services/utils";

const CampaignInvoice = (props) => {
    const bill = props.bill
    const { t } = useTranslation()

    console.log(bill);

    return (
        <>
            <div id={'invoicePage'} className={styles.InvoicePage}>
                <div className={styles.viewBtn} onClick={() => downloadPDF('invoicePage')}>Download invoice PDF</div>
                <div className={styles.InvoiceTop}>
                    <div className={styles.InvoiceLogo}>
                        <img className="logo" src={logoFull} />
                    </div>
                    <div className={styles.InvoiceAddress}>
                        <div className={styles.InvoiceTableBlock}>
                            <div className={styles.InvoiceTableHead}>
                                {t("BackOffice.MyBilld.Invoice.Bill")}
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillNumber")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.number}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillDate")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.date}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillName")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.associationName}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillAddress")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.assosiationAddress}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillPostal")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.associationPostal}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillCountry")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.associationCity}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.InvoiceMiddle}>
                    <table className={styles.InvoiceTable}>
                        <thead>
                            <tr>
                                <th>
                                    {t("BackOffice.MyBilld.Invoice.BillLineName")}
                                </th>
                                <th>
                                    {t("BackOffice.MyBilld.Invoice.BillLineQuantity")}
                                </th>
                                <th>
                                    {t("BackOffice.MyBilld.Invoice.BillLineAmount")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {bill.reasons && bill.reasons.map((reason) => (
                                <tr>
                                    <td>
                                        {reason.motif}
                                    </td>
                                    <td>
                                        1
                                    </td>
                                    <td>
                                        {reason.rising + '€'}
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td></td>
                                <td>
                                    T.V.A. 20%
                                </td>
                                <td>
                                    {bill.maamFee} €
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    Total TTC
                                </td>
                                <td>
                                    {bill.totalTTC + '€'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.InvoiceBottom}>
                    <div className={styles.InvoiceTableBlock}>
                        <div className={styles.InvoiceTableHead}>
                            {t("BackOffice.MyBilld.Invoice.CampaignDetail")}
                        </div>
                        <div className={styles.InvoiceTableRow}>
                            <div className={styles.InvoiceTableCell}>
                                {t("BackOffice.MyBilld.Invoice.DonationsCollected")}
                            </div>
                            <div className={styles.InvoiceTableCell}>
                                {bill.totalCollectedAmount + '€'}
                            </div>
                        </div>
                        <div className={styles.InvoiceTableRow}>
                            <div className={styles.InvoiceTableCell}>
                                {t("BackOffice.MyBilld.Invoice.DonationsReceivedCC")}
                            </div>
                            <div className={styles.InvoiceTableCell}>
                                {bill.amountByCC + '€'}
                            </div>
                        </div>
                        <div className={styles.InvoiceTableRow}>
                            <div className={styles.InvoiceTableCell}>
                                {t("BackOffice.MyBilld.Invoice.CerfaDonations")}
                            </div>
                            <div className={styles.InvoiceTableCell}>
                                {bill.donationsOnline}
                            </div>
                        </div>
                        <div className={styles.InvoiceTableRow}>
                            <div className={styles.InvoiceTableCell}>
                                {t("BackOffice.MyBilld.Invoice.TotalDonations")}
                            </div>
                            <div className={styles.InvoiceTableCell}>
                                {bill.amount + '€'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.InvoiceFooter}>
                    SAS ODAYA CHARITYCLIC 18 BD FREDERIC SAUVAGE 13014 MARSEILLE<br />
                    Numéro de TVA intracommunautaire : FR3589798616<br />
                    Numéro RCS Marseille B897 968 616 Capital Social 1 000 €<br />
                    charityclic.com
                </div>
            </div>
        </>
    )
}

export default CampaignInvoice;