import styles from "./index.css";
import cerfa from "../../assets/cerfa_blue.svg";
import finance from "../../assets/finance.svg";
import support from "../../assets/support.svg";
import ContactForm from "../../Components/ContactForm";
import arrowDown from "../../assets/angle-down-solid.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
import whatsapp from "../../assets/whatsapp.svg";
import linkedin from "../../assets/linkedin.svg";
import sharelink from "../../assets/share-link.png";
import gift1 from "../../assets/gift-box-1.svg";
import gift2 from "../../assets/gift-box-2.svg";
import gift3 from "../../assets/gift-box-3.svg";
import gift4 from "../../assets/gift-box-4.svg";
import gift5 from "../../assets/gift-box.svg";
import iconDonate from "../../assets/icon_donate.svg";
import iconAssoc from "../../assets/icon_association.svg";
import iconSecu from "../../assets/icon_securite.svg";
import iconConfiance from "../../assets/icon_confiance.svg";
import iconCerfa from "../../assets/cerfa.svg";
import { AiOutlineQrcode } from "react-icons/ai";
import donorProfile from "../../assets/donor_profile_pic.png";
import roseFacebook from "../../assets/New/roseFacebook.png";
import roseTwitter from "../../assets/New/roseTwitter.png";
import roseWhatsapp from "../../assets/New/roseWhatsapp.png";
import roseMail from "../../assets/New/roseMail.png";
import roseLink from "../../assets/New/roseLink.png";
import roseLinkedin from "../../assets/New/roseLinkedin.png";
import roseQr from "../../assets/New/roseQr.png";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
} from "react-share";
import Modal from "react-modal";
import QRCode from "react-qr-code";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Assoc from "../../Components/Assoc";
import { useRef, useState, useEffect, useContext } from "react";
import Select from "react-select";
import { UserContext } from "../../Context/UserContext";
import {
  getPageByUrl,
  getCampainDonations,
  getSponsorNumbers,
  getCollectFromCamp,
  getMyBilletteries,
  getBackOffice,
} from "../../Services/ws";
import { numberWithSpaces, calcPercentage } from "../../Services/utils";
import dynamicLoader from "react-dynamic-loader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { isMobile } from "react-device-detect";
// import { Helmet } from "react-helmet";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import QrCode from "../../Components/QrCode/QrCode";
import Countdown from "react-countdown";
import Hand from "../../assets/New/hand.png";
import check from "../../assets/New/check-circle2.svg";
import check2 from "../../assets/New/check-circle.svg";
import Award from "../../assets/New/award.png";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { fr } from "date-fns/locale";
import Moment from "moment";
import CountUp from "react-countup";
import {
  Waypoint,
  previousPosition,
  currentPosition,
  event,
} from "react-waypoint";
// import SEO from "../../Components/SEO/SEO";

var fx = require("money");

fx.base = "EUR";
fx.rates = {
  USD: 1.19, // eg. 1 USD === 0.745101 EUR
  GBP: 0.86, // etc...
  ILS: 3.89,
  EUR: 1, // always include the base rate (1:1)
  /* etc */
};
const setResult = (donations, id) => {
  var dons = donations.filter(
    (donation) => donation.sponsor && donation.sponsor.id == id
  );
  var result = 0;
  dons.forEach((don) => {
    if (don.currency.toUpperCase() == "EUR") {
      result += don.amount;
    } else if (don.currency.toUpperCase() == "USD") {
      result += don.amount / fx.rates["USD"];
    } else if (don.currency.toUpperCase() == "GBP") {
      result += don.amount / fx.rates["GBP"];
    } else if (don.currency.toUpperCase() == "ILS") {
      result += don.amount / fx.rates["ILS"];
    }
  });
  return Math.round(result);
};

var gifts = [gift1, gift2, gift3, gift4, gift5, gift5];
const moment = require("moment");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    borderRadius: 20,
    zInd1ex: 20,
    minHeight: 200,
    display: "flex",
    flexDirection: "column",
  },
};
Modal.setAppElement("#root");
const options = [
  { label: "Euro €", value: "eur" },
  { label: "Usd $", value: "usd" },
  { label: "Ils ₪", value: "ils" },
  { label: "Gbp £", value: "gbp" },
];

const paymentOptions = [
  { label: "Sur 12 mois", value: "12" },
  { label: "Sur 11 mois", value: "11" },
  { label: "Sur 10 mois", value: "10" },
  { label: "Sur 9 mois", value: "9" },
  { label: "Sur 8 mois", value: "8" },
  { label: "Sur 7 mois", value: "7" },
  { label: "Sur 6 mois", value: "6" },
  { label: "Sur 5 mois", value: "5" },
  { label: "Sur 4 mois", value: "4" },
  { label: "Sur 3 mois", value: "3" },
  { label: "Sur 2 mois", value: "2" },
];

const TopDonateurLeft = ({
  firstName,
  lastName,
  message,
  amount,
  currency,
}) => {
  var cur = "€";
  switch (currency) {
    case "usd":
      cur = "$";
      break;
    case "ils":
      cur = "₪";
      break;
    case "gbp":
      cur = "£";
      break;
    default:
      break;
  }
  return (
    <div className={"TopDonateurContainer"}>
      <div className="handContainer">
        <img width="24" src={Hand} />
      </div>
      <div>
        <div className="TopDonateurName">{`${firstName} ${lastName}`}</div>
        <div className="TopDonateurTxt">{message}</div>
      </div>
      <div className="TopDonateurDon">
        <CountUp separator=" " duration={5} end={amount} />
        {cur}
      </div>
    </div>
  );
};

const TopAssoc = ({
  campain,
  history,
  userState,
  setUserState,
  sponsor,
  donations,
}) => {
  const [amount, setAmount] = useState(null);
  const [currency, setCurrency] = useState(options[0].value);
  const [oneTime, setOneTime] = useState(true);
  const [intervals, setIntervals] = useState("12");
  const { t } = useTranslation();
  const [donFor, setDonFor] = useState("");

  const handleVal = (id, affects) => {
    affects.forEach((aff) => {
      document.getElementById(aff.idAffect).classList.remove("checked");
    });
    var checked = document.getElementById(id);
    checked.classList.add("checked");
    var don = affects.filter((aff) => {
      return aff.idAffect == id;
    });
    setDonFor(don[0].val);
  };
  const reduct = Number(amount) - Number(amount) * 0.66;
  const donateurTemp = [];
  if (donations.length < 4) {
    donations[0] && donateurTemp.push(donations[0]);
    donations[1] && donateurTemp.push(donations[1]);
    donations[2] && donateurTemp.push(donations[2]);
    donations[3] && donateurTemp.push(donations[3]);
  } else {
    donateurTemp.push(donations[0]);
    donateurTemp.push(donations[1]);
    donateurTemp.push(donations[2]);
    donateurTemp.push(donations[3]);
  }
  return (
    <div className={"TopAssocContainer"}>
      {new Date() > new Date(campain.startingAt) &&
        new Date(campain.endingAt) > new Date() && (
          <div className={"TopDonateur"}>
            {donateurTemp &&
              donateurTemp.map((don, index) => {
                return (
                  <TopDonateurLeft
                    key={index}
                    firstName={don.anonymous ? "anonyme" : don.donor.firstName}
                    lastName={don.anonymous ? "" : don.donor.lastName}
                    message={don.donor.message}
                    amount={don.amount}
                    currency={don.currency}
                  />
                );
              })}
          </div>
        )}
      {new Date() < new Date(campain.startingAt) && (
        <div style={{ width: "10%" }}></div>
      )}
      {new Date() > new Date(campain.endingAt) && (
        <div style={{ width: "33%" }}></div>
      )}
      <div className={"TopAffiche"}>
        <div
          className={
            new Date() > new Date(campain.startingAt)
              ? "topVideoStyle"
              : "topVideoStyleLater"
          }
        >
          {campain.poster[0].type == "PICTURE" ? (
            <img className={"headMedia"} src={campain.poster[0].url}></img>
          ) : (
            <video
              preload={"auto"}
              autoPlay={true}
              muted={true}
              className={"headMediaVideo"}
              controls
            >
              <source src={campain.poster[0].url} />
            </video>
            // <video className={"headMediaVideo"} poster={campain.sharingImage} src={campain.poster[0].url} controls>
            //   Your browser does not support the video tag.
            // </video>
          )}
        </div>
      </div>
      {new Date() > new Date(campain.endingAt) && (
        <div style={{ width: "33%" }}></div>
      )}
      {new Date(campain.endingAt) > new Date() && (
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        //
        <>
          <div
            className={"TopDon"}
            style={{
              display: isMobile ? "none" : "block",
              position: "fixed",
              bottom: "50px",
              right: "0px",
            }}
          >
            <div className="assoc-donation-block">
              <div className="topDonBox">
                <div className="handContainer">
                  <img width="24px" src={Hand} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="topDonBoxTitle">
                    {t("Campaign.MakeDonation")}
                  </div>
                  <div className="topDonBoxCerfa">{t("Campaign.GetCerfa")}</div>
                </div>
              </div>
              <span
                className="assoc-donation-block-title"
                style={{ fontSize: "16px", marginTop: "14px" }}
              >
                {t("Campaign.Amount")}
              </span>
              <div className="assoc-donation-block-amountLine">
                <div
                  onClick={() => {
                    setAmount(26);
                  }}
                  className={
                    amount == 26
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  26{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                <div
                  onClick={() => {
                    setAmount(52);
                  }}
                  className={
                    amount == 52
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  52{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
              </div>
              <div className="assoc-donation-block-amountLine">
                <div
                  onClick={() => {
                    setAmount(126);
                  }}
                  className={
                    amount == 126
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  126{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                <div
                  onClick={() => {
                    setAmount(260);
                  }}
                  className={
                    amount == 260
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  260{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                {/* <div onClick={() => {setAmount(500)}} className={amount == 500 ? "assoc-donation-block-amount assoc-donation-block-amount-selected" : "assoc-donation-block-amount"}>500 €</div> */}
              </div>
              <div className="assoc-donation-block-amountLine">
                <div
                  onClick={() => {
                    setAmount(520);
                  }}
                  className={
                    amount == 520
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  520{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                <div
                  onClick={() => {
                    setAmount(1040);
                  }}
                  className={
                    amount == 1040
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  1040{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                {/* <div
                onClick={() => {
                  setAmount(400);
                }}
                className={
                  amount == 400
                    ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                    : "assoc-donation-block-amount"
                }
                style={{paddingTop:"4px",paddingBottom:"4px", width:"24%"}}
              >
                400 €
              </div> */}
                {/* <div onClick={() => {setAmount(500)}} className={amount == 500 ? "assoc-donation-block-amount assoc-donation-block-amount-selected" : "assoc-donation-block-amount"}>500 €</div> */}
              </div>
              <div
                style={{ marginTop: 10, display: "flex", alignItems: "center" }}
                className="assoc-donation-block-amountLine"
              >
                <input
                  style={{
                    minWidth: "100px",
                    width: "30%",
                    height: "12px",
                    borderRadius: "10px 0px 0px 10px",
                  }}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  onFocus={() => setAmount(null)}
                  type="number"
                  placeholder={t("Campaign.OtherAmount")}
                  className="assoc-donation-block-amount-input"
                  value={amount}
                ></input>
                <select
                  className="select-donFor"
                  value={currency}
                  placeholder="EUR €"
                  onChange={(e) => setCurrency(e.target.value)}
                  style={{
                    width: "100px",
                    height: "38px",
                    fontSize: "12px",
                    borderRadius: "0px 10px 10px 0px",
                    marginTop: "4px",
                    marginBottom: "4px",
                    marginRight: "92px",
                    marginLeft: "0px",
                  }}
                >
                  {options.map((opt, index) => {
                    return (
                      <option key={index} value={opt.value}>
                        {opt.label}
                      </option>
                      // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                    );
                  })}
                </select>
              </div>
              {campain && campain.affects.length > 1 && (
                // <div style={{width:"100%", display: "flex", justifyContent: "center"}}>
                <select
                  className="select-donFor"
                  value={donFor}
                  onChange={(e) => setDonFor(e.target.value)}
                  style={{ width: "150px", fontSize: "12px" }}
                >
                  {campain.affects.map((aff, index) => {
                    return (
                      <option key={index} value={aff.idAffect}>
                        {aff.val}
                      </option>
                      // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                    );
                  })}
                </select>
                // </div>
              )}
              <div className="assoc-donation-block-amount-checkbox-wrapper">
                <div className="assoc-donation-block-amount-checkbox-line">
                  <input
                    onChange={() => setOneTime(true)}
                    checked={oneTime}
                    style={{
                      width: 25,
                      height: 25,
                      backgroundColor: "#f1f0ec",
                    }}
                    type="radio"
                    className="radio-toolbar"
                  />
                  <div className="assoc-donation-block-amount-checkbox-text">
                    {t("Campaign.DonatePeriod")}
                  </div>
                </div>
                <div
                  style={{ marginTop: 8 }}
                  className="assoc-donation-block-amount-checkbox-line"
                >
                  <input
                    onChange={() => setOneTime(false)}
                    checked={!oneTime}
                    style={{ width: 25, height: 25 }}
                    type="radio"
                    className="radio-toolbar"
                  />
                  <div className="assoc-donation-block-amount-checkbox-text">
                    {t("Campaign.DonateSeveral")}
                  </div>
                </div>
                {!oneTime && (
                  // <Select
                  //   value={intervals}
                  //   onChange={(val) => {
                  //     //console.log(val);
                  //     setIntervals(val);
                  //   }}
                  //   placeholder={paymentOptions[0].label}
                  //   classNamePrefix="assoc-select"
                  //   className="donation-months-container"
                  //   options={paymentOptions}
                  // />
                  <select
                    // className="select-donFor"
                    value={intervals}
                    placeholder={paymentOptions[0].label}
                    onChange={(e) => setIntervals(e.target.value)}
                    style={{
                      width: "150px",
                      borderRadius: "010px",
                      marginTop: "4px",
                      marginBottom: "4px",
                      marginRight: "auto",
                      marginLeft: "auto",
                      fontSize: "16px",
                      padding: "12px 16px",
                      border: "1px solid #9a406b",
                      borderRadius: "10px",
                      color: "#dd3476",
                    }}
                  >
                    {paymentOptions.map((opt, index) => {
                      return (
                        <option key={index} value={opt.value}>
                          {opt.label}
                        </option>
                        // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                      );
                    })}
                  </select>
                )}
              </div>
              {!oneTime && currency == "eur" && (
                <div
                  className="yourAmountText"
                  style={{
                    fontSize: "12px",
                    width: "80%",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  {t("Dashboard.donate.Total")}
                  {numberWithSpaces(Number(amount), currency)}{" "}
                  {t("Donate.spread")} {Number(intervals)} {t("Donate.month")}{" "}
                  Soit{" "}
                  {numberWithSpaces(
                    Number(amount) / Number(intervals),
                    currency
                  )}{" "}
                  {t("Donate.setByMonth")} <br />
                  {t("Donate.Tax.Reduction")}{" "}
                  {numberWithSpaces(
                    Number(Number(amount) - Number(amount) * 0.66),
                    currency
                  )}
                  .
                </div>
              )}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUserState({
                    ...userState,
                    donation: {
                      amount: amount,
                      currency: currency,
                      times: oneTime,
                      donateToId: campain.id,
                      sponsorId: sponsor ? sponsor.id : null,
                      returnUrl: window.location.href,
                      intervals: intervals,
                      donFor: donFor,
                    },
                  });
                  history.push("/donate");
                }}
                className="assoc-donation-block-amount-donate-button"
              >
                {t("Campaign.MakeDonation")}
              </div>
              <div className="assoc-donation-block-amount-donate-txt">
                {t("Campaign.GetCerfa")}
              </div>
            </div>
          </div>
          <div
            className={"TopDon"}
            style={{
              opacity: isMobile ? "1" : "0",
              pointerEvents: isMobile ? "auto" : "none",
            }}
          >
            <div className="assoc-donation-block">
              <div className="topDonBox">
                <div className="handContainer">
                  <img width="24px" src={Hand} />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="topDonBoxTitle">
                    {t("Campaign.MakeDonation")}
                  </div>
                  <div className="topDonBoxCerfa">{t("Campaign.GetCerfa")}</div>
                </div>
              </div>
              <span
                className="assoc-donation-block-title"
                style={{ fontSize: "16px", marginTop: "14px" }}
              >
                {t("Campaign.Amount")}
              </span>
              <div className="assoc-donation-block-amountLine">
                <div
                  onClick={() => {
                    setAmount(26);
                  }}
                  className={
                    amount == 26
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  26{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                <div
                  onClick={() => {
                    setAmount(52);
                  }}
                  className={
                    amount == 52
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  52{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
              </div>
              <div className="assoc-donation-block-amountLine">
                <div
                  onClick={() => {
                    setAmount(126);
                  }}
                  className={
                    amount == 126
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  126{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                <div
                  onClick={() => {
                    setAmount(260);
                  }}
                  className={
                    amount == 260
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  260{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                {/* <div onClick={() => {setAmount(500)}} className={amount == 500 ? "assoc-donation-block-amount assoc-donation-block-amount-selected" : "assoc-donation-block-amount"}>500 €</div> */}
              </div>
              <div className="assoc-donation-block-amountLine">
                <div
                  onClick={() => {
                    setAmount(520);
                  }}
                  className={
                    amount == 520
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  520{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                <div
                  onClick={() => {
                    setAmount(1040);
                  }}
                  className={
                    amount == 1040
                      ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                      : "assoc-donation-block-amount"
                  }
                  style={{
                    paddingTop: "4px",
                    paddingBottom: "4px",
                    width: "30%",
                  }}
                >
                  1040{" "}
                  {currency === "eur"
                    ? "€"
                    : currency == "usd"
                    ? "$"
                    : currency == "ils"
                    ? "₪"
                    : "£"}
                </div>
                {/* <div
                      onClick={() => {
                        setAmount(400);
                      }}
                      className={
                        amount == 400
                          ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                          : "assoc-donation-block-amount"
                      }
                      style={{paddingTop:"4px",paddingBottom:"4px", width:"24%"}}
                    >
                      400 €
                    </div> */}
                {/* <div onClick={() => {setAmount(500)}} className={amount == 500 ? "assoc-donation-block-amount assoc-donation-block-amount-selected" : "assoc-donation-block-amount"}>500 €</div> */}
              </div>
              <div
                style={{ marginTop: 10, display: "flex", alignItems: "center" }}
                className="assoc-donation-block-amountLine"
              >
                <input
                  style={{
                    minWidth: "100px",
                    width: "30%",
                    height: "12px",
                    borderRadius: "10px 0px 0px 10px",
                  }}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  onFocus={() => setAmount(null)}
                  type="number"
                  placeholder={t("Campaign.OtherAmount")}
                  className="assoc-donation-block-amount-input"
                  value={amount}
                ></input>
                <select
                  className="select-donFor"
                  value={currency}
                  placeholder="EUR €"
                  onChange={(e) => setCurrency(e.target.value)}
                  style={{
                    width: "100px",
                    height: "38px",
                    fontSize: "12px",
                    borderRadius: "0px 10px 10px 0px",
                    marginTop: "4px",
                    marginBottom: "4px",
                    marginRight: "92px",
                    marginLeft: "0px",
                  }}
                >
                  {options.map((opt, index) => {
                    return (
                      <option key={index} value={opt.value}>
                        {opt.label}
                      </option>
                      // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                    );
                  })}
                </select>
              </div>
              {campain && campain.affects.length > 1 && (
                // <div style={{width:"100%", display: "flex", justifyContent: "center"}}>
                <select
                  className="select-donFor"
                  value={donFor}
                  onChange={(e) => setDonFor(e.target.value)}
                  style={{ width: "150px", fontSize: "12px" }}
                >
                  {campain.affects.map((aff, index) => {
                    return (
                      <option key={index} value={aff.idAffect}>
                        {aff.val}
                      </option>
                      // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                    );
                  })}
                </select>
                // </div>
              )}
              <div className="assoc-donation-block-amount-checkbox-wrapper">
                <div className="assoc-donation-block-amount-checkbox-line">
                  <input
                    onChange={() => setOneTime(true)}
                    checked={oneTime}
                    style={{
                      width: 25,
                      height: 25,
                      backgroundColor: "#f1f0ec",
                    }}
                    type="radio"
                    className="radio-toolbar"
                  />
                  <div className="assoc-donation-block-amount-checkbox-text">
                    {t("Campaign.DonatePeriod")}
                  </div>
                </div>
                <div
                  style={{ marginTop: 8 }}
                  className="assoc-donation-block-amount-checkbox-line"
                >
                  <input
                    onChange={() => setOneTime(false)}
                    checked={!oneTime}
                    style={{ width: 25, height: 25 }}
                    type="radio"
                    className="radio-toolbar"
                  />
                  <div className="assoc-donation-block-amount-checkbox-text">
                    {t("Campaign.DonateSeveral")}
                  </div>
                </div>
                {!oneTime && (
                  // <Select
                  //   value={intervals}
                  //   onChange={(val) => {
                  //     //console.log(val);
                  //     setIntervals(val);
                  //   }}
                  //   placeholder={paymentOptions[0].label}
                  //   classNamePrefix="assoc-select"
                  //   className="donation-months-container"
                  //   options={paymentOptions}
                  // />
                  <select
                    // className="select-donFor"
                    value={intervals}
                    placeholder={paymentOptions[0].label}
                    onChange={(e) => setIntervals(e.target.value)}
                    style={{
                      width: "150px",
                      borderRadius: "010px",
                      marginTop: "4px",
                      marginBottom: "4px",
                      marginRight: "auto",
                      marginLeft: "auto",
                      fontSize: "16px",
                      padding: "12px 16px",
                      border: "1px solid #9a406b",
                      borderRadius: "10px",
                      color: "#dd3476",
                    }}
                  >
                    {paymentOptions.map((opt, index) => {
                      return (
                        <option key={index} value={opt.value}>
                          {opt.label}
                        </option>
                        // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                      );
                    })}
                  </select>
                )}
              </div>
              {!oneTime && currency == "eur" && (
                <div
                  className="yourAmountText"
                  style={{
                    fontSize: "12px",
                    width: "80%",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  {t("Dashboard.donate.Total")}
                  {numberWithSpaces(Number(amount), currency)}{" "}
                  {t("Donate.spread")} {Number(intervals)} {t("Donate.month")}{" "}
                  Soit{" "}
                  {numberWithSpaces(
                    Number(amount) / Number(intervals),
                    currency
                  )}{" "}
                  {t("Donate.setByMonth")} <br />
                  {t("Donate.Tax.Reduction")}{" "}
                  {numberWithSpaces(
                    Number(Number(amount) - Number(amount) * 0.66),
                    currency
                  )}
                  .
                </div>
              )}
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUserState({
                    ...userState,
                    donation: {
                      amount: amount,
                      currency: currency,
                      times: oneTime,
                      donateToId: campain.id,
                      sponsorId: sponsor ? sponsor.id : null,
                      returnUrl: window.location.href,
                      intervals: intervals,
                      donFor: donFor,
                    },
                  });
                  history.push("/donate");
                }}
                className="assoc-donation-block-amount-donate-button"
              >
                {t("Campaign.MakeDonation")}
              </div>
              <div className="assoc-donation-block-amount-donate-txt">
                {t("Campaign.GetCerfa")}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const TopAssocParain = ({
  campain,
  history,
  sponsor,
  sponsorNbs,
  donations,
}) => {
  const [userState, setUserState] = useContext(UserContext);
  const { t } = useTranslation();
  var total = 0;
  var currency = "eur";
  if (sponsorNbs && sponsorNbs.funding) {
    for (let i = 0; i < sponsorNbs.funding.length; i++) {
      const element = sponsorNbs.funding[i];
      total += element ? element.amount : 0;
    }
  }

  var totalRecu = setResult(donations, sponsor.id);
  var percentage =
    sponsorNbs && sponsorNbs.funding
      ? calcPercentage(totalRecu, sponsor.amount)
      : 0;

  var donationsParrain = donations.filter(
    (donation) => donation.sponsor && donation.sponsor.id == sponsor.id
  );

  var percentageComplete = 0;
  if (percentage > 100) {
    var percentageCompleteTemp = percentage - 100;
    percentageComplete = 100 - percentageCompleteTemp;
  }
  return (
    <div
    //   className={"topVideoCont"}
    //   style={{
    //     flex: 1,
    //     display: "flex",
    //     flexDirection: "row",
    //     marginTop: 100,
    //     width: "78%",
    //     marginLeft: "auto",
    //     marginRight: "auto",
    //   }}
    >
      <div
      //   className="parain-block"
      >
        <div className="parainContainer">
          <img className="logoParrain" src={sponsor.picture} />
          <div className="parrain-desc">
            <div
              style={{
                marginTop: 10,
                marginLeft: 15,
                marginRight: 10,
                color: "#d22164",
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {sponsor.name}
            </div>
            <div style={{ marginLeft: 15, marginRight: 10, color: "#301824" }}>
              {t("Campaign.SponsorText")}{" "}
              <span
                style={{
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  color: "#301824",
                }}
              >
                {numberWithSpaces(sponsor.amount)}
              </span>
              <div style={{ color: "#301824" }}>
                {t("Campaign.SponsorGoal")}
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", fontSize: "1.55em", marginTop: 20 }}>
          {t("Campaign.SponsorAmount")}{" "}
          <span style={{ fontWeight: 500, whiteSpace: "nowrap" }}>
            {/* {numberWithSpaces(total)} */}
            {totalRecu}€
          </span>
        </div>
        <div
        //   style={{
        //     width: "90%",
        //     margin: "auto",
        //     marginTop: 40,
        //     display: "flex",
        //     flexDirection: "row",
        //     justifyContent: "center",
        //     alignItems: "center",
        //   }}
        >
          <div className={"dontation-header-containers-pbCont"}>
            <div className="dontation-header-containers-progressBar">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  width: `${percentage > 100 ? 100 : percentage}%`,
                }}
                className="dontation-header-containers-progress"
              >
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#fff",
                    opacity: percentage > 3 ? "1" : "0",
                  }}
                >
                  {percentage}%
                </div>
              </div>
              {percentageComplete > 0 && (
                <div
                  style={{
                    position: "relative",
                    top: "-32px",
                    left: `calc(${percentageComplete}% - 16px)`,
                  }}
                  className=""
                >
                  <img src={check} alt="" />
                </div>
              )}
            </div>
            {/* <div style={{marginLeft: 15, fontSize: '1.3em', fontWeight: '500'}}>{percentage}%</div> */}
          </div>
          {/* <div className="dontation-header-containers-progressBar-sponsor" style={{width:"50%", margin:"50px auto"}}>
            <div
              style={{ width: `${percentage > 100 ? 100 : percentage}%` }}
              className="dontation-header-containers-progress"
            ></div>
          <div style={{ marginLeft: 15, fontSize: "1.3em", fontWeight: "500" }}>
            {percentage}%
          </div>
          </div> */}
        </div>

        <div className="containerPrincipal">
          <TopAssoc
            history={history}
            campain={campain}
            userState={userState}
            setUserState={setUserState}
            sponsor={sponsor}
            donations={donationsParrain}
          />
        </div>

        {/* <CarouselDonors sponsor={sponsor} donations={donations} /> */}
      </div>
      {/* <div
        className="topVideoStyle"
        style={{ width: "52%", marginLeft: "auto" }}
      > */}
      {/* {campain.poster[0].type == "PICTURE" ? (
          <img className="headMedia" src={campain.poster[0].url}></img>
        ) : (
          <video className="headMedia" src={campain.poster[0].url} controls>
            Your browser does not support the video tag.
          </video>
        )} */}
      {/* <div style={{marginTop: 20}} className={"assoc-comp-content-title"}>{campain.title[0].content}</div> */}
      {/* <Assoc
          campainName={campain.title[0].content}
          title={campain.associationName}
          desc={campain.description[0].content}
          img={campain.associationLogoUrl}
        /> */}
      {/* </div> */}
    </div>
  );
};

const Carousel = ({
  pictures = [],
  videos = [],
  style = {},
  reverse = false,
}) => {
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 1500,

    slidesToShow: isMobile
      ? 1
      : pictures.length + videos.length >= 3
      ? 3
      : pictures.length + videos.length,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div style={{ position: "relative", marginRight: 10, ...style }}>
      <Slider variableWidth ref={sliderRef} {...settings}>
        {!reverse &&
          pictures.map((picture, index) => (
            <div className="slider-block" key={index}>
              <img className="assoc-slider-img" src={picture} />
            </div>
          ))}
        {!reverse &&
          videos.map((picture, index) => (
            <div className="slider-block" key={index}>
              <video
                preload={"auto"}
                autoPlay={true}
                muted={true}
                controls
                className="assoc-slider-img"
              >
                <source src={picture} />
              </video>
              {/* <video controls className="assoc-slider-img" src={picture} /> */}
            </div>
          ))}
        {reverse &&
          videos.map((picture, index) => (
            <div className="slider-block" key={index}>
              <video
                preload={"auto"}
                autoPlay={true}
                muted={true}
                controls
                className="assoc-slider-img"
              >
                <source src={picture} />
              </video>
              {/* <video controls className="assoc-slider-img" src={picture} /> */}
            </div>
          ))}
        {reverse &&
          pictures.map((picture, index) => (
            <div className="slider-block" key={index}>
              <img className="assoc-slider-img" src={picture} />
            </div>
          ))}
      </Slider>
      {/* {(pictures.length + videos.length) > 3 &&
            <>
                <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 230, top: 0, display: 'flex'}}>
                <img onClick={() => {
                    //console.log(sliderRef);
                    sliderRef.current.slickPrev()
                }} style={{width: 35, marginLeft: 'auto', marginRight: 'auto',  zIndex: 30, transform: 'rotate(90deg)'}} src={arrowDown}></img>
                </div>
                <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 230, top: 0, right: 0, display: 'flex'}}>
                <img onClick={() => {
                    //console.log(sliderRef);
                    sliderRef.current.slickNext()
                }}  style={{width: 35, marginLeft: 'auto', marginRight: 'auto',  zIndex: 30, transform: 'rotate(270deg)'}} src={arrowDown}></img>
                </div>
            </>
        } */}
    </div>
  );
};

const CarouselParain = ({
  campain,
  history,
  sponsor,
  sponsorNbs,
  donations,
}) => {
  const { t } = useTranslation();
  var total = 0;
  var currency = "eur";
  if (sponsorNbs && sponsorNbs.funding) {
    for (let i = 0; i < sponsorNbs.funding.length; i++) {
      const element = sponsorNbs.funding[i];
      total += element ? element.amount : 0;
    }
  }
  var totalRecu = setResult(donations, sponsor.id);
  var percentage =
    sponsorNbs && sponsorNbs.funding
      ? calcPercentage(totalRecu, sponsor.amount)
      : 0;
  const sliderRef = useRef(null);
  const sliderContainerRef = useRef(null);
  var calcSize =
    sliderContainerRef && sliderContainerRef.current
      ? Math.trunc(sliderContainerRef.current.offsetWidth / 270)
      : sponsor.length;
  calcSize = calcSize > sponsor.length ? sponsor.length : calcSize;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 1500,

    slidesToScroll: 1,
    autoplay: true,
    dots: false,
    infinite: true,
    // centerMode: true,
    arrows: true,

    slidesToShow: isMobile ? 1 : calcSize,
    // lazyLoad: true,
    // variableWidth: 190,
  };

  const [resDon, setResDon] = useState([]);
  const [amountDon, setAmountDon] = useState([]);
  useEffect(() => {
    var allRes = [];
    var arrDon = [];
    sponsor.map((spon) => {
      var amount = spon.amount;
      var recolte = 0;
      var amountDonTemp = 0;
      for (let i = 0; i < donations.length; i++) {
        if (donations[i].sponsor) {
          if (donations[i].sponsor.id === spon.id) {
            recolte = recolte + donations[i].amount;
            amountDonTemp++;
          }
        }
      }
      var temp = recolte > 0 ? calcPercentage(recolte, amount) : 0;
      var resTemp = {
        amount: amount,
        recolte: recolte,
        percent: temp,
        ok: temp - 100,
      };

      allRes.push(resTemp);
      arrDon.push(amountDonTemp);
    });
    setAmountDon(arrDon);
    setResDon(allRes);
  }, [sponsor, donations]);
  return (
    <div
      ref={sliderContainerRef}
      style={{
        marginTop: 30,
        position: "relative",
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Slider ref={sliderRef} {...settings}>
        {sponsor.map((sponsor, index) => (
          <div
            onClick={() => {
              window
                .open(
                  `/${campain.linkParam}?sponsor=${
                    sponsor.name
                    //   .replaceAll(
                    //   " ",
                    //   "-"
                    // )
                  }`
                )
                .focus();
            }}
            className="parain_block"
            style={{ display: "flex", flexDirection: "column!important" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "flex-end",
                margin: "20px 0px",
              }}
            >
              <div
                key={index}
                onClick={() => {
                  window
                    .open(`/${campain.linkParam}?sponsor=${sponsor.name}`)
                    .focus();
                }}
              >
                <img
                  className="sponsor-slider-img"
                  src={sponsor.picture}
                  style={{ width: "75px", height: "75px", borderRadius: "50%" }}
                />
                {/* <span style={{color: '#507CAB', fontWeight: 'bold', fontSize: 16, whiteSpace: 'nowrap'}}>Objectif: {numberWithSpaces(sponsor.amount)}</span> */}
              </div>
              <div
                style={{
                  fontSize: 20,
                  marginTop: 5,
                  color: "#d12164",
                  textAlign: "center",
                }}
              >
                {sponsor.name}
              </div>
            </div>
            {/* {new Date() > new Date(campain.startingAt) && ( */}
            {1 == 1 && (
              <div style={{ marginBottom: "-10px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {resDon[index] && (
                    <div>{numberWithSpaces(resDon[index].recolte)}</div>
                  )}
                  {resDon[index] && (
                    <div>{numberWithSpaces(resDon[index].amount)}</div>
                  )}
                </div>
                <div
                  className="dontation-header-containers-progressBar-sponsor"
                  style={{ width: "90%", margin: "10px auto" }}
                >
                  {resDon[index] && resDon[index].percent > 0 && (
                    <div
                      style={{
                        width: `${
                          new Date() < new Date(campain.startingAt)
                            ? 0
                            : resDon[index].percent > 100
                            ? 100
                            : resDon[index].percent
                        }%`,
                      }}
                      className="dontation-header-containers-progress"
                    ></div>
                  )}
                </div>
                {resDon[index] && resDon[index].percent > 0 ? (
                  <div
                    style={{
                      marginLeft: 15,
                      fontSize: "1.3em",
                      fontWeight: "500",
                    }}
                  >
                    {new Date() < new Date(campain.startingAt)
                      ? 0
                      : resDon[index].percent}
                    %
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: 15,
                      fontSize: "1.3em",
                      fontWeight: "500",
                    }}
                  >
                    0%
                  </div>
                )}
              </div>
            )}
            {new Date() > new Date(campain.startingAt) &&
            resDon[index] &&
            resDon[index].percent > 100 ? (
              <div
                className="check-donor"
                style={{
                  left: `calc(100% - ${Number(resDon[index].ok)}% - 32px)`,
                }}
              >
                <img src={check} alt="" />
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  top: "-59px",
                  // left: `calc(100% - ${Number(resDon[index].ok)}% - 32px)`,
                }}
              >
                <img style={{ opacity: 0 }} src={check} alt="" />
              </div>
            )}
            {amountDon[index] ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: "20px",
                  color: "#48333D",
                  fontWeight: "bold",
                }}
              >
                {`${amountDon[index]} ${t("Charity.Dons")}`}{" "}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: "20px",
                  color: "#48333D",
                  fontWeight: "bold",
                }}
              >{`0 ${t("Charity.Dons")}`}</div>
            )}
          </div>
        ))}
      </Slider>
      {/* {sponsors.length >= 7 &&
        <>
            <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 350, top: 0, display: 'flex'}}>
            <img onClick={() => {
                //console.log(sliderRef);
                sliderRef.current.slickPrev()
            }} style={{width: 35, marginLeft: 'auto', marginRight: 'auto',  zIndex: 30, transform: 'rotate(90deg)'}} src={arrowDown}></img>
            </div>
            <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 350, top: 0, right: 0, display: 'flex'}}>
            <img onClick={() => {
                //console.log(sliderRef);
                sliderRef.current.slickNext()
            }}  style={{width: 35, marginLeft: 'auto', marginRight: 'auto',  zIndex: 30, transform: 'rotate(270deg)'}} src={arrowDown}></img>
            </div>
            </>
        } */}
    </div>
  );
};

const CarouselDonors = ({ donations, campain, sponsor }) => {
  const sliderRef = useRef(null);
  const { t } = useTranslation();

  donations = donations.filter(
    (donation) => donation.sponsor && donation.sponsor.id == sponsor.id
  );
  const settings = {
    dots: false,
    infinite: true,
    // centerMode: true,
    arrows: true,
    speed: 500,
    autoplaySpeed: 1500,

    slidesToShow: 1,
    // lazyLoad: true,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div style={{ marginTop: 30, position: "relative" }}>
      <Slider ref={sliderRef} variableWidth {...settings}>
        {donations.map((sponsor, index) => (
          <div key={index} onClick={() => {}} className="donor_block">
            <img className="donor-slider-img" src={donorProfile} />
            <div
              style={{
                fontSize: 14,
                textAlign: "center",
                marginTop: 10,
                height: 40,
                overflow: "hidden",
              }}
            >
              {sponsor.anonymous == true
                ? t("Campaign.SponsorAnonymous")
                : `${sponsor.donor.firstName} ${sponsor.donor.lastName}`}
            </div>
            <div style={{ fontSize: 14, textAlign: "center", marginTop: 10 }}>
              {numberWithSpaces(sponsor.amount, sponsor.currency)}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

const DonAssoc = ({
  history,
  campain,
  userState,
  setUserState,
  sponsor,
  setSelectedAward,
  setIsGiftModalOpen,
  selectedAward,
}) => {
  const [amount, setAmount] = useState(null);
  const [oneTime, setOneTime] = useState(true);
  const [currency, setCurrency] = useState(options[0].value);
  const { t } = useTranslation();

  const [urlLink, setUrlLink] = useState("");

  useEffect(() => {
    const urlTemp = window.location.href;
    const newUrl = urlTemp;
    // .replaceAll("%20", "-")
    setUrlLink(newUrl);
  }, []);

  return (
    <div
      className={"donAssocCont"}
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 100,
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div
        style={{
          width: isMobile ? "100%" : "56%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{ marginTop: 0, minHeight: 200 }}
          className={"assocContainer-donations-ranks"}
        >
          {campain.awards[0] && (
            <div
              onClick={() => {
                setUserState({
                  ...userState,
                  donation: {
                    amount: campain.awards[0].amount,
                    currency: "eur",
                    times: "1",
                    donateToId: campain.id,
                    sponsorId: sponsor ? sponsor.id : null,
                    returnUrl: window.location.href,
                  },
                });
                history.push("/donate");
              }}
              onMouseLeave={() => {
                setSelectedAward(null);
                setIsGiftModalOpen(false);
              }}
              onMouseEnter={() => {
                setSelectedAward(campain.awards[0]);
                setIsGiftModalOpen(true);
              }}
              className={"assocContainer-donations-block"}
            >
              {/* <img className="image-gift" src={gift1}/> */}
              <span className={"assocContainer-donations-price"}>
                {numberWithSpaces(campain.awards[0].amount)}
              </span>
              <span className={"assocContainer-donations-title"}>
                {campain.awards[0].title}
              </span>
            </div>
          )}
          {campain.awards[1] && (
            <div
              onClick={() => {
                setUserState({
                  ...userState,
                  donation: {
                    amount: campain.awards[1].amount,
                    currency: "eur",
                    times: "1",
                    donateToId: campain.id,
                    sponsorId: sponsor ? sponsor.id : null,
                    returnUrl: window.location.href,
                  },
                });
                history.push("/donate");
              }}
              onMouseLeave={() => {
                setSelectedAward(null);
                setIsGiftModalOpen(false);
              }}
              onMouseEnter={() => {
                setSelectedAward(campain.awards[1]);
                setIsGiftModalOpen(true);
                // setUserState({...userState, donation: {
                //     amount: campain.awards[1].amount,
                //     currency: {label: "Euro", value: 'eur'},
                //     times: '1',
                //     donateToId: campain.id,
                //     sponsorId: sponsor ? sponsor.id : null,
                //     returnUrl: window.location.href
                // }})
                // history.push('/donate')
              }}
              className={"assocContainer-donations-block"}
            >
              {/* <img className="image-gift" src={gift2}/> */}
              <span className={"assocContainer-donations-price"}>
                {numberWithSpaces(campain.awards[1].amount)}
              </span>
              <span className={"assocContainer-donations-title"}>
                {campain.awards[1].title}
              </span>
            </div>
          )}
          {campain.awards[2] && (
            <div
              onClick={() => {
                setUserState({
                  ...userState,
                  donation: {
                    amount: campain.awards[2].amount,
                    currency: "eur",
                    times: "1",
                    donateToId: campain.id,
                    sponsorId: sponsor ? sponsor.id : null,
                    returnUrl: window.location.href,
                  },
                });
                history.push("/donate");
              }}
              onMouseLeave={() => {
                setSelectedAward(null);
                setIsGiftModalOpen(false);
              }}
              onMouseEnter={() => {
                setSelectedAward(campain.awards[2]);
                setIsGiftModalOpen(true);

                // setUserState({...userState, donation: {
                //     amount: campain.awards[2].amount,
                //     currency: {label: "Euro", value: 'eur'},
                //     times: '1',
                //     donateToId: campain.id,
                //     sponsorId: sponsor ? sponsor.id : null,
                //     returnUrl: window.location.href
                // }})
                // history.push('/donate')
              }}
              className={"assocContainer-donations-block"}
            >
              {/* <img className="image-gift" src={gift3}/> */}
              <span className={"assocContainer-donations-price"}>
                {numberWithSpaces(campain.awards[2].amount)}
              </span>
              <span className={"assocContainer-donations-title"}>
                {campain.awards[2].title}
              </span>
            </div>
          )}
        </div>
        <div className={"assocContainer-donations-ranks"}>
          {campain.awards[3] && (
            <div
              onClick={() => {
                setUserState({
                  ...userState,
                  donation: {
                    amount: campain.awards[3].amount,
                    currency: "eur",
                    times: "1",
                    donateToId: campain.id,
                    sponsorId: sponsor ? sponsor.id : null,
                    returnUrl: window.location.href,
                  },
                });
                history.push("/donate");
              }}
              onMouseLeave={() => {
                setSelectedAward(null);
                setIsGiftModalOpen(false);
              }}
              onMouseEnter={() => {
                setSelectedAward(campain.awards[3]);
                setIsGiftModalOpen(true);

                // setUserState({...userState, donation: {
                //     amount: campain.awards[3].amount,
                //     currency: {label: "Euro", value: 'eur'},
                //     times: '1',
                //     donateToId: campain.id,
                //     sponsorId: sponsor ? sponsor.id : null,
                //     returnUrl: window.location.href
                // }})
                // history.push('/donate')
              }}
              className={"assocContainer-donations-block"}
            >
              {/* <img className="image-gift" src={gift4}/> */}
              <span className={"assocContainer-donations-price"}>
                {numberWithSpaces(campain.awards[3].amount)}
              </span>
              <span className={"assocContainer-donations-title"}>
                {campain.awards[3].title}
              </span>
            </div>
          )}
          {campain.awards[4] && (
            <div
              onClick={() => {
                setUserState({
                  ...userState,
                  donation: {
                    amount: campain.awards[4].amount,
                    currency: "eur",
                    times: "1",
                    donateToId: campain.id,
                    sponsorId: sponsor ? sponsor.id : null,
                    returnUrl: window.location.href,
                  },
                });
                history.push("/donate");
              }}
              onMouseLeave={() => {
                setSelectedAward(null);
                setIsGiftModalOpen(false);
              }}
              onMouseEnter={() => {
                setSelectedAward(campain.awards[4]);
                setIsGiftModalOpen(true);

                // setUserState({...userState, donation: {
                //     amount: campain.awards[4].amount,
                //     currency: {label: "Euro", value: 'eur'},
                //     times: '1',
                //     donateToId: campain.id,
                //     sponsorId: sponsor ? sponsor.id : null,
                //     returnUrl: window.location.href
                // }})
                // history.push('/donate')
              }}
              className={"assocContainer-donations-block"}
            >
              {/* <img className="image-gift" src={gift5}/> */}
              <span className={"assocContainer-donations-price"}>
                {numberWithSpaces(campain.awards[4].amount)}
              </span>
              <span className={"assocContainer-donations-title"}>
                {campain.awards[4].title}
              </span>
            </div>
          )}
          {campain.awards[5] && (
            <div
              onClick={() => {
                setUserState({
                  ...userState,
                  donation: {
                    amount: campain.awards[5].amount,
                    currency: "eur",
                    times: "1",
                    donateToId: campain.id,
                    sponsorId: sponsor ? sponsor.id : null,
                    returnUrl: window.location.href,
                  },
                });
                history.push("/donate");
              }}
              onMouseLeave={() => {
                setSelectedAward(null);
                setIsGiftModalOpen(false);
              }}
              onMouseEnter={() => {
                setSelectedAward(campain.awards[5]);
                setIsGiftModalOpen(true);

                // setUserState({...userState, donation: {
                //     amount: campain.awards[5].amount,
                //     currency: {label: "Euro", value: 'eur'},
                //     times: '1',
                //     donateToId: campain.id,
                //     sponsorId: sponsor ? sponsor.id : null,
                //     returnUrl: window.location.href
                // }})
                // history.push('/donate')
              }}
              className={"assocContainer-donations-block"}
            >
              {/* <img className="image-gift" src={gift5}/> */}
              <span className={"assocContainer-donations-price"}>
                {numberWithSpaces(campain.awards[5].amount)}
              </span>
              <span className={"assocContainer-donations-title"}>
                {campain.awards[5].title}
              </span>
            </div>
          )}
        </div>
        {!isMobile && selectedAward && selectedAward.logo && (
          <div style={{ top: -100 }} className="giftModal">
            <div style={{ fontSize: 20, textAlign: "center" }}>
              {t("Campaign.SponsorBecome")}{" "}
              {selectedAward && selectedAward.title}{" "}
              {selectedAward &&
                selectedAward.gift &&
                t("Campaign.SponsorGift") + selectedAward.gift}
            </div>
            {selectedAward && selectedAward.logo && (
              <img
                style={{
                  width: "50%",
                  objectFit: "contain",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src={selectedAward.logo}
              />
            )}
            {/* {selectedAward &&<div style={{marginTop: 'auto'}} onClick={async () => {
                    setIsGiftModalOpen(false)
                    setUserState({...userState, donation: {
                        amount: selectedAward.amount,
                        currency: {label: "Euro", value: 'eur'},
                        times: '1',
                        donateToId: campain.id,
                        sponsorId: sponsor ? sponsor.id : null,
                        returnUrl: window.location.href
                    }})
                    history.push('/donate')
                    }} className={'smbtn btnGeneral'}>Faire un don de {numberWithSpaces(selectedAward.amount)}</div>}
                <div onClick={async () => {setIsGiftModalOpen(false)}} className={'smbtn btnGeneral'}>Retour</div> */}
          </div>
        )}
      </div>
      <div
        style={{ marginLeft: "auto", marginRight: 0, width: "40%" }}
        className="assoc-donation-block"
      >
        <span className="assoc-donation-block-title">
          {t("Campaign.Amount")}
        </span>
        <div className="assoc-donation-block-amountLine">
          <div
            onClick={() => {
              setAmount(26);
            }}
            className={
              amount == 26
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
          >
            26{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
          <div
            onClick={() => {
              setAmount(52);
            }}
            className={
              amount == 52
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
          >
            52{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
          <div
            onClick={() => {
              setAmount(126);
            }}
            className={
              amount == 126
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
          >
            126{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
        </div>
        <div className="assoc-donation-block-amountLine">
          <div
            onClick={() => {
              setAmount(260);
            }}
            className={
              amount == 260
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
          >
            260{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
          <div
            onClick={() => {
              setAmount(520);
            }}
            className={
              amount == 520
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
          >
            520{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
          <div
            onClick={() => {
              setAmount(1040);
            }}
            className={
              amount == 1040
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
          >
            1040{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
        </div>
        <div className="assoc-donation-block-amountLine">
          <input
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            onFocus={() => setAmount(null)}
            type="number"
            placeholder={t("Campaign.OtherAmount")}
            className="assoc-donation-block-amount-free"
            value={amount}
          ></input>
          <Select
            value={currency}
            onChange={(val) => {
              setCurrency(val);
            }}
            placeholder="EUR €"
            classNamePrefix="assoc-select"
            className="react-select-container"
            options={options}
          />
          {/* <div className="assoc-donation-block-amount">Euro €</div> */}
        </div>
        <div className="assoc-donation-block-amount-checkbox-wrapper">
          <div className="assoc-donation-block-amount-checkbox-line">
            <input
              onChange={() => setOneTime(true)}
              checked={oneTime}
              style={{ width: 25, height: 25 }}
              type="radio"
              className="radio-toolbar"
            />
            <div className="assoc-donation-block-amount-checkbox-text">
              {t("Campaign.DonatePeriod")}
            </div>
          </div>
          <div
            style={{ marginTop: 8 }}
            className="assoc-donation-block-amount-checkbox-line"
          >
            <input
              onChange={() => setOneTime(false)}
              checked={!oneTime}
              style={{ width: 25, height: 25 }}
              type="radio"
              className="radio-toolbar"
            />
            <div className="assoc-donation-block-amount-checkbox-text">
              {t("Campaign.DonateSeveral")}
            </div>
          </div>
          {!oneTime && (
            <Select
              placeholder={paymentOptions[0].label}
              classNamePrefix="assoc-select"
              className="donation-months-container"
              options={paymentOptions}
            />
          )}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setUserState({
              ...userState,
              donation: {
                amount: amount,
                currency: currency,
                times: oneTime ? "1" : "12",
                donateToId: campain.id,
                sponsorId: sponsor ? sponsor.id : null,
                returnUrl: window.location.href,
              },
            });
            history.push("/donate");
          }}
          className="assoc-donation-block-amount-donate"
        >
          {t("Campaign.MakeDonation")}
        </div>
        <div className="assoc-donation-block-amount-donate-txt">
          {t("Campaign.GetCerfa")}
        </div>
      </div>
    </div>
  );
};

const renderer = ({ days, hours, minutes, seconds, completed, props }) => {
  if (days < 10) {
    days = "0" + days;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown in current campain
    return (
      <div className="CountdownContainer">
        <div className="CountdownTitle">{props.status}</div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className="CountdownRemains">{props.title}</div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">{days}</div>
            <div className="CountdownDay">{props.d}</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">:</div>
            <div style={{ opacity: "0" }}>:</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">{hours}</div>
            <div className="CountdownDay">{props.h}</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">:</div>
            <div style={{ opacity: "0" }}>:</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">{minutes}</div>
            <div className="CountdownDay">{props.m}</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">:</div>
            <div style={{ opacity: "0" }}>:</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">{seconds}</div>
            <div className="CountdownDay">{props.s}</div>
          </div>
        </div>
      </div>

      //   <div className="Countdown">
      //       <span>{props.title}</span>
      //       {`${days} : ${hours} : ${minutes} : ${seconds} `}
      //       </div>
    );
    //   <span>{`${days} : ${hours} H : ${minutes} M : ${seconds} S`}</span>;
  }
};

const renderer2 = ({ days, hours, minutes, seconds, completed, props }) => {
  if (days < 10) {
    days = "0" + days;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown in current campain
    return (
      <div className="CountdownContainer">
        <div className="CountdownTitle">{props.status}</div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className="CountdownRemains">{props.title}</div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">{days}</div>
            <div className="CountdownDay">{props.d}</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">:</div>
            <div style={{ opacity: "0" }}>:</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">{hours}</div>
            <div className="CountdownDay">{props.h}</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">:</div>
            <div style={{ opacity: "0" }}>:</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">{minutes}</div>
            <div className="CountdownDay">{props.m}</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">:</div>
            <div style={{ opacity: "0" }}>:</div>
          </div>
          <div className="CountdownDayDot">
            <div className="CountdownNumber">{seconds}</div>
            <div className="CountdownDay">{props.s}</div>
          </div>
        </div>
      </div>

      //   <div className="Countdown">
      //       <span>{props.title}</span>
      //       {`${days} : ${hours} : ${minutes} : ${seconds} `}
      //       </div>
    );
    //   <span>{`${days} : ${hours} H : ${minutes} M : ${seconds} S`}</span>;
  }
};

const IlReste = ({ date, renderer, futur }) => {
  const { t } = useTranslation();
  const [isFutur, setIsFutur] = useState(null);
  useState(() => {
    if (futur) {
      setIsFutur(true);
    } else {
      setIsFutur(false);
    }
  }, [futur]);
  return (
    <Countdown
      date={date}
      renderer={renderer}
      status={isFutur ? t("campainStarting") : t("Campaign.Current")}
      title={isFutur ? "" : t("Home.IlReste")}
      d={t("Campaign.jours")}
      h={t("Campaign.heures")}
      m={t("Campaign.minutes")}
      s={t("Campaign.secondes")}
    />
  );
};

const ProgressBar = ({ campain }) => {
  const { t } = useTranslation();
  var isFuture = new Date(campain.startingAt) > new Date();

  var total = 0;
  var currency = "eur";

  if (isFuture == false && campain.funding) {
    for (let i = 0; i < campain.funding.length; i++) {
      const element = campain.funding[i];

      try {
        total += fx(element.amount)
          .from(element.currency.toUpperCase())
          .to("EUR");
      } catch (error) {}
    }
  }
  var percentage = calcPercentage(total, campain.goal.amount);
  var percentageBonus = 0;
  var percentageComplete = 0;
  if (percentage > 100) {
    var percentageCompleteTemp = percentage - 100;
    percentageComplete = 100 - percentageCompleteTemp;
  }

  if (campain.goal.amountBonus) {
    percentageBonus = calcPercentage(total, campain.goal.amountBonus);
    // percentageComplete = calcPercentage(campain.goal.amount,campain.goal.amount) - (110-percentageBonus)
    percentageComplete = calcPercentage(
      campain.goal.amount,
      campain.goal.amountBonus - campain.goal.amount
    );
    var percentageBonusComplete = 0;
    // if (percentageBonus > 100) {
    //   var percentageBonusCompleteTemp = percentageBonus - 100;
    //   percentageBonusComplete = 100 - percentageBonusCompleteTemp;
    // }
  }

  var currencyLogo = "";
  if (campain.goal.currency == "eur") {
    currencyLogo = "€";
  } else if (campain.goal.currency == "usd") {
    currencyLogo = "$";
  } else if (campain.goal.currency == "ils") {
    currencyLogo = "₪";
  } else if (campain.goal.currency == "gbp") {
    currencyLogo = "£";
  }

  return (
    <div className={"progressBarContainer"}>
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            width: "80%",
            margin: "auto",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div className="progressBarAmount">
            <div style={{ color: "#d12164", fontWeight: "600" }}>
              {t("Charity.Funds")}
            </div>
            {numberWithSpaces(total)}
          </div>
          {percentage > 100 && percentageBonus && (
            <div className="progressBarAmount">
              <div style={{ color: "#d12164", fontWeight: "600" }}>
                {t("Campaign.Goal")}
              </div>
              {numberWithSpaces(campain.goal.amount)}
            </div>
          )}
          {percentage > 100 ? (
            percentageBonus ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ color: "#d12164", fontWeight: "600" }}>
                  {t("Campaign.BonusGoal")}
                </div>
                <div className="progressBarAmount">
                  {numberWithSpaces(campain.goal.amountBonus)}
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ color: "#d12164", fontWeight: "600" }}>Bonus</div>
                <div className="progressBarAmount">
                  {numberWithSpaces(total)}
                </div>
              </div>
            )
          ) : (
            <div className="progressBarAmount">
              <div style={{ color: "#d12164", fontWeight: "600" }}>
                {t("Campaign.Goal")}
              </div>
              {numberWithSpaces(campain.goal.amount)}
            </div>
          )}
        </div>
        <div className={"dontation-header-containers-pbCont"}>
          <div className="dontation-header-containers-progressBar">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                // width: `${percentage > 100 && !percentageBonus ? 100 : percentage<100 && !percentageBonus ?percentage:percentage>100 && percentageBonus>100?100:percentageBonus}%`,
                width: `${
                  percentage > 100 && !percentageBonus
                    ? 100
                    : percentage > 100 && percentageBonus
                    ? 50
                    : percentage
                }%`,
              }}
              className="dontation-header-containers-progress"
            >
              <div
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                  opacity: percentage > 3 ? "1" : "0",
                }}
              >
                {percentage}%
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                width: `${
                  percentage > 100 && percentageBonus > 100
                    ? 100
                    : percentageBonus
                }%`,
              }}
              className="dontation-header-containers-progress-bonus"
            ></div>
            {percentage > 100 && (
              <div
                style={{
                  position: "relative",
                  top: "-32px",
                  left:
                    percentageBonus > 0
                      ? `calc(50% - 32px)`
                      : `calc(100% - 32px)`,
                  zIndex: "1002",
                }}
                className=""
              >
                <img src={check} alt="" />
              </div>
            )}
          </div>
          {/* <div style={{marginLeft: 15, fontSize: '1.3em', fontWeight: '500'}}>{percentage}%</div> */}
        </div>
      </div>
    </div>
  );
};

const DonGeneral = ({
  campain,
  history,
  userState,
  setUserState,
  sponsor,
  donations,
}) => {
  const [amount, setAmount] = useState(null);
  const [currency, setCurrency] = useState(options[0].value);
  const [oneTime, setOneTime] = useState(true);
  const [intervals, setIntervals] = useState("12");
  const { t } = useTranslation();
  const [donFor, setDonFor] = useState("");
  const [donTemp, setDonTemp] = useState(null);

  const [urlLink, setUrlLink] = useState("");

  useEffect(() => {
    const urlTemp = window.location.href;
    const newUrl = urlTemp;
    // .replaceAll("%20", "-")
    setUrlLink(newUrl);
  }, []);

  const handleVal = (id, affects) => {
    affects.forEach((aff) => {
      document.getElementById(aff.idAffect).classList.remove("checked");
    });
    var checked = document.getElementById(id);
    checked.classList.add("checked");
    var don = affects.filter((aff) => {
      return aff.idAffect == id;
    });
    setDonFor(don[0].val);
  };
  useEffect(() => {
    const donateurTemp = [];
    //    if(donations.length<4){
    donations[0] && donateurTemp.push(donations[0]);
    donations[1] && donateurTemp.push(donations[1]);
    donations[2] && donateurTemp.push(donations[2]);
    donations[3] && donateurTemp.push(donations[3]);
    setDonTemp(donateurTemp);
  }, [donations]);
  //    }else{
  //        donateurTemp.push(donations[donations.length-1])
  //        donateurTemp.push(donations[donations.length-2])
  //        donateurTemp.push(donations[donations.length-3])
  //        donateurTemp.push(donations[donations.length-4])
  //    }
  return (
    <div className="donGeneralContainer">
      <div
        style={{ justifyContent: "space-between" }}
        className="assoc-donation-block-amountLine"
      >
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(26);
          }}
          className={
            amount == 26
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          26{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(52);
          }}
          className={
            amount == 52
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          52{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(126);
          }}
          className={
            amount == 126
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          126{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
      </div>
      <div
        style={{ justifyContent: "space-between" }}
        className="assoc-donation-block-amountLine"
      >
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(260);
          }}
          className={
            amount == 260
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          260{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(520);
          }}
          className={
            amount == 520
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          520{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
        <div
          style={{ fontSize: "25px" }}
          onClick={() => {
            setAmount(1040);
          }}
          className={
            amount == 1040
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          1040{" "}
          {currency === "eur"
            ? "€"
            : currency == "usd"
            ? "$"
            : currency == "ils"
            ? "₪"
            : "£"}
        </div>
        {/* <div
          onClick={() => {
            setAmount(600);
          }}
          className={
            amount == 600
              ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
              : "assoc-donation-block-amount"
          }
        >
          600 €
        </div> */}
      </div>
      <div
        style={{ marginTop: 10, display: "flex", alignItems: "center" }}
        className="assoc-donation-block-amountLine"
      >
        {/* <input
          style={{ width: "40%" }}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          onFocus={() => setAmount(null)}
          type="number"
          placeholder={t("Campaign.OtherAmount")}
          className="assoc-donation-block-amount-input"
          value={amount}
        ></input>
        <Select
          value={currency}
          onChange={(val) => {
            //console.log(val);
            setCurrency(val);
          }}
          placeholder="EUR €"
          classNamePrefix="assoc-select"
          className="react-select-container"
          options={options}
        /> */}
        <input
          style={{
            width: "130px",
            height: "22px",
            borderRadius: "10px 0px 0px 10px",
          }}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          onFocus={() => setAmount(null)}
          type="number"
          placeholder={t("Campaign.OtherAmount")}
          className="assoc-donation-block-amount-input"
          value={amount}
        ></input>
        <select
          className="select-donFor"
          value={currency}
          placeholder="EUR €"
          onChange={(e) => setCurrency(e.target.value)}
          style={{
            width: "100px",
            borderRadius: "0px 10px 10px 0px",
            marginTop: "4px",
            marginBottom: "4px",
            marginRight: "92px",
            marginLeft: "0px",
          }}
        >
          {options.map((opt, index) => {
            return (
              <option key={index} value={opt.value}>
                {opt.label}
              </option>
              // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
            );
          })}
        </select>
      </div>
      {campain && campain.affects.length > 1 && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <select
            style={{}}
            className="select-donFor"
            value={donFor}
            onChange={(e) => setDonFor(e.target.value)}
          >
            {campain.affects.map((aff) => {
              return (
                <option value={aff.idAffect}>{aff.val}</option>
                // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
              );
            })}
          </select>
        </div>
      )}
      <div
        className="assoc-donation-block-amount-checkbox-wrapper"
        style={{ width: "232px", margin: "auto", marginTop: "20px" }}
      >
        <div className="assoc-donation-block-amount-checkbox-line">
          <input
            onChange={() => setOneTime(true)}
            checked={oneTime}
            style={{ width: 25, height: 25, backgroundColor: "#f1f0ec" }}
            type="radio"
            className="radio-toolbar"
          />
          <div className="assoc-donation-block-amount-checkbox-text">
            {t("Campaign.DonatePeriod")}
          </div>
        </div>
        <div
          style={{ marginTop: 8 }}
          className="assoc-donation-block-amount-checkbox-line"
        >
          <input
            onChange={() => setOneTime(false)}
            checked={!oneTime}
            style={{ width: 25, height: 25 }}
            type="radio"
            className="radio-toolbar"
          />
          <div className="assoc-donation-block-amount-checkbox-text">
            {t("Campaign.DonateSeveral")}
          </div>
        </div>
        {!oneTime && (
          // <Select
          //   value={intervals}
          //   onChange={(val) => {
          //     //console.log(val);
          //     setIntervals(val);
          //   }}
          //   placeholder={paymentOptions[0].label}
          //   classNamePrefix="assoc-select"
          //   className="donation-months-container"
          //   options={paymentOptions}
          // />
          <select
            className="select-donFor"
            value={intervals}
            placeholder={paymentOptions[0].label}
            onChange={(e) => setIntervals(e.target.value)}
            style={{
              width: "218px",
              height: "50px",
              fontSize: "12px",
              borderRadius: "010px",
              marginTop: "4px",
              marginBottom: "4px",
              marginRight: "92px",
              marginLeft: "0px",
              marginTop: "20px",
            }}
          >
            {paymentOptions.map((opt, index) => {
              return (
                <option key={index} value={opt.value}>
                  {opt.label}
                </option>
                // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
              );
            })}
          </select>
        )}
      </div>
      {!oneTime && currency == "eur" && (
        <div
          className="yourAmountText"
          style={{
            fontSize: "12px",
            width: "80%",
            margin: "auto",
            marginTop: "20px",
            textAlign: "center",
          }}
        >
          {t("Dashboard.donate.Total")}
          {numberWithSpaces(Number(amount), currency.value)}{" "}
          {t("Donate.spread")} {Number(intervals)} {t("Donate.month")} Soit{" "}
          {numberWithSpaces(Number(amount) / Number(intervals), currency.value)}{" "}
          {t("Donate.setByMonth")} <br />
          {t("Donate.Tax.Reduction")}{" "}
          {numberWithSpaces(
            Number(Number(amount) - Number(amount) * 0.66),
            currency.value
          )}
          .
        </div>
      )}
      {/* {campain && campain.affects.length>1 && <div className="affects-container">{campain.affects.map((aff)=>{
                return(
                    <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>    
                )
            })}</div>} */}
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          setUserState({
            ...userState,
            donation: {
              amount: amount,
              currency: currency,
              times: oneTime,
              donateToId: campain.id,
              sponsorId: sponsor ? sponsor.id : null,
              returnUrl: window.location.href,
              intervals: intervals,
              donFor: donFor,
            },
          });
          history.push("/donate");
        }}
        className="assoc-donation-block-amount-donate-button"
      >
        {t("Campaign.MakeDonation")}
      </div>
      <div className="assoc-donation-block-amount-donate-txt">
        {t("Campaign.GetCerfa")}
      </div>
    </div>
  );
};

const DonationHeader = ({ campain }) => {
  const { t } = useTranslation();

  var isFuture = new Date(campain.startingAt) > new Date();

  var delta =
    Math.abs(
      new Date(isFuture ? campain.startingAt : campain.endingAt).getTime() -
        new Date().getTime()
    ) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60; // in theory the modulus is not required

  var total = 0;
  var currency = "eur";

  if (isFuture == false && campain.funding) {
    for (let i = 0; i < campain.funding.length; i++) {
      const element = campain.funding[i];

      try {
        total += fx(element.amount)
          .from(element.currency.toUpperCase())
          .to("EUR");
      } catch (error) {}
    }
  }
  var percentage = calcPercentage(total, campain.goal.amount);

  const [urlLink, setUrlLink] = useState("");

  useEffect(() => {
    const urlTemp = window.location.href;
    const newUrl = urlTemp;
    // .replaceAll("%20", "-")
    setUrlLink(newUrl);
  }, []);
  const [linkCopyed, setLinkCopyed] = useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setLinkCopyed(true);
    setTimeout(() => setLinkCopyed(false), 3000);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 50,
        position: "relative",
      }}
    >
      {!isFuture && (
        <div className="dontation-header-containers">
          <div className="dontation-header-containers-block1">
            <div className="dontation-header-containers-objectifBlock">
              {t("Campaign.Objective")} <br />
              {numberWithSpaces(
                campain.goal.amount || "",
                campain.goal.currency
              )}
            </div>
            <div className="dontation-header-containers-amountBlock">
              {t("Campaign.AmountCollected")} <br />
              {numberWithSpaces(total, currency)}
            </div>
          </div>
          <div className="dontation-header-containers-block2">
            {/* <div className={"dontation-header-containers-pbCont"}>
                        <div className="dontation-header-containers-progressBar">
                            <div style={{width: `${percentage > 100 ? 100 : percentage}%`}} className="dontation-header-containers-progress"></div>
                        </div>
                        <div style={{marginLeft: 15, fontSize: '1.3em', fontWeight: '500'}}>{percentage}%</div>
                    </div> */}
            <div className={"donation-header-timeleft"}>
              <div className={"donation-header-title"}>
                {isFuture ? t("campainStarting") : t("Campaign.TimeLeft")}
              </div>
              <div className="dontation-header-containers-timeleft-container">
                <div className="dontation-header-containers-timeleft-block">
                  {days}
                  <br />
                  {t("Campaign.TimeDays")}
                </div>
                <div className="dontation-header-containers-timeleft-block">
                  {hours}
                  <br />
                  {t("Campaign.TimeHours")}
                </div>
                <div className="dontation-header-containers-timeleft-block">
                  {minutes}
                  <br />
                  {t("Campaign.TimeMinutes")}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isFuture && (
        <div className="dontation-header-containers">
          <div className="dontation-header-containers-block1">
            <div className="dontation-header-containers-objectifBlock">
              {t("Campaign.Objective")} <br />
              {numberWithSpaces(
                campain.goal.amount || "",
                campain.goal.currency
              )}
            </div>
            <div
              className="dontation-header-containers-amountBlock"
              style={{ fontSize: "1.1em", padding: "10px" }}
            >
              {t("Campaign.IsFuture")}
            </div>
          </div>
          <div className="dontation-header-containers-block2">
            {/* <div className={"dontation-header-containers-pbCont"}>
                        <div className="dontation-header-containers-progressBar">
                            <div style={{width: `${percentage > 100 ? 100 : percentage}%`}} className="dontation-header-containers-progress"></div>
                        </div>
                        <div style={{marginLeft: 15, fontSize: '1.3em', fontWeight: '500'}}>{percentage}%</div>
                    </div> */}
            <div className={"donation-header-timeleft"}>
              <div className={"donation-header-title"}>
                {isFuture ? t("campainStarting") : t("Campaign.TimeLeft")}
              </div>
              <div className="dontation-header-containers-timeleft-container">
                <div className="dontation-header-containers-timeleft-block">
                  {days}
                  <br />
                  {t("Campaign.TimeDays")}
                </div>
                <div className="dontation-header-containers-timeleft-block">
                  {hours}
                  <br />
                  {t("Campaign.TimeHours")}
                </div>
                <div className="dontation-header-containers-timeleft-block">
                  {minutes}
                  <br />
                  {t("Campaign.TimeMinutes")}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isMobile && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            right: 15,
          }}
        >
          <FacebookShareButton url={urlLink}>
            <img className={"h-socialIcon-top"} src={roseFacebook} />
          </FacebookShareButton>
          <TwitterShareButton
            title={`Soutenons : ${campain.title[0].content}`}
            url={urlLink}
          >
            <img className={"h-socialIcon-top"} src={roseTwitter} />
          </TwitterShareButton>
          <WhatsappShareButton url={urlLink}>
            <img className={"h-socialIcon-top"} src={roseWhatsapp} />
          </WhatsappShareButton>
          <EmailShareButton url={urlLink}>
            <img className={"h-socialIcon-top"} src={roseMail} />
            {/* <EmailIcon size={32} round /> */}
          </EmailShareButton>
          {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
          <div style={{ paddingTop: "9px" }}>
            <QrCode className={"h-socialIcon-top"} url={urlLink} />
          </div>
          <LinkedinShareButton url={urlLink}>
            <img className={"h-socialIcon-top"} src={roseLinkedin} />
          </LinkedinShareButton>
          <div onClick={() => copyToClipboard(urlLink)}>
            <img
              className={"h-socialIcon-top"}
              style={{ cursor: "pointer" }}
              src={roseLink}
            />
            {linkCopyed && <p style={{ position: "absolute" }}>Link copied</p>}
          </div>
        </div>
      )}
    </div>
  );
};

const DonationHeaderFinished = ({ campain }) => {
  const { t } = useTranslation();
  var delta =
    Math.abs(new Date(campain.endingAt).getTime() - new Date().getTime()) /
    1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60; // in theory the modulus is not required

  var total = 0;
  var currency = "eur";

  for (let i = 0; i < campain.funding?.length; i++) {
    const element = campain.funding[i];
    try {
      total += fx(element.amount)
        .from(element.currency.toUpperCase())
        .to("EUR");
    } catch (error) {}
  }
  var percentage = calcPercentage(total, campain.goal.amount);

  const [urlLink, setUrlLink] = useState("");

  useEffect(() => {
    const urlTemp = window.location.href;
    const newUrl = urlTemp;
    // .replaceAll("%20", "-")
    setUrlLink(newUrl);
  }, []);
  const [linkCopyed, setLinkCopyed] = useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setLinkCopyed(true);
    setTimeout(() => setLinkCopyed(false), 3000);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 50,
        position: "relative",
      }}
    >
      <div
        style={{ flexDirection: "column" }}
        className="dontation-header-containers"
      >
        <div
          style={{
            width: "90%",
            flexDirection: isMobile ? "column" : "row",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className="dontation-header-containers-block1"
        >
          <div
            style={{ marginRight: isMobile ? 0 : 20 }}
            className="dontation-header-containers-objectifBlock"
          >
            {t("Campaign.Objective")} <br />
            {numberWithSpaces(campain.goal.amount || "", campain.goal.currency)}
          </div>
          <div
            style={{ marginLeft: isMobile ? 0 : 20 }}
            className="dontation-header-containers-objectifBlock"
          >
            {t("Campaign.AmountCollected")} <br />
            {numberWithSpaces(total, currency)}
          </div>
        </div>
        <div
          style={{
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
          }}
          className="dontation-header-containers-block2"
        >
          <div className={"dontation-header-containers-pbCont"}>
            <div
              style={{ width: "100%" }}
              className="dontation-header-containers-progressBar"
            >
              <div
                style={{ width: `${percentage > 100 ? 100 : percentage}%` }}
                className="dontation-header-containers-progress"
              ></div>
            </div>
            <div
              style={{ marginLeft: 15, fontSize: "1.3em", fontWeight: "500" }}
            >
              {percentage}%
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            right: 15,
          }}
        >
          <FacebookShareButton url={urlLink}>
            <img className={"h-socialIcon-top"} src={roseFacebook} />
          </FacebookShareButton>
          <TwitterShareButton
            title={`Soutenons : ${campain.title[0].content}`}
            url={urlLink}
          >
            <img className={"h-socialIcon-top"} src={roseTwitter} />
          </TwitterShareButton>
          <WhatsappShareButton url={urlLink}>
            <img className={"h-socialIcon-top"} src={roseWhatsapp} />
          </WhatsappShareButton>
          <EmailShareButton url={urlLink}>
            <img className={"h-socialIcon-top"} src={roseMail} />
            {/* <EmailIcon size={32} round /> */}
          </EmailShareButton>
          {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
          <div style={{ paddingTop: "9px" }}>
            <QrCode className={"h-socialIcon-top"} url={urlLink} />
          </div>
          <LinkedinShareButton url={urlLink}>
            <img className={"h-socialIcon-top"} src={roseLinkedin} />
          </LinkedinShareButton>
          <div onClick={() => copyToClipboard(urlLink)}>
            <img
              className={"h-socialIcon-top"}
              style={{ cursor: "pointer" }}
              src={roseLink}
            />
            {linkCopyed && <p style={{ position: "absolute" }}>Link copied</p>}
          </div>
        </div>
      )}
    </div>
  );
};

const donations = [{}, {}, {}, {}];

export default function Campain({ history }) {
  const [campain, setCampain] = useState(null);
  const [association, setAssociation] = useState(null);
  const [userState, setUserState] = useContext(UserContext);
  const [sponsor, setSponsor] = useState(null);
  const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sponsorNbs, setSponsorNbs] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [giftModalIsOpen, setIsGiftModalOpen] = useState(false);
  const [giftModalHover, setIsGiftModalHover] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const [linkCopyed, setLinkCopyed] = useState(false);
  const [billetteries, setBilletteries] = useState(null);
  const [editorTemp, setEditorTemp] = useState("");
  const [readMore, setReadMore] = useState(false);
  const { t } = useTranslation();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setLinkCopyed(true);
    setTimeout(() => setLinkCopyed(false), 3000);
  };

  const [urlLink, setUrlLink] = useState("");

  useEffect(() => {
    const urlTemp = window.location.href;
    const newUrl = urlTemp;
    // .replaceAll("%20", "-")
    setUrlLink(newUrl);
  }, []);

  useEffect(() => {
    if (editorState) {
      const ediTemp = editorState
        .getCurrentContent()
        .getPlainText("\u0001")
        .split(" ");
      const arrTemp = [];
      for (let i = 0; i < 50; i++) {
        arrTemp.push(ediTemp[i]);
      }
      var str = arrTemp.join(" ");
      setEditorTemp(str + "...");
    }
  }, [editorState]);

  useEffect(() => {
    window.scrollTo(0, 0);
    var params = history.location.pathname.split("/");

    // if (campData) {
    //     setCampain(campain)
    //     setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(campain.content[0].content))))
    //     return ;
    // }
    if (
      history.location.pathname &&
      history.location.pathname.indexOf("/visualize") != -1
    ) {
      var res = userState;
      try {
        setCampain(res.campainVisualize);
        // res && setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(res.campainVisualize.content))))
      } catch (error) {
        // console.log(error)
      }

      setLoading(false);
    } else {
      getPageByUrl(params[params.length - 1]).then(async (res) => {
        //console.log('RESULT', res);
        setCampain(res);
        res &&
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(res.content[0].content))
            )
          );

        setLoading(false);

        //console.log(history.location.search);
        if (history.location.search.indexOf("?sponsor=") != -1) {
          var sponsorName = decodeURI(
            history.location.search.slice(9, history.location.search.length)
          );
          //console.log(res.sponsors.find((el) => el.name == sponsorName));
          var sponsor = res.sponsors.find((el) => el.name == sponsorName);
          if (sponsor) {
            setSponsor(sponsor);
            var nbs = await getSponsorNumbers(res.id);
            //console.log('NBS', nbs);
            var sponsorNbs = nbs.find((el) => el.sponsor.id == sponsor.id);
            //console.log(sponsorNbs);
            setSponsorNbs(sponsorNbs);
          }
        }

        res &&
          getCampainDonations(res.id).then((donations) => {
            setDonations(donations);
          });
      });
    }

    // if (history.location.state) {
    //     // setCampain(history.location.state.camp)
    //     // setAssociation(userState.association)
    // }
  }, []);

  useEffect(() => {
    function compare(a, b) {
      if (a.startingAt < b.startingAt) {
        return -1;
      }
      if (a.startingAt > b.startingAt) {
        return 1;
      }
      return 0;
    }
    const getAllBilletterie = async (assoId) => {
      const billetterie = await getBackOffice(
        `/billetterie/me/campaign-paired/${campain.id}`
      );
      const results = await getMyBilletteries(assoId);
      const billetterieTemp = [];
      if (results.length) {
        var count = 0;
        for (let i = 0; i < results.length; i++) {
          if (
            results[i].association === campain.association._id &&
            new Date(results[i].tickets[0].dateEvent) > new Date()
          ) {
            count++;
            billetterieTemp.push(results[i]);
            if (count === 3) {
              break;
            }
          }
        }
        billetterieTemp.sort(compare);
        setBilletteries(billetterie);
      }
    };
    if (campain) {
      getAllBilletterie(campain.association._id);
    }
  }, [campain]);

  if (loading) {
    return <div style={{ height: "calc(100vh - 403px)" }} />;
  }
  if (!campain) {
    return (
      <div
        style={{
          height: "calc(100vh - 403px)",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontSize: 45,
        }}
      >
        {t("Charity.PageNotFound")}
      </div>
    );
  }
  const pictures = campain.medias
    .filter((el) => el.type == "PICTURE")
    .map((el) => el.url);
  const videos = campain.medias
    .filter((el) => el.type == "VIDEO")
    .map((el) => el.url);

  if (campain.association.block) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "78%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 100,
          border: "5px solid rgb(169 86 86)",
          borderRadius: 15,
          padding: 30,
        }}
      >
        This association has been blocked. Please contact website administrator.
      </div>
    );
  }

  if (!campain.accepted) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "78%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 100,
          border: "5px solid rgb(169 86 86)",
          borderRadius: 15,
          padding: 30,
        }}
      >
        This page has been rejected. Please contact website administrator.
      </div>
    );
  }

  // for (let i = 0; i < campain.funding.length; i++) {
  //   const element = campain.funding[i];

  //   try {
  //     total += fx(element.amount)
  //       .from(element.currency.toUpperCase())
  //       .to("EUR");
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // }

  var donated = 0;
  if (donations.length) {
    for (let i = 0; i < donations.length; i++) {
      // const element = donations[i];

      try {
        if (donations[i].currency == "ils") {
          // .from(donations[i].currency.toUpperCase())
          // .to("EUR"));
        }
        donated += fx(donations[i].amount)
          .from(donations[i].currency.toUpperCase())
          .to("EUR");
      } catch (error) {
        console.log(error);
      }
      // donated = donated + Number(donations[i].amount);
    }
  }

  if (new Date(campain.endingAt) < new Date()) {
    return (
      <div className={"assocContainer"}>
        {/* <SEO
          title={`Soutenons : ${campain.title[0].content}`}
          description={`Association : ${campain.associationName}`}
          name={`CharityClick`}
          type="website"
          image={
            campain.sharingImage ? campain.sharingImage : campain.medias[0]?.url
          }
        /> */}
        <Helmet>
          <title>{`Soutenons : ${campain.title[0].content}`}</title>
          <meta
            property="og:title"
            content={`Soutenons : ${campain.title[0].content}`}
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:description"
            content={`Association : ${campain.associationName}`}
          />
          <meta property="og:site_name" content="CharityClic" />
          <meta
            property="og:image"
            itemProp={"image"}
            content={
              campain.sharingImage
                ? campain.sharingImage
                : campain.medias[0]?.url
            }
          />
          <meta
            property="og:image:secure_url"
            itemProp={"image"}
            content={
              campain.sharingImage
                ? campain.sharingImage
                : campain.medias[0]?.url
            }
          />
        </Helmet>
        {/* <DonationHeaderFinished campain={campain} /> */}

        {!isMobile && (
          <div className="topCampainContainer">
            <div
            // style={{width:"50%"}}
            >
              <Assoc
                customStyle={{ width: "43%", marginRight: 0 }}
                campainName={campain.title[0].content}
                img={campain.associationLogoUrl}
                title={campain.associationName}
                desc={campain.description[0].content}
              />
            </div>
            <div
            //  style={{width:"30%"}}
            >
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div
                  className={"share-block-top"}
                  style={{
                    width: "32%",
                    marginRight: "auto",
                    marginLeft: "3%",
                    marginTop: 20,
                  }}
                >
                  <div
                    onClick={() => {
                      setUserState({
                        ...userState,
                        donation: {
                          amount: 0,
                          currency: "eur",
                          times: 1,
                          donateToId: campain.id,
                          sponsorId: sponsor ? sponsor.id : null,
                          returnUrl: urlLink,
                          intervals: 1,
                          // donFor:donFor
                        },
                      });
                      history.push("/donate");
                    }}
                    className="assoc-donation-block-amount-donate"
                  >
                    {t("Campaign.MakeDonation")}
                  </div>
                  <div
                    className="assoc-donation-block-amount-donate-txt"
                    style={{
                      fontSize: "10px",
                      marginBottom: "-5px",
                      marginLeft: "-100px",
                      width: "300px",
                    }}
                  >
                    {t("Campaign.GetCerfa")}
                  </div> */}
              {/* <span style={{textAlign: 'center', color: '#507cab', fontSize: 20, fontWeight: 'bold'}}>{t("Campaign.SocialShare")}</span> */}
              {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: 10,
                    }}
                  >
                    <FacebookShareButton url={urlLink}>
                      <img className={"h-socialIcon-top"} src={roseFacebook} />
                    </FacebookShareButton>
                    <TwitterShareButton
                      title={`Soutenons : ${campain.title[0].content}`}
                      url={urlLink}
                    >
                      <img className={"h-socialIcon-top"} src={roseTwitter} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={urlLink}>
                      <img className={"h-socialIcon-top"} src={roseWhatsapp} />
                    </WhatsappShareButton>
                    <EmailShareButton url={urlLink}>
                      <img className={"h-socialIcon-top"} src={roseMail} /> */}
              {/* <EmailIcon size={32} round /> */}
              {/* </EmailShareButton> */}
              {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
              {/* <div style={{ paddingTop: "9px" }}>
                      <QrCode className={"h-socialIcon-top"} url={urlLink} />
                    </div>
                    <LinkedinShareButton url={urlLink}>
                      <img className={"h-socialIcon-top"} src={roseLinkedin} />
                    </LinkedinShareButton>
                    <div onClick={() => copyToClipboard(urlLink)}>
                      <img
                        className={"h-socialIcon-top"}
                        style={{ cursor: "pointer" }}
                        src={roseLink}
                      />
                      {linkCopyed && (
                        <p style={{ position: "absolute" }}>Link copied</p>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        )}

        <div className="completedContainer">
          <div className="containerCompleted">{t("Campaign.Completed")}</div>
          <div
            className={"endCampDesc"}
            style={{ fontSize: 26, textAlign: "justify", width: "65%" }}
          >
            <div>
              {t("Campaign.Over")}
              <div
                style={{
                  cursor: "pointer",
                  color: "#da2a6f",
                  textDecoration: "underline",
                }}
                onClick={() =>
                  history.push(`/${campain.association.linkParam}`)
                }
              >
                {t("Campaign.End.Back")}
              </div>
            </div>
          </div>
        </div>
        {/* {isMobile && (
          <div style={{ margin: "auto", marginBottom: "-30px" }}>
            <div
              className={"share-block-top"}
              style={
                {
                  // width: "32%",
                  // marginRight: "auto",
                  // marginLeft: "3%",
                  // marginTop: 20,
                }
              }
            >
              <div
                onClick={() => {
                  setUserState({
                    ...userState,
                    donation: {
                      amount: 0,
                      currency: "eur",
                      times: 1,
                      donateToId: campain.id,
                      sponsorId: sponsor ? sponsor.id : null,
                      returnUrl: urlLink,
                      intervals: 1,
                      donFor: null,
                    },
                  });
                  history.push("/donate");
                }}
                className="assoc-donation-block-amount-donate"
              >
                {t("Campaign.MakeDonation")}
              </div>
              <div
                className="assoc-donation-block-amount-donate-txt"
                style={{ fontSize: "8px", marginBottom: "-5px" }}
              >
                {t("Campaign.GetCerfa")}
              </div> */}
        {/* <span style={{textAlign: 'center', color: '#507cab', fontSize: 20, fontWeight: 'bold'}}>{t("Campaign.SocialShare")}</span> */}
        {/* <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <FacebookShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseFacebook} />
                </FacebookShareButton>
                <TwitterShareButton
                  title={`Soutenons : ${campain.title[0].content}`}
                  url={urlLink}
                >
                  <img className={"h-socialIcon-top"} src={roseTwitter} />
                </TwitterShareButton>
                <WhatsappShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseWhatsapp} />
                </WhatsappShareButton>
                <EmailShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseMail} /> */}
        {/* <EmailIcon size={32} round /> */}
        {/* </EmailShareButton> */}
        {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
        {/* <div style={{ paddingTop: "9px" }}>
                  <QrCode className={"h-socialIcon-top"} url={urlLink} />
                </div>
                <LinkedinShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseLinkedin} />
                </LinkedinShareButton>
                <div onClick={() => copyToClipboard(urlLink)}>
                  <img
                    className={"h-socialIcon-top"}
                    style={{ cursor: "pointer" }}
                    src={roseLink}
                  />
                  {linkCopyed && (
                    <p style={{ position: "absolute" }}>Link copied</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )} */}

        <div className="containerPrincipal">
          <TopAssoc
            status="finished"
            history={history}
            campain={campain}
            userState={userState}
            setUserState={setUserState}
            sponsor={sponsor}
            donations={donations}
          />
        </div>

        {/* FIN AFFICHAGE PRINCIPAL */}

        {/* DEBUT AFFICHAGE INFO ASSO MOBILE */}
        {isMobile && (
          <Assoc
            customStyle={{ width: "70%" }}
            campainName={campain.title[0].content}
            img={campain.associationLogoUrl}
            title={campain.associationName}
            desc={campain.description[0].content}
          />
        )}
        {/* FIN AFFICHAGE INFO ASSO MOBILE */}

        {/* DEBUT AFFICHAGE OBJECTIF ET STATS CAMPAGNE */}

        <div className={"objStatContainer"}>
          <div className={"objContainer"}>
            {new Date() > new Date(campain.startingAt) && (
              <div className={"objSubContainer"}>
                <div>
                  <span
                    className={"objCollecte"}
                    style={{ marginRight: "25px" }}
                  >
                    {numberWithSpaces(donated)}
                  </span>{" "}
                  {t("Charity.Funds")}
                </div>
                <div>
                  {donations ? (
                    <span className={"objDonnateur"}>{donations.length}</span>
                  ) : (
                    <span className={"objDonnateur"}>0</span>
                  )}{" "}
                  {t("Charity.Donors")}
                </div>
              </div>
            )}
            <div className={"objTxt"}>
              {t("Campaign.Goal")}:{" "}
              {campain && numberWithSpaces(campain.goal.amount)}{" "}
            </div>
          </div>
          {new Date() > new Date(campain.startingAt) ? (
            <div className={"statContainer"}>
              <ProgressBar
                history={history}
                sponsor={sponsor}
                campain={campain}
                sponsorNbs={sponsorNbs}
                donations={donations}
              />
            </div>
          ) : (
            <div className="aboutCampain">{t("Campaign.IsFuture")}</div>
          )}
        </div>

        {/* FIN AFFICHAGE OBJECTIF ET STATS CAMPAGNE */}

        {/* DEBUT A PROPOS DE LA CAMPAGNE */}

        <div style={{ width: "80%", margin: "auto" }}>
          <div className="aboutCampain">{t("AboutCampain")}</div>

          <div
            style={{ height: "100px!important", overflowY: "hidden" }}
            className={"assocContainer-desc"}
          >
            {/* {campain.content[0].content} */}
            {/* <Editor style={{height:"100px!important",overflowY:"hidden"}} readOnly  editorState={editorState} /> */}
            {readMore == true ? (
              <div className={"assocContainer-desc"}>
                {/* {campain.content[0].content} */}
                <Editor readOnly toolbarHidden editorState={editorState} />
                <div
                  onClick={() => setReadMore(false)}
                  style={{
                    color: "#d12164",
                    cursor: "pointer",
                    fontSize: "20px",
                    fontWeight: 600,
                    marginBottom: "40px",
                    textAlign: "justify",
                  }}
                >
                  {t("Campain.close")}
                </div>
              </div>
            ) : (
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  marginBottom: "40px",
                  textAlign: "justify",
                }}
              >
                <div>
                  {editorTemp}&nbsp;{" "}
                  <span
                    onClick={() => setReadMore(true)}
                    style={{ color: "#d12164", cursor: "pointer" }}
                  >
                    {t("Campain.readMore")}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* FIN A PROPOS DE LA CAMPAGNE */}

        {/* DEBUT CAROUSEL IMAGES */}

        <Carousel
          pictures={pictures}
          videos={videos}
          style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
          reverse
        />
        <div style={{ height: 100 }}></div>

        {/* FIN CAROUSEL IMAGES */}

        <div className="completedContainer">
          <div className="containerCompleted">{t("Campaign.Completed")}</div>
          <div
            className={"endCampDesc"}
            style={{ fontSize: 26, textAlign: "justify", width: "65%" }}
          >
            <div>
              {t("Campaign.Over")}
              <div
                style={{
                  cursor: "pointer",
                  color: "#da2a6f",
                  textDecoration: "underline",
                }}
                onClick={() =>
                  history.push(`/${campain.association.linkParam}`)
                }
              >
                {t("Campaign.End.Back")}
              </div>
            </div>
          </div>
        </div>

        {/* <div style={{fontSize: 45, textAlign: 'center', marginTop: 40}}>{t("Charity.ThankYou")}</div>
            <div className={'wrapper'}>
            {campain.poster[0].type == 'PICTURE' ? (
                <img style={{height: isMobile? 300 : 500}} className="headMedia" src={campain.poster[0].url}></img>
            ) : (
                <video style={{height: isMobile? 300 :500}}  className="headMedia" src={campain.poster[0].url} controls>
                    Your browser does not support the video tag.
                </video>
            )}
            <div style={{marginTop: 20}} className={"assoc-comp-content-title"}>{campain.title[0].content}</div>
            <Assoc customStyle={{marginLeft: 0}} campainName={campain.title[0].content} title={campain.associationName} desc={campain.description[0].content} img={campain.associationLogoUrl} />
        </div> */}
        {/* <div className={'endCampCont'} style={{width: '70%', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'row', marginTop: 40}}>
           
            <div className={'endCampDonate'} style={{display: 'flex', flexDirection: 'column', width: "35%", marginLeft: 40}}>
                <div style={{fontSize: isMobile? 20 :30, width: isMobile ? 200 : 250, marginTop: isMobile? 20 : 0}} onClick={async () => {
                    // //console.log(campain);
                    var res = await getCollectFromCamp(campain.id)
                    //console.log(res);
                    setUserState({...userState, donation: {
                        amount: null,
                        currency: {label: "Euro", value: 'eur'},
                        times: '1',
                        donateToId: res.id,
                        sponsorId: sponsor ? sponsor.id : null,
                        returnUrl: urlLink
                    }})
                    history.push('/donate')
                }} className="assoc-donation-block-amount-donate">{t("Campaign.MakeDonation")}</div>
                <div style={{textAlign: 'center'}} className="assoc-donation-block-amount-donate-txt">{t("Campaign.GetCerfa")}</div>
            </div>
        </div>
        <Carousel pictures={pictures} videos={videos} style={{width: '82%', marginLeft: 'auto', marginRight: 'auto'}} />
        <div style={{height: 100}}></div> */}

        <div className="completedContainer">
          <div className="containerCompleted">{t("Thanks")}</div>
        </div>
      </div>
    );
  }
  var isFuture = new Date(campain.startingAt) > new Date();

  function openGiftModal() {
    setIsGiftModalOpen(true);
  }

  function afterOpenGiftModal() {}

  function closeGiftModal() {
    setIsGiftModalOpen(false);
  }

  var delta =
    Math.abs(new Date(campain.startingAt).getTime() - new Date().getTime()) /
    1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear().toString();
  const currentMonth = currentDate.getMonth().toString();
  const currentDay = currentDate.getDay().toString();
  const formattedDate = currentDay + currentMonth + currentYear;

  // const copyToClipboard = (text) => {
  //   navigator.clipboard.writeText(text);
  //   setLinkCopyed(true);
  //   setTimeout(() => setLinkCopyed(false), 3000);
  // };
  var donated = 0;
  if (donations.length) {
    for (let i = 0; i < donations.length; i++) {
      try {
        if (donations[i].currency == "ils") {
          // .from(donations[i].currency.toUpperCase())
          // .to("EUR"));
        }
        donated += fx(donations[i].amount)
          .from(donations[i].currency.toUpperCase())
          .to("EUR");
      } catch (error) {
        console.log(error);
      }
    }
  }
  return (
    <div className={"assocContainer"}>
      {/* <Modal
            isOpen={giftModalIsOpen || giftModalHover}
            onAfterOpen={afterOpenGiftModal}
            onRequestClose={closeGiftModal}
            style={customStyles}
            >
                <div style={{fontSize: 20, textAlign: 'center'}}>Devenez {selectedAward && selectedAward.title} {selectedAward && selectedAward.gift && `et recevez en cadeau: ${selectedAward.gift}`}</div>
                {selectedAward && selectedAward.logo &&<img style={{width: '50%', objectFit: 'contain', marginLeft: 'auto', marginRight: 'auto'}} src={selectedAward.logo} />}
            {selectedAward &&<div style={{marginTop: 'auto'}} onClick={async () => {
                setIsGiftModalOpen(false)
                setUserState({...userState, donation: {
                    amount: selectedAward.amount,
                    currency: {label: "Euro", value: 'eur'},
                    times: '1',
                    donateToId: campain.id,
                    sponsorId: sponsor ? sponsor.id : null,
                    returnUrl: urlLink
                }})
                history.push('/donate')
                }} className={'smbtn btnGeneral'}>Faire un don de {numberWithSpaces(selectedAward.amount)}</div>}
            <div onClick={async () => {setIsGiftModalOpen(false)}} className={'smbtn btnGeneral'}>Retour</div>

            </Modal> */}
      {/* <SEO
        title={`Soutenons : ${campain.title[0].content}`}
        description={`Association : ${campain.associationName}`}
        name={`CharityClick`}
        type="website"
        image={
          campain.sharingImage ? campain.sharingImage : campain.medias[0]?.url
        }
      /> */}
      <Helmet>
        <title>{`Soutenons : ${campain.title[0].content}`}</title>
        <meta
          property="og:title"
          content={`Soutenons : ${campain.title[0].content}`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={`Association : ${campain.associationName}`}
        />
        <meta property="og:site_name" content="CharityClic" />
        <meta
          property="og:image"
          itemProp={"image"}
          content={
            campain.sharingImage ? campain.sharingImage : campain.medias[0]?.url
          }
        />
        <meta
          property="og:image:secure_url"
          itemProp={"image"}
          content={
            campain.sharingImage ? campain.sharingImage : campain.medias[0]?.url
          }
        />
      </Helmet>
      {sponsor ? (
        <>
          {/* DEBUT PAGE PARRAIN */}

          {/* <DonationHeader campain={campain} /> */}

          <div className="">
            <div>
              {!isMobile ? (
                <div className="topCampainContainer">
                  <div>
                    <Assoc
                      customStyle={{ width: "43%", marginRight: 0 }}
                      campainName={campain.title[0].content}
                      img={campain.associationLogoUrl}
                      title={campain.associationName}
                      desc={campain.description[0].content}
                    />
                  </div>
                  <div>
                    <div
                      style={
                        {
                          // display: "flex",
                          // flexDirection: "row",
                          // width: "100%",
                          // marginLeft: "auto",
                          // marginRight: "auto",
                        }
                      }
                    >
                      {/* img parrain */}
                      <img className="logoParrainBig" src={sponsor.picture} />
                      <div
                        className={"share-block-top"}
                        style={
                          {
                            // width: "32%",
                            // marginRight: "auto",
                            // marginLeft: "3%",
                            // marginTop: 20,
                          }
                        }
                      >
                        <div
                          onClick={() => {
                            setUserState({
                              ...userState,
                              donation: {
                                amount: 0,
                                currency: "eur",
                                times: 1,
                                donateToId: campain.id,
                                sponsorId: sponsor ? sponsor.id : null,
                                returnUrl: urlLink,
                                intervals: 1,
                                // donFor:donFor
                              },
                            });
                            history.push("/donate");
                          }}
                          className="assoc-donation-block-amount-donate"
                        ></div>
                        <div
                          className="assoc-donation-block-amount-donate-txt"
                          style={{ fontSize: "8px", marginBottom: "-5px" }}
                        >
                          {t("Campaign.GetCerfa")}
                        </div>
                        {/* <span style={{textAlign: 'center', color: '#507cab', fontSize: 20, fontWeight: 'bold'}}>{t("Campaign.SocialShare")}</span> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: 10,
                          }}
                        >
                          <FacebookShareButton url={urlLink}>
                            <img
                              className={"h-socialIcon-top"}
                              src={roseFacebook}
                            />
                          </FacebookShareButton>
                          <TwitterShareButton
                            title={`Soutenons : ${campain.title[0].content}`}
                            url={urlLink}
                          >
                            <img
                              className={"h-socialIcon-top"}
                              src={roseTwitter}
                            />
                          </TwitterShareButton>
                          <WhatsappShareButton url={urlLink}>
                            <img
                              className={"h-socialIcon-top"}
                              src={roseWhatsapp}
                            />
                          </WhatsappShareButton>
                          <EmailShareButton url={urlLink}>
                            <img
                              className={"h-socialIcon-top"}
                              src={roseMail}
                            />
                            {/* <EmailIcon size={32} round /> */}
                          </EmailShareButton>
                          {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
                          <div style={{ paddingTop: "9px" }}>
                            <QrCode
                              className={"h-socialIcon-top"}
                              url={urlLink}
                            />
                          </div>
                          <LinkedinShareButton url={urlLink}>
                            <img
                              className={"h-socialIcon-top"}
                              src={roseLinkedin}
                            />
                          </LinkedinShareButton>
                          <div onClick={() => copyToClipboard(urlLink)}>
                            <img
                              className={"h-socialIcon-top"}
                              style={{ cursor: "pointer" }}
                              src={roseLink}
                            />
                            {linkCopyed && (
                              <p style={{ position: "absolute" }}>
                                Link copied
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Assoc
                  customStyle={{ width: "70%" }}
                  campainName={campain.title[0].content}
                  img={campain.associationLogoUrl}
                  title={campain.associationName}
                  desc={campain.description[0].content}
                />
              )}
            </div>
          </div>
          {isMobile && (
            <div
              className={"share-block-top"}
              style={{
                width: "32%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 20,
              }}
            >
              <div
                onClick={() => {
                  setUserState({
                    ...userState,
                    donation: {
                      amount: 0,
                      currency: "eur",
                      times: 1,
                      donateToId: campain.id,
                      sponsorId: sponsor ? sponsor.id : null,
                      returnUrl: urlLink,
                      intervals: 1,
                      // donFor:donFor
                    },
                  });
                  history.push("/donate");
                }}
                className="assoc-donation-block-amount-donate"
              >
                {t("Campaign.MakeDonation")}
              </div>
              <div
                className="assoc-donation-block-amount-donate-txt"
                style={{
                  fontSize: "10px",
                  marginBottom: "-5px",
                  marginLeft: "-100px",
                  width: "300px",
                }}
              >
                {t("Campaign.GetCerfa")}
              </div>
              {/* <span style={{textAlign: 'center', color: '#507cab', fontSize: 20, fontWeight: 'bold'}}>{t("Campaign.SocialShare")}</span> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <FacebookShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseFacebook} />
                </FacebookShareButton>
                <TwitterShareButton
                  title={`Soutenons : ${campain.title[0].content}`}
                  url={urlLink}
                >
                  <img className={"h-socialIcon-top"} src={roseTwitter} />
                </TwitterShareButton>
                <WhatsappShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseWhatsapp} />
                </WhatsappShareButton>
                <EmailShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseMail} />
                  {/* <EmailIcon size={32} round /> */}
                </EmailShareButton>
                {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
                <div style={{ paddingTop: "9px" }}>
                  <QrCode className={"h-socialIcon-top"} url={urlLink} />
                </div>
                <LinkedinShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseLinkedin} />
                </LinkedinShareButton>
                <div onClick={() => copyToClipboard(urlLink)}>
                  <img
                    className={"h-socialIcon-top"}
                    style={{ cursor: "pointer" }}
                    src={roseLink}
                  />
                  {linkCopyed && (
                    <p style={{ position: "absolute" }}>Link copied</p>
                  )}
                </div>
              </div>
            </div>
          )}

          {new Date(campain.startingAt) > new Date() ? (
            <IlReste
              futur={true}
              date={new Date(campain.startingAt)}
              renderer={renderer}
            />
          ) : new Date(campain.endingAt) <= new Date() ? null : (
            <IlReste
              futur={false}
              date={new Date(campain.endingAt)}
              renderer={renderer2}
            />
          )}
          <TopAssocParain
            history={history}
            sponsor={sponsor}
            campain={campain}
            sponsorNbs={sponsorNbs}
            donations={donations}
          />

          {/* DEBUT AFFICHAGE OBJECTIF ET STATS CAMPAGNE */}

          <div className={"objStatContainer"}>
            <div className={"objContainer"}>
              {new Date() > new Date(campain.startingAt) && (
                <div className={"objSubContainer"}>
                  <div>
                    <span
                      className={"objCollecte"}
                      style={{ marginRight: "25px" }}
                    >
                      {numberWithSpaces(donated)}
                    </span>
                    {t("Charity.Funds")}
                  </div>
                  <div>
                    {donations ? (
                      <span className={"objDonnateur"}>{donations.length}</span>
                    ) : (
                      <span className={"objDonnateur"}>0</span>
                    )}{" "}
                    {t("Charity.Donors")}
                  </div>
                </div>
              )}
              <div className={"objTxt"}>
                {t("Campaign.Goal")}:{" "}
                {campain && numberWithSpaces(campain.goal.amount)}
              </div>
            </div>
            {new Date() > new Date(campain.startingAt) ? (
              <div className={"statContainer"}>
                <ProgressBar
                  history={history}
                  sponsor={sponsor}
                  campain={campain}
                  sponsorNbs={sponsorNbs}
                  donations={donations}
                />
              </div>
            ) : (
              <div className="aboutCampain">{t("Campaign.IsFuture")}</div>
            )}
          </div>

          {/* FIN AFFICHAGE OBJECTIF ET STATS CAMPAGNE */}

          {/* DEBUT A PROPOS DE LA CAMPAGNE */}

          <div style={{ width: "80%", margin: "auto" }}>
            <div className="aboutCampain">{t("AboutCampain")}</div>

            {readMore == true ? (
              <div className={"assocContainer-desc"}>
                {/* {campain.content[0].content} */}
                <Editor readOnly toolbarHidden editorState={editorState} />
                <div
                  onClick={() => setReadMore(false)}
                  style={{
                    color: "#d12164",
                    cursor: "pointer",
                    fontSize: "20px",
                    fontWeight: 600,
                    marginBottom: "40px",
                    textAlign: "justify",
                  }}
                >
                  {t("Campain.close")}
                </div>
              </div>
            ) : (
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  marginBottom: "40px",
                  textAlign: "justify",
                }}
              >
                <div>
                  {editorTemp}&nbsp;{" "}
                  <span
                    onClick={() => setReadMore(true)}
                    style={{ color: "#d12164", cursor: "pointer" }}
                  >
                    {t("Campain.readMore")}
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* FIN A PROPOS DE LA CAMPAGNE */}

          {/* DEBUT CAROUSEL IMAGES */}

          <Carousel
            pictures={pictures}
            videos={videos}
            style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            reverse
          />
          <div style={{ height: 100 }}></div>

          {/* FIN CAROUSEL IMAGES */}

          {/* DEBUT PACKS */}
          {campain.awards.length > 0 && (
            <div style={{ marginBottom: "-10px" }} className={"aboutCampain"}>
              {t("memberPack")}
            </div>
          )}
          <div
            style={{
              width: "83%",
              position: "relative",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              marginTop: 40,
            }}
          >
            <div
              style={{ marginTop: 0, justifyContent: "center" }}
              className={"assocContainer-donations-ranks"}
            >
              {campain.awards.map((award, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    setUserState({
                      ...userState,
                      donation: {
                        amount: award.amount,
                        currency: "eur",
                        times: "1",
                        donateToId: campain.id,
                        sponsorId: sponsor ? sponsor.id : null,
                        returnUrl: window.location.href,
                      },
                    });
                    history.push("/donate");
                  }}
                  style={{ width: isMobile ? "30%" : "13%" }}
                  className={"assocContainer-donations-block"}
                >
                  {/* <img className="image-gift" src={gifts[idx]}/> */}
                  <span className={"assocContainer-donations-title"}>
                    {award.title}
                  </span>
                  {/* <div style={{width:"90%",margin:"10px 0px", borderTop:"1px solid #000"}}></div> */}
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "50px", marginTop: "10px" }}
                      src={award.logo ? award.logo : Award}
                      alt=""
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ margin: "15px 0" }}
                        className={"assocContainer-donations-price"}
                      >
                        {numberWithSpaces(award.amount)}
                      </div>
                      {award.gift ? (
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "14px",
                            margin: "5px 0px",
                          }}
                        >
                          {t("giftMembers")}
                        </div>
                      ) : (
                        <div> </div>
                      )}
                      {award.gift ? (
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "5px",
                            marginBottom: "15px",
                          }}
                        >
                          {award.gift}
                        </div>
                      ) : (
                        <div> </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {!isMobile && selectedAward && selectedAward.logo && (
              <div className="giftModal">
                <div style={{ fontSize: 16, textAlign: "center" }}>
                  Devenez {selectedAward && selectedAward.title}{" "}
                  {selectedAward &&
                    selectedAward.gift &&
                    `et recevez en cadeau: ${selectedAward.gift}`}
                </div>
                {selectedAward && selectedAward.logo && (
                  <img
                    style={{
                      width: "50%",
                      objectFit: "contain",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={selectedAward.logo}
                  />
                )}
                {/* {selectedAward &&<div style={{marginTop: 'auto'}} onClick={async () => {
                    setIsGiftModalOpen(false)
                    setUserState({...userState, donation: {
                        amount: selectedAward.amount,
                        currency: {label: "Euro", value: 'eur'},
                        times: '1',
                        donateToId: campain.id,
                        sponsorId: sponsor ? sponsor.id : null,
                        returnUrl: window.location.href
                    }})
                    history.push('/donate')
                    }} className={'smbtn btnGeneral'}>Faire un don de {numberWithSpaces(selectedAward.amount)}</div>}
                <div onClick={async () => {setIsGiftModalOpen(false)}} className={'smbtn btnGeneral'}>Retour</div> */}
              </div>
            )}
          </div>

          {/* FIN PACKS */}

          {/* FIN PAGE PARRAIN */}
        </>
      ) : (
        <>
          {/* DEBUT TOP DE LA CAMPAGNE SI PAS MOBILE */}

          {!isMobile && (
            <div className="topCampainContainer">
              <div>
                <Assoc
                  customWidth="90%"
                  // customStyle={{ width: "43%", marginRight: 0 }}
                  campainName={campain.title[0].content}
                  img={campain.associationLogoUrl}
                  title={campain.associationName}
                  desc={campain.description[0].content}
                />
              </div>
              <div>
                <div
                  style={
                    {
                      // display: "flex",
                      // flexDirection: "row",
                      // width: "100%",
                      // marginLeft: "auto",
                      // marginRight: "auto",
                    }
                  }
                >
                  <div
                    className={"share-block-top"}
                    style={
                      {
                        // width: "32%",
                        // marginRight: "auto",
                        // marginLeft: "3%",
                        // marginTop: 20,
                      }
                    }
                  >
                    <div
                      onClick={() => {
                        setUserState({
                          ...userState,
                          donation: {
                            amount: 0,
                            currency: "eur",
                            times: 1,
                            donateToId: campain.id,
                            sponsorId: sponsor ? sponsor.id : null,
                            returnUrl: urlLink,
                            intervals: 1,
                            donFor: null,
                          },
                        });
                        history.push("/donate");
                      }}
                      className="assoc-donation-block-amount-donate"
                    >
                      {t("Campaign.MakeDonation")}
                    </div>
                    <div
                      className="assoc-donation-block-amount-donate-txt"
                      style={{ fontSize: "8px", marginBottom: "-5px" }}
                    >
                      {t("Campaign.GetCerfa")}
                    </div>
                    {/* <span style={{textAlign: 'center', color: '#507cab', fontSize: 20, fontWeight: 'bold'}}>{t("Campaign.SocialShare")}</span> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <FacebookShareButton url={urlLink}>
                        <img
                          className={"h-socialIcon-top"}
                          src={roseFacebook}
                        />
                      </FacebookShareButton>
                      <TwitterShareButton
                        title={`Soutenons : ${campain.title[0].content}`}
                        url={urlLink}
                      >
                        <img className={"h-socialIcon-top"} src={roseTwitter} />
                      </TwitterShareButton>
                      <WhatsappShareButton url={urlLink}>
                        <img
                          className={"h-socialIcon-top"}
                          src={roseWhatsapp}
                        />
                      </WhatsappShareButton>
                      <EmailShareButton url={urlLink}>
                        <img className={"h-socialIcon-top"} src={roseMail} />
                        {/* <EmailIcon size={32} round /> */}
                      </EmailShareButton>
                      {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
                      <div style={{ paddingTop: "9px" }}>
                        <QrCode className={"h-socialIcon-top"} url={urlLink} />
                      </div>
                      <LinkedinShareButton url={urlLink}>
                        <img
                          className={"h-socialIcon-top"}
                          src={roseLinkedin}
                        />
                      </LinkedinShareButton>
                      <div onClick={() => copyToClipboard(urlLink)}>
                        <img
                          className={"h-socialIcon-top"}
                          style={{ cursor: "pointer" }}
                          src={roseLink}
                        />
                        {linkCopyed && (
                          <p style={{ position: "absolute" }}>Link copied</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* FIN TOP DE LA CAMPAGNE SI PAS MOBILE */}

          {/* DEBUT DECOMPTE AVEC COMPTE A REBOURS */}

          {new Date(campain.startingAt) > new Date() ? (
            <IlReste
              futur={true}
              date={new Date(campain.startingAt)}
              renderer={renderer}
            />
          ) : new Date(campain.endingAt) <= new Date() ? null : (
            <IlReste
              futur={false}
              date={new Date(campain.endingAt)}
              renderer={renderer2}
            />
          )}
          {/* <Countdown date={new Date(campain.endingAt)} renderer={renderer2} /> */}

          {/* FIN DECOMPTE AVEC COMPTE A REBOURS */}

          {/* <DonationHeader campain={campain} /> */}
          {/* <StatusCampain campain={campain} /> */}

          {/* DEBUT AFFICHAGE PRINCIPAL */}

          {isMobile && (
            <div
              className={"share-block-top"}
              style={{
                width: "32%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 20,
              }}
            >
              <div
                onClick={() => {
                  setUserState({
                    ...userState,
                    donation: {
                      amount: 0,
                      currency: "eur",
                      times: 1,
                      donateToId: campain.id,
                      sponsorId: sponsor ? sponsor.id : null,
                      returnUrl: urlLink,
                      intervals: 1,
                      // donFor:donFor
                    },
                  });
                  history.push("/donate");
                }}
                className="assoc-donation-block-amount-donate"
              >
                {t("Campaign.MakeDonation")}
              </div>
              <div
                className="assoc-donation-block-amount-donate-txt"
                style={{
                  fontSize: "10px",
                  marginBottom: "-5px",
                  marginLeft: "-100px",
                  width: "300px",
                }}
              >
                {t("Campaign.GetCerfa")}
              </div>
              {/* <span style={{textAlign: 'center', color: '#507cab', fontSize: 20, fontWeight: 'bold'}}>{t("Campaign.SocialShare")}</span> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <FacebookShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseFacebook} />
                </FacebookShareButton>
                <TwitterShareButton
                  title={`Soutenons : ${campain.title[0].content}`}
                  url={urlLink}
                >
                  <img className={"h-socialIcon-top"} src={roseTwitter} />
                </TwitterShareButton>
                <WhatsappShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseWhatsapp} />
                </WhatsappShareButton>
                <EmailShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseMail} />
                  {/* <EmailIcon size={32} round /> */}
                </EmailShareButton>
                {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
                <div style={{ paddingTop: "9px" }}>
                  <QrCode className={"h-socialIcon-top"} url={urlLink} />
                </div>
                <LinkedinShareButton url={urlLink}>
                  <img className={"h-socialIcon-top"} src={roseLinkedin} />
                </LinkedinShareButton>
                <div onClick={() => copyToClipboard(urlLink)}>
                  <img
                    className={"h-socialIcon-top"}
                    style={{ cursor: "pointer" }}
                    src={roseLink}
                  />
                  {linkCopyed && (
                    <p style={{ position: "absolute" }}>Link copied</p>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="containerPrincipal">
            <TopAssoc
              history={history}
              campain={campain}
              userState={userState}
              setUserState={setUserState}
              sponsor={sponsor}
              donations={donations}
            />
          </div>

          {/* FIN AFFICHAGE PRINCIPAL */}

          {/* DEBUT AFFICHAGE INFO ASSO MOBILE */}

          {isMobile && (
            <Assoc
              customStyle={{ width: "70%" }}
              campainName={campain.title[0].content}
              img={campain.associationLogoUrl}
              title={campain.associationName}
              desc={campain.description[0].content}
            />
          )}

          {/* FIN AFFICHAGE INFO ASSO MOBILE */}

          {/* DEBUT AFFICHAGE OBJECTIF ET STATS CAMPAGNE */}

          <div className={"objStatContainer"}>
            <div className={"objContainer"}>
              {new Date() > new Date(campain.startingAt) && (
                <div className={"objSubContainer"}>
                  <div>
                    <span
                      className={"objCollecte"}
                      style={{ marginRight: "25px" }}
                    >
                      {numberWithSpaces(donated)}
                    </span>
                    {t("Charity.Funds")}
                  </div>
                  <div>
                    {donations ? (
                      <span className={"objDonnateur"}>{donations.length}</span>
                    ) : (
                      <span className={"objDonnateur"}>0</span>
                    )}{" "}
                    {t("Charity.Donors")}
                  </div>
                </div>
              )}
              <div className={"objTxt"}>
                {t("Campaign.Goal")}:{" "}
                {campain && numberWithSpaces(campain.goal.amount)}
              </div>
            </div>
            {new Date() > new Date(campain.startingAt) ? (
              <div className={"statContainer"}>
                <ProgressBar
                  history={history}
                  sponsor={sponsor}
                  campain={campain}
                  sponsorNbs={sponsorNbs}
                  donations={donations}
                />
              </div>
            ) : (
              <div className="aboutCampain">{t("Campaign.IsFuture")}</div>
            )}
          </div>

          {/* FIN AFFICHAGE OBJECTIF ET STATS CAMPAGNE */}

          {/* DEBUT A PROPOS DE LA CAMPAGNE */}

          <div style={{ width: "80%", margin: "auto" }}>
            <div className="aboutCampain">{t("AboutCampain")}</div>

            {readMore == true ? (
              <div className={"assocContainer-desc"}>
                {/* {campain.content[0].content} */}
                <Editor readOnly toolbarHidden editorState={editorState} />
                <div
                  onClick={() => setReadMore(false)}
                  style={{
                    color: "#d12164",
                    cursor: "pointer",
                    fontSize: "20px",
                    fontWeight: 600,
                    marginBottom: "40px",
                    textAlign: "justify",
                  }}
                >
                  {t("Campain.close")}
                </div>
              </div>
            ) : (
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  marginBottom: "40px",
                  textAlign: "justify",
                }}
              >
                <div>
                  {editorTemp}&nbsp;{" "}
                  <span
                    onClick={() => setReadMore(true)}
                    style={{ color: "#d12164", cursor: "pointer" }}
                  >
                    {t("Campain.readMore")}
                  </span>
                </div>
              </div>
            )}
          </div>

          {/* FIN A PROPOS DE LA CAMPAGNE */}

          {/* DEBUT CAROUSEL IMAGES */}

          <Carousel
            pictures={pictures}
            videos={videos}
            style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
            reverse
          />
          <div style={{ height: 100 }}></div>

          {/* FIN CAROUSEL IMAGES */}

          {/* DEBUT CAROUSEL SPONSOR */}

          {sponsor && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <DonAssoc
                history={history}
                campain={campain}
                userState={userState}
                setUserState={setUserState}
                sponsor={sponsor}
                setSelectedAward={setSelectedAward}
                selectedAward={selectedAward}
                setIsGiftModalOpen={setIsGiftModalOpen}
              />
              <div
                style={{
                  width: isMobile ? "100%" : "80%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div
                  className={"share-block"}
                  style={{
                    width: "40%",
                    marginTop: 20,
                    marginLeft: "auto",
                    marginRight: 0,
                  }}
                >
                  <span
                    style={{
                      textAlign: "center",
                      color: "#507cab",
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    Partagez avec vos amis !
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      marginTop: 10,
                    }}
                  >
                    <EmailShareButton url={urlLink}>
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                    <FacebookShareButton url={urlLink}>
                      <img className={"h-socialIcon"} src={facebook} />
                    </FacebookShareButton>
                    <QrCode url={urlLink} />
                    <LinkedinShareButton url={urlLink}>
                      <img className={"h-socialIcon"} src={linkedin} />
                    </LinkedinShareButton>
                    <TwitterShareButton
                      title={`Soutenons : ${campain.title[0].content}`}
                      url={urlLink}
                    >
                      <img className={"h-socialIcon"} src={twitter} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={urlLink}>
                      <img className={"h-socialIcon"} src={whatsapp} />
                    </WhatsappShareButton>
                    <div
                      className={"h-socialIcon share-link"}
                      onClick={() => copyToClipboard(urlLink)}
                    >
                      <img src={sharelink} />
                      {linkCopyed && <p>Link copyed</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {campain.sponsors.length > 0 && !sponsor && (
            <>
              <div style={{ marginBottom: "-10px" }} className={"aboutCampain"}>
                {t("donParain")}
              </div>
              <CarouselParain
                history={history}
                sponsor={campain.sponsors}
                campain={campain}
                sponsorNbs={sponsorNbs}
                donations={donations}
              />
            </>
          )}

          {/* FIN CAROUSEL SPONSOR */}

          {/* DEBUT PACKS */}
          {campain.awards.length > 0 && (
            <div style={{ marginBottom: "-10px" }} className={"aboutCampain"}>
              {t("memberPack")}
            </div>
          )}
          <div
            style={{
              width: "83%",
              position: "relative",
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              marginTop: 40,
            }}
          >
            <div
              style={{ marginTop: 0, justifyContent: "center" }}
              className={"assocContainer-donations-ranks"}
            >
              {campain.awards.map((award, idx) => (
                <div
                  key={idx}
                  onClick={() => {
                    setUserState({
                      ...userState,
                      donation: {
                        amount: award.amount,
                        currency: "eur",
                        times: "1",
                        donateToId: campain.id,
                        sponsorId: sponsor ? sponsor.id : null,
                        returnUrl: window.location.href,
                      },
                    });
                    history.push("/donate");
                  }}
                  style={{ width: isMobile ? "30%" : "13%" }}
                  className={"assocContainer-donations-block"}
                >
                  {/* <img className="image-gift" src={gifts[idx]}/> */}
                  <span className={"assocContainer-donations-title"}>
                    {award.title}
                  </span>
                  {/* <div style={{width:"90%",margin:"10px 0px", borderTop:"1px solid #000"}}></div> */}
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ width: "50px", marginTop: "10px" }}
                      src={award.logo ? award.logo : Award}
                      alt=""
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ margin: "15px 0" }}
                        className={"assocContainer-donations-price"}
                      >
                        {numberWithSpaces(award.amount)}
                      </div>
                      {award.gift ? (
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "14px",
                            margin: "5px 0px",
                          }}
                        >
                          {t("giftMembers")}
                        </div>
                      ) : (
                        <div> </div>
                      )}
                      {award.gift ? (
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "14px",
                            marginTop: "5px",
                            marginBottom: "15px",
                          }}
                        >
                          {award.gift}
                        </div>
                      ) : (
                        <div> </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {!isMobile && selectedAward && selectedAward.logo && (
              <div className="giftModal">
                <div style={{ fontSize: 16, textAlign: "center" }}>
                  Devenez {selectedAward && selectedAward.title}{" "}
                  {selectedAward &&
                    selectedAward.gift &&
                    `et recevez en cadeau: ${selectedAward.gift}`}
                </div>
                {selectedAward && selectedAward.logo && (
                  <img
                    style={{
                      width: "50%",
                      objectFit: "contain",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={selectedAward.logo}
                  />
                )}
                {/* {selectedAward &&<div style={{marginTop: 'auto'}} onClick={async () => {
                    setIsGiftModalOpen(false)
                    setUserState({...userState, donation: {
                        amount: selectedAward.amount,
                        currency: {label: "Euro", value: 'eur'},
                        times: '1',
                        donateToId: campain.id,
                        sponsorId: sponsor ? sponsor.id : null,
                        returnUrl: window.location.href
                    }})
                    history.push('/donate')
                    }} className={'smbtn btnGeneral'}>Faire un don de {numberWithSpaces(selectedAward.amount)}</div>}
                <div onClick={async () => {setIsGiftModalOpen(false)}} className={'smbtn btnGeneral'}>Retour</div> */}
              </div>
            )}
          </div>
        </>
      )}
      {/* FIN PACKS */}
      {/* <TopAssoc history={history} campain={campain} userState={userState} setUserState={setUserState}/> */}

      {/* DEBUT DON GENERAL */}

      <div>
        <div
          style={{ marginTop: campain.awards.length > 0 ? "140px" : "0px" }}
          className="aboutCampain"
        >
          {t("Campaign.Amount")}
        </div>
        <DonGeneral
          history={history}
          campain={campain}
          userState={userState}
          setUserState={setUserState}
          sponsor={sponsor}
          donations={donations}
        />
      </div>

      {/* FIN DON GENERAL */}

      <div
        style={{ marginBottom: 30 }}
        className={"campain-container-donators"}
      >
        {/* <div  className={'campain-block1'}>
                <Carousel pictures={pictures} videos={videos}/>
            </div> */}
        {donations && donations.length > 0 && (
          <div className={""}>
            {/* {isFuture == false &&
                <> */}
            {new Date() > new Date(campain.startingAt) && (
              <div
                className={""}
                style={{
                  fontSize: 25,
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 10,
                  textAlign: "center",
                }}
              >
                {t("Campaign.Participated")}
              </div>
            )}
            <div className="campain-subcontainer-donators">
              {isFuture == false ? (
                donations.map((donation, index) => {
                  if (sponsor) {
                    if (
                      !donation.sponsor ||
                      sponsor.id != donation.sponsor.id
                    ) {
                      return null;
                    }
                  }
                  return (
                    <div key={index} className="donation-block-donators">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div>
                            {" "}
                            <span className="fame-donator">
                              {donation.anonymous == false
                                ? donation.donor.firstName +
                                  " " +
                                  donation.donor.lastName
                                : t("Campaign.SponsorAnonymous")}
                            </span>
                            {donation.sponsor && (
                              <span className="fame-sponsor">
                                {" "}
                                via {donation.sponsor.name}
                              </span>
                            )}
                          </div>
                          <div>{donation.message}</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                            width: "120px",
                            minWidth: "120px",
                          }}
                        >
                          <div className="fame-amount">
                            {numberWithSpaces(
                              donation.amount || donation.originalAmount,
                              donation.currency || donation.originalCurrency
                            )}
                          </div>
                          <div className="fame-date">
                            {formatDistanceToNow(new Date(donation.createdAt), {
                              addSuffix: true,
                              locale:
                                t("Campaign.Participated") == "Ont participés"
                                  ? fr
                                  : null,
                            })}
                            {/* {moment(new Date(donation.createdAt)).format('DD/MM/YYYY')} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="aboutCampainFuture">
                  {t("Campaign.AllDonations")} : {days}
                  {t("Campaign.TimeDaysShort")} {hours}h {minutes}m
                </div>
              )}
            </div>
            {/* </>
                } */}
          </div>
        )}
      </div>
      {billetteries && billetteries.length ? (
        <div>
          <div className="typeText">{t("Billeterie.title")}</div>
          <div className="billetterieCampagneContainer">
            {billetteries.map((billet, index) => {
              return (
                <div key={index} className="billetCampagnContainer">
                  <img className="billetImage" src={billet.sharingImage} />
                  <div className="billetInfo">
                    <div className="billetDate">
                      {Moment(billet.dateEvent).format("DD/MM/YYYY")}
                    </div>
                    <div className="billetDesc">{t("Campain.readMore")}</div>
                  </div>
                  <div
                    onClick={() => {
                      window.open(`/${billet.linkParam}`).focus();
                    }}
                    className="billetLink"
                  >
                    {t("Campain.billetterieShop")}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
