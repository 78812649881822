import styles from './index.css';
import { sendContactForm } from '../../Services/ws';
import { useState } from 'react';
import validator from 'validator';
import { store } from 'react-notifications-component';
import { useTranslation } from 'react-i18next'

export default function ContactForm({title, desc,subTitle, history}) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [description, setDesc] = useState('')
    const [validated, setValidated] = useState(false)
    const {t} = useTranslation()
    const language = localStorage.getItem('i18nextLng')
    
    const validateForm = () => {
        var errorFields = []
                lastName.isEmpty() && errorFields.push(`Nom de famille`)
                firstName.isEmpty() && errorFields.push(`Prénom`)
                email.isEmpty() && errorFields.push(`Email`)
                phone.isEmpty() && errorFields.push(`Téléphone`)
                subject.isEmpty() && errorFields.push(`Sujet`)
                description.isEmpty() && errorFields.push(`Description`)
                if (errorFields.length == 0) {
                    if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone)) {
                        store.addNotification({
                            title: "Erreur",
                            message: "Numéro de téléphone invalide. Utilisez le format international",
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                            duration: 10000,
                            onScreen: true
                            }
                        });
                        return false
                    }
                    if (!validator.isEmail(email)) {
                        store.addNotification({
                            title: "Erreur",
                            message: "Email invalide.",
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                            duration: 10000,
                            onScreen: true
                            }
                        });
                        return false
                    }
                    return true
                }
                store.addNotification({
                    title: "Erreur",
                    message: "Merci de remplir les champs suivants: " + errorFields.join(', '),
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 10000,
                      onScreen: true
                    }
                  });
                  return false
    }
    return <div className={'contactFormContainer'}>
        {validated == false ? 
        (
            <div className={'contactForm'}>
        <div className={'cf-title'}>{t(title)}</div>
        {subTitle && <div className={'cf-title'}>{t(subTitle)}</div>}
        <div className={'cf-desc'}>{t(desc)}</div>
        <div className={'cf-inputRow'}>
            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder={t("AssociationPage.FormName")} className={'cf-smallInput cf-left'}></input>
            <input value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder={t('AssociationPage.FormLastName')} className={'cf-smallInput cf-right'}></input>
        </div>
        <div className={'cf-inputRow'}>
            <input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder={t("AssociationPage.FormPhone")} className={'cf-smallInput  cf-left'}></input>
            <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t('AssociationPage.FormEmail')} className={'cf-smallInput cf-right'}></input>
        </div>
        <div className={'cf-inputRow'}>
            <input value={subject} onChange={(e) => setSubject(e.target.value)} placeholder={t("AssociationPage.FormSubject")} className={'cf-bigInput'}></input>
        </div>
        <div className={'cf-inputCol'}>
            <div className={'cf-textareaTitle'}>{t("AssociationPage.FormText")}</div>
            <textarea value={description} onChange={(e) => setDesc(e.target.value)} res className={'cf-textArea'}></textarea>
        </div>
        <div onClick={async () => {
            if (validateForm() == false) return;

            var res = await sendContactForm(firstName, lastName, phone, email, subject, description)
            if (res && res.sent == true) {
                setValidated(true)
            } else {

            }
            
        }} className={'btnNext'}>{t("Send")}</div>
        </div>
        ) : (
            <div className={'contactForm'}>
            <div>
                <p className="signUpEnd">
                Nous avons bien reçu votre demande.<br /> Notre équipe reviendra vers vous sous<br /> 48 heures.
                </p>
            </div>
            <div className="btnNext" style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '100px'}} onClick={async () => {
                history.push('/');
                  
            }}>{t("AssociationPage.FormBack")}</div>
        </div>
        )}
        
    </div>
}
