import React from 'react'

import styles from './DownloadButton.module.css'

const DownloadButton = props => {
    const { buttonStyle } = props
    let buttonClass = styles.downloadButton

    switch(buttonStyle) {
        case 'Primary':
            buttonClass = styles.downloadButtonPrimary
            break
        case 'Danger':
            buttonClass = styles.downloadButtonDanger
            break
        case 'Link':
            buttonClass = styles.downloadButtonLink
            break
        default:
            buttonClass = styles.downloadButton
            break
    }

    return (
        <button type="button" className={buttonClass} onClick={props.action}>
            {props.name}
        </button>
    )
}

export default DownloadButton;