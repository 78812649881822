import React, {useState} from 'react';
import logoFull from '../../assets/website_logo.svg'
import styles from './Invoice.module.css'
import { formatedDate } from "../../Services/utils";
import { useTranslation } from "react-i18next"
import { downloadPDF } from "../../Services/utils";
import DownloadButton from "../Elements/DownloadButton/DownloadButton";

const PageDeDonInvoice = (props) => {
    const bill = props.bill

    const { t } = useTranslation()

    return (
        <>
            <div id={'invoicePage'} className={styles.InvoicePage}>
            <div className={styles.viewBtn} onClick={() => downloadPDF('invoicePage', 'a4', 3508, 2480, 'portrait')}>Download invoice PDF</div>
                {/* <DownloadButton name={'Download invoice PDF'} action={() => downloadPDF('invoicePage', 'a4', 3508, 2480, 'portrait')} /> */}
                <div className={styles.InvoiceTop}>
                    <div className={styles.InvoiceLogo}>
                        <img className="logo" src={logoFull} />
                    </div>
                    <div className={styles.InvoiceAddress}>
                        <div className={styles.InvoiceTableBlock}>
                            <div className={styles.InvoiceTableHead}>
                                {t("BackOffice.MyBilld.Invoice.Bill")}
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillNumber")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.number}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillDate")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.date}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillName")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.associationName}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillAddress")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.assosiationAddress}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillPostal")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.associationPostal}
                                </div>
                            </div>
                            <div className={styles.InvoiceTableRow}>
                                <div className={styles.InvoiceTableCell}>
                                    {t("BackOffice.MyBilld.Invoice.BillCountry")}
                                </div>
                                <div className={styles.InvoiceTableCell}>
                                    {bill.associationCity}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.InvoiceMiddle}>
                    <table className={styles.InvoiceTable}>
                        <thead>
                            <tr>
                                <th>{t("BackOffice.MyBilld.Invoice.BillLineName")}</th>
                                <th>{t("BackOffice.MyBilld.Invoice.BillLineQuantity")}</th>
                                <th>{t("BackOffice.MyBilld.Invoice.BillLineAmount")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t("BackOffice.MyBilld.Invoice.OnlineDonation")}</td>
                                <td>{bill.amount}</td>
                                <td>{bill.charityFee + ' €'}</td>
                            </tr>
                            <tr>
                                <td>{t("BackOffice.MyBilld.Invoice.StripeFees")}</td>
                                <td></td>
                                <td>{bill.maamFee + ' €'}</td>
                            </tr>
                            <tr>
                                <td>{t("BackOffice.MyBilld.Invoice.CashDonations")}</td>
                                <td>{bill.amountByCash + ' €'}</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>{t("BackOffice.MyBilld.Invoice.NumberOnlineDonations")}</td>
                                <td>{bill.donationsOnline}</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>{t("BackOffice.MyBilld.Invoice.NumberManualDonations")}</td>
                                <td>{bill.donationsManually}</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Total HT</td>
                                <td>{bill.totalTH} €</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>T.V.A. 20%</td>
                                <td>{bill.totalTVA} €</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Total TTC</td>
                                <td>{bill.totalTTC} €</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={styles.InvoiceBottom}>
                    <table className={styles.InvoiceTable}>
                        <thead>
                            <tr>
                                <th colspan='6' style={{textAlign: 'center'}}>{t("BackOffice.MyBilld.Invoice.DonationDetails")}</th>
                            </tr>
                            <tr>
                                <th>{t("BackOffice.MyBilld.Invoice.DetailName")}</th>
                                <th>{t("BackOffice.MyBilld.Invoice.DetailDate")}</th>
                                <th>{t("BackOffice.MyBilld.Invoice.DetailAmount")}</th>
                                <th>{t("BackOffice.MyBilld.Invoice.DetailCharityFee")}</th>
                                <th>{t("BackOffice.MyBilld.Invoice.DetailStripeFee")}</th>
                                <th>{t("BackOffice.MyBilld.Invoice.DetailNet")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bill.donors.map(donor => (
                                <tr>
                                    <td>{donor.fullName}</td>
                                    <td>{formatedDate(donor.donationDate)}</td>
                                    <td>{donor.amount} €</td>
                                    <td>{donor.charityFee} €</td>
                                    <td>{donor.stripeFee} €</td>
                                    <td>{donor.net} €</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={styles.InvoiceDates}>
                        <span>{t("BackOffice.MyBilld.Invoice.TransferState")}: </span>
                        {t("BackOffice.MyBilld.Invoice.WillTransfer")} 01.{bill.invoiceMonth}.{bill.invoiceYear} {t("BackOffice.MyBilld.Invoice.And")} 08.{bill.invoiceMonth}.{bill.invoiceYear}
                    </div>
                </div>
                <div className={styles.InvoiceFooter}>
                    SAS ODAYA CHARITYCLIC 18 BD FREDERIC SAUVAGE 13014 MARSEILLE<br />
                    Numéro de TVA intracommunautaire : FR3589798616<br />
                    Numéro RCS Marseille B897 968 616 Capital Social 1 000 €<br />
                    charityclic.com
                </div>
            </div>
        </>
    )
}

export default PageDeDonInvoice;