import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { useEffect, useState, useRef } from "react";
import { getInvoices, getInvoicesByMonth } from "../../Services/ws";
import DownloadButton from "../../Components/Elements/DownloadButton/DownloadButton";
import CampaignInvoice from "../../Components/Invoices/Campaign";
import PageDeDonInvoice from "../../Components/Invoices/TransactionInvoice";
import Search from "../../Components/Elements/Search/Search"
import { downloadExcel, downloadPDF, formatedDate } from "../../Services/utils";
import { useTranslation } from "react-i18next";

import styles from './MyBills.module.css'


const MyBills = () => {
  const [dataSource, setDataSource] = useState([])
  const [search, setSearch] = useState('')
  const [currentData, setCurrentData] = useState([])
  const [dataYear, setDataYear] = useState();
  const [years, setYears] = useState();
  const [part, setPart] = useState(0);
  const [bill, setBill] = useState(0);
  const [openSelectYear, setOpenSelectYear] = useState(false);
  const [openSelectMonth, setOpenSelectMonth] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const pdfExportComponent = useRef(null);
  const { t } = useTranslation()

  const getData = async () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const data = await getInvoices(`/invoices/${currentYear}`);
    setDataSource(data)
    setCurrentData(data)
    setDataYear(currentYear)
    setSelectedYear(currentYear)
  }

  const updateData = async (year) => {
    const data = await getInvoices(`/invoices/${year}`)
    setDataSource(data)
    setCurrentData(data)
    setDataYear(year)
    setSelectedYear(year)
    setOpenSelectYear(false)
  }

  const updateByMonth = async (month) => {
    const data = await getInvoicesByMonth(`/invoices/${selectedYear}/${month}`)
    setDataSource(data)
    setCurrentData(data)
    setSelectedMonth(month)
    setOpenSelectMonth(false)
  }

  const getYears = () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    let years = []
    for(let i = 2021; i <= currentYear; i++) {
      years.push(i)
    }
    setYears(years)
  }

  const months = ['January','February','March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const monthNames = number => {
    switch(number) {
      case 1:
        return 'January';
      case 2:
        return 'February';
      case 3:
        return 'March';
      case 4:
        return 'April';
      case 5:
        return 'May';
      case 6:
        return 'June';
      case 7:
        return 'July';
      case 8:
        return 'August';
      case 9:
        return 'September';
      case 10:
        return 'October';
      case 11:
        return 'November';
      case 12:
        return 'December';
      default:
        return 'No name';
        break;
    }
  }

  const columns = [
    'BackOffice.MyBills.InvoiceNumber',
    'BackOffice.MyBills.InvoiceOrigin',
    'BackOffice.MyBills.InvoiceAssociationName',
    'BackOffice.MyBills.InvoiceDate',
    'BackOffice.MyBills.InvoiceAmount'
  ]

  useEffect(() => {
    getData();
    getYears();
  }, []);

  const CellRender = (cell) => (
    <p style={{fontSize: '12px'}}>{cell}</p>
  )

  const openDonsInvoice = (invoice) => {
    setBill(invoice)
    setPart(2)
  }

  const openCampaignInvoice = (invoice) => {
    setBill(invoice)
    setPart(1)
  }

  const backToBills = () => {
    setPart(0)
  }

  const handleSearch = (event) => {
    setSearch(event.target.value.toLowerCase());
    if(search.length > 1) {
      const result = currentData.filter(item => {
        Object.values(item).find(association => {
          association[0].associationName.toLowerCase().includes(search)
        })
      })
      setDataSource(result)
    } else {
      setDataSource(currentData)
    }
  };

  return (
    <>
      <div>
        {part === 0 &&
        <>
          <div className={styles.pageHead}>
            <h1 style={{color:"#145a89"}}>{t("BackOffice.MyBills.Title")}</h1>
          </div>

          <div className={styles.selectRow}>
            <div className={styles.selectWrapper}>
              <div className={styles.selectLabel}>
                Selected year:
              </div>
              <div className={styles.selectBlock}>
                <div className={styles.select} onClick={() => setOpenSelectYear(true)}>
                  {selectedYear}
                </div>
                {openSelectYear &&
                  <div className={styles.options}>
                    <ul>
                      {years.map(year => (
                          <li onClick={() => updateData(year)}>{year}</li>
                      ))}
                    </ul>
                  </div>
                }
              </div>
            </div>

            {/*<div className={styles.selectWrapper}>*/}
            {/*  <div className={styles.selectLabel}>*/}
            {/*    Selected month:*/}
            {/*  </div>*/}
            {/*  <div className={styles.selectBlock}>*/}
            {/*    <div className={styles.select} onClick={() => setOpenSelectMonth(true)}>*/}
            {/*      {selectedMonth}*/}
            {/*    </div>*/}
            {/*    {openSelectMonth &&*/}
            {/*    <div className={styles.options}>*/}
            {/*      <ul>*/}
            {/*        {months.map((month, index) => (*/}
            {/*            <li onClick={() => updateByMonth(index+1)}>{month}</li>*/}
            {/*        ))}*/}
            {/*      </ul>*/}
            {/*    </div>*/}
            {/*    }*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className={styles.selectWrapper}>
              <Search change={handleSearch} />
            </div>
          </div>

          <div id={'billsTable'} className={styles.tableGrid}></div>
          {dataSource.slice(0).reverse().map((month, index) => {
            let monthConverted = Object.keys(month).map((key) => [Number(key), month[key]]);
            return(
                <div className={styles.tableCol}>
                  <div className={styles.tableNavbar}>
                    <div className={styles.tableName}>
                      {dataYear + ' ' + monthNames(monthConverted[0][0])}
                    </div>
                  </div>
                  <TableContainer component={Paper} className={styles.tableContainer} id={'associationTable' + index}>
                    <Table  className={styles.table} aria-label="simple table">
                      <TableHead className={styles.tableHead}>
                        <TableRow>
                          {
                            columns.map(cel => (
                                <TableCell size='small'>{t(cel)}</TableCell>
                            ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody className={styles.tableBody}>
                        {monthConverted.map((bills, index) => {
                          return (
                              bills[1].slice(0).reverse().map(bill => {
                                let earing = 0
                                let totalEaring = 0
                                if(bill.reasons.length > 0) {
                                  bill.reasons.map(reason => {
                                    earing = earing + reason.rising
                                  });
                                }
                                totalEaring = earing + bill.charityFee
                                return (
                                    <TableRow
                                        key={bill?.donation?.id}
                                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                    >
                                      <TableCell>{bill.number}</TableCell>
                                      <TableCell>{bill.reasons.length > 0 ? 'Campaign' : 'Page de don'}</TableCell>
                                      <TableCell>{bill.associationName}</TableCell>
                                      <TableCell>{bill.date}</TableCell>
                                      <TableCell >
                                        {bill.totalTTC.toFixed(2)}
                                        {/* {bill.reasons.length > 0 ? totalEaring.toFixed(2) : bill.charityFee} */}
                                        </TableCell>
                                      <TableCell>
                                        {bill.reasons.length === 0 &&
                                          <div className={styles.viewBtn} onClick={() => openDonsInvoice(bill)}>{t('BackOffice.MyBills.Invoice.Open')}</div>
                                          // <DownloadButton name={t('BackOffice.MyBills.Invoice.Open')} action={() => openDonsInvoice(bill)} />
                                        }
                                        {bill.reasons.length > 0 &&
                                        <div className={styles.viewBtn} onClick={() => openCampaignInvoice(bill)}>{t('BackOffice.MyBills.Invoice.Open')}</div>
                                          // <DownloadButton name={t('BackOffice.MyBills.Invoice.Open')} action={() => openCampaignInvoice(bill)} />
                                        }
                                      </TableCell>
                                    </TableRow>
                                )}
                              )
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
            )
          })}
        </>
        }

        {part === 1 &&
          <>
            <div className={styles.pageHead}>
              <div className={styles.viewBtn} style={{display: 'inline-block'}} onClick={() => backToBills()} >Back</div>
            </div>

            <CampaignInvoice bill={bill} />
          </>
        }

        {part === 2 &&
        <>
          <div className={styles.pageHead}>
            <DownloadButton name={'Back'} action={() => backToBills()} />
          </div>

          <PageDeDonInvoice bill={bill} />
        </>
        }
      </div>
    </>
  );
}

export default MyBills;
