import {useRef, useState, useEffect, useContext} from "react"
import { getPageByUrl, getBackOffice } from '../Services/ws';
import { UserContext } from "../Context/UserContext";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Campain from "./Campain";
import Association from "./Association";
import Billetterie from './Billetterie/Billetterie'

export default function Donations({history}) {
    const [campain, setCampain] = useState(null)
    const [assoc, setAssoc] = useState(null)
    const [billetterie,setBilletterie] = useState(null)
    useEffect(() => {

        if(history.location.search){
            var queryTemp = history.location.search.replace("-","-")
            
            history.location.search = queryTemp
        }
        var params = history.location.pathname.split('/');
        setAssoc(null)
        setCampain(null)

        getPageByUrl(params[params.length - 1]).then((res) => {
            //console.log('RESULT', res);
            if (res.packs) {
                if (res.goal) {
                    setCampain(res)
                } else {
                    setAssoc(res)
                }
            }else if(res.tickets){
                setBilletterie(res)
            }
        })
    }, [history.location.pathname])
    return <div>
        {campain && <Campain history={history} />}
        {assoc && <Association history={history} />}
        {billetterie && <Billetterie history={history} billetterie={billetterie} />}
    </div>
}
