import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import {useContext, useEffect, useState} from "react";
import {makeTransfer, getPayoutsForAssociation, getTreasuryAmount} from "../../Services/ws";
import { formatedDate } from "../../Services/utils";
import DownloadButton from '../../Components/Elements/DownloadButton/DownloadButton'
import Modal from "../../Components/Elements/Modal/Modal"
import { useTranslation } from "react-i18next"

import styles from './Treasury.module.css'
import {UserContext} from "../../Context/UserContext";

const Treasury = () => {
  const [dataSource, setDataSource] = useState([])
  const [search, setSearch] = useState('')
  const [currentData, setCurrentData] = useState([])
  const [balance, setBalance] = useState(0)
  const [currency, setCurrency] = useState('')
  const [currencyName, setCurrencyName] = useState('')
  const [transferAmount, setTransferAmount] = useState(0)
  const [showModal, setModalShow] = useState(false)
  const [transferError, setTransferError] = useState(false)
  const [transferErrorText, setTransferErrorText] = useState('')
  const [transferSuccess, setTransferSuccess] = useState(false)
  const [transferSuccessText, setTransferSuccessText] = useState('')
  const [userState, setUserState] = useContext(UserContext);
  const { t } = useTranslation()

  const getAppBalance = async () => {
    let data = await getTreasuryAmount('/treasury/amount/charity');
    setBalance(data)
    setCurrency('€')
  }

  const getHistory = async () => {
    const payouts = await getPayoutsForAssociation(`/payouts/charity`)
    if(payouts) {
      setDataSource(payouts)
    }
  }

  const showCheckPopup = () => {
    if(balance < transferAmount) {
      setTransferErrorText(`You can't transfer ${transferAmount} ${currency}, because your transfer balance ${balance} ${currency}`)
      setTransferError(true)

      setTimeout(() => {
        setTransferErrorText('')
        setTransferError(false)
      }, 5000)
    } else {
      setModalShow(true)
    }
  }

  const makeTransferToAccount = async () => {
    const data = {
      "amount": transferAmount,
      "currency": currencyName,
      "bankId": "ba_1J9UleIWOV9KfAhRWSRmmr6B",
      "bankDetails": "1B54",
      "bankName": "CREDIT LYONNAIS",
      "associationId": "charity"
    }

    const payout = await makeTransfer(`/treasury/transfer/acct_1IgnQQIWOV9KfAhR`, data);
    if(payout.id) {
      setTransferSuccessText(`Your transfer of ${transferAmount} ${currency} was successfull`)
      setTransferSuccess(true)

      setTimeout(() => {
        setTransferSuccessText('')
        setTransferSuccess(false)
      }, 5000)
    } else {
      setTransferErrorText(`Something went wrong`)
      setTransferError(true)

      setTimeout(() => {
        setTransferErrorText('')
        setTransferError(false)
      }, 5000)
    }

    setModalShow(false)
    setTransferAmount(0)
    await getAppBalance()
  }

  const modalContentBlock = (amount) => {
    return(
        <>
          <div className={'text'}>
            Are you sure you want transfer {amount} {currency} to CharityClick account?
          </div>
          <div className="buttons">
            <DownloadButton name={'Yes'} action={()=>{makeTransferToAccount()}} />
            <DownloadButton buttonStyle={'Link'} name={'No'} action={() => setModalShow(false)}/>
          </div>
        </>
    )
  }

  const columns = [
    'BackOffice.Treasury.Date',
    'BackOffice.Treasury.Amount',
    'BackOffice.Treasury.Bank',
    'BackOffice.Treasury.IBan',
  ]

  useEffect(() => {
    getAppBalance()
    getHistory()
  }, []);

  const CellRender = (cell) => (
    <p style={{fontSize: '12px'}}>{cell}</p>
  )

  return (
    <>
      <div>
        <div className={styles.pageHead}>
          <h1 style={{color:"#145a89"}}>{t("BackOffice.Treasury.Title")}</h1>
        </div>

        <div className={styles.checkout}>
          At checkout <span>{balance} {currency}</span>
        </div>

        <div className={styles.makeTransfer}>
          <label htmlFor={'transferAmount'}>Make a transfer to the Charityclic bank account</label>
          <input name={'transferAmount'}
                 type={'number'}
                 value={transferAmount}
                 onChange={(e) => setTransferAmount(e.target.value)}
          />
          <button type={'button'} onClick={() => showCheckPopup()}>Validate</button>
        </div>

        {transferError &&
          <div className={styles.transferError}>
            {transferErrorText}
          </div>
        }

        {transferSuccess &&
          <div className={styles.transferSuccess}>
            {transferSuccessText}
          </div>
        }

        {
          dataSource.length && (
          <>
            <div className={styles.tableTitle}>
              {t('BackOffice.Treasury.PayoutTitle')}
            </div>
            <TableContainer component={Paper} className={styles.tableContainer} id={'associationTable'}>
              <Table className={styles.table} aria-label="simple table">
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    {
                      columns.map(cel => (
                          <TableCell size='small'>{t(cel)}</TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody className={styles.tableBody}>
                  {dataSource.length ? dataSource.map((row) => (
                      <TableRow
                          key={row?.donation?.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center">{CellRender(formatedDate(row?.date))}</TableCell>
                        <TableCell align="center">{CellRender(row?.amount + '€')}</TableCell>
                        <TableCell align="center">{CellRender(row?.bank)}</TableCell>
                        <TableCell align="center">{CellRender('**** **** **** ' + row?.bankDetails)}</TableCell>
                      </TableRow>
                  )) : 'There is no transfers yet'}
                </TableBody>
              </Table>
            </TableContainer>
          </>
          )
        }
      </div>

      {showModal &&
        <Modal modalContent={modalContentBlock(transferAmount)} modalClose={() => setModalShow(false)} />
      }
    </>
  );
}

export default Treasury;