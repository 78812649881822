import { configureRefreshFetch, fetchJSON } from 'refresh-fetch'
import React, {useState, useContext, useEffect} from 'react';
import { UserContext } from "../Context/UserContext";
import {APIURL} from './consts'
import regeneratorRuntime from "regenerator-runtime";

const newFetch = configureRefreshFetch({
    fetch: (url, options = {}) => {
        var res = fetchJSON(url, options)
        // console.log(res);
        
        return res
    },
    shouldRefreshToken: error => {
            //   console.log('ERROR', error);
              return error.status == 401;
    },
    refreshToken: () => {
        new Promise(async (resolve, reject) => {
        var accessToken = localStorage.getItem('accessToken')            
        var refreshToken = localStorage.getItem('refreshToken')            
            const res = await fetch(APIURL + '/auth/refreshToken', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                    'refresh': refreshToken
                }
            });
                //console.log(res);
                const result = await res.json();
                //console.log(result);
                if (res.ok) {
                    localStorage.setItem('accessToken', result.accessToken)            
                    localStorage.setItem('refreshToken', result.refreshToken)
                    resolve();
                } else {
                    if (result.message == "WRONG_REFRESH_TOKEN") {
                        localStorage.setItem('accessToken', null)            
                        localStorage.setItem('refreshToken', null)
                        }
                    reject();
                }
        })
    }
  })
// const refreshFetch = configureRefreshFetch({
//   // Pass fetch function you want to wrap, it should already be adding
//   // token to the request
//   fetch,
//   // shouldRefreshToken is called when API fetch fails and it should decide
//   // whether the response error means we need to refresh token
//   shouldRefreshToken: error => {
//       console.log('ERROR', error);
//       return false;
//   },
//   // refreshToken should call the refresh token API, save the refreshed
//   // token and return promise -- resolving it when everything goes fine,
//   // rejecting it when refreshing fails for some reason
//   refreshToken: () => Promise.resolve()
// })

export const getCollect = async () => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(APIURL + '/collect/me', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
        });
        // console.log(res);
        
        var result  = res.body;
    return result
}

export const getMyCampains = async () => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(APIURL + '/campaign/me/0', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
        });
        var result  = res.body;
    return result
}

export const getMyBilletteries = async (assoId) => {
    // var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(APIURL + `/billetterie/me/0/${assoId}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${accessToken}`
        }
        });
        var result  = res.body;
    return result
}


export const getCollectFromCamp = async (campId) => {
    const res = await newFetch(APIURL + '/search/campaigns/collect/'+ campId, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        });
        var result  = res.body;
    return result
}

export const getCampainDonations = async (id) => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(`${APIURL}/search/${id}/donations/0`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
        var result  = res.body;
    return result
}

export const getBilletterieDonations = async (id) => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(`${APIURL}/search/${id}/billetteriedonations/0`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
        var result  = res.body;
    return result
}

export const createCollect = async (linkParam, url, sharingImage, title, description, content, pictures, videos, logo, name, user_id) => {
    var accessToken = localStorage.getItem('accessToken')
    var medias = [];
    // if (video) {
    //     medias.push({"language": "EN", "url": video, "type": "VIDEO"})
    // }
    for (let i = 0; i < pictures.length; i++) {
        const element = pictures[i];
        medias.push({
            "language": "EN", "url": element, "type": "PICTURE"
        })
    }
    for (let i = 0; i < videos.length; i++) {
        const element = videos[i];
        medias.push({
            "language": "EN", "url": element, "type": "VIDEO"
        })
    }
    const res = await newFetch(APIURL + '/collect/me/create', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(
            {
                user_id: user_id,
                associationLogoUrl: logo,
                associationName: name,
                sharingImage: sharingImage,
                "linkParam": linkParam,
                "poster": [
                  {
                    "language": "EN",
                    "url": url,
                    "type": url.indexOf('.mp4') > 0 ? "VIDEO" : "PICTURE"
                  }
                ],
                "title": [
                  {
                    "content": title,
                    "language": "EN"
                  }
                ],
                "description": [
                  {
                    "content": description,
                    "language": "EN"
                  }
                ],
                "content": [
                  {
                    "content": content,
                    "language": "EN"
                  }
                ],
                "medias": medias
              }
        )        
        });
        var result  = res.body;
        // console.log(result);
        return result;
}

export const createCampain = async (pic, sharingImage, campTitle, description, article, pictures, startDate, endDate, obj, url, name, logo, videos, awards, affect,draft,pairingMail,pairing,pairingId,objBonus) => {
    var accessToken = localStorage.getItem('accessToken')
    var medias = [];
    for (let i = 0; i < pictures.length; i++) {
        const element = pictures[i];
        medias.push({
            "language": "EN", "url": element, "type": "PICTURE"
        })
    }
    for (let i = 0; i < videos.length; i++) {
        const element = videos[i];
        medias.push({
            "language": "EN", "url": element, "type": "VIDEO"
        })
    }
    const res = await newFetch(APIURL + '/campaign/me/create', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(
            {
                "linkParam": url,
                associationName: name,
                associationLogoUrl: logo,
                sharingImage: sharingImage,
                "poster": [
                  {
                    "language": "EN",
                    "url": pic,
                    "type": pic.indexOf('.mp4') > 0 ? "VIDEO" : "PICTURE"
                  }
                ],
                "title": [
                  {
                    "content": campTitle,
                    "language": "EN"
                  }
                ],
                "description": [
                  {
                    "content": description,
                    "language": "EN"
                  }
                ],
                "content": [
                  {
                    "content": article,
                    "language": "EN"
                  }
                ],
                "affects":affect,
                "pairingMail":pairingMail,
                "pairingId":pairingId,
                "awards": awards,
                "medias": medias,
                "draft":draft,
                "pairing":pairing,
                "startingAt": startDate.toISOString(),
                "endingAt": endDate.toISOString(),
                "goal": {
                  "amount": parseInt(obj),
                  "amountBonus": objBonus?parseInt(objBonus):0,
                  "currency": "eur",
                  "bonusMultiplier": 0
                }
              }
        )        
        });
        var result  = res.body;
        // console.log(result);
        return result
}

export const createBilletterie = async (pic, sharingImage, campTitle, description, article, pictures, startDate, endDate, url, name, logo, videos, ticket,idCampaignPaired="") => {
    var accessToken = localStorage.getItem('accessToken')
    var medias = [];
    for (let i = 0; i < pictures.length; i++) {
        const element = pictures[i];
        medias.push({
            "language": "EN", "url": element, "type": "PICTURE"
        })
    }
    for (let i = 0; i < videos.length; i++) {
        const element = videos[i];
        medias.push({
            "language": "EN", "url": element, "type": "VIDEO"
        })
    }
    try{

    
    const res = await newFetch(APIURL + '/billetterie/me/create', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(
            {
                "linkParam": url,
                associationName: name,
                associationLogoUrl: logo,
                sharingImage: sharingImage,
                "poster": [
                  {
                    "language": "EN",
                    "url": pic,
                    "type": pic.indexOf('.mp4') > 0 ? "VIDEO" : "PICTURE"
                  }
                ],
                "title": [
                  {
                    "content": campTitle,
                    "language": "EN"
                  }
                ],
                "description": [
                  {
                    "content": description,
                    "language": "EN"
                  }
                ],
                "tickets":ticket,
                "content": [
                  {
                    "content": article,
                    "language": "EN"
                  }
                ],
                "medias": medias,
                "startingAt": startDate.toISOString(),
                "endingAt": endDate.toISOString(),
                "idCampaignPaired":idCampaignPaired,
              }
        )        
        });
        var result  = res.body;
        // console.log(result);
        return result
    }catch(error){
        console.log(error);
    }
}

export const updateCampain = async (id, pic, sharingImage, campTitle, description, article, pictures, startDate, endDate, obj, url, name, logo, videos, awards, affect,draft,pairingMail,pairing,pairingId,objBonus) => {
    var accessToken = localStorage.getItem('accessToken')
    var medias = [];
    for (let i = 0; i < pictures.length; i++) {
        const element = pictures[i];
        medias.push({
            "language": "EN", "url": element, "type": "PICTURE"
        })
    }
    for (let i = 0; i < videos.length; i++) {
        const element = videos[i];
        medias.push({
            "language": "EN", "url": element, "type": "VIDEO"
        })
    }
    const res = await newFetch(APIURL + '/campaign/me/update/' + id, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(
            {
                "linkParam": url,
                associationName: name,
                associationLogoUrl: logo,
                sharingImage: sharingImage,
                "poster": [
                  {
                    "language": "EN",
                    "url": pic,
                    "type": pic.indexOf('.mp4') > 0 ? "VIDEO" : "PICTURE"
                  }
                ],
                "title": [
                  {
                    "content": campTitle,
                    "language": "EN"
                  }
                ],
                "description": [
                  {
                    "content": description,
                    "language": "EN"
                  }
                ],
                "content": [
                  {
                    "content": article,
                    "language": "EN"
                  }
                ],
                "affects":affect,
                "pairingMail":pairingMail,
                "pairingId":pairingId,
                "awards": awards,
                "medias": medias,
                "draft":draft,
                "pairing":pairing,
                "startingAt": startDate.toISOString(),
                "endingAt": endDate.toISOString(),
                "goal": {
                  "amount": parseInt(obj),
                  "amountBonus": parseInt(objBonus),
                  "currency": "eur",
                  "bonusMultiplier": 0
                }
              }
        )        
        });
        var result  = res.body;
        return result
}

export const updateBilletterie = async (id, pic, sharingImage, campTitle, description, article, pictures, startDate, endDate, url, name, logo, videos,ticket, associationId,idCampaignPaired="") => {
    var accessToken = localStorage.getItem('accessToken')
    // console.log("token");
    // console.log(accessToken);
    var medias = [];
    if(pictures && pictures.length){

        for (let i = 0; i < pictures.length; i++) {
            const element = pictures[i];
            medias.push({
                "language": "EN", "url": element, "type": "PICTURE"
            })
        }
    }
    if(videos && videos.length){

        for (let i = 0; i < videos.length; i++) {
            const element = videos[i];
            medias.push({
                "language": "EN", "url": element, "type": "VIDEO"
            })
        }
    }
    const res = await fetchJSON(APIURL + '/billetterie/me/update/' + id, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(
            {
                "linkParam": url,
                associationId:associationId,
                associationName: name,
                associationLogoUrl: logo,
                sharingImage: sharingImage,
                "poster": [
                  {
                    "language": "EN",
                    "url": pic,
                    "type": pic.indexOf('.mp4') > 0 ? "VIDEO" : "PICTURE"
                  }
                ],
                "title": [
                  {
                    "content": campTitle,
                    "language": "EN"
                  }
                ],
                "description": [
                  {
                    "content": description,
                    "language": "EN"
                  }
                ],
                "tickets":ticket,
                "content": [
                  {
                    "content": article,
                    "language": "EN"
                  }
                ],
                "medias": medias,
                "startingAt": startDate.toISOString(),
                "endingAt": endDate.toISOString(),
                "idCampaignPaired":idCampaignPaired,
              }
        )        
        });
        var result  = res.body;
        // console.log(res);
        // console.log(result);
        return result
}

export const getPaired = async(id)=>{
    const res = await newFetch(APIURL + `/campaign/me/paired/${id}`)
    return res
}

export const pairingCampaign = async(campaignId, response)=>{
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(APIURL + `/campaign/me/pairing/${campaignId}/${response}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
        });
        var result  = res.body;
        return result;
        // console.log(result);
}

export const updateCollect = async (linkParam, url, sharingImage,  title, description, content, pictures, videos, logo, name, user_id) => {
    var accessToken = localStorage.getItem('accessToken')
    var medias = [];
    // if (video) {
    //     medias.push({"language": "EN", "url": video, "type": "VIDEO"})
    // }
    for (let i = 0; i < pictures.length; i++) {
        const element = pictures[i];
        medias.push({
            "language": "EN", "url": element, "type": "PICTURE"
        })
    }
    for (let i = 0; i < videos.length; i++) {
        const element = videos[i];
        medias.push({
            "language": "EN", "url": element, "type": "VIDEO"
        })
    }
    const res = await newFetch(APIURL + '/collect/me/update', {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(
            {
                user_id: user_id,
                associationLogoUrl: logo,
                associationName: name,
                sharingImage: sharingImage,
                "linkParam": linkParam,
                "poster": [
                  {
                    "language": "EN",
                    "url": url,
                    "type": url.indexOf('.mp4') > 0 ? "VIDEO" : "PICTURE"
                  }
                ],
                "title": [
                  {
                    "content": title,
                    "language": "EN"
                  }
                ],
                "description": [
                  {
                    "content": description,
                    "language": "EN"
                  }
                ],
                "content": [
                  {
                    "content": content,
                    "language": "EN"
                  }
                ],
                "medias": medias
              }
        )        
        });
        var result  = res.body;
        // console.log(result);
}

export const publishCollect = async () => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(APIURL + '/collect/me/publish', {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }      
        });
        var result  = res.body;
        //console.log(result);
}

export const publishCampain = async (id) => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(APIURL + '/campaign/me/publish/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }      
        });
        var result  = res.body;
        //console.log(result);
}
export const publishBilletterie = async (id) => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(APIURL + '/billetterie/me/publish/' + id, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }      
        });
        var result  = res.body;
        //console.log(result);
}


export const addSponsor = async (campainId, name, picUrl, goal) => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(`${APIURL}/campaign/me/${campainId}/sponsor`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(
            {
                "name": name,
                "picture": picUrl,
                "amount": parseInt(goal)
            }
        )        
        });
        var result  = res.body;
        //console.log(result);
        return result
}

export const removeSponsor = async (campainId, sponsorId) => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(`${APIURL}/campaign/me/${campainId}/sponsor/${sponsorId}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
        });
        var result  = res.body;
        //console.log(result);
        return result
}

export const getCampains = async (offset=0) => {
    const res = await newFetch(`${APIURL}/search/campaigns/latest/${offset}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    var result  = res.body;
    //console.log(result);
    return result
}

export const getAllCampains = async (offset=0) => {
    const res = await newFetch(`${APIURL}/search/campaigns/`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    var result  = res.body;
    //console.log(result);
    return result
}

export const getAllCamps = async()=>{
    const res = await newFetch(`${APIURL}/campaign/back-office`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    var result  = res.body;
    //console.log(result);
    return result
}

export const getUpcomingCampains = async (offset=0) => {
    const res = await newFetch(`${APIURL}/search/campaigns/upcoming/${offset}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    var result  = res.body;
    //console.log(result);
    return result
}
export const getSponsorNumbers = async (id) => {
    const res = await newFetch(`${APIURL}/search/${id}/sponsors`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    var result  = res.body;
    //console.log(result);
    return result
}

export const getData = async () => {
    const res = await newFetch(`${APIURL}/search/total`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    var result  = res.body;
    //console.log(result);
    return result
}


export const getGateway = async (accId) => {
    const res = await newFetch(`${APIURL}/search/${accId}/gateway`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    var result  = res.body;
    // console.log(result);
    return result
}

export const createPayment = async (id, amount, currency, bizName,firstName, lastName, phone, email, addr, zipCode, city, country, sponsorId, isAnonymous, message,donFor) => {
    const res = await newFetch(`${APIURL}/donation/create/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            {
            "donor": {
                "businessName": bizName.isEmpty() ? null : bizName,
                "firstName": firstName,
                "lastName": lastName,
                "phone": phone,
                "email": email,
                "address": {
                  "lineOne": addr,
                  "lineTwo": null,
                  "zipCode": zipCode,
                  "city": city,
                  "state": null,
                  "country": country
                }
              },
              "transaction": {
                "amount": parseFloat(amount),
                "currency": currency
              },
              "sponsorId": sponsorId,
              "anonymous": isAnonymous,
              "message": message,
              "donFor":donFor
            }
        ) 
    });
    var result  = res.body;
    return result
}
export const createPayment2 = async (id, amount, currency, bizName,firstName, lastName, phone, email, addr, zipCode, city, country, sponsorId, isAnonymous, message,donFor) => {
    const res = await newFetch(`${APIURL}/donation/create2/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            {
            "donor": {
                "businessName": bizName.isEmpty() ? null : bizName,
                "firstName": firstName,
                "lastName": lastName,
                "phone": phone,
                "email": email,
                "address": {
                  "lineOne": addr,
                  "lineTwo": null,
                  "zipCode": zipCode,
                  "city": city,
                  "state": null,
                  "country": country
                }
              },
              "transaction": {
                "amount": parseFloat(amount),
                "currency": currency
              },
              "sponsorId": sponsorId,
              "anonymous": isAnonymous,
              "message": message,
              "donFor":donFor
            }
        ) 
    });
    var result  = res.body;
    return result
}

export const createPaymentSub = async (id, amount, currency, bizName,firstName, lastName, phone, email, addr, zipCode, city, country, sponsorId, isAnonymous, message, cardToken, intervals, donFor) => {
    // console.log(intervals);
    const res = await newFetch(`${APIURL}/donation/createSub/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(
            {
            "donor": {
                "businessName": bizName.isEmpty() ? null : bizName,
                "firstName": firstName,
                "lastName": lastName,
                "phone": phone,
                "email": email,
                "address": {
                  "lineOne": addr,
                  "lineTwo": null,
                  "zipCode": zipCode,
                  "city": city,
                  "state": null,
                  "country": country
                }
              },
              "transaction": {
                "amount": parseFloat(amount),
                "currency": currency,
                "intervals": parseInt(intervals),
                "cardToken": cardToken
              },
              "sponsorId": sponsorId,
              "anonymous": isAnonymous,
              "message": message,
              "donFor":donFor,
            }
        ) 
    });
    var result  = res.body;
    // console.log(result);
    return result
}


export const getAssocByName = async (name, offset) => {
    const res = await newFetch(`${APIURL}/search/association/${name}/${offset}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    var result  = res.body;
    //console.log(result);
    return result
}


export const getLinkAvailability = async (link) => {
    const res = await newFetch(`${APIURL}/campaign/availability/${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    //console.log(res)
    var result  = res.body;
    if(res.body.available){
        const res2 = await newFetch(`${APIURL}/billetterie/availability/${link}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        
        //console.log(res2)
        result = res2.body
    }
    return result
}


export const getPageByUrl = async (name) => {

    try {
        const res = await newFetch(`${APIURL}/search/param/${name}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        var result  = res.body;
        //console.log(result);
        return result
    } catch (error) {
        return null        
    }
}

export const createManualPayment = async (id, amount, currency, bizName,firstName, lastName, phone, email, addr, zipCode, city, country, sponsorId, isAnonymous, message, type) => {
    var accessToken = localStorage.getItem('accessToken')
    //console.log(amount);
    //console.log(parseFloat(amount));
    
    const res = await newFetch(`${APIURL}/donation/me/createManual/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(
            {donation: {
                    "donor": {
                        "businessName": bizName?bizName:'.',
                        "firstName": firstName,
                        "lastName": lastName,
                        "phone": phone,
                        "email": email,
                        "address": {
                        "lineOne": addr,
                        "lineTwo": null,
                        "zipCode": zipCode,
                        "city": city,
                        "state": null,
                        "country": country
                        }
                    },
                    "transaction": {
                        "amount": parseFloat(amount),
                        "currency": currency
                    },
                    "sponsorId": sponsorId? sponsorId:null,
                    "anonymous": isAnonymous,
                    "message": (message && message.length > 0) ? message : null
                },
                "type": type
            }
        ) 
    });
    var result  = res.body;
    // console.log(result);
    return result
}

export const createManualPayment2 = async (id, amount, currency, bizName,firstName, lastName, phone, email, addr, zipCode, city, country, sponsorId, isAnonymous, message, type) => {
    var accessToken = localStorage.getItem('accessToken')
    //console.log(amount);
    //console.log(parseFloat(amount));
    
    const res = await newFetch(`${APIURL}/donation/me/createManual2/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(
            {donation: {
                    "donor": {
                        "businessName": bizName?bizName:'.',
                        "firstName": firstName,
                        "lastName": lastName,
                        "phone": phone,
                        "email": email,
                        "address": {
                        "lineOne": addr,
                        "lineTwo": null,
                        "zipCode": zipCode,
                        "city": city,
                        "state": null,
                        "country": country
                        }
                    },
                    "transaction": {
                        "amount": parseFloat(amount),
                        "currency": currency
                    },
                    "sponsorId": sponsorId? sponsorId:null,
                    "anonymous": isAnonymous,
                    "message": (message && message.length > 0) ? message : null
                },
                "type": type
            }
        ) 
    });
    var result  = res.body;
    // console.log(result);
    return result
}

export const getAssocDonations = async (offset = 0) => {
    var accessToken = localStorage.getItem('accessToken')

    
    try {
        const res = await newFetch(`${APIURL}/donation/me/all/${offset}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`

            }
        });
        var result  = res.body;
        //console.log(result);
        return result
    } catch (error) {
        console.log(error);
        return null        
    }
}

function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }


export const getExcel = async (offset = 0) => {
    var accessToken = localStorage.getItem('accessToken')

    try {
        const res = await fetch(`${APIURL}/donation/me/excel`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`

            }
        });
        var result  = await res.blob();
        download(result, "export")
        //console.log(res.body);
        return res
    } catch (error) {
        //console.log(error);
        
        return null        
    }
}

export const getTreasury = async (data) => {
    var accessToken = localStorage.getItem('accessToken')

    try {
        const res = await newFetch(`${APIURL}/treasury/me`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(data)
        });
        var result  = res.body;
        //console.log(result);
        return result
    } catch (error) {
        return null        
    }
}


export const getAssocDonationsByName = async (offset = 0, name) => {
    var accessToken = localStorage.getItem('accessToken')

    try {
        const res = await newFetch(`${APIURL}/donation/me/name/${name}/${offset}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`

            }
        });
        var result  = res.body;
        // console.log(result);
        return result
    } catch (error) {
        return null        
    }
}

export const sendContactForm = async (firstName, lastName, phone, email, subject, description) => {

    try {
        const res = await newFetch(`${APIURL}/search/contact`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "firstName": firstName,
                "lastName": lastName,
                "phone": phone,
                "email": email,
                "subject": subject,
                "description": description
              })
        });
        var result  = res.body;
        //console.log(result);
        return result
    } catch (error) {
        return null        
    }
}


export const getSettings = async () => {
    var accessToken = localStorage.getItem('accessToken')
    const res = await newFetch(APIURL + '/settings/me', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }      
        });
        var result  = res.body;
        //console.log(result);
        return result
}

export const resetPassword = async (email) => {
    const res = await newFetch(APIURL + `/auth/requestPasswordChange/${email}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }      
        });
        var result  = res.body;
        //console.log(result);
        return result
}


export const resetNewPassword = async (password, token) => {
    const res = await newFetch(APIURL + `/auth/resetPassword/${password}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'password': `${token}`
        }      
        });
        var result  = res.body;
        //console.log(result);
        return result
}


export const sendCerfa = async (email) => {
    //console.log(email);
    const res = await newFetch(APIURL + `/donation/user`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({email: email})
        });
        
        var result  = res.body;
        //console.log(result);
        return result
}


export const getBackOffice = async (link) => {
  const accessToken = localStorage.getItem('accessToken');

  const res = await newFetch(`${APIURL}${link}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
  });

  var result  = res.body;
  return result
}

export const blockAssociation = async (link) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    });

    var result  = res.body;
    return result
}

export const unblockAssociation = async (link) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    });

    var result  = res.body;
    return result
}

export const acceptDonationPage = async (link) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    });

    var result  = res.body;
    return result
}


export const rejectDonationPage = async (link) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    });

    var result  = res.body;
    return result
}

export const showingDonationPage = async (link) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    });

    var result  = res.body;
    return result
}


export const hiddenDonationPage = async (link) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    });

    var result  = res.body;
    return result
}

export const getBalance = async (link) => {
    const accessToken = localStorage.getItem('accessToken');
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    });

    var result  = res.body;
    return result
}

export const getAllCutomers = async (link) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result = res.body;
    return result
}

export const getAllFiles = async (link) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result = res.body;
    return result
}

export const getAllPersons = async (link) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result = res.body;
    return result
}

export const retrieveFile = async (link) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result = res.body;
    return result
}

export const updatePersonDocs = async (link, data) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    });

    var result  = res.body;
    return result
}

export const getInvoices = async (link) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result  = res.body;
    return result
}

export const getInvoicesByMonth = async (link) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result  = res.body;
    return result
}

export const getInvoicesForAssociation = async (link) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result  = res.body;
    // console.log(result)
    return result
}

export const getInvoicesForCampaign = async (link) => {
    const accessToken = localStorage.getItem('accessToken');
    
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result  = res.body;
    return result
}

export const sendingBillet = async(firstname,lastname,price,categoryBillet,nameEvent,dateEvent,timeEvent,placeEvent,adressEvent,zip,city,email) => {
    const accessToken = localStorage.getItem('accessToken');
        const data = {firstname,lastname,price,categoryBillet,nameEvent,dateEvent,timeEvent,placeEvent,adressEvent,zip,city,email}
        const res = await newFetch(`${APIURL}/billetterie/me/sendbillet`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(data)
        });
    
        var result  = res.body;
        return result
}

export const editInvoiceData = async (link, data) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    });

    var result  = res.body;
    return result
}

export const createClosingInvoice = async (link, data) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    var result  = res.body;
    return result
}

export const makeTransfer = async (link, data) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    var result  = res.body;
    return result
}

export const donationsForCampaign = async (link, data) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    });

    var result  = res.body;
    return result
}

export const getAssociationById = async (link) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result  = res.body;
    return result
}

export const getPayoutsForAssociation = async (link) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result  = res.body;
    return result
}

export const getBankAccountById = async (link) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    });

    var result  = res.body;
    return result
}

export const transferAmountsToBank = async (link, data) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    });

    var result  = res.body;
    return result
}

export const updateAccountData = async (link, data) => {
    const accessToken = localStorage.getItem('accessToken');

    const res = await newFetch(`${APIURL}${link}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(data)
    });

    var result  = res.body;
    return result
}

export const getTreasuryAmount = async (link) => {
    const res = await newFetch(`${APIURL}${link}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });

    var result  = res.body;
    return result
}

export const removeBillets = async (campainId,buyTickets) => {

    const allBilletterie = await getMyBilletteries()
    const filtered = allBilletterie.filter((bil)=>bil.id===campainId)
    // console.log(campainId);
    // console.log(allBilletterie);
    // console.log(filtered);

    filtered[0].tickets.forEach((tick)=>{
        for(let i=0;i<buyTickets.length; i++){
            if(tick.id === buyTickets[i].id){
                tick.nbrPlacesBilletRestant = (tick.nbrPlacesBilletRestant - buyTickets[i].nbrPlace).toString()
                // console.log(tick.nbrPlacesBilletRestant);
            }
        }
    })
        // console.log(filtered[0].id, filtered[0].poster[0].url, filtered[0].sharingImage, filtered[0].title[0].content, filtered[0].description[0].content, filtered[0].content[0].content, filtered[0].medias=null, new Date(filtered[0].startingAt), new Date(filtered[0].endingAt), filtered[0].linkParam, filtered[0].associationName, filtered[0].logo, filtered[0].videos=null,filtered[0].tickets, filtered[0].association);
        await updateBilletterie (filtered[0].id, filtered[0].poster[0].url, filtered[0].sharingImage, filtered[0].title[0].content, filtered[0].description[0].content, filtered[0].content[0].content, filtered[0].medias=null, new Date(filtered[0].startingAt), new Date(filtered[0].endingAt), filtered[0].linkParam, filtered[0].associationName, filtered[0].logo, filtered[0].videos=null,filtered[0].tickets, filtered[0].association,filtered[0].idCampaignPaired?filtered[0].idCampaignPaired:"")
        // return result

}