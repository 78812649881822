import React, {useState, createRef} from 'react';
import styles from './index.css';
import add from '../../assets/add.png'
import { addSponsor, removeSponsor } from '../../Services/ws';
import {uploadFile} from '../../Services/s3'
import { Store } from 'react-notifications-component';
import Modal from 'react-modal';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "react-loader-spinner";
import { numberWithSpaces } from '../../Services/utils';
import { useTranslation } from "react-i18next";

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius: 20
    }
  };
Modal.setAppElement('#root')

export default function AddParrains({campain, userState, setUserState, updateCampain}) {
    const [name, setName] = useState('');
    const [obj, setObj] = useState('');
    const inputOpenFileRefLogo = createRef();
    const [pic, setPic] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedParain, setSelectedParain] = useState(null);
    const [parainToDel, setParainToDel] = useState(null);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const { t } = useTranslation();

    const [modalIsOpen,setIsOpen] = React.useState(false);
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
    }
  
    function closeModal(){
      setIsOpen(false);
    }

    const [modal2IsOpen,setIsOpen2] = React.useState(false);
    function openModal2() {
      setIsOpen2(true);
    }
  
    function afterOpenModal2() {
    }
  
    function closeModal2(){
      setIsOpen2(false);
    }

    return (
        <div>
            <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            >

            <div className="dashFormTitle">{t("Dashboard.CreateSponsor.ConfirmCreation")}</div>

                <div style={{marginRight: 0}} className="smllRow">
                    <div className={'smbtn btnGeneral'} onClick={async () => {
                        var errorFields = []
                        !name && errorFields.push(t("Dashboard.CreateSponsor.ErrorName"))
                        !pic && errorFields.push(`Photo`)
                        !obj && errorFields.push(t("Dashboard.CreateSponsor.ErrorGoal"))
        
        
                        if (errorFields.length > 0) {
                            Store.addNotification({
                                title: t("SingIn.Errors"),
                                message: t("Dashboard.CharityPage.FillFields") + errorFields.join(', '),
                                type: "danger",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 10000,
                                    onScreen: true
                                }
                                });
                                return;
                        }
                        //console.log('loading', loading);
                        
                        if (loading == true) {
                            return ;
                        }
                        setLoading(true)
                        if (  inputOpenFileRefLogo.current) {
                            inputOpenFileRefLogo.current.value = ''
                        }
                        if (selectedParain) {
                            var result = await removeSponsor(campain.id, selectedParain.id)
                            // var newCampains = userState.campains.map((el) => el.id == result.id ? result : el)
                            // //console.log(newCampains);
                            // updateCampain(result);
                            // setUserState({...userState, campains: newCampains});
                            // return ;
                        }
                        try {
                            

                            var result = await addSponsor(campain.id, name, pic, obj.replace(/\s+/g, ''))
                            var newCampains = userState.campains.map((el) => el.id == result.id ? result : el)
                            //console.log(newCampains);
                            Store.addNotification({
                                title: t("Dashboard.CharityPage.Success"),
                                message: t("Dashboard.CreateSponsor.SponsorAdded"),
                                type: "success",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 10000,
                                    onScreen: true
                                }
                                });
                            updateCampain(result);
                            setName('')
                            setPic(null)
                            setObj('')
            
                            setUserState({...userState, campains: newCampains});
                            setIsOpen(false)
                            setSelectedParain(null)
                            setLoading(false)
                        } catch (error) {
                            setLoading(false)
                        }
                    }}>{t("Dashboard.LogoutConfirm")}</div>
                    <div onClick={async () => {setIsOpen(false)}} className={'smbtn btnGeneral'}>{t("Dashboard.LogoutCancel")}</div>
                </div>
            </Modal>
            <Modal
            isOpen={modal2IsOpen}
            onAfterOpen={afterOpenModal2}
            onRequestClose={closeModal2}
            style={customStyles}
            >

            <div className="dashFormTitle">{t("Dashboard.CreateSponsor.SponsorDeletion")}</div>

                <div style={{marginRight: 0}} className="smllRow">
                    <div className={'smbtn btnGeneral'} onClick={async () => {
                       var result = await removeSponsor(campain.id, parainToDel.id)
                       var newCampains = userState.campains.map((el) => el.id == result.id ? result : el)
                       //console.log(newCampains);
                       updateCampain(result);
                       setUserState({...userState, campains: newCampains});
                       setParainToDel(null)
                       setIsOpen2(false)
                    }}>{t("Dashboard.LogoutConfirm")}</div>
                    <div onClick={async () => {setIsOpen2(false)}} className={'smbtn btnGeneral'}>{t("Dashboard.LogoutCancel")}</div>
                </div>
            </Modal>
            <p className="dashTitle">{t("Dashboard.CreateSponsor.AddSponsor")}</p>
            <div className="dashForm">
                <p className="dashFormTitle">{selectedParain ? t("Dashboard.CreateSponsor.ModifySponsorProfile") : t("Dashboard.CreateSponsor.CreateSponsorProfile")}</p>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateSponsor.SponsorName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" value={name}  onChange={(evt) => {setName(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateSponsor.SponsorGoal")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="number" value={obj} onChange={(evt) => {setObj(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateSponsor.SponsorPhoto")}</p>
                    </div>
                    {loadingLogo ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefLogo?.current.click()}} />
                            )}
                    {/* <img className="dashAdd" src={add} alt="add" onClick={() => { //console.log(inputOpenFileRefLogo); inputOpenFileRefLogo?.current.click()}} /> */}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingLogo(true)
                            var fileUrl = await uploadFile(e.target.files[0])
                            //console.log(fileUrl);
                            setPic(fileUrl)
                            setLoadingLogo(false)

                        }
                    }} type="file"  accept="image/*, video/mp4" ref={inputOpenFileRefLogo} style={{ display: 'none' }}/>
                    {pic && 
                        <img className="picturePreview" src={pic}></img>
                    }
                </div>
                <div className="dashBtns">
                    <div onClick={async () => {
                        setIsOpen(true)
                    }} className={'btn btnGeneral'}>{t("Dashboard.CharityPage.Enter")}</div>
                </div>
            </div>
            <div className="dashForm">
                <p className="dashFormTitle">{t("Dashboard.CreateSponsor.SponsorCampaign")}</p>
                <div style={{overflowY: 'auto', flexDirection: 'row'}} className="dashRow">
                    {
                        campain && [...campain.sponsors].reverse().map((item) => {
                            //console.log(item);
                            
                            return (
                                <div key={item.id}>
                                <div className="dashCard">
                                    <img className="dashAvatar" src={item.picture} alt="avatar" />
                                    <div style={{width: '50%'}} className="dashCol">
                                        <p style={{fontSize: '18px'}}>{item.name}</p>
                                        <p className="dashBlueTxt">Objectif :</p>
                                        <p className="dashBlueTxt">{numberWithSpaces(item.amount)}</p>
                                    </div>
                                </div>
                                <div className="smllRow">
                                    <div onClick={() => {
                                        setName(item.name)
                                        setObj('' + item.amount)
                                        setPic(item.picture)
                                        setSelectedParain(item)
                                    }} className={'smbtn btnGeneral'}>{t("SingIn.Modify")}</div>
                                    <div onClick={async () => {
                                        openModal2()
                                        setParainToDel(item)
                                    }} className={'smbtn btnGeneral'}>{t("Dashboard.CharityPage.PhotosDelete")}</div>
                                </div>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
