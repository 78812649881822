import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {pairingCampaign} from '../../Services/ws'

function Pairing() {
  const history = useHistory();
  const [pairing,setPairing] = useState();

  var {campaignId,response} = useParams();
  
  useEffect(()=>{
    const getPairingFunc = async()=>{
        const getPairing = await pairingCampaign(campaignId,response)
        setPairing(getPairing.response)
        setTimeout(() =>{
            history.push('/');
        },5000)
    }
    getPairingFunc()

  },[])

  return <div>
      {pairing=="valide"?<p>La campagne a bien été jumelée.</p>:null}
  </div>;
}

export default Pairing;
