import React, {useState, createRef, useEffect} from 'react';
import styles from './index.css';
import add from '../../assets/add.png'
import {uploadFile} from '../../Services/s3'
import {createCollect, updateCollect, publishCollect, getLinkAvailability} from '../../Services/ws'
import { store } from 'react-notifications-component';
import dynamicLoader from 'react-dynamic-loader'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Loader from "react-loader-spinner";
import draftToHtml from 'draftjs-to-html';
import { isMobile } from 'react-device-detect';
import searchIcon from '../../assets/search-solid.svg'

export default function Don({userState, setUserState, history, setShowWarning}) {

    const [search, setSearch] = useState('');

    
    return (
        <div>
            <div className="row">
                <div className="DonSearchBar">
                    <img className="DonIcon" src={searchIcon} alt="search" />
                    <input className="DonInput" type="text" placeholder="Rechercher une association" value={search} onChange={(evt) => {
                        //console.log('setsearch', evt.target.value);
                        
                        setSearch(evt.target.value)
                        }} />
                </div>
                <div onClick={() => {
                    //console.log('getExcel');
                }} style={{borderRadius: 60}} className="btnLeftDash btnGeneral">Excel</div>
                {/* <div className="btnRightDash btnGeneral">PDF</div> */}
            </div>
            <div className="col">
            <table style={{width: '90%', overflowX: 'scroll', whiteSpace: 'nowrap', display: 'block'}}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Association</th>
                        <th>Objet</th>
                        <th>Stripe</th>
                        <th>Nom du responsable</th>
                        <th>C.I du gérant</th>
                        <th>Status</th>
  
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <td>01/01/2021</td>
                        <td>Migdal</td>
                        <td>Validation de l'association</td>
                        <td style={{color: 'orange'}}>En cours</td>
                        <td>haim</td>
                        <td style={{color: 'green'}}>Validé</td>
                        <td style={{color: 'green'}}>Validé</td>
                    </tr>
                    <tr>
                        <td>01/01/2021</td>
                        <td>Migdal</td>
                        <td>Page de dons</td>
                        <td style={{color: 'orange'}}>En attente</td>
                        <td>Haim</td>
                        <td style={{color: 'green'}}>Validé</td>
                        <td style={{color: 'green'}}>Reçu</td>
                    </tr>
                    <tr>
                        <td>01/01/2021</td>
                        <td>Migdal</td>
                        <td>Page de campagne</td>
                        <td style={{color: 'green'}}>Validé</td>
                        <td>Haim</td>
                        <td style={{color: 'red'}}>Pas reçu</td>
                        <td style={{color: 'red'}}>Pas reçu</td>
                    </tr>
                </tbody>
            </table>
                    
            {/* <div style={{justifyContent: 'center', width: '90%', display: 'flex', flexDirection: 'row'}}> */}
                {/* {pageNb > 0 && <div onClick={() => setPageNb(pageNb - 1)} style={{fontSize: 30, cursor: 'pointer'}}>{"<"}</div>}
                <div style={{textAlign: 'center', fontSize: '1.25em', marginTop: 10, marginLeft: 10}}>Page {pageNb + 1}</div>
                {donations && donations.length == 15 && <div onClick={() => setPageNb(pageNb + 1)} style={{fontSize: 30, marginLeft: 10, cursor: 'pointer'}}>{">"}</div>} */}
            {/* </div> */}
            </div>
        </div>
    )
}
