import styles from "./index.css";
import { useRef, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import Hand from "../../assets/New/hand.png";
import { isMobile } from "react-device-detect";
import { numberWithSpaces } from "../../Services/utils";

const options = [
  { label: "EUR €", value: "eur" },
  { label: "USD $", value: "usd" },
  { label: "ILS ₪", value: "ils" },
  { label: "GBP £", value: "gbp" },
];

const paymentOptions = [
  { label: "Sur 12 mois", value: "12" },
  { label: "Sur 11 mois", value: "11" },
  { label: "Sur 10 mois", value: "10" },
  { label: "Sur 9 mois", value: "9" },
  { label: "Sur 8 mois", value: "8" },
  { label: "Sur 7 mois", value: "7" },
  { label: "Sur 6 mois", value: "6" },
  { label: "Sur 5 mois", value: "5" },
  { label: "Sur 4 mois", value: "4" },
  { label: "Sur 3 mois", value: "3" },
  { label: "Sur 2 mois", value: "2" },
];

export default function Assoc({
  donateToId,
  userState,
  setUserState,
  history,
}) {
  const [amount, setAmount] = useState(null);
  const [oneTime, setOneTime] = useState(true);
  const [currency, setCurrency] = useState(options[0].value);
  const [intervals, setIntervals] = useState({ value: 12 });
  const { t } = useTranslation();

  return (
    <div
      className={"TopDon"}
      style={{
        display: isMobile ? "none" : "block",
        position: "fixed",
        bottom: "50px",
        right: "0px",
      }}
    >
      <div className="assoc-donation-block">
        <div className="topDonBox">
          <div className="handContainer">
            <img width="24px" src={Hand} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="topDonBoxTitle">{t("Campaign.MakeDonation")}</div>
            <div className="topDonBoxCerfa">{t("Campaign.GetCerfa")}</div>
          </div>
        </div>
        <span
          className="assoc-donation-block-title"
          style={{ fontSize: "16px", marginTop: "14px" }}
        >
          {t("Campaign.Amount")}
        </span>
        <div className="assoc-donation-block-amountLine">
          <div
            onClick={() => {
              setAmount(26);
            }}
            className={
              amount == 26
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              width: "30%",
            }}
          >
            26{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
          <div
            onClick={() => {
              setAmount(52);
            }}
            className={
              amount == 52
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              width: "30%",
            }}
          >
            52{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
        </div>
        <div className="assoc-donation-block-amountLine">
          <div
            onClick={() => {
              setAmount(126);
            }}
            className={
              amount == 126
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              width: "30%",
            }}
          >
            126{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
          <div
            onClick={() => {
              setAmount(260);
            }}
            className={
              amount == 260
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              width: "30%",
            }}
          >
            260{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
          {/* <div onClick={() => {setAmount(500)}} className={amount == 500 ? "assoc-donation-block-amount assoc-donation-block-amount-selected" : "assoc-donation-block-amount"}>500 €</div> */}
        </div>
        <div className="assoc-donation-block-amountLine">
          <div
            onClick={() => {
              setAmount(520);
            }}
            className={
              amount == 520
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              width: "30%",
            }}
          >
            520{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
          <div
            onClick={() => {
              setAmount(1040);
            }}
            className={
              amount == 1040
                ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                : "assoc-donation-block-amount"
            }
            style={{
              paddingTop: "4px",
              paddingBottom: "4px",
              width: "30%",
            }}
          >
            1040{" "}
            {currency === "eur"
              ? "€"
              : currency == "usd"
              ? "$"
              : currency == "ils"
              ? "₪"
              : "£"}
          </div>
          {/* <div
                onClick={() => {
                  setAmount(400);
                }}
                className={
                  amount == 400
                    ? "assoc-donation-block-amount assoc-donation-block-amount-selected"
                    : "assoc-donation-block-amount"
                }
                style={{paddingTop:"4px",paddingBottom:"4px", width:"24%"}}
              >
                400 €
              </div> */}
          {/* <div onClick={() => {setAmount(500)}} className={amount == 500 ? "assoc-donation-block-amount assoc-donation-block-amount-selected" : "assoc-donation-block-amount"}>500 €</div> */}
        </div>
        <div
          style={{ marginTop: 10, display: "flex", alignItems: "center" }}
          className="assoc-donation-block-amountLine"
        >
          <input
            style={{
              minWidth: "100px",
              width: "30%",
              height: "12px",
              borderRadius: "10px 0px 0px 10px",
            }}
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            onFocus={() => setAmount(null)}
            type="number"
            placeholder={t("Campaign.OtherAmount")}
            className="assoc-donation-block-amount-input"
            value={amount}
          ></input>
          <select
            className="select-donFor"
            value={currency}
            placeholder="EUR €"
            onChange={(e) => setCurrency(e.target.value)}
            style={{
              width: "100px",
              height: "38px",
              fontSize: "12px",
              borderRadius: "0px 10px 10px 0px",
              marginTop: "4px",
              marginBottom: "4px",
              marginRight: "92px",
              marginLeft: "0px",
            }}
          >
            {options.map((opt, index) => {
              return (
                <option key={index} value={opt.value}>
                  {opt.label}
                </option>
                // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
              );
            })}
          </select>
        </div>
        <div className="assoc-donation-block-amount-checkbox-wrapper">
          <div className="assoc-donation-block-amount-checkbox-line">
            <input
              onChange={() => setOneTime(true)}
              checked={oneTime}
              style={{ width: 25, height: 25, backgroundColor: "#f1f0ec" }}
              type="radio"
              className="radio-toolbar"
            />
            <div className="assoc-donation-block-amount-checkbox-text">
              {t("Campaign.DonatePeriod")}
            </div>
          </div>
          <div
            style={{ marginTop: 8 }}
            className="assoc-donation-block-amount-checkbox-line"
          >
            <input
              onChange={() => setOneTime(false)}
              checked={!oneTime}
              style={{ width: 25, height: 25 }}
              type="radio"
              className="radio-toolbar"
            />
            <div className="assoc-donation-block-amount-checkbox-text">
              {t("Campaign.DonateSeveral")}
            </div>
          </div>
          {!oneTime && (
            // <Select
            //   value={intervals}
            //   onChange={(val) => {
            //     //console.log(val);
            //     setIntervals(val);
            //   }}
            //   placeholder={paymentOptions[0].label}
            //   classNamePrefix="assoc-select"
            //   className="donation-months-container"
            //   options={paymentOptions}
            // />
            <select
              // className="select-donFor"
              value={intervals}
              placeholder={paymentOptions[0].label}
              onChange={(e) => setIntervals(e.target.value)}
              style={{
                width: "150px",
                borderRadius: "010px",
                marginTop: "4px",
                marginBottom: "4px",
                marginRight: "auto",
                marginLeft: "auto",
                fontSize: "16px",
                padding: "12px 16px",
                border: "1px solid #9a406b",
                borderRadius: "10px",
                color: "#dd3476",
              }}
            >
              {paymentOptions.map((opt, index) => {
                return (
                  <option key={index} value={opt.value}>
                    {opt.label}
                  </option>
                  // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                );
              })}
            </select>
          )}
        </div>
        {!oneTime && currency == "eur" && (
          <div
            className="yourAmountText"
            style={{
              fontSize: "12px",
              width: "80%",
              margin: "auto",
              textAlign: "center",
            }}
          >
            {t("Dashboard.donate.Total")}
            {numberWithSpaces(Number(amount), currency)} {t("Donate.spread")}{" "}
            {Number(intervals.value)} {t("Donate.month")} Soit{" "}
            {numberWithSpaces(
              Number(amount) / Number(intervals.value),
              currency
            )}{" "}
            {t("Donate.setByMonth")} <br />
            {t("Donate.Tax.Reduction")}{" "}
            {numberWithSpaces(
              Number(Number(amount) - Number(amount) * 0.66),
              currency
            )}
            .
          </div>
        )}
        <div
          onClick={() => {
            setUserState({
              ...userState,
              donation: {
                amount: amount,
                currency: currency,
                times: oneTime,
                donateToId: donateToId,
                sponsorId: null,
                returnUrl: window.location.href,
                intervals: intervals,
              },
            });
            history.push("/donate");
          }}
          className="assoc-donation-block-amount-donate-button"
        >
          {t("Campaign.MakeDonation")}
        </div>
        <div className="assoc-donation-block-amount-donate-txt">
          {t("Campaign.GetCerfa")}
        </div>
      </div>
    </div>
  );
}
