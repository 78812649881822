import styles from './index.css';
import { sendCerfa } from '../../Services/ws';
import { useState } from 'react';
import { Store } from 'react-notifications-component';
import validator from 'validator';
import { useTranslation} from "react-i18next";

export default function Donations() {
    var [email, setEmail] = useState('')
    const { t } = useTranslation()

    return <div className={'signupContainer'}>
        <div className={''}>
        <div className={'cf-desc'}>{t("DonationPage.TopText")}</div>
        <div className={'dn-title'}>
            <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t("DonationPage.EmailText")} className={'cf-smallInput'}></input>
        </div>
        <div onClick={() => {
            //console.log('here');
            if (!validator.isEmail(email)) {
                Store.addNotification({
                    title: t("Donations.Toast.Error"),
                    message: t("Donations.Toast.InvalidEmail"),
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 10000,
                    onScreen: true
                    }
                });
                return false
            }

            sendCerfa(email)
                Store.addNotification({
                    title: t("Donations.Toast.Confirmation"),
                    message: t("Donations.Toast.ValidEmail"),
                    type: "success",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                  setEmail('');
        }} className={'btnNext'}>{t("DonationPage.BtnText")}</div>
        <div className={'donateSubTitle'}>{t("DonationPage.BottomText")}</div>
        </div>
    </div>
}
