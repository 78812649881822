import S3 from 'react-aws-s3';
import { v4 as uuidv4 } from 'uuid';

const config = {
    bucketName: 'charityclic-dev',
    // dirName: 'media', /* optional */
    region: 'eu-west-3',
    accessKeyId: 'AKIAQICUDU5U7EWMKZPQ',
    secretAccessKey: 'zm1VJZuxCcnw3B8JJM1zPK5GAWJqeU7/HkmcK+m8',
    // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
}

const ReactS3Client = new S3(config);
/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

/* This is optional */
export const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
        // var reader = new FileReader();

        // //Read the contents of Image File.
        // reader.readAsDataURL(file);
        // reader.onload = function (e) {

        //     //Initiate the JavaScript Image object.
        //     var image = new Image();

        //     //Set the Base64 string return from FileReader as source.
        //     image.src = e.target.result;

        //     //Validate the File Height and Width.
        //     image.onload = function () {
        //         var height = this.height;
        //         var width = this.width;
        //         //console.log(width);
        //         //console.log(height);
                
        //         // if (height < 400 || width < 400) {
        //         // alert("Image trop petite. Minimum 400x400.");
        //         // // reject({erro: 'size'})
        //         // return false;
        //         // }
                const newFileName = uuidv4();

                //console.log(file, newFileName);
                
                ReactS3Client
                    .uploadFile(file, newFileName)
                    .then(data => resolve(data.location))
                    .catch(err => reject(err))
                // alert("Uploaded image has valid Height and Width.");
                // return true;
            // };
        // };
    })
}
