import React from 'react'

import styles from './Modal.module.css'

const Modal = props => {
    const {modalTitle, modalContent, modalClose} = props

    return(
        <div className={styles.modalWrapper}>
            <div className={styles.modalBlock}>
                <div className={styles.modalClose} onClick={modalClose} />
                {modalTitle &&
                <div className={styles.modalHead}>
                    {modalTitle}
                </div>
                }
                <div className={styles.modalBody}>
                    {modalContent}
                </div>
            </div>
        </div>
    )
}

export default Modal;