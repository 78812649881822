import React, {useState, createRef, useEffect, useContext} from 'react';
import styles from './index.css';
import searchIcon from '../../assets/search-solid.svg'
import { Divider } from '@material-ui/core';
import { UserContext } from "../../Context/UserContext";
import { getAssocDonations, createManualPayment, getAssocDonationsByName, getExcel } from '../../Services/ws';
import Select from 'react-select'
import { Store } from 'react-notifications-component';
import validator from 'validator';
import { numberWithSpaces } from '../../Services/utils';
import PhoneInput from 'react-phone-input-2'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const options = [
    { label: 'EUR €', value: 'eur' },
    { label: 'USD $', value: 'usd' },
    { label: 'ILS ₪', value: 'ils' },
    { label: 'GBP £', value: 'gbp' },
  ]

var fx = require("money");

fx.base = "EUR";
fx.rates = {
    "USD" : 1.19, // eg. 1 USD === 0.745101 EUR
    "GBP" : 0.86, // etc...
    "ILS" : 3.89,
    "EUR" : 1,        // always include the base rate (1:1)
    /* etc */
}

export default function Donateur({}) {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('');
    const [postal, setPostal] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [phone, setPhone] = useState('');
    const [search, setSearch] = useState('');
    const inputOpenFileRef = createRef();
    const [userState, setUserState] = useContext(UserContext);
    const [amount, setAmount] = useState(null)
    const [currency, setCurrency] = useState(options[0])
    const [campain, setCampain] = useState(null)
    const [isCash, setIsCash] = useState(true)
    const [isAnonymous, setIsAnonymous] = useState(null)
    const [campainOptions, setCampainOptions] = useState([])
    const [donations, setDonations] = useState([])
    const [company, setCompany] = useState(false)
    const [companyName, setCompanyName] = useState('')
    const [pageNb, setPageNb] = useState(0)
    const { t } = useTranslation()
    const [arrParrains,setArrParrains] = useState([])
    const [parrain,setParrain] = useState([])
    const [idParrain,setIdParrain] = useState('')

    const customStyles = {
        option: (provided, state) => ({
        //   ...provided,
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
        //   width: "100%",
        }),
        singleValue: (provided, state) => {
        //   const opacity = state.isDisabled ? 0.5 : 1;
        //   const transition = 'opacity 300ms';
      
          return { };
        }
      }


    useEffect(() => {
        getAssocDonations(0).then((res) => {
            setDonations(res || [])
        })
        var campOpts = []
        var parOptsTemp = []
        var parOpts = []
        if (userState.collect && userState.collect.id) {
            campOpts.push({label: userState.collect.title[0].content, value: userState.collect.id})
        }
        if (userState.campains) {
            for (let i = 0; i < userState.campains.length; i++) {
                const element = userState.campains[i];
                campOpts.push({label: element.title[0].content, value: element.id})
            }
        }
        setCampainOptions(campOpts)

            var parrOpts = []
            const camTemp = userState.campains.filter((cam)=>cam.id===campain)
            if(camTemp[0]?.sponsors.length){
                for(let i=0;i<camTemp[0].sponsors.length;i++) {
                    parrOpts.push({label:camTemp[0].sponsors[i].name,value:camTemp[0].sponsors[i].id})
                }
            }
            setArrParrains(parrOpts)
        
    }, [])

    
useEffect(()=>{
    if(campain){
        var parrOpts = []
        const camTemp = userState.campains.filter((cam)=>cam.id===campain)
        if(camTemp[0]?.sponsors.length){
            for(let i=0;i<camTemp[0].sponsors.length;i++) {
                parrOpts.push({label:camTemp[0].sponsors[i].name,value:camTemp[0].sponsors[i].id})
            }
        }
        setArrParrains(parrOpts)
    }

},[campain,userState])

useEffect(()=>{

    if(arrParrains.length && parrain.value){
        setIdParrain(parrain.value);
    }else{
        setIdParrain(null)
    }

},[parrain,arrParrains])

    useEffect(() => {
        
        if (search.length >= 1) {
            getAssocDonationsByName(pageNb, search).then((res) => {
                setDonations(res || [])
            })
        } else {
            //console.log('pageNb', pageNb*5);
            
            getAssocDonations(pageNb).then((res) => {
                setDonations(res || [])
            })
        }
    }, [search, pageNb])

    return (
        <div>
            
            <div className="row">
                <div className="dashInput2-new">
                    <img className="DonIcon" src={searchIcon} alt="search" />
                    <input className="DonInput" type="text" placeholder={t("Dashboard.Donation.FindDonor")} value={search} onChange={(evt) => {
                        //console.log('setsearch', evt.target.value);
                        
                        setSearch(evt.target.value)
                        }} />
                </div>
                <div onClick={() => {
                    //console.log('getExcel');
                    
                    getExcel()
                }} style={{borderRadius: 60}} className="newBtnNext2">Excel</div>
                {/* <div className="btnRightDash btnGeneral">PDF</div> */}
            </div>
            <div className="col">
            <table style={{width: '90%', overflowX: 'scroll', whiteSpace: 'nowrap', display: 'block'}}>
                <thead>
                    <tr>
                        <th>{t("Dashboard.Donation.ColOrigin")}</th>
                        <th>{t("Dashboard.Donation.ColPageName")}</th>
                        <th>{t("Dashboard.Donation.ColCerfa")}</th>
                        <th>{t("Dashboard.Donation.ColOriginalAmount")}</th>
                        <th>{t("Dashboard.Donation.ColConvertedAmount")}</th>
                        <th>Nb</th>
                        <th>Type</th>
                        <th>{t("Dashboard.Donation.ColAmbassador")}</th>
                        <th>{t("Dashboard.Donation.ColName")}</th>
                        <th>{t("Dashboard.Donation.ColFirstName")}</th>
                        <th>Email</th>
                        <th>Tel.</th>
                        <th>{t("Dashboard.Donation.ColAddress")}</th>
                        <th>{t("Dashboard.Donation.ColCity")}</th>
                        <th>{t("Dashboard.Donation.ColZipCode")}</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {donations && donations.map((don,index) => {
                        let convertedMoney = fx(don?.originalAmount).from(don?.originalCurrency.toUpperCase()).to('EUR');
                        convertedMoney = Number(convertedMoney.toFixed(2))
                        return (
                        <tr key={index} className="donateurTable">
                            <td>{userState.collect && (don.donation?.campaign?.id === userState.collect.id) ? 'Association' : t("Dashboard.Donation.Campaign")}</td>
                            <td>{don.donation?.campaign.title[0].content}</td>
                            <td>{don.internalReference}</td>
                            <td>{don.donation?.amount ? numberWithSpaces(don.donation?.amount, don.donation?.currency) : numberWithSpaces(don.originalAmount, don.originalCurrency)}</td>
                            <td>{numberWithSpaces(convertedMoney)}</td>
                            <td>{don.donation?.intervals} fois</td>
                            <td>{don.type === 'STRIPE' ? 'CB' : 'Manuel'}</td>
                            <td>{don.donation?.sponsor ? don.donation?.sponsor.name : '-'}</td>
                            <td>{don.donation?.donor.lastName}</td>
                            <td>{don.donation?.donor.firstName}</td>
                            <td>{don.donation?.donor.email}</td>
                            <td>{don.donation?.donor.phone}</td>
                            <td>{don.donation?.donor.address.lineOne}</td>
                            <td>{don.donation?.donor.address.city}</td>
                            <td>{don.donation?.donor.address.zipCode}</td>
                            <td>{moment(new Date(don.donation?.createdAt)).format('DD/MM/YYYY')}</td>
                        </tr>)}
                    )}
                </tbody>
            </table>
                    
            <div style={{justifyContent: 'center', width: '90%', display: 'flex', flexDirection: 'row'}}>
                {pageNb > 0 && <div onClick={() => setPageNb(pageNb - 1)} style={{fontSize: 30, cursor: 'pointer'}}>{"<"}</div>}
                <div style={{textAlign: 'center', fontSize: '1.25em', marginTop: 10, marginLeft: 10}}>Page {pageNb + 1}</div>
                {donations && donations.length == 30 && <div onClick={() => setPageNb(pageNb + 1)} style={{fontSize: 30, marginLeft: 10, cursor: 'pointer'}}>{">"}</div>}
            </div>
            </div>
        </div>
    )
}