import React, {useState, createRef, useEffect} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import {uploadFile} from '../../Services/s3';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import plus from "../../assets/plus.png";
import { updatePersonDocs, getAllPersons } from "../../Services/ws";
import Plus3 from '../../assets/New/plus3.png'

export default function Documents({userState}) {
    const [loading, setLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [loadingID, setLoadingID] = useState(false);
    const [loadingRIB, setLoadingRIB] = useState(false);
    const [loadingJustif, setLoadingJustif] = useState(false);
    const [status, setStatus] = useState(null);
    const [statusMsg, setStatusMsg] = useState(false);
    const [ID, setID] = useState(null);
    const [ID_BACK, setID_BACK] = useState(null);
    const [statusIDMsg, setStatusIDMsg] = useState(false);
    const [rib, setRib] = useState(null);
    const [ribMsg, setRibMSg] = useState(false);
    const [Justif, setJustif] = useState(null);
    const [addressMsg, setAddressMsg] = useState(false);
    const inputOpenFileIDRef = createRef();
    const inputOpenFileStatusRef = createRef();
    const inputOpenFileRIBRef = createRef();
    const inputOpenFileJustifRef = createRef();
    const inputOpenFileRef = createRef();
    const stripe = useStripe();
    const {t} = useTranslation()

    const documents = userState.association.documents;
    const accountId = userState.association.stripeGateway.accountId;

    const updatePersonFiles = async (data, type) => {
        const token = await stripe.createToken('person', data)
        const object = {
            "accountId": accountId,
            "docs": data,
        }
        const result = await updatePersonDocs('/association/update-person', object);
        if(result) {
            switch (type) {
                case 'status':
                    setStatus(null);
                    setStatusMsg(true);
                    break;
                case 'id_card':
                    setID(null);
                    setStatusIDMsg(true);
                    break;
                case 'address':;
                    setJustif(null)
                    setAddressMsg(true);
                    break;
                case 'rib':
                    setRib(null);
                    setRibMSg(true);
                default:
                    break;
            }
        }
    }

    // const getPerson = async () => {
    //     const person = await getAllPersons(`/association/all-persons/${accountId}/3`);
    // }
    //
    // useEffect(() => {
    //     getPerson()
    // },[])

    return (
        <div>
            <div className="dashForm" style={{marginBottom: '100px'}}>
            <p className="dashTitle">{t("Dashboard.Documents")}</p>

            <div className="documentsStatus">
                <p>{t("Dashboard.Documents.VerificationStatus")}<b>{documents.verificationStatus}</b></p>
            </div>

            {documents.verificationStatus !== 'verified' &&
                <>
                    <p>{t("Dashboard.Documents.ReuploadDocuments")}</p>

                    <p className={'dashTitle'}>{t("Dashboard.Documents.YourDocuments")}</p>

                    <h4>{t("Dashboard.Documents.PreviouslyLoaded")}</h4>
                    <p><b>{t("SingIn.Step3.DocumentsAssocStatuses")}:</b> {documents.documentThreeFile ? documents.documentThreeFile : 'not loaded'}</p>
                    <p><b>{t("SingIn.Step3.DocumentsManagerId")}:</b> {documents.documentOneFile ? documents.documentOneFile : 'not loaded'}</p>
                    <p><b>{t("SingIn.Step3.DocumentsAddressProof")}:</b> {documents.documentTwoFile ? documents.documentTwoFile : 'not loaded'}</p>
                    <p><b>{t("SingIn.Step3.DocumentsRIB")}:</b> {documents.documentFourFile ? documents.documentFourFile : 'not loaded'}</p>

                    <div className="signuUpForm">
                    <div style={{display: 'flex', flexDirection: 'row',justifyContent:"space-between",alignItems: 'center'}}>
                        <p className="inputTitle">{t("SingIn.Step3.DocumentsAssocStatuses")}</p>
                        <div onClick={() => {inputOpenFileStatusRef.current.click()}} className="newSignupPlus2" style={{marginRight: '20px'}}>
                            {loadingStatus == true ?(
                                <Loader
                                    type="ThreeDots"
                                    color="#507cab"
                                    height={30}
                                    width={30}/>
                            ) :(
                                <img style={{width: 40, height: 40}} src={Plus3} alt="plus"  />
                            )}
                            {status && <p style={{paddingTop: 10}} className="btnTxt">{t("SingIn.Modify")} </p>}
                        </div>
                        </div>
                        <div style={{textAlign: 'center'}}>{status && status.filename}</div>
                        <input onChange={async (e) => {
                            if (e.target.files[0]) {
                                var myHeaders = new Headers();
                                myHeaders.append("Authorization", process.env.REACT_APP_STRIPE_PUBLIC_KEY_2);

                                var formdata = new FormData();
                                formdata.append("file", e.target.files[0]);
                                formdata.append("purpose", "additional_verification");

                                var requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: formdata,
                                    redirect: 'follow'
                                };

                                setLoadingStatus(true)
                                fetch("https://files.stripe.com/v1/files", requestOptions)
                                    .then(response => response.json())
                                    .then(result => {
                                        //console.log(result);
                                        setStatus(result)
                                        setLoadingStatus(false)

                                    })
                                    .catch(error => {
                                        setLoadingStatus(false)
                                        //console.log('error', error)
                                    });
                            }
                        }} type="file" accept="application/pdf, image/png" ref={inputOpenFileStatusRef} style={{ display: 'none' }}/>
                        {status && !statusMsg &&
                            <button className={'updateBtn'} onClick={async () => {
                                const data = {
                                   "verification": {
                                       "additional_document": {
                                           "front": status.id
                                       }
                                   }
                                }

                                await updatePersonFiles(data, 'status');
                            }
                            }>{t("Dashboard.Documents.UpdateDocument")}</button>
                        }

                        {statusMsg &&
                            <p className={'updateMsg'}>{t("Dashboard.Documents.FilesWillUpdate")}</p>
                        }
                        <div style={{display: 'flex', flexDirection: 'row',justifyContent:"space-between",alignItems: 'center'}}>
                        <p className="inputTitle">{t("SingIn.Step3.DocumentsManagerId")}</p>
                        <div onClick={() => {inputOpenFileIDRef.current.click()}} className="newSignupPlus2" style={{marginRight: '20px'}}>
                            {loadingID == true ?(
                                <Loader
                                    type="ThreeDots"
                                    color="#507cab"
                                    height={30}
                                    width={30}/>
                            ) :(
                                <img style={{width: 40, height: 40}} src={Plus3}  alt="plus"  />
                            )}
                            {ID && <p style={{paddingTop: 10}} className="btnTxt">{t("SingIn.Modify")} </p>}
                        </div>
                        </div>
                        <div style={{textAlign: 'center'}}>{ID && ID.filename}</div>
                        <input  onChange={async (e) => {
                            //console.log(e.target);
                            if (e.target.files[0]) {
                                var myHeaders = new Headers();
                                myHeaders.append("Authorization", process.env.REACT_APP_STRIPE_PUBLIC_KEY_2);

                                var formdata = new FormData();
                                formdata.append("file", e.target.files[0]);
                                formdata.append("purpose", "identity_document");

                                var requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: formdata,
                                    redirect: 'follow'
                                };

                                setLoadingID(true)

                                fetch("https://files.stripe.com/v1/files", requestOptions)
                                    .then(response => response.json())
                                    .then(result => {
                                        //console.log(result);
                                        setID(result)
                                    })
                                    .catch(error => console.log('error', error));
                                fetch("https://files.stripe.com/v1/files", requestOptions)
                                    .then(response => response.json())
                                    .then(result => {
                                        //console.log(result);
                                        setID_BACK(result)
                                        setLoadingID(false)
                                    })
                                    .catch(error => {
                                        setLoadingID(false)
                                    });
                            }
                        }} type="file" accept="application/pdf, image/png" ref={inputOpenFileIDRef} style={{ display: 'none' }}/>
                        {ID && !statusIDMsg &&
                                <button className={'updateBtn'} onClick={async () => {
                                    const data = {
                                        "verification": {
                                            "document": {
                                                "front": ID.id
                                            }
                                        }
                                    }

                                    await updatePersonFiles(data, 'id_card');
                                }
                                }>{t("Dashboard.Documents.UpdateDocument")}</button>
                        }

                        {statusIDMsg &&
                            <p className={'updateMsg'}>{t("Dashboard.Documents.FilesWillUpdate")}</p>
                        }
                        <div style={{display: 'flex', flexDirection: 'row',justifyContent:"space-between",alignItems: 'center'}}>
                        <p className="inputTitle">{t("SingIn.Step3.DocumentsAddressProof")}</p>
                        <div onClick={() => {inputOpenFileJustifRef.current.click()}} className="newSignupPlus2" style={{marginRight: '20px'}}>
                            {loadingJustif == true ?(
                                <Loader
                                    type="ThreeDots"
                                    color="#507cab"
                                    height={30}
                                    width={30}/>
                            ) :(
                                <img style={{width: 40, height: 40}} src={Plus3}  alt="plus" />
                            )}
                            {Justif && <p style={{paddingTop: 10}} className="btnTxt">{t("SingIn.Modify")} </p>}
                        </div>
                        </div>
                        <div style={{textAlign: 'center'}}>{Justif && Justif.filename}</div>
                        <input  onChange={async (e) => {
                            //console.log(e.target);
                            if (e.target.files[0]) {
                                var myHeaders = new Headers();
                                myHeaders.append("Authorization", process.env.REACT_APP_STRIPE_PUBLIC_KEY_2);

                                var formdata = new FormData();
                                formdata.append("file", e.target.files[0]);
                                formdata.append("purpose", "additional_verification");

                                var requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: formdata,
                                    redirect: 'follow'
                                };

                                setLoadingJustif(true)

                                fetch("https://files.stripe.com/v1/files", requestOptions)
                                    .then(response => response.json())
                                    .then(result => {
                                        //console.log(result);
                                        setJustif(result)
                                        setLoadingJustif(false)
                                    })
                                    .catch(error => {
                                        setLoadingJustif(false)
                                    });
                            }
                        }} type="file" accept="application/pdf, image/png" ref={inputOpenFileJustifRef} style={{ display: 'none' }}/>
                        {Justif && !addressMsg &&
                            <button className={'updateBtn'} onClick={async () => {
                                const data = {
                                    "verification": {
                                        "document": {
                                            "back": Justif.id
                                        }
                                    }
                                }

                                await updatePersonFiles(data, 'address');
                            }
                            }>{t("Dashboard.Documents.UpdateDocument")}</button>
                        }

                        {addressMsg &&
                            <p className={'updateMsg'}>{t("Dashboard.Documents.FilesWillUpdate")}</p>
                        }
                        <div style={{display: 'flex', flexDirection: 'row',justifyContent:"space-between",alignItems: 'center'}}>
                        <p className="inputTitle">{t("SingIn.Step3.DocumentsRIB")}</p>
                        <div onClick={() => {inputOpenFileRIBRef.current.click()}} className="newSignupPlus2" style={{marginRight: '20px'}}>
                            {loadingRIB == true ?(
                                <Loader
                                    type="ThreeDots"
                                    color="#507cab"
                                    height={30}
                                    width={30}/>
                            ) :(
                                <img style={{width: 40, height: 40}} src={Plus3}  alt="plus"  />
                            )}
                            {rib && <p style={{paddingTop: 10}} className="btnTxt">{t("SingIn.Modify")} </p>}
                        </div>
                        </div>
                        <div style={{textAlign: 'center'}}>{rib && rib.filename}</div>
                        <input  onChange={async (e) => {
                            //console.log(e.target);
                            if (e.target.files[0]) {
                                var myHeaders = new Headers();
                                myHeaders.append("Authorization", process.env.REACT_APP_STRIPE_PUBLIC_KEY_2);

                                var formdata = new FormData();
                                formdata.append("file", e.target.files[0]);
                                formdata.append("purpose", "additional_verification");

                                var requestOptions = {
                                    method: 'POST',
                                    headers: myHeaders,
                                    body: formdata,
                                    redirect: 'follow'
                                };
                                setLoadingRIB(true)

                                fetch("https://files.stripe.com/v1/files", requestOptions)
                                    .then(response => response.json())
                                    .then(result => {
                                        setLoadingRIB(false)
                                        //console.log(result);
                                        setRib(result)
                                    })
                                    .catch(error => {
                                        setLoadingRIB(false)
                                        //console.log('error', error)
                                    });
                            }
                        }} type="file" accept="application/pdf, image/png" ref={inputOpenFileRIBRef} style={{ display: 'none' }}/>
                        {rib && !ribMsg &&
                            <button className={'updateBtn'} onClick={async () => {
                                const data = {
                                    "verification": {
                                        "additional_document": {
                                            "back": rib.id
                                        }
                                    }
                                }

                                await updatePersonFiles(data, 'rib');
                            }
                            }>{t("Dashboard.Documents.UpdateDocument")}</button>
                        }

                        {ribMsg &&
                            <p className={'updateMsg'}>{t("Dashboard.Documents.FilesWillUpdate")}</p>
                        }
                    </div>
                </>
            }

            {documents.verificationStatus === 'verified' &&
                <>
                    <p className={'dashTitle'}>{t("Dashboard.Documents.YourDocuments")}</p>

                    <h4>Uploaded documents</h4>
                    <p><b>{t("SingIn.Step3.DocumentsAssocStatuses")}:</b> {documents.documentThreeFile ? documents.documentThreeFile : 'not loaded'}</p>
                    <p><b>{t("SingIn.Step3.DocumentsManagerId")}:</b> {documents.documentOneFile ? documents.documentOneFile : 'not loaded'}</p>
                    <p><b>{t("SingIn.Step3.DocumentsAddressProof")}:</b> {documents.documentTwoFile ? documents.documentTwoFile : 'not loaded'}</p>
                    <p><b>{t("SingIn.Step3.DocumentsRIB")}:</b> {documents.documentFourFile ? documents.documentFourFile : 'not loaded'}</p>
                </>
            }
        </div>
            </div>
    )
}