import React, {useState, useContext, useEffect} from 'react';
import { UserContext } from "../../Context/UserContext";

import styles from './index.css';
import ValidateAsso from './ValidateAsso.js'
import Associations from './Associations.js'
import Donateur from './Donateur.js'
import Settings from './Settings.js'
import Treasury from './Treasury.js'
import Facture from './Facture.js'
import {getCollect, getMyCampains} from '../../Services/ws'
import Modal from 'react-modal';
import { isMobile } from 'react-device-detect';

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius: 20
    }
  };
Modal.setAppElement('#root')

export default function Dashboard({history}) {
    const [menu, setMenu] = useState(0);
    const [userState, setUserState] = useContext(UserContext);
    const [reload, setReload] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [modalIsOpen,setIsOpen] = React.useState(false);
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
    }
  
    function closeModal(){
      setIsOpen(false);
    }

    useEffect(() => {

    }, [])

    // //console.log(userState.gatewayRequirements.requirements);

    // if (userState.gatewayRequirements.requirements.length > 0) {
    //     return (
    //     <div className="dashbooardContainer">
    //         <div className="dashbooardMenu">
    //             <img className="menuImg" src={userState.association.logoUrl || test} alt="logo" />
    //             <p className="imgSub">{userState.association.usageName}</p>
    //             <p style={{marginTop: 'auto', marginBottom: 150}} className={menu === 6 ? "menuSelected" : "menu"} onClick={() => {
    //                 setUserState({association: null, user: null, accessToken: null});
    //                 history.push('/login')
    //             }}>Déconnexion</p>
    //         </div>
    //         <div style={{fontSize: 30, justifyContent: 'center', alignItems: 'center', display: 'flex'}} className="menuDetail">
    //             Votre compte est en cours de validation
    //         </div>
    //     </div>
    //     )
    // }
    // //console.log(localStorage.getItem('refreshToken'));
    
    // if (!userState || !userState.user || !userState.association || localStorage.getItem('refreshToken') == 'null') {
    //     history.push('/login')
    //     return <div />
    // }

    return (
        <div className="dashbooardContainer">
        {!isMobile &&
                <div className="dashbooardMenu">
                    <p className="imgSub">Charityclic</p>
                    <p className={menu === 0 ? "menuCRMSelected" : "menuCRM"} onClick={() => {setMenu(0)}}>Validation association</p>
                    <p className={menu === 1 ? "menuCRMSelected" : "menuCRM"} onClick={() => {setReload(!reload); setMenu(1)}}>Association</p>
                    <p className={menu === 2 ? "menuCRMSelected" : "menuCRM"} onClick={() => {setMenu(2)}}>Donateurs</p>
                    <p className={menu === 3 ? "menuCRMSelected" : "menuCRM"} onClick={() => {setMenu(3)}}>Situation association</p>
                    <p className={menu === 4 ? "menuCRMSelected" : "menuCRM"} onClick={() => {setMenu(4)}}>Trésorerie</p>
                    <p className={menu === 5 ? "menuCRMSelected" : "menuCRM"} onClick={() => {setMenu(5)}}>Mes factures</p>
                    <p className={menu === 6 ? "menuCRMSelected" : "menuCRM"} onClick={() => {}}>Statistiques</p>
                    <p className={menu === 7 ? "menuCRMSelected" : "menuCRM"} onClick={() => {
                        openModal()
                    }}>Déconnexion</p>
                </div>
            }
            <div className="menuDetail">
                {
                    menu === 0 && <ValidateAsso userState={userState} history={history} setUserState={setUserState} setShowWarning={setShowWarning} />
                }
                {
                    menu === 1 && <Associations userState={userState} setUserState={setUserState} history={history} reload={reload}/>
                }
                {
                    menu === 2 && <Donateur userState={userState} />
                }
                {
                    menu === 3 && <Donateur userState={userState} />
                }
                {
                    menu === 4 && <Treasury userState={userState} />
                }
                {
                    menu === 5 && <Facture userState={userState} />
                }
                {/* {
                    menu === 6 && <Settings userState={userState} />
                } */}
            </div>
        </div>
    )
}
