import React, { useState, useEffect } from 'react';
import CampaignInvoice from '../../Components/Invoices/Campaign'
import PageDeDonInvoice from "../../Components/Invoices/TransactionInvoice";
import { getInvoicesForAssociation, getBackOffice } from '../../Services/ws'
import { useTranslation } from "react-i18next";
import styles from "../BackOffice/MyBills.module.css";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import DownloadButton from "../../Components/Elements/DownloadButton/DownloadButton";


export default function Facture({userState}) {
    const association = userState.association
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const [part, setPart] = useState(0)
    const [bill, setBill] = useState(0)
    const [invoices, setInvoices] = useState([])
    const [campaignName,setCampaignName] = useState([])
    const { t } = useTranslation()


    const getData = async () => {
        const associationId= association.id;
        const data = await getInvoicesForAssociation(`/invoices/id/${associationId}/${currentYear}`)
        const allCampaign = await getBackOffice("/campaign/back-office-names")
        setCampaignName(allCampaign)
        setInvoices(data)
    }

    const monthNames = number => {
        switch (number) {
            case 1:
                return 'January';
            case 2:
                return 'February';
            case 3:
                return 'March';
            case 4:
                return 'April';
            case 5:
                return 'May';
            case 6:
                return 'June';
            case 7:
                return 'July';
            case 8:
                return 'August';
            case 9:
                return 'September';
            case 10:
                return 'October';
            case 11:
                return 'November';
            case 12:
                return 'December';
            default:
                return 'No name';
                break;
        }
    }

    useEffect(() => getData(), [])

    const columns = [
        'Dashboard.Donation.ColOrigin',
        'Dashboard.Donation.ColName',
        'Dashboard.Invoices.Date',
        'Dashboard.Invoices.InvoiceNumber',
        'Dashboard.Invoice.Amount',
        ''
    ]

    const openDonsInvoice = (invoice) => {
        setBill(invoice)
        setPart(2)
    }

    const openCampaignInvoice = (invoice) => {
        setBill(invoice)
        setPart(1)
    }

    const backToBills = () => {
        setPart(0)
    }

    return (
        <div>
            { part === 0 &&
                <>
                    <p className="dashTitle">{t("Dashboard.Factures.Title")} {association.usageName}</p>

                    {invoices.slice(0).reverse().map((month, index) => {
                        let monthConverted = Object.keys(month).map((key) => [Number(key), month[key]]);
                        return(
                            <div className={styles.tableCol} id="tablemargin">
                                <div className={styles.tableNavbar}>
                                    <div className={styles.tableName} id="titretablemargin">
                                         {monthNames(monthConverted[0][0]) + ' ' + currentYear}
                                    </div>
                                </div>
                                <TableContainer component={Paper} className={styles.tableContainer} id={'associationTable' + index}>
                                    <Table  className={styles.table} aria-label="simple table">
                                        <TableHead className={styles.tableHead} id="textetable">
                                            <TableRow>
                                                {
                                                    columns.map(cel => (
                                                        <TableCell size='small' id="textetable">{t(cel)}</TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={styles.tableBody}>
                                            {monthConverted.map((bills, index) => {
                                                return (
                                                    bills[1].slice(0).reverse().map(bill => {
                                                        const camp = campaignName.filter((cam)=>cam._id===bill.campaign)
                                                        return (
                                                            <TableRow
                                                                key={bill?.donation?.id}
                                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                            >
                                                                <TableCell id="textetable">{bill.reasons.length > 0 ? 'Campaign' : 'Page de don' }</TableCell>
                                                                <TableCell>{camp[0]?.title[0]?.content}</TableCell>
                                                                <TableCell id="textetable">{bill.date}</TableCell>
                                                                <TableCell id="textetable">{bill.number}</TableCell>
                                                                <TableCell id="textetable">{bill.totalTTC + ' €'}</TableCell>
                                                                <TableCell id="textetable">
                                                                    {bill.reasons.length > 0 &&
                                                                        <DownloadButton name={t("BackOffice.MyBills.Invoice.Open")} action={() => openCampaignInvoice(bill)} />
                                                                    }
                                                                    {!bill.reasons.length > 0 &&
                                                                            <DownloadButton name={t("BackOffice.MyBills.Invoice.Open")} action={() => openDonsInvoice(bill)}/>
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    )
                                                )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                        </div>
                        )
                    })}
                </>
            }

            { part === 1 &&
                <>
                    <div className={styles.pageHead}>
                        {/* <DownloadButton name={'Back'} action={() => backToBills()} /> */}
                        <div className={styles.viewBtn} style={{display:"inline"}} name={'Back'} onClick={() => backToBills()}>Back</div>
                    </div>

                    <CampaignInvoice bill={bill} />
                </>

            }

            {part === 2 &&
            <>
                <div className={styles.pageHead}>
                    {/* <DownloadButton name={'Back'} action={() => backToBills()} /> */}
                    <div className={styles.viewBtn} style={{display:"inline"}} name={'Back'} onClick={() => backToBills()}>Back</div>
                </div>

                <PageDeDonInvoice bill={bill} />
            </>
            }
        </div>
    )
}