import React, { useState, createRef, useEffect, useContext } from "react";
import verified from "../../assets/verified.png";
import Select from "react-select";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  createPayment2,
  createPaymentSub,
  getGateway,
  getMyBilletteries,
  removeBillets,
  sendingBillet,
} from "../../Services/ws";
import { UserContext } from "../../Context/UserContext";
import { numberWithSpaces } from "../../Services/utils";
import { Store } from "react-notifications-component";
import validator from "validator";
import Loader from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import countries from "../../Services/countries";
import { isMobile } from "react-device-detect";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Plus from "../../assets/New/plus.svg";
import Minus from "../../assets/New/minus.svg";

const options = [
  { label: "EUR €", value: "eur" },
  { label: "USD $", value: "usd" },
  { label: "ILS ₪", value: "ils" },
  { label: "GBP £", value: "gbp" },
];

const paymentOptions = [
  { label: "Sur 12 mois", value: "12" },
  { label: "Sur 11 mois", value: "11" },
  { label: "Sur 10 mois", value: "10" },
  { label: "Sur 9 mois", value: "9" },
  { label: "Sur 8 mois", value: "8" },
  { label: "Sur 7 mois", value: "7" },
  { label: "Sur 6 mois", value: "6" },
  { label: "Sur 5 mois", value: "5" },
  { label: "Sur 4 mois", value: "4" },
  { label: "Sur 3 mois", value: "3" },
  { label: "Sur 2 mois", value: "2" },
];

function Donate({ history }) {
  const [userState, setUserState] = useContext(UserContext);
  const [step, setStep] = useState(0);
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("FR");
  const [address, setAddress] = useState("");
  const [postal, setPostal] = useState("");
  const [company, setCompany] = useState(false);
  const [anonymous, setAnonymous] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [name, setName] = useState("");
  const [msg, setMsg] = useState("");
  const [email, setEmail] = useState("");
  const [titulaire, setTitulaire] = useState("");
  const [amount, setAmount] = useState(0);
  const [oneTime, setOneTime] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const [currency, setCurrency] = useState(options[0]);
  const [loading, setLoading] = useState(false);
  const [intervals, setIntervals] = useState("12");
  const [resultByMonth, setResultByMonth] = useState("");
  // const [reminderByMonth,setReminderByMonth] = useState('');
  const [campTicket, setCampTicket] = useState(null);
  const [buyTicketsRemaining, setBuyTicketsRemaining] = useState(null);
  const [buyTickets, setBuyTickets] = useState(null);
  const [totalBillets, setTotalBillets] = useState(0);
  const { t } = useTranslation();

  const testMail = async (tickets) => {
    for (let i = 0; i < tickets.length; i++) {
      for (let j = 0; j < tickets[i].nbrPlace; j++) {
        const temp = campTicket[0].tickets.filter(
          (tick) => tick.id === tickets[i].id
        );
        const dateTemp = `${temp[0].dateEvent.slice(
          8,
          10
        )}/${temp[0].dateEvent.slice(5, 7)}/${temp[0].dateEvent.slice(0, 4)}`;
        const heureTemp = temp[0].dateEvent.slice(11, 16);
        await sendingBillet(
          firstname,
          lastname,
          temp[0].prixBillet,
          temp[0].categoryBillet,
          campTicket[0].title[0].content,
          dateTemp,
          heureTemp,
          temp[0].nomLieu,
          temp[0].adresse,
          temp[0].codePostal,
          temp[0].place,
          email
        );
      }
    }
  };

  useEffect(() => {
    if (userState.tickets) {
      const getAllBillets = async () => {
        const res = await getMyBilletteries();
        const result = res.filter(
          (tic) => tic.id === userState.tickets.campain
        );
        setCampTicket(result);
      };
      getAllBillets();

      // setAmount(userState.donation.amount ? '' + userState.donation.amount : '')
      // setIntervals(userState.donation.intervals)
      // setOneTime(userState.donation.times)
      // setCurrency(userState.donation.currency)
    }
  }, []);

  useEffect(() => {
    if (campTicket) {
      const billTemp = [];
      const remainTemp = [];
      var totalTemp = 0;
      for (let i = 0; i < campTicket[0].tickets.length; i++) {
        userState.tickets.buyTickets.forEach((buy) => {
          if (campTicket[0].tickets[i].id === buy.id) {
            billTemp.push(buy);
            remainTemp.push({
              ...buy,
              nbrPlace:
                Number(campTicket[0].tickets[i].nbrPlacesBilletRestant) -
                buy.nbrPlace,
            });
            totalTemp += buy.nbrPlace;
          }
        });
      }
      setBuyTickets(billTemp);
      setBuyTicketsRemaining(remainTemp);
      setTotalBillets(totalTemp);
    }
  }, [campTicket]);

  useEffect(() => {
    if (step === 3) {
      testMail(buyTickets);
    }
  }, [step]);

  useEffect(() => {
    if (buyTickets) {
      var totalTemp = 0;
      buyTickets.forEach((tic) => {
        campTicket[0].tickets.forEach((cam) => {
          if (cam.id === tic.id) {
            totalTemp += Number(tic.nbrPlace) * Number(cam.prixBillet);
          }
        });
      });
      setAmount(totalTemp);
    }
  }, [buyTickets]);

  const [time, setTime] = useState("12");
  useEffect(() => {
    const setByMonth = (amount, currency, step) => {
      const reduct = Number(amount) - Number(amount) * 0.66;
      if (!oneTime) {
        if (!intervals || intervals == undefined || intervals == 1) {
          setTime("12");
        } else {
          setTime(intervals.value);
        }
        if (amount && amount.length) {
          var dev = "";
          if (currency.value === "eur") {
            dev = "€";
          } else if (currency.value === "usd") {
            dev = "$";
          } else if (currency.value === "ils") {
            dev = "₪";
          } else if (currency.value === "gbp") {
            dev = "£";
          }

          if (step === 1) {
            return (
              <text className="yourAmountText-new">
                {t("Dashboard.donate.Total")}
                {numberWithSpaces(Number(amount), currency.value)}{" "}
                {t("Donate.spread")} {Number(intervals)} {t("Donate.month")}{" "}
                Soit{" "}
                {numberWithSpaces(
                  Number(amount) / Number(intervals),
                  currency.value
                )}{" "}
                {t("Donate.setByMonth")} <br />
                {t("Donate.Tax.Reduction")}{" "}
                {numberWithSpaces(Number(reduct), currency.value)}.
              </text>
            );
          }
          // else if(step===3){
          //     return `${numberWithSpaces(Number(amount)/Number(time),currency.value)} ${t("Donate.spread")} ${time} ${t("Donate.month")}`;
          // }
          // }
        } else {
          return null;
        }
      } else {
        return (
          <text className="yourAmountText-new">
            {`${t("Billetterie.Choice")} ${totalBillets} ${t(
              "Billetterie.billet"
            )}.`}
            <br />
            {`${t("Billetterie.total")} ${numberWithSpaces(
              Number(amount),
              currency.value
            )}.`}
          </text>
        );
      }
    };

    setResultByMonth(setByMonth(amount, currency, 1));
    // setReminderByMonth(setByMonth(amount,currency,3))
  }, [amount, currency, intervals, oneTime, t, time]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);
  const handleSubmit = async (event) => {
    setLoading(true);
    // Block native form submission.
    //   event.preventDefault();

    if (!stripe || !elements || loading == true) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    const cardElement = elements.getElement(CardElement);
    //    bizName,firstName, lastName, phone, email, addr, zipCode, city, country, sponsorId, isAnonymous, message
    stripe.createToken(cardElement).then(async (resToken) => {
      var res = null;
      var remBil = null;
      if (oneTime == true) {
        res = await createPayment2(
          userState.tickets.campain,
          amount,
          currency.value,
          companyName,
          firstname,
          lastname,
          "+" + phone,
          email,
          address,
          postal,
          city,
          country,
          null,
          anonymous,
          msg.isEmpty() ? null : msg,
          null
        );
        if (res) {
          remBil = await removeBillets(userState.tickets.campain, buyTickets);
        }
        stripe
          .confirmCardPayment(res.clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })
          .then(function (result) {
            setLoading(false);
            if (result.error) {
              Store.addNotification({
                title: t("SingIn.Errors"),
                message:
                  result.error.message || t("Dashboard.Donate.PaymentError"),
                type: "danger",
                insert: "top",
                container: "top-center",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 10000,
                  onScreen: true,
                },
              });
              // Show error to your customer
              //   showError(result.error.message);
            } else {
              // The payment succeeded!
              setStep(3);
              //   orderComplete(result.paymentIntent.id);
            }
          });
      } else {
        res = await createPaymentSub(
          userState.donation.donateToId,
          amount,
          currency.value,
          companyName,
          firstname,
          lastname,
          "+" + phone,
          email,
          address,
          postal,
          city,
          country,
          null,
          anonymous,
          msg.isEmpty() ? null : msg,
          resToken.token.id,
          intervals.value || (oneTime ? "1" : "12"),
          null
        );
        if (res) {
          remBil = await removeBillets(userState.tickets.campain, buyTickets);
        }
        setStep(3);
      }
    });
  };

  const validateStep = (step) => {
    switch (step) {
      case 0:
        if (amount && currency) {
          setStep(1);
          return true;
        }
        Store.addNotification({
          title: t("SingIn.Errors"),
          message: t("Dashboard.Donate.FillError"),
          type: "danger",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 10000,
            onScreen: true,
          },
        });
        return false;
        break;
      case 1:
        var errorFields = [];
        !lastname && errorFields.push(t("Dashboard.Donate.NameError"));
        !firstname && errorFields.push(t("Dashboard.Donate.FirstNameError"));
        !phone && errorFields.push(t("Dashboard.Donate.PhoneError"));
        !email && errorFields.push(`Email`);
        !address && errorFields.push(t("Dashboard.Donate.AddressError"));
        !city && errorFields.push(t("Dashboard.Donate.CityError"));
        !postal && errorFields.push(t("Dashboard.Donate.ZipCodeError"));
        !country && errorFields.push(t("Dashboard.Donate.CountryError"));
        !((company == true && !companyName.isEmpty()) || company == false) &&
          errorFields.push(t("Dashboard.Donate.CompanyNameError"));
        if (errorFields.length > 0) {
          Store.addNotification({
            title: t("SingIn.Errors"),
            message:
              t("Dashboard.CharityPage.FillFields") + errorFields.join(", "),
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 10000,
              onScreen: true,
            },
          });
          return;
        }
        if (!validator.isEmail(email)) {
          Store.addNotification({
            title: t("SingIn.Errors"),
            message: t("Dashboard.Donate.InvalidEmail"),
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 10000,
              onScreen: true,
            },
          });
          return false;
        }

        if (
          !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
            "+" + phone
          )
        ) {
          Store.addNotification({
            title: t("SingIn.Errors"),
            message: t("Dashboard.Donate.InvalidPhone"),
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 10000,
              onScreen: true,
            },
          });
          return false;
        }
        // if (!validator.isN(postal)) {
        //     Store.addNotification({
        //         title: "Erreur",
        //         message: "Numero de téléphone invalide. (ex: +33606060606)",
        //         type: "danger",
        //         insert: "top",
        //         container: "top-center",
        //         animationIn: ["animate__animated", "animate__fadeIn"],
        //         animationOut: ["animate__animated", "animate__fadeOut"],
        //         dismiss: {
        //         duration: 10000,
        //         onScreen: true
        //         }
        //     });
        //     return false
        // }
        setStep(2);
        return true;
      // }
      // Store.addNotification({
      //     title: "Erreur",
      //     message: "Merci de remplir tous les champs.",
      //     type: "danger",
      //     insert: "top",
      //     container: "top-center",
      //     animationIn: ["animate__animated", "animate__fadeIn"],
      //     animationOut: ["animate__animated", "animate__fadeOut"],
      //     dismiss: {
      //     duration: 10000,
      //     onScreen: true
      //     }
      // });
      // return false
      case 2:
        var errorFields = [];
        !titulaire && errorFields.push(`Nom du titulaire de la carte`);

        if (errorFields.length > 0) {
          Store.addNotification({
            title: t("SingIn.Errors"),
            message:
              t("Dashboard.CharityPage.FillFields") + errorFields.join(", "),
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 10000,
              onScreen: true,
            },
          });
          return;
        }
        handleSubmit();
        // Store.addNotification({
        //     title: "Erreur",
        //     message: "Merci de remplir tous les champs.",
        //     type: "danger",
        //     insert: "top",
        //     container: "top-center",
        //     animationIn: ["animate__animated", "animate__fadeIn"],
        //     animationOut: ["animate__animated", "animate__fadeOut"],
        //     dismiss: {
        //     duration: 10000,
        //     onScreen: true
        //     }
        // });
        return false;
      // case 3:
      //     if (!iban.isEmpty() && !bic.isEmpty() && !bank.isEmpty() && !bankAddress.isEmpty()) {
      //         // setStep(3)
      //         createAccount();
      //         return true
      //     }
      //     Store.addNotification({
      //         title: "Erreur",
      //         message: "Merci d'accepter les conditions générales.",
      //         type: "danger",
      //         insert: "top",
      //         container: "top-center",
      //         animationIn: ["animate__animated", "animate__fadeIn"],
      //         animationOut: ["animate__animated", "animate__fadeOut"],
      //         dismiss: {
      //         duration: 10000,
      //         onScreen: true
      //         }
      //     });
      //     return false
      default:
        break;
    }
  };

  const removeTickets = (id) => {
    const nbrTemp = [...buyTickets];
    const nbrRemaining = [...buyTicketsRemaining];
    var totalTemp = 0;

    for (let i = 0; i < nbrTemp.length; i++) {
      if (nbrTemp[i].nbrPlace > 0) {
        if (nbrTemp[i].id === id) {
          nbrTemp[i].nbrPlace = nbrTemp[i].nbrPlace - 1;
          nbrRemaining[i].nbrPlace = nbrRemaining[i].nbrPlace + 1;
        }
      }
      totalTemp += nbrTemp[i].nbrPlace;
    }
    setBuyTickets(nbrTemp);
    setBuyTicketsRemaining(nbrRemaining);
    setTotalBillets(totalTemp);
  };

  const addTickets = (id) => {
    const nbrTemp = [...buyTickets];
    const nbrRemaining = [...buyTicketsRemaining];
    var totalTemp = 0;

    for (let i = 0; i < nbrTemp.length; i++) {
      if (nbrRemaining[i].nbrPlace > 0) {
        if (nbrTemp[i].id === id) {
          nbrTemp[i].nbrPlace = nbrTemp[i].nbrPlace + 1;
          nbrRemaining[i].nbrPlace = nbrRemaining[i].nbrPlace - 1;
        }
      }
      totalTemp += nbrTemp[i].nbrPlace;
    }
    setBuyTickets(nbrTemp);
    setBuyTicketsRemaining(nbrRemaining);
    setTotalBillets(totalTemp);
  };

  return (
    <div className="mainDonContainer">
      <div
        className="signupContainer"
        style={{ marginBottom: step < 3 ? "100px" : "30px" }}
      >
        <div
          className="signUpRow"
          style={{ justifyContent: "space-evenly", flexDirection: "row" }}
        >
          {step == 0 ? (
            <p className="donateTxt">{t("Billetterie.yourtickets")}</p>
          ) : step == 1 ? (
            <p className="donateTxt">
              {t("Dashboard.Donate.PersonalInformation")}
            </p>
          ) : step == 2 ? (
            <p className="donateTxt" style={{ marginRight: "70px" }}>
              {t("Dashboard.Donate.Payment")}
            </p>
          ) : step == 3 ? (
            <p className="donateTxt" style={{ marginRight: "30px" }}>
              {" "}
              {t("Charity.HomeAdditionalTax")}{" "}
            </p>
          ) : (
            <p></p>
          )}
        </div>
        <div
          style={{ flexDirection: "row", marginBottom: "40px" }}
          className="signUpRowDot"
        >
          <div className={"stepDot"} />
          <div className={step >= 1 ? "stepLineFull" : "stepLine"} />
          <div className={step >= 1 ? "stepDot" : "stepDotEmpty"} />
          <div className={step >= 2 ? "stepLineFull" : "stepLine"} />
          <div className={step >= 2 ? "stepDot" : "stepDotEmpty"} />
          <div className={step >= 3 ? "stepLineFull" : "stepLine"} />
          <div className={step >= 3 ? "stepDot" : "stepDotEmpty"} />
        </div>
        {step === 0 && (
          <>
            <p className="signupTitle">{t("Billetterie.yourtickets")}</p>
            {/* <p className="donateSubTitle">{t("Dashboard.Donate.TaxBenefit")}</p> */}
            {/* <div className="signupUnderline" /> */}
            <div className="signUpForm">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "30px",
                  margin: "20px auto",
                  width: "90%",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignItems: "space-between",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {campTicket &&
                    campTicket[0].tickets.map((tick, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="yourAmountText-new"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>{tick.categoryBillet}</div>
                            <div>
                              {numberWithSpaces(tick.prixBillet, tick.currency)}
                            </div>
                          </div>
                          <div
                            className="yourAmountText-new"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            <div onClick={() => removeTickets(tick.id)}>
                              <img src={Minus} />
                            </div>
                            <div>
                              {buyTickets &&
                                buyTickets[index].id == tick.id && (
                                  <div style={{ margin: "0px 10px" }}>
                                    {buyTickets[index].nbrPlace}
                                  </div>
                                )}
                            </div>
                            <div onClick={() => addTickets(tick.id)}>
                              <img src={Plus} />
                            </div>
                          </div>
                          <div
                            className="yourAmountText-new"
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>{t("Billetterie.subTot")}:</div>
                            <div>
                              {buyTickets &&
                                numberWithSpaces(
                                  tick.prixBillet * buyTickets[index].nbrPlace,
                                  tick.currency
                                )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/* <div className="donatePriceBox">
                                    <input type="number"  onChange={(e) => {
                                        setAmount(e.target.value)
                                    }}   className="donateFirstPrice" value={amount}></input>
                                </div> */}
                {/* <Select style={{position: 'relative!important',top:"0px!important",width: '100px', backgroundColor:"rgba(73, 31, 50,0.2)!important"}} value={currency} onChange={(val) => {
                                    //console.log(val);
                                    setCurrency(val)
                                }}   placeholder="EUR €" classNamePrefix="assoc-select" className={styles.selectAsso} options={options} /> */}
                {/* <select
                                    className="select-currency"
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                >
                                    {options.map((op, index) => {
                                return (
                                        <option key={index} value={op.value}>{op.label}</option>
                                        // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                                    );
                                    })}
                                </select> */}
              </div>
              <div className="assoc-donation-block-amount-checkbox-wrapper-new">
                {/* <div className="assoc-donation-block-amount-checkbox-line-new" 
                                // style={{marginLeft: '30%'}}
                                >
                                    <input onChange={() => setOneTime(true)} checked={oneTime} style={{width: 25, height: 25}} type="radio" className="radio-toolbar"/>
                                    <div className="yourAmountText-new">{t("Dashboard.Donate.TextOnce")}</div>
                                </div>
                                <div className="assoc-donation-block-amount-checkbox-line-new"
                                //  style={{marginLeft: '30%'}}
                                 >
                                    <input onChange={() => setOneTime(false)} checked={!oneTime} style={{width: 25, height: 25}} type="radio" className="radio-toolbar"/>
                                    <div className="yourAmountText-new">{t("Dashboard.Donate.SeveralTimes")}</div>
                                </div> */}
                {!oneTime && (
                  <div
                    style={{
                      width: isMobile ? "70%" : "40%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    {/* <Select value={intervals} onChange={(val) => {
                                        //console.log(val);
                                        setIntervals(val);
                                    }}  placeholder={paymentOptions[0].label} classNamePrefix="assoc-select" className="donation-months-container" options={paymentOptions} /> */}
                    <select
                      placeholder={paymentOptions[0].label}
                      className="select-Interval"
                      value={intervals}
                      onChange={(e) => setIntervals(e.target.value)}
                    >
                      {paymentOptions.map((op, index) => {
                        return (
                          <option key={index} value={op.value}>
                            {op.label}
                          </option>
                          // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                        );
                      })}
                    </select>
                  </div>
                )}
              </div>
              {!oneTime && (
                <div
                  className="yourAmountText-new"
                  style={{
                    margin: "20px auto",
                    width: "80%",
                    textAlign: "center",
                  }}
                >
                  {`${t("Billetterie.Choice")} ${totalBillets} ${t(
                    "Billetterie.billet"
                  )} ${t("over")} ${Number(intervals)} ${t("Donate.month")}`}
                  <br />
                  {`${t("Billetterie.total")} ${numberWithSpaces(
                    Number(amount),
                    currency.value
                  )}, ${t("thattosay")} ${numberWithSpaces(
                    Number(amount) / Number(intervals),
                    currency.value
                  )} ${t("Donate.setByMonth")}`}
                </div>
              )}
              <p style={{ textAlign: "center", paddingTop: "20px" }}>
                {resultByMonth}
              </p>
            </div>

            <div
              className="btnNext"
              onClick={() => {
                validateStep(0);
              }}
            >
              {t("Dashboard.CharityPage.Enter")}
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <p className="signupTitle">{t("Dashboard.Donate.FillFields")}</p>
            <p className="donateSubTitle">
              {t("Dashboard.Donate.GenerateCerfa")}
            </p>
            <div className="signupUnderline" />
            <div className="signuUpForm">
              <div className="donateRadioRow">
                <div>
                  <input
                    type="radio"
                    className="radio-toolbar"
                    value="particulier"
                    name="don"
                    checked={!company}
                    onChange={() => {
                      setCompany(false);
                    }}
                  />{" "}
                  {t("Dashboard.Donate.Particular")}
                </div>
                <div>
                  <input
                    type="radio"
                    className="radio-toolbar"
                    value="company"
                    name="don"
                    checked={company}
                    onChange={() => {
                      setCompany(true);
                    }}
                  />{" "}
                  {t("Dashboard.Donate.Business")}
                </div>
              </div>
              {company && (
                <input
                  className="inputSignup"
                  type="text"
                  value={companyName}
                  placeholder={t("Dashboard.Donate.CompanyName")}
                  onChange={(evt) => {
                    setCompanyName(evt.target.value);
                  }}
                />
              )}
              <div className="signUpRow">
                <input
                  className="inputSignupHalf"
                  style={{ marginRight: "20px" }}
                  type="text"
                  value={lastname}
                  placeholder={t("Dashboard.Donate.Name")}
                  onChange={(evt) => {
                    setLastname(evt.target.value);
                  }}
                />
                <input
                  className="inputSignupHalf"
                  type="text"
                  value={firstname}
                  placeholder={t("Dashboard.Donate.FirstName")}
                  onChange={(evt) => {
                    setFirstname(evt.target.value);
                  }}
                />
              </div>
              <div className="signUpRow">
                <PhoneInput
                  country={"fr"}
                  containerClass="inputSignupHalf"
                  inputClass="phoneInput"
                  dropdownClass="phoneDropdown"
                  placeholder={t("Dashboard.Donate.Phone")}
                  value={phone}
                  onChange={(phoneNb) => {
                    if (phoneNb.length == 3 && phoneNb[2] == "0") {
                      setPhone(phoneNb.substring(0, 2));
                      return;
                    }
                    setPhone(phoneNb);
                  }}
                />
                {/* <input className="inputSignupHalf" style={{marginRight: '20px'}} type="text" value={phone}  placeholder="Téléphone *" onChange={(evt) => {setPhone(evt.target.value)}} /> */}
                <input
                  style={{ marginLeft: "20px" }}
                  className="inputSignupHalf"
                  type="text"
                  value={email}
                  placeholder="Email *"
                  onChange={(evt) => {
                    setEmail(evt.target.value);
                  }}
                />
              </div>
              <input
                className="inputSignup"
                type="text"
                value={address}
                placeholder={t("Dashboard.Donate.Address")}
                onChange={(evt) => {
                  setAddress(evt.target.value);
                }}
              />
              <input
                className="inputSignup"
                type="text"
                value={city}
                placeholder={t("Dashboard.Donate.City")}
                onChange={(evt) => {
                  setCity(evt.target.value);
                }}
              />
              <div className="signUpRow">
                <input
                  className="inputSignupHalf"
                  style={{ marginRight: "20px" }}
                  type="text"
                  value={postal}
                  placeholder="Code postal *"
                  onChange={(evt) => {
                    setPostal(evt.target.value);
                  }}
                />
                {/* <Select onChange={(val) => {
                                            //console.log(val);
                                            setCountry(val.label)
                                        }}  placeholder={t("Dashboard.Donate.Country")}  classNamePrefix="country-select" className="react-select-country-don" options={countries} /> */}
                <select
                  className="inputSignupHalf"
                  placeholder="EUR €"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  style={{
                    borderRadius: "10px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginRight: "20px",
                    marginLeft: "0px",
                    width: "367px!important",
                  }}
                >
                  {countries.map((opt, index) => {
                    return (
                      <option key={index} value={opt.value}>
                        {opt.label}
                      </option>
                      // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                    );
                  })}
                </select>
              </div>
              {/* <input className="inputSignupHalf" type="text" value={country}  placeholder="Pays *" onChange={(evt) => {setCountry(evt.target.value)}} /> */}
            </div>
            <div
              className="btnNext"
              onClick={() => {
                validateStep(1);
              }}
            >
              {t("Dashboard.CharityPage.Enter")}
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <p className="signupTitle">{t("Dashboard.Donate.FillFields")}</p>
            <div className="signupUnderline" />
            <div className="signuUpForm">
              <p className="donatePrice">
                {numberWithSpaces(amount, currency.value)}
              </p>
              <p style={{ textAlign: "center", paddingTop: "20px" }}>
                {resultByMonth}
              </p>
              {/* <p>{reminderByMonth}</p> */}
            </div>
            <div
              className={"cardElem"}
              style={{
                backgroundColor: "white",
                borderRadius: 20,
                padding: 20,
                width: "70%",
                marginLeft: "auto",
                marginRight: "auto",
                border: "1px solid #471d30",
              }}
            >
              <CardElement />
              {/* <div className="donateFirstCol">
                                <p>Numéro de carte</p>
                                <div className="signUpRow donateCardInput">

                                    <input className="donateInput" maxLength="4" value={card}  placeholder="1234" onChange={(evt) => {setCard(evt.target.value)}} />
                                    <input className="donateInput" maxLength="4" value={card2}  placeholder="5678" onChange={(evt) => {setCard2(evt.target.value)}} />
                                    <input className="donateInput" maxLength="4" value={card3}  placeholder="3456" onChange={(evt) => {setCard3(evt.target.value)}} />
                                    <input className="donateInput" maxLength="4" value={card4}  placeholder="2456" onChange={(evt) => {setCard4(evt.target.value)}} />
                                </div>
                            </div>
                            <div className="donateSecCol">
                                <p>Date d'expiration</p>
                                <div className="signUpRow donateCardInput" style={{marginRight: '40px'}}>
                                    <input className="donateInputDate" maxLength="2" value={month} placeholder="05" onChange={(evt) => {setMonth(evt.target.value)}} />
                                    <span style={{color: '#B5B5B5'}}>/</span>
                                    <input className="donateInputDate" maxLength="2" value={year}  placeholder="21" onChange={(evt) => {setYear(evt.target.value)}} />
                                </div>
                            </div>
                            <div className="donateLastCol">
                                <p>CVV</p>
                                <div className="signUpRow donateCardInput" style={{marginRight: '40px'}}>
                                    <input className="donateInput" maxLength="3" value={cvv}  placeholder="123" onChange={(evt) => {setCvv(evt.target.value)}} />
                                </div>
                            </div> */}
            </div>
            <div
              className="donateLastCol"
              style={{ marginLeft: "auto", marginRight: "auto", width: "70%" }}
            >
              <p
                style={{
                  marginLeft: isMobile ? 0 : "60px",
                  marginTop: "30px",
                  fontSize: 16,
                }}
              >
                {t("Dashboard.Donate.CardHolder")}
              </p>
              <div className="signUpRow donateCardInput">
                <input
                  className="donateInput"
                  style={{ width: "100%" }}
                  value={titulaire}
                  placeholder={t("Dashboard.Donate.FullName")}
                  onChange={(evt) => {
                    setTitulaire(evt.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className={"donateLastStepTitle"}
              style={{ marginLeft: "30%", marginTop: "30px", fontSize: 16 }}
            >
              <input
                type="radio"
                className="radio-toolbar"
                value="name"
                name="don"
                checked={!anonymous}
                onChange={() => {
                  setAnonymous(false);
                }}
              />
              <span>{t("Dashboard.Donate.AppearName")}</span>
              <input
                disabled={true}
                className="donateSmInput"
                value={firstname + " " + lastname}
                placeholder={t("Dashboard.Donate.FullName")}
                onChange={(evt) => {
                  setName(evt.target.value);
                }}
              />
            </div>
            <div
              className={"donateLastStepTitle"}
              style={{ marginLeft: "30%", marginTop: "30px", fontSize: 16 }}
            >
              <input
                type="radio"
                className="radio-toolbar"
                value="anonymous"
                name="don"
                checked={anonymous}
                onChange={() => {
                  setAnonymous(true);
                }}
              />{" "}
              {t("Dashboard.Donate.AnonimDonation")}
            </div>
            <div
              className="donateLastCol"
              style={{ marginLeft: "auto", marginRight: "auto", width: "70%" }}
            >
              <p
                style={{
                  marginLeft: isMobile ? 0 : "60px",
                  marginTop: "30px",
                  fontSize: 16,
                }}
              >
                {t("Dashboard.Donate.LeaveMessage")}
              </p>
              <div className="signUpRow donateCardInput">
                <textarea
                  className="donateInput"
                  style={{ width: "100%", height: "190px" }}
                  value={msg}
                  rows={3}
                  onChange={(evt) => {
                    setMsg(evt.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className="btnNext"
              onClick={() => {
                validateStep(2);
                // setStep(3)
              }}
            >
              {" "}
              {loading == true ? (
                <Loader
                  type="ThreeDots"
                  color="#507cab"
                  height={30}
                  width={30}
                />
              ) : (
                t("Dashboard.CharityPage.Enter")
              )}
            </div>
            <div className="signUpRow">
              <img src={verified} alt="lock" className="lockIcon" />
              <p>{t("Dashboard.Donate.StripePayment")}</p>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <p className="signupTitle">
              {t("Dashboard.Donate.Congratulations")} <br />
              {t("Dashboard.Donate.SendCerfa")}
            </p>
            <div
              className="btnNext"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "100px",
              }}
              onClick={async () => {
                // window.location = userState.donation.returnUrl;
                history.push("/");
              }}
            >
              {t("Dashboard.Donate.Back")}
            </div>
            {/* <div className="signupUnderline" />
                        <div className="signuUpForm">
                            <img src={cerfa} alt="cerfaDoc" style={{marginTop: '30px', borderRadius: '25px'}} />
                        </div> */}
          </>
        )}
      </div>
      {/* {
                step === 3 &&
                <div className="donateDl">
                    <p style={{margin: '0px', textAlign: 'center', fontSize: '32px'}}>Vous pouvez également le télécharger en PDF: </p>
                    <div className="btn" style={{marginTop: 'auto', marginBottom: 'auto'}} onClick={() => {}}>Cliquer ici</div>
                </div>
            } */}
    </div>
  );
}

export default function BuyTickets(props) {
  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const [userState, setUserState] = useContext(UserContext);
  const stripePromise = new Promise((resolve, reject) => {
    getGateway(userState.tickets.campain).then((result) => {
      if (result.accountId) {
        //console.log('isCollect');

        // resolve(
        //     loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
        //     // loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { stripeAccount: "acct_xxx" })
        //   );
        resolve(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
            stripeAccount: result.accountId,
          })
          // loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { stripeAccount: "acct_xxx" })
        );
      } else {
        //console.log('isCampain');

        resolve(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
          // loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { stripeAccount: "acct_xxx" })
        );
      }
    });
  });
  // useEffect(async () => {
  //     var res = await getGateway(userState.donation.donateToId)
  //     //console.log(res);

  // }, [])
  return (
    <Elements stripe={stripePromise}>
      <Donate {...props} />
    </Elements>
  );
}
