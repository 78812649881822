import styles from './index.css';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from "../../Context/UserContext";
import { APIURL } from '../../Services/consts';
import { resetPassword } from '../../Services/ws';
import { Store } from 'react-notifications-component';
import { useTranslation} from "react-i18next";

export default function Login({history}) {
    var [email, setEmail] = useState("")
    var [password, setPassword] = useState("")
    const [userState, setUserState] = useContext(UserContext);
    const { t } = useTranslation()

    if (userState && userState.user && localStorage.getItem('refreshToken') != 'null') {
        history.push('/dashboard')
        return <div />
    }

    return <div className={'mainDonContainer'}>
        <div className={'signupContainer'}>
      <p className="signupTitle">{t("login")}</p>
        <div className={'signuUpForm'}>
            <input placeholder={t("Login.UserId")} className={'inputSignup'} value={email} onChange={(evt) => setEmail(evt.target.value)}></input>
            <input placeholder={t("Login.Password")} type="password" className={'inputSignup'} value={password} onChange={(evt) => setPassword(evt.target.value)}></input>
            <div onClick={async () => {
                 if (email) {
                     try {
                        var result = await resetPassword(email)
                        if (result && result.sent == true) {
                           Store.addNotification({
                               title: "Confirmation",
                               message: "Un email vous a été envoyé",
                               type: "success",
                               insert: "top",
                               container: "top-center",
                               animationIn: ["animate__animated", "animate__fadeIn"],
                               animationOut: ["animate__animated", "animate__fadeOut"],
                               dismiss: {
                                 duration: 5000,
                                 onScreen: true
                               }
                             });
                        } else {
                            Store.addNotification({
                                title: "Erreur",
                                message: "Email invalide",
                                type: "danger",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                  duration: 5000,
                                  onScreen: true
                                }
                              });
                        }
                     } catch (error) {
                        Store.addNotification({
                            title: "Erreur",
                            message: "Email introuvable.",
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                              duration: 5000,
                              onScreen: true
                            }
                          }); 
                     }
    
                 } else {
                    Store.addNotification({
                        title: "Erreur",
                        message: "Merci de renseigner votre Email",
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 5000,
                          onScreen: true
                        }
                      });
                 }
             }} 
             className={'fogotPassword'}>{t("Login.FogotPassword")}</div>
        </div>
        {/* <div className={'login-inputRow'}> */}
         <div onClick={async () => {
             try {
               const res = await fetch(APIURL + '/auth/signIn', {
                 method: 'POST',
                 headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    "email": email,
                    "password": password
                  })
                });
                    
                    if (res.status == 201) {
                        var result = await res.json();
                        //console.log(result);
                        
                        localStorage.setItem('accessToken', result.accessToken)            
                        localStorage.setItem('refreshToken', result.refreshToken)            
                        // console.log(result)
                        setUserState(result)
                        history.push('/dashboard');
                    } else {
                        //console.log(res);
                        Store.addNotification({
                            title: "Erreur",
                            message: "Identifiants incorrects",
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                              duration: 5000,
                              onScreen: true
                            }
                          });
                    }                     
             } catch (error) {
                Store.addNotification({
                    title: "Erreur",
                    message: "Identifiants incorrects",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
             }

                
         }} className={'btnNext'}>{t("Login.Login")}</div>
        {/* </div> */}
        <div className="notRegistered">
         <div>{t("notRegistered")}</div>
        <div onClick={() => {
          history.push('/signup')
        }} className={'signup'}>{t("Login.Singup")}</div>
        </div>

        </div>
    </div>
}
