import styles from "./Billetterie.css";
import cerfa from "../../assets/cerfa_blue.svg";
import finance from "../../assets/finance.svg";
import support from "../../assets/support.svg";
import ContactForm from "../../Components/ContactForm";
import arrowDown from "../../assets/angle-down-solid.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
import whatsapp from "../../assets/whatsapp.svg";
import linkedin from "../../assets/linkedin.svg";
import sharelink from "../../assets/share-link.png";
import gift1 from "../../assets/gift-box-1.svg";
import gift2 from "../../assets/gift-box-2.svg";
import gift3 from "../../assets/gift-box-3.svg";
import gift4 from "../../assets/gift-box-4.svg";
import gift5 from "../../assets/gift-box.svg";
import iconDonate from "../../assets/icon_donate.svg";
import iconAssoc from "../../assets/icon_association.svg";
import iconSecu from "../../assets/icon_securite.svg";
import iconConfiance from "../../assets/icon_confiance.svg";
import iconCerfa from "../../assets/cerfa.svg";
import { AiOutlineQrcode } from "react-icons/ai";
import donorProfile from "../../assets/donor_profile_pic.png";
import roseFacebook from "../../assets/New/roseFacebook.png";
import roseTwitter from "../../assets/New/roseTwitter.png";
import roseWhatsapp from "../../assets/New/roseWhatsapp.png";
import roseMail from "../../assets/New/roseMail.png";
import roseLink from "../../assets/New/roseLink.png";
import roseLinkedin from "../../assets/New/roseLinkedin.png";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
} from "react-share";
import Modal from "react-modal";
import QRCode from "react-qr-code";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AssocBilletterie from "../../Components/AssocBilletterie";
import { useRef, useState, useEffect, useContext } from "react";
import Select from "react-select";
import { UserContext } from "../../Context/UserContext";
import {
  getPageByUrl,
  getCampainDonations,
  getSponsorNumbers,
  getCollectFromCamp,
} from "../../Services/ws";
import { numberWithSpaces, calcPercentage } from "../../Services/utils";
import dynamicLoader from "react-dynamic-loader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import QrCode from "../../Components/QrCode/QrCode";
import Countdown from "react-countdown";
import Hand from "../../assets/New/hand.png";
import check from "../../assets/New/check-circle2.svg";
import check2 from "../../assets/New/check-circle.svg";
import Award from "../../assets/New/award.png";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import Moment from "moment";
import Plus from "../../assets/New/plus.svg";
import Minus from "../../assets/New/minus.svg";

const ChoixBillets = ({
  title,
  price,
  nbrPlaceRemaining,
  nbrPlaces,
  remove,
  add,
  currency,
}) => {
  return (
    <div>
      <div className="billetContainer">
        <div className="billetTitle">{title}</div>
        <div className="billetPrice">{numberWithSpaces(price, currency)}</div>
        <div>
          <div className="billetRemains">
            il reste {nbrPlaceRemaining} places
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              onClick={remove}
              style={{ margin: "10px", fontSize: "18px", cursor: "pointer" }}
            >
              <img src={Minus} />
            </div>
            <div style={{ margin: "10px", fontSize: "18px" }}>{nbrPlaces}</div>
            <div
              onClick={add}
              style={{ margin: "10px", fontSize: "18px", cursor: "pointer" }}
            >
              <img src={Plus} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Billetterie({ history }) {
  const [campain, setCampain] = useState(null);
  const [association, setAssociation] = useState(null);
  const [userState, setUserState] = useContext(UserContext);
  const [sponsor, setSponsor] = useState(null);
  const [donations, setDonations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sponsorNbs, setSponsorNbs] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [giftModalIsOpen, setIsGiftModalOpen] = useState(false);
  const [giftModalHover, setIsGiftModalHover] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const [linkCopyed, setLinkCopyed] = useState(false);
  const [tickets, setTickets] = useState(null);
  const [buyTicketsRemaining, setBuyTicketsRemaining] = useState([]);
  const [buyTickets, setBuyTickets] = useState([]);
  const [dateEvent, setDateEvent] = useState(null);
  const [editorTemp, setEditorTemp] = useState("");
  const [readMore, setReadMore] = useState(false);
  const { t } = useTranslation();

  const [urlLink, setUrlLink] = useState("");

  useEffect(() => {
    const urlTemp = window.location.href;
    const newUrl = urlTemp.replaceAll("%20", "-");
    setUrlLink(newUrl);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    var params = history.location.pathname.split("/");

    // if (campData) {
    //     setCampain(campain)
    //     setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(campain.content[0].content))))
    //     return ;
    // }
    if (
      history.location.pathname &&
      history.location.pathname.indexOf("/ticketvisualize") != -1
    ) {
      var res = userState;
      try {
        setCampain(res.campainVisualize);
        // res && setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(res.campainVisualize.content))))
      } catch (error) {
        // console.log(error)
      }

      setLoading(false);
    } else {
      getPageByUrl(params[params.length - 1]).then(async (res) => {
        //console.log('RESULT', res);
        setCampain(res);
        res &&
          setEditorState(
            EditorState.createWithContent(
              convertFromRaw(JSON.parse(res.content[0].content))
            )
          );

        setLoading(false);

        //console.log(history.location.search);
        if (history.location.search.indexOf("?sponsor=") != -1) {
          var sponsorName = decodeURI(
            history.location.search.slice(9, history.location.search.length)
          );
          //console.log(res.sponsors.find((el) => el.name == sponsorName));
          var sponsor = res.sponsors.find((el) => el.name == sponsorName);
          if (sponsor) {
            setSponsor(sponsor);
            var nbs = await getSponsorNumbers(res.id);
            //console.log('NBS', nbs);
            var sponsorNbs = nbs.find((el) => el.sponsor.id == sponsor.id);
            //console.log(sponsorNbs);
            setSponsorNbs(sponsorNbs);
          }
        }

        res &&
          getCampainDonations(res.id).then((donations) => {
            // console.log(donations);
            setDonations(donations);
          });
      });
    }

    // if (history.location.state) {
    //     // setCampain(history.location.state.camp)
    //     // setAssociation(userState.association)
    // }
  }, []);

  useEffect(() => {
    if (editorState) {
      const ediTemp = editorState
        .getCurrentContent()
        .getPlainText("\u0001")
        .split(" ");
      const arrTemp = [];
      // console.log(ediTemp);
      for (let i = 0; i < 50; i++) {
        arrTemp.push(ediTemp[i]);
      }
      var str = arrTemp.join(" ");
      setEditorTemp(str + "...");
    }
  }, [editorState]);

  useEffect(() => {
    if (campain && campain.tickets) {
      setTickets(campain.tickets);

      const buyTicketsTemp = [];
      const buyTicketsRemainingTemp = [];
      campain.tickets.forEach((tick) => {
        buyTicketsRemainingTemp.push({
          id: tick.id,
          nbrPlace: tick.nbrPlacesBilletRestant,
        });
        buyTicketsTemp.push({
          id: tick.id,
          nbrPlace: 0,
        });
      });
      setBuyTicketsRemaining(buyTicketsRemainingTemp);
      setBuyTickets(buyTicketsTemp);
    }
  }, campain);

  const removeTickets = (id) => {
    const nbrTemp = [...buyTickets];
    const nbrRemaining = [...buyTicketsRemaining];

    for (let i = 0; i < nbrTemp.length; i++) {
      if (nbrTemp[i].nbrPlace > 0) {
        if (nbrTemp[i].id === id) {
          nbrTemp[i].nbrPlace = nbrTemp[i].nbrPlace - 1;
          nbrRemaining[i].nbrPlace = nbrRemaining[i].nbrPlace + 1;
        }
      }
    }
    setBuyTickets(nbrTemp);
    setBuyTicketsRemaining(nbrRemaining);
  };

  const addTickets = (id) => {
    const nbrTemp = [...buyTickets];
    const nbrRemaining = [...buyTicketsRemaining];

    for (let i = 0; i < nbrTemp.length; i++) {
      if (nbrRemaining[i].nbrPlace > 0) {
        if (nbrTemp[i].id === id) {
          nbrTemp[i].nbrPlace = nbrTemp[i].nbrPlace + 1;
          nbrRemaining[i].nbrPlace = nbrRemaining[i].nbrPlace - 1;
        }
      }
    }
    setBuyTickets(nbrTemp);
    setBuyTicketsRemaining(nbrRemaining);
  };

  useEffect(() => {}, [buyTickets]);

  if (loading) {
    return <div style={{ height: "calc(100vh - 403px)" }} />;
  }
  if (!campain) {
    return (
      <div
        style={{
          height: "calc(100vh - 403px)",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontSize: 45,
        }}
      >
        {t("Charity.PageNotFound")}
      </div>
    );
  }
  const pictures = campain.medias
    .filter((el) => el.type == "PICTURE")
    .map((el) => el.url);
  const videos = campain.medias
    .filter((el) => el.type == "VIDEO")
    .map((el) => el.url);

  if (campain.association.block) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "78%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 100,
          border: "5px solid rgb(169 86 86)",
          borderRadius: 15,
          padding: 30,
        }}
      >
        This association has been blocked. Please contact website administrator.
      </div>
    );
  }

  if (!campain.accepted) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "78%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 100,
          border: "5px solid rgb(169 86 86)",
          borderRadius: 15,
          padding: 30,
        }}
      >
        This page has been rejected. Please contact website administrator.
      </div>
    );
  }
  var donated = 0;
  if (donations.length) {
    for (let i = 0; i < donations.length; i++) {
      donated = donated + Number(donations[i].amount);
    }
  }

  const Carousel = ({
    pictures = [],
    videos = [],
    style = {},
    reverse = false,
  }) => {
    const sliderRef = useRef(null);
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      autoplaySpeed: 1500,

      slidesToShow: isMobile
        ? 1
        : pictures.length + videos.length >= 3
        ? 3
        : pictures.length + videos.length,
      slidesToScroll: 1,
      autoplay: true,
    };

    return (
      <div style={{ position: "relative", marginRight: 10, ...style }}>
        <Slider variableWidth ref={sliderRef} {...settings}>
          {!reverse &&
            pictures.map((picture, index) => (
              <div className="slider-block" key={index}>
                <img className="assoc-slider-img" src={picture} />
              </div>
            ))}
          {!reverse &&
            videos.map((picture, index) => (
              <div className="slider-block" key={index}>
                <video controls className="assoc-slider-img" src={picture} />
              </div>
            ))}
          {reverse &&
            videos.map((picture, index) => (
              <div className="slider-block" key={index}>
                <video controls className="assoc-slider-img" src={picture} />
              </div>
            ))}
          {reverse &&
            pictures.map((picture, index) => (
              <div className="slider-block" key={index}>
                <img className="assoc-slider-img" src={picture} />
              </div>
            ))}
        </Slider>
        {/* {(pictures.length + videos.length) > 3 &&
              <>
                  <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 230, top: 0, display: 'flex'}}>
                  <img onClick={() => {
                      //console.log(sliderRef);
                      sliderRef.current.slickPrev()
                  }} style={{width: 35, marginLeft: 'auto', marginRight: 'auto',  zIndex: 30, transform: 'rotate(90deg)'}} src={arrowDown}></img>
                  </div>
                  <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 230, top: 0, right: 0, display: 'flex'}}>
                  <img onClick={() => {
                      //console.log(sliderRef);
                      sliderRef.current.slickNext()
                  }}  style={{width: 35, marginLeft: 'auto', marginRight: 'auto',  zIndex: 30, transform: 'rotate(270deg)'}} src={arrowDown}></img>
                  </div>
              </>
          } */}
      </div>
    );
  };
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setLinkCopyed(true);
    setTimeout(() => setLinkCopyed(false), 3000);
  };

  return (
    <div>
      {/* DEBUT HEADER BILLETTERIE */}

      {!isMobile && (
        <div className="topCampainContainer">
          <div
          // style={{width:"50%"}}
          >
            <AssocBilletterie
              customStyle={{ width: "43%", marginRight: 0 }}
              mobile={false}
              campainName={campain.title[0].content}
              img={campain.sharingImage}
              title={campain.associationName}
              desc={campain.description[0].content}
            />
          </div>
          <div
          //  style={{width:"30%"}}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                className={"share-block-top"}
                style={{
                  width: "60%",
                  marginRight: "auto",
                  marginLeft: "3%",
                  marginTop: 20,
                }}
              >
                <div
                  style={{ fontSize: "16px" }}
                  onClick={() => {
                    setUserState({
                      ...userState,
                      tickets: {
                        buyTickets: buyTickets,
                        // amount: amount,
                        // currency: currency,
                        // times: oneTime,
                        campain: campain.id,
                        donateToId: campain.association._id,
                        // sponsorId: sponsor ? sponsor.id : null,
                        // returnUrl: window.location.href,
                        // intervals: intervals,
                        // donFor: donFor,
                      },
                    });
                    history.push("/buytickets");
                  }}
                  className="assoc-donation-block-amount-donate"
                >
                  {t("Billetterie.buynow")}
                </div>
                <div
                  className="assoc-donation-block-amount-donate-txt"
                  style={{ fontSize: "10px", marginBottom: "-5px" }}
                >
                  {t("Campaign.getmail")}
                </div>
                {/* <span style={{textAlign: 'center', color: '#507cab', fontSize: 20, fontWeight: 'bold'}}>{t("Campaign.SocialShare")}</span> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <FacebookShareButton url={urlLink}>
                    <img className={"h-socialIcon-top"} src={roseFacebook} />
                  </FacebookShareButton>
                  <TwitterShareButton
                    title={`Soutenons : ${campain.title[0].content}`}
                    url={urlLink}
                  >
                    <img className={"h-socialIcon-top"} src={roseTwitter} />
                  </TwitterShareButton>
                  <WhatsappShareButton url={urlLink}>
                    <img className={"h-socialIcon-top"} src={roseWhatsapp} />
                  </WhatsappShareButton>
                  <EmailShareButton url={urlLink}>
                    <img className={"h-socialIcon-top"} src={roseMail} />
                    {/* <EmailIcon size={32} round /> */}
                  </EmailShareButton>
                  {/* <AiOutlineQrcode> <QrCode url={urlLink}/> </AiOutlineQrcode> */}
                  <div style={{ paddingTop: "9px" }}>
                    <QrCode className={"h-socialIcon-top"} url={urlLink} />
                  </div>
                  <LinkedinShareButton url={urlLink}>
                    <img className={"h-socialIcon-top"} src={roseLinkedin} />
                  </LinkedinShareButton>
                  <div onClick={() => copyToClipboard(urlLink)}>
                    <img
                      className={"h-socialIcon-top"}
                      style={{ cursor: "pointer" }}
                      src={roseLink}
                    />
                    {linkCopyed && (
                      <p style={{ position: "absolute" }}>Link copied</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className="topCampainContainer">
        <div
        // style={{width:"50%"}}
        >
          <AssocBilletterie
            customStyle={{ width: "43%", marginRight: 0 }}
            campainName={campain.title[0].content}
            img={campain.sharingImage}
            title={campain.associationName}
            desc={campain.description[0].content}
          />
        </div>
      </div> */}

      {/* FIN HEADER BILLETTERIE */}

      {/* DEBUT LIEU ET DATE */}

      <div className="lieuDateContainer">
        {campain && (
          <div className="lieuDate">
            {t("Billetterie.Place")}:{" "}
            <span style={{ color: "#301824" }}>
              {campain?.tickets[0]?.nomLieu ? campain.tickets[0].nomLieu : ""}
            </span>
          </div>
        )}
        {campain && (
          <div className="lieuDate">
            {t("Billetterie.On")}{" "}
            <span style={{ color: "#301824" }}>
              {campain?.tickets[0]?.dateEvent
                ? Moment(campain.tickets[0].dateEvent).format("DD/MM/YYYY")
                : ""}
            </span>
          </div>
        )}
      </div>

      {/* FIN LIEU ET DATE */}

      {/* DEBUT AFFICHE BILLETTERIE */}

      <div className="afficheBilletterieContainer">
        <img
          style={{ width: "100%", height: "500px", objectFit: "cover" }}
          src={campain.poster[0].url}
          alt="evenment poster"
        />
      </div>

      {/* FIN AFFICHE BILLETTERIE */}

      {/* DEBUT AFFICHAGE INFO ASSO MOBILE */}
      {isMobile && (
        <AssocBilletterie
          customStyle={{ width: "70%" }}
          mobile={true}
          campainName={campain.title[0].content}
          img={campain.sharingImage}
          title={campain.associationName}
          desc={campain.description[0].content}
        />
      )}
      {/* FIN AFFICHAGE INFO ASSO MOBILE */}

      {/* DEBUT A PROPOS DE LA CAMPAGNE */}

      <div style={{ width: "80%", margin: "auto" }}>
        <div className="aboutCampain">{t("AboutBilletterie")}</div>

        {readMore == true ? (
          <div className={"assocContainer-desc"}>
            {/* {campain.content[0].content} */}
            <Editor readOnly toolbarHidden editorState={editorState} />
            <div
              onClick={() => setReadMore(false)}
              style={{
                color: "#d12164",
                cursor: "pointer",
                fontSize: "20px",
                fontWeight: 600,
                marginBottom: "40px",
                textAlign: "justify",
              }}
            >
              {t("Campain.close")}
            </div>
          </div>
        ) : (
          <div
            style={{
              fontSize: "20px",
              fontWeight: 600,
              marginBottom: "40px",
              textAlign: "justify",
            }}
          >
            <div>
              {editorTemp}&nbsp;{" "}
              <span
                onClick={() => setReadMore(true)}
                style={{ color: "#d12164", cursor: "pointer" }}
              >
                {t("Campain.readMore")}
              </span>
            </div>
          </div>
        )}
      </div>

      {/* FIN A PROPOS DE LA CAMPAGNE */}

      {/* DEBUT CAROUSEL IMAGES */}

      <Carousel
        pictures={pictures}
        videos={videos}
        style={{ width: "95%", marginLeft: "auto", marginRight: "auto" }}
        reverse
      />
      {/* <div style={{ height: 100 }}></div> */}

      {/* FIN CAROUSEL IMAGES */}

      {/* DEBUT CHOIX DES BILLETS */}

      <div className="typeText">{t("Billetterie.typeText")}</div>

      <div className="allBilletsContainer">
        {tickets &&
          tickets.map((ticket, index) => {
            return (
              <ChoixBillets
                key={index}
                title={ticket.categoryBillet}
                price={ticket.prixBillet}
                nbrPlaceRemaining={buyTicketsRemaining[index].nbrPlace}
                currency={ticket.currency}
                nbrPlaces={buyTickets[index].nbrPlace}
                remove={() => removeTickets(ticket.id)}
                add={() => addTickets(ticket.id)}
              />
            );
          })}
      </div>

      {/* FIN CHOIX DES BILLETS */}

      {/* DEBUT ACHETER BILLETS */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "40px",
        }}
      >
        <button
          onClick={() => {
            setUserState({
              ...userState,
              tickets: {
                buyTickets: buyTickets,
                // amount: amount,
                // currency: currency,
                // times: oneTime,
                campain: campain.id,
                donateToId: campain.association._id,
                // sponsorId: sponsor ? sponsor.id : null,
                // returnUrl: window.location.href,
                // intervals: intervals,
                // donFor: donFor,
              },
            });
            history.push("/buytickets");
          }}
          className="btnPurchaseBillet"
        >
          {t("Billetterie,purchase")}
        </button>
      </div>

      {/* FIN ACHETER BILLETS */}
    </div>
  );
}
