import react from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// import SEO from "../../Components/SEO/SEO";

import LogoShare from "../../assets/logo-share.jpeg";

import Img1 from "./img/1.jpeg";
import Img2 from "./img/2.jpeg";
import Img3 from "./img/3.jpeg";

import homeStyles from "../Home/Home.css";
import styles from "./styles.css";

export default function AboutUs(props) {
  const { t } = useTranslation();
  return (
    <>
      {/* <SEO
        title={t("Charity.About")}
        description={"CharityClic about page"}
        name={`CharityClick`}
        type="website"
        image={LogoShare}
      /> */}
      <Helmet>
        <title>{t("Charity.About")}</title>
        <meta property="og:title" content={t("Charity.About")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={"CharityClic about page"} />
        <meta property="og:site_name" content="CharityClic" />
        <meta property="og:image" itemProp={"image"} content={LogoShare} />
        <meta
          property="og:image:secure_url"
          itemProp={"image"}
          content={LogoShare}
        />
      </Helmet>
      <div className={"homeContainer"}>
        <div className={"aboutUs"}>
          <h1 className={"charityAbout"}>{t("Charity.About")}</h1>
          <div style={{ position: "relative" }}>
            <div className={"images"}>
              <img
                style={{ zIndex: "100", width: "225px" }}
                alt={"2"}
                src={Img2}
              />
              <img
                style={{ zIndex: "100", width: "225px" }}
                alt={"1"}
                src={Img1}
              />
              <img
                style={{ zIndex: "100", width: "225px" }}
                alt={"3"}
                src={Img3}
              />
            </div>
            <div className="fond"></div>
          </div>
          <div className="txts">
            <blockquote style={{ color: "#71505f" }}>
              <i>
                « Les associations nous donnent l'opportunité de participer à
                des causes pour nous amener vers un monde meilleur. »
              </i>
            </blockquote>
            <p>
              Charityclic a pour mission d’accompagner les associations afin de
              les aider à se développer. Nous avons créé une plateforme qui
              répond aux besoins des associations leur apportant une technique
              qui leur permettra de gagner un temps précieux dans leur
              organisation.
            </p>
            <p>
              Mais aussi nous sommes là pour donner des solutions afin
              d’optimiser au maximum les campagnes de don.
            </p>
            <p>
              Depuis plus de 25 ans dans le monde associatif, nous avons acqui
              une expérience que nous mettons aujourd'hui à votre disposition.
            </p>
            <p>N’hésitez pas à nous contacter.</p>
            <p>David Haim et Arié</p>
          </div>
        </div>
      </div>
    </>
  );
}
