import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import {useEffect, useState} from "react";
import {
    getBackOffice,
    getData,
    getAllCampains,
    getCampainDonations,
    acceptDonationPage,
    rejectDonationPage,
    showingDonationPage,
    hiddenDonationPage
} from "../../Services/ws";
import { downloadExcel, downloadPDF, formatedDate, numberWithSpaces } from "../../Services/utils";
import DownloadButton from '../../Components/Elements/DownloadButton/DownloadButton'
import Search from "../../Components/Elements/Search/Search"
import Modal from "../../Components/Elements/Modal/Modal"
import { useTranslation} from "react-i18next"
import checkSuccess from  '../../assets/check.png';
import checkError from '../../assets/close.png'
import CampaignClosing from "../../Components/Invoices/CampaignClosing";

import styles from './Campaigns.module.css'

const Campaigns = () => {
    const [dataSource, setDataSource] = useState([]);
    const [search, setSearch] = useState('')
    const [currentData, setCurrentData] = useState([])
    const [showModal, setModalShow] = useState(false)
    const [modalStatus, setModalStatus] = useState('')
    const [currentAssociationId, setAssociationId] = useState('')
    const [campaignPage, setCampaignPage] = useState(1)
    const [campaign, setCampaign] = useState('')
    const [campaignFiltered,setCampaignFiltered] = useState([])
    const [allAssoc,setAllAssoc] = useState([])
    const { t } = useTranslation()

    const setData = async () => {
        const data = await getBackOffice('/campaign/back-office/');
        setDataSource(data)
        setCurrentData(data)
    }

    const setAssoc = async () => {
        const data = await getBackOffice('/association/back-office/');
        setAllAssoc(data)
    }

    const handleSearch = (event) => {
        setSearch(event.target.value.toLowerCase());
        if(search.length > 1) {
            const result = currentData.filter(item => item.associationName.toLowerCase().includes(search) || item.title[0].content.toLowerCase().includes(search))
            setDataSource(result)
        } else {
            setDataSource(currentData)
        }
    };

    const columns = [
        'BackOffice.Campaign.Campaign',
        'BackOffice.Campaign.Association',
        'BackOffice.Campaign.StartDate',
        'BackOffice.Campaign.EndDate',
        'BackOffice.Campaign.CampaignPage',
        'BackOffice.Campaign.Aff',
        'BackOffice.Campaign.ValidateCampaignPage',
        'BackOffice.Campaign.StatusCampaign',
        'BackOffice.Campaign.CollectedAmount',
        'BackOffice.Campaign.AmountInvoiced',
        'BackOffice.Campaign.CampaignClosure',
        'BackOffice.Campaign.PaymentStatus',
        'BackOffice.Campaign.View'
    ]

    const columnsFiltered = [
        [
            'BackOffice.Campaign.Association',
            'BackOffice.Campaign.Campaign',
            'BackOffice.Campaign.StartDate',
            'BackOffice.Campaign.EndDate',
            'BackOffice.Donation.Amount',
            'BackOffice.Donation.Pairing',
            'BackOffice.Campaign.View',
            'BackOffice.Campaign.ValidateCampaignPage',
            'BackOffice.Showing',

            // 'BackOffice.Campaign.CampaignPage',
            // 'BackOffice.Campaign.Aff',
            // 'BackOffice.Campaign.StatusCampaign',
            // 'BackOffice.Campaign.CollectedAmount',
            // 'BackOffice.Campaign.AmountInvoiced',
            // 'BackOffice.Campaign.CampaignClosure',
            // 'BackOffice.Campaign.PaymentStatus',
        ],
        [
            'BackOffice.Campaign.Association',
            'BackOffice.Campaign.Campaign',
            'BackOffice.Campaign.StartDate',
            'BackOffice.Campaign.EndDate',
            'BackOffice.Donation.Amount',
            'BackOffice.Campaign.CollectedAmount',
            'BackOffice.Donation.Pairing',
            'BackOffice.Campaign.View',
            'BackOffice.Campaign.ValidateCampaignPage',
            'BackOffice.Showing',

            // 'BackOffice.Campaign.CampaignPage',
            // 'BackOffice.Campaign.Aff',
            // 'BackOffice.Campaign.StatusCampaign',
            // 'BackOffice.Campaign.AmountInvoiced',
            // 'BackOffice.Campaign.CampaignClosure',
            // 'BackOffice.Campaign.PaymentStatus',
        ],
        [
            'BackOffice.Campaign.Association',
            'BackOffice.Campaign.Campaign',
            'BackOffice.Campaign.StartDate',
            'BackOffice.Campaign.EndDate',
            'BackOffice.Donation.Amount',
            'BackOffice.Campaign.CollectedAmount',
            'BackOffice.Donation.Pairing',
            'BackOffice.Campaign.CampaignClosure',
            'BackOffice.Campaign.AmountInvoiced',
            'BackOffice.Campaign.View',
            'BackOffice.Campaign.PaymentStatus',
            'BackOffice.Campaign.ValidateCampaignPage',
            'BackOffice.Showing',



            // 'BackOffice.Campaign.CampaignPage',
            // 'BackOffice.Campaign.Aff',
            // 'BackOffice.Campaign.StatusCampaign',
        ],
    ]

    useEffect(() => {
        setData()
        setAssoc()
    }, []);

    const CellRender = (cell,sizing=null) => (
        <p style={{fontSize: '12px',width:sizing===1?"150px":sizing===2?"175px":"100px"}}>{cell}</p>
    )

    const handleModal = (status, id) => {
        setAssociationId(id)
        setModalShow(true)
        setModalStatus(status)
    }

    const setCampaignPageAccepted = campaignId => {
        acceptDonationPage(`/campaign/accept/${campaignId}`)
        setModalShow(false)
        window.location.reload(false);
    }

    const setCampaignPageRejected = campaignId => {
        rejectDonationPage(`/campaign/reject/${campaignId}`)
        setModalShow(false)
        window.location.reload(false);
    }

    const setCampaignShow = campaignId => {
        showingDonationPage(`/campaign/show/${campaignId}`)
        setModalShow(false)
        window.location.reload(false);
    }

    const setCampaignHidden = campaignId => {
        hiddenDonationPage(`/campaign/hiding/${campaignId}`)
        setModalShow(false)
        window.location.reload(false);
    }

    const openCampaignClosure = (campaign) => {
        setCampaign(campaign)
        setCampaignPage(2)
    }

    const modalContentChange = (campaignId) => {
        return(
            <>
                <div className="text">
                    {t("BackOffice.Associations.DonationValidateText")}
                </div>
                <div className="buttons">
                    <DownloadButton buttonStyle={'primary'} name={t("Charity.Accept")} action={()=>setCampaignPageAccepted(campaignId)} />
                    <DownloadButton buttonStyle={'danger'} name={t("Charity.Reject")} action={()=>setCampaignPageRejected(campaignId)} />
                </div>
            </>
        )
    }

    useEffect(()=>{

        if(dataSource){

            const today = new Date()
            const before = []
            const during = []
            const after = []
            const arrTemp = []
            dataSource.forEach((data)=>{
                if(today > new Date(data.endingAt) && data.goal) {
                    after.push(data)
                } else if(today < new Date(data.startingAt) && data.goal) {
                    before.push(data)
                } else if(today > new Date(data.startingAt) && today < new Date(data.endingAt) && data.goal) {
                    during.push(data)
                } 
                // else {
                //     after.push(data)
                // }
                // if(!data.funding.length){
                
                // }else if(today<new Date(data.startingAt)){
                //     before.push(data)
                // }else if(today>new Date(data.endingAt)){
                //     after.push(data)
                // }else{
                //     during.push(data)
                // }
                
            })
            arrTemp.push(before)
            arrTemp.push(during)
            arrTemp.push(after)

            setCampaignFiltered(arrTemp);
        }


    },[dataSource])

    return (
        <>
            { campaignPage === 1 &&
                <>
                    <div>
                        <div className={styles.pageHead}>
                            <h1 style={{color:"#145a89"}}>{t("BackOffice.Campaign.Title")}</h1>
                            <div className={styles.tableNavbar}>
                                <Search change={handleSearch} />
                                {/* <div className={styles.tableNavbarButtons}>
                                     <div className={styles.viewBtn} style={{marginRight:"10px"}} name={'PDF'} onClick={() => downloadPDF('associationTable')} >PDF</div>
                                    <div className={styles.viewBtn} name={'Excel'} onClick={() => downloadExcel('associationTable')} >Excel</div>
                                </div> */}
                            </div>
                        </div>
                        <div className={styles.pageHead}>
                            <div className={styles.tableNavbar}>
                                <p style={{color:"#145a89", fontSize:"20px"}}>{t("BackOffice.CampagneAVenir")}</p>
                                <div className={styles.tableNavbarButtons}>
                                     <div className={styles.viewBtn} style={{marginRight:"10px"}} name={'PDF'} onClick={() => downloadPDF('associationTable')} >PDF</div>
                                    <div className={styles.viewBtn} name={'Excel'} onClick={() => downloadExcel('associationTable')} >Excel</div>
                                </div>
                            </div>
                        </div>

                {
                    dataSource.length && (
                        <TableContainer component={Paper} className={styles.tableContainer} id={'associationTable'}>
                            <Table className={styles.table} aria-label="simple table">
                                <TableHead className={styles.tableHead}>
                                    <TableRow>
                                        {
                                            columnsFiltered[0].map(cel => (
                                                <TableCell size='small'>{t(cel)}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody className={styles.tableBody}>
                                    {campaignFiltered[0].map((row) => {
                                        const campaignStartDate = new Date(row?.startingAt)
                                        const campaignEndDate = new Date(row?.endingAt)
                                        const currentDate = new Date()
                                        let campaignStatus = ''
                                        let campaignPageStatus = row?.accepted ? 'Accepted' : 'Rejected'
                                        let campaignRising = 0;

                                        if(currentDate > campaignEndDate) {
                                            campaignStatus = t('BackOffice.Campaign.Ended')
                                        } else if(currentDate < campaignStartDate) {
                                            campaignStatus = t('BackOffice.Campaign.Future')
                                        } else if(currentDate > campaignStartDate && currentDate < campaignEndDate) {
                                            campaignStatus = t('BackOffice.Campaign.Progress')
                                        } else {
                                            campaignStatus = t('BackOffice.Campaign.Ended')
                                        }

                                        row.funding?.map(rising => {
                                            campaignRising = campaignRising + rising.amount
                                        })
                                        var assoPaired = ""
                                        if(row.pairingId!=""){
                                            assoPaired = allAssoc.filter((asso)=>asso.association._id == row.pairingId)
                                        }
                                        return (
                                            <TableRow
                                            
                                                key={row?.donation?.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>{CellRender(row?.associationName)}</TableCell>
                                                <TableCell>{CellRender(row?.title[0].content)}</TableCell>
                                                <TableCell>{CellRender(formatedDate(campaignStartDate))}</TableCell>
                                                <TableCell>{CellRender(formatedDate(campaignEndDate))}</TableCell>
                                                <TableCell>
                                                    
                                                    {CellRender(numberWithSpaces(row?.goal?.amount),1)}
                                                    
                                                </TableCell>
                                                <TableCell>
                                                    {row.pairingId==="" ? 
                                                    (<div style={{color:"#bf3626",textAlign:"center"}}>Non</div>)
                                                :
                                                (<div style={{textAlign: 'center'}}>{assoPaired[0].association.usageName}
                                                </div>)
                                                }
                                                    
                                                </TableCell>
                                                <TableCell className={styles.tableLink}>
                                                    <div style={{textAlign: 'center'}}>
                                                    <a className={styles.viewBtn} style={{textDecoration:"none"}} href={'/' + row?.linkParam} target="blank">
                                                        View
                                                    </a>
                                                    </div>
                                                </TableCell>
                                                {/* <TableCell>
                                                    {(row?.accepted && row?.linkParam) &&
                                                    <img style={{display: 'block', maxWidth:20, margin: '0 auto'}} src={checkSuccess} alt={''} />
                                                    }

                                                    {(!row?.accepted || !row?.linkParam) &&
                                                    <img style={{display: 'block', maxWidth:20, margin: '0 auto'}} src={checkError} alt={''} />
                                                    }
                                                </TableCell> */}
                                                {/* <TableCell>
                                                    {
                                                    row.affects.length && row.affects.length>1?row.affects.map((aff)=>{
                                                        return(
                                                            <p style={{textAlign:'center',borderBottom:"1px solid #333"}}>{aff.val}</p>
                                                        )
                                                    }):<p style={{textAlign: 'center'}}>-</p>}
                                                </TableCell> */}
                                                <TableCell>
                                                    <div className={styles.blockedStatus} style={{marginBottom:"10px", textAlign: 'center'}}>{campaignPageStatus}</div>
                                                    {row?.id && <>
                                                    {row.accepted ? (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-start',cursor: "pointer"}} onClick={()=>setCampaignPageRejected(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#1f8838",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    :
                                                    (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-end',cursor: "pointer"}} onClick={()=>setCampaignPageAccepted(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#bf3626",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    }
                                                    {/* <DownloadButton name={'Change status'} buttonStyle={'Link'} action={() => handleModal('change', row?.id)} /> */}
                                                    </>
                                                    }
                                                </TableCell>
                                                {/* <TableCell>{campaignStatus}</TableCell> */}
                                                {/* <TableCell>{campaignRising}</TableCell> */}
                                                {/* <TableCell>{row?.invoiceAmount ? row?.invoiceAmount + ' €' : '-'}</TableCell> */}
                                                {/* <TableCell>
                                                    {!row.closed && !row.invoice && !row.payout &&
                                                        <DownloadButton name={t('BackOffice.Campaign.CloseCampaign')} action={() => openCampaignClosure(row)} />
                                                    }
                                                    {row.closed && row.invoice && !row.payout &&
                                                        <DownloadButton name={t('BackOffice.Campaign.MakeTransfer')} action={() => openCampaignClosure(row)} />
                                                    }
                                                    {row.closed && row.invoice && row.payout &&
                                                        'Campain Closed'
                                                    }
                                                </TableCell> */}
                                                {/* <TableCell>
                                                    {!row?.payout &&
                                                        t('BackOffice.Campaign.PaymentNoReleased')
                                                    }
                                                    {row?.payout &&
                                                        t('BackOffice.Campaign.PaymentReleased')
                                                    }
                                                </TableCell> */}
                                                 <TableCell>
                                                    <div className={styles.blockedStatus} style={{marginBottom:"10px", textAlign: 'center'}}>{!row.hiding || row.hiding === false ? "Affichée":"Cachée"}</div>
                                                    {row?.id && <>
                                                    {(row.hiding === false) ? (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-start',cursor: "pointer"}} onClick={()=>setCampaignHidden(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#1f8838",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    :
                                                    (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-end',cursor: "pointer"}} onClick={()=>setCampaignShow(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#bf3626",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    }
                                                    {/* <DownloadButton name={'Change status'} buttonStyle={'Link'} action={() => handleModal('change', row?.id)} /> */}
                                                    </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                }
                        <div className={styles.pageHead}>
                            <div className={styles.tableNavbar}>
                                <p style={{color:"#145a89", fontSize:"20px"}}>{t("BackOffice.CampagneEnCours")}</p>
                                <div className={styles.tableNavbarButtons}>
                                     <div className={styles.viewBtn} style={{marginRight:"10px"}} name={'PDF'} onClick={() => downloadPDF('associationTable')} >PDF</div>
                                    <div className={styles.viewBtn} name={'Excel'} onClick={() => downloadExcel('associationTable')} >Excel</div>
                                </div>
                            </div>
                        </div>

{
                            dataSource.length && (
                                <TableContainer component={Paper} className={styles.tableContainer} id={'associationTable2'}>
                                    <Table className={styles.table} aria-label="simple table">
                                        <TableHead className={styles.tableHead}>
                                            <TableRow>
                                                {
                                                    columnsFiltered[1].map(cel => (
                                                        <TableCell size='small'>{t(cel)}</TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={styles.tableBody}>
                                            {campaignFiltered[1].map((row) => {
                                                const campaignStartDate = new Date(row?.startingAt)
                                                const campaignEndDate = new Date(row?.endingAt)
                                                const currentDate = new Date()
                                                let campaignStatus = ''
                                                let campaignPageStatus = row?.accepted ? 'Accepted' : 'Rejected'
                                                let campaignRising = 0;

                                                if(currentDate > campaignEndDate) {
                                                    campaignStatus = t('BackOffice.Campaign.Ended')
                                                } else if(currentDate < campaignStartDate) {
                                                    campaignStatus = t('BackOffice.Campaign.Future')
                                                } else if(currentDate > campaignStartDate && currentDate < campaignEndDate) {
                                                    campaignStatus = t('BackOffice.Campaign.Progress')
                                                } else {
                                                    campaignStatus = t('BackOffice.Campaign.Ended')
                                                }

                                                row.funding?.map(rising => {
                                                    campaignRising = campaignRising + rising.amount
                                                })

                                                var assoPaired = ""
                                                if(row.pairingId!=""){
                                                    assoPaired = allAssoc.filter((asso)=>asso.association._id == row.pairingId)
                                                }

                                                return (
                                                    <TableRow
                                            
                                                key={row?.donation?.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>{CellRender(row?.associationName)}</TableCell>
                                                <TableCell>{CellRender(row?.title[0].content)}</TableCell>
                                                <TableCell>{CellRender(formatedDate(campaignStartDate))}</TableCell>
                                                <TableCell>{CellRender(formatedDate(campaignEndDate))}</TableCell>
                                                <TableCell>
                                                    
                                                    {CellRender(numberWithSpaces(row?.goal?.amount),1)}
                                                    
                                                </TableCell>
                                                <TableCell>{CellRender(numberWithSpaces(campaignRising),1)}</TableCell>
                                                <TableCell>
                                                    {row.pairingId==="" ? 
                                                    (<div style={{color:"#bf3626",textAlign:"center"}}>Non</div>)
                                                :
                                                (<div style={{textAlign: 'center'}}>{assoPaired[0].association.usageName}
                                                </div>)
                                                }
                                                    
                                                </TableCell>
                                                <TableCell className={styles.tableLink}>
                                                    <div style={{textAlign: 'center'}}>
                                                    <a className={styles.viewBtn} style={{textDecoration:"none"}} href={'/' + row?.linkParam} target="blank">
                                                        View
                                                    </a>
                                                    </div>
                                                </TableCell>
                                                {/* <TableCell>
                                                    {(row?.accepted && row?.linkParam) &&
                                                    <img style={{display: 'block', maxWidth:20, margin: '0 auto'}} src={checkSuccess} alt={''} />
                                                    }

                                                    {(!row?.accepted || !row?.linkParam) &&
                                                    <img style={{display: 'block', maxWidth:20, margin: '0 auto'}} src={checkError} alt={''} />
                                                    }
                                                </TableCell> */}
                                                {/* <TableCell>
                                                    {
                                                    row.affects.length && row.affects.length>1?row.affects.map((aff)=>{
                                                        return(
                                                            <p style={{textAlign:'center',borderBottom:"1px solid #333"}}>{aff.val}</p>
                                                        )
                                                    }):<p style={{textAlign: 'center'}}>-</p>}
                                                </TableCell> */}
                                                <TableCell>
                                                    <div className={styles.blockedStatus} style={{marginBottom:"10px", textAlign: 'center'}}>{campaignPageStatus}</div>
                                                    {row?.id && <>
                                                    {row.accepted ? (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-start',cursor: "pointer"}} onClick={()=>setCampaignPageRejected(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#1f8838",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    :
                                                    (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-end',cursor: "pointer"}} onClick={()=>setCampaignPageAccepted(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#bf3626",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    }
                                                    {/* <DownloadButton name={'Change status'} buttonStyle={'Link'} action={() => handleModal('change', row?.id)} /> */}
                                                    </>
                                                    }
                                                </TableCell>
                                                {/* <TableCell>{campaignStatus}</TableCell> */}
                                                {/* <TableCell>{campaignRising}</TableCell> */}
                                                {/* <TableCell>{row?.invoiceAmount ? row?.invoiceAmount + ' €' : '-'}</TableCell> */}
                                                {/* <TableCell>
                                                    {!row.closed && !row.invoice && !row.payout &&
                                                        <DownloadButton name={t('BackOffice.Campaign.CloseCampaign')} action={() => openCampaignClosure(row)} />
                                                    }
                                                    {row.closed && row.invoice && !row.payout &&
                                                        <DownloadButton name={t('BackOffice.Campaign.MakeTransfer')} action={() => openCampaignClosure(row)} />
                                                    }
                                                    {row.closed && row.invoice && row.payout &&
                                                        'Campain Closed'
                                                    }
                                                </TableCell> */}
                                                {/* <TableCell>
                                                    {!row?.payout &&
                                                        t('BackOffice.Campaign.PaymentNoReleased')
                                                    }
                                                    {row?.payout &&
                                                        t('BackOffice.Campaign.PaymentReleased')
                                                    }
                                                </TableCell> */}
                                                <TableCell>
                                                    <div className={styles.blockedStatus} style={{marginBottom:"10px", textAlign: 'center'}}>{!row.hiding || row.hiding === false ? "Affichée":"Cachée"}</div>
                                                    {row?.id && <>
                                                    {!row.hiding || row.hiding === false ? (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-start',cursor: "pointer"}} onClick={()=>setCampaignHidden(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#1f8838",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    :
                                                    (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-end',cursor: "pointer"}} onClick={()=>setCampaignShow(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#bf3626",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    }
                                                    {/* <DownloadButton name={'Change status'} buttonStyle={'Link'} action={() => handleModal('change', row?.id)} /> */}
                                                    </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        }
                        <div className={styles.pageHead}>
                            <div className={styles.tableNavbar}>
                                <p style={{color:"#145a89", fontSize:"20px"}}>{t("BackOffice.Campagnefinis")}</p>
                                <div className={styles.tableNavbarButtons}>
                                     <div className={styles.viewBtn} style={{marginRight:"10px"}} name={'PDF'} onClick={() => downloadPDF('associationTable')} >PDF</div>
                                    <div className={styles.viewBtn} name={'Excel'} onClick={() => downloadExcel('associationTable')} >Excel</div>
                                </div>
                            </div>
                        </div>

{
                            dataSource.length && (
                                <TableContainer component={Paper} className={styles.tableContainer} id={'associationTable3'}>
                                    <Table className={styles.table} aria-label="simple table">
                                        <TableHead className={styles.tableHead}>
                                            <TableRow>
                                                {
                                                    columnsFiltered[2].map(cel => (
                                                        <TableCell size='small'>{t(cel)}</TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={styles.tableBody}>
                                            {campaignFiltered[2].map((row) => {
                                                const campaignStartDate = new Date(row?.startingAt)
                                                const campaignEndDate = new Date(row?.endingAt)
                                                const currentDate = new Date()
                                                let campaignStatus = ''
                                                let campaignPageStatus = row?.accepted ? 'Accepted' : 'Rejected'
                                                let campaignRising = 0;

                                                if(currentDate > campaignEndDate) {
                                                    campaignStatus = t('BackOffice.Campaign.Ended')
                                                } else if(currentDate < campaignStartDate) {
                                                    campaignStatus = t('BackOffice.Campaign.Future')
                                                } else if(currentDate > campaignStartDate && currentDate < campaignEndDate) {
                                                    campaignStatus = t('BackOffice.Campaign.Progress')
                                                } else {
                                                    campaignStatus = t('BackOffice.Campaign.Ended')
                                                }

                                                row.funding?.map(rising => {
                                                    campaignRising = campaignRising + rising.amount
                                                })

                                                var assoPaired = ""
                                                if(row.pairingId!=""){
                                                    assoPaired = allAssoc.filter((asso)=>asso.association._id == row.pairingId)
                                                }
                                                return (
                                                    <TableRow
                                            
                                                key={row?.donation?.id}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>{CellRender(row?.associationName)}</TableCell>
                                                <TableCell>{CellRender(row?.title[0].content)}</TableCell>
                                                <TableCell>{CellRender(formatedDate(campaignStartDate))}</TableCell>
                                                <TableCell>{CellRender(formatedDate(campaignEndDate))}</TableCell>
                                                <TableCell>
                                                    
                                                    {CellRender(numberWithSpaces(row?.goal?.amount),1)}
                                                    
                                                </TableCell>
                                                <TableCell>{CellRender(numberWithSpaces(campaignRising),1)}</TableCell>
                                                <TableCell>
                                                    {!row.pairingId?
                                                    (<div style={{color:"#bf3626",textAlign:"center"}}>Non</div>)
                                                    :
                                                    row.pairingId && row.pairingId==="" ? 
                                                    (<div style={{color:"#bf3626",textAlign:"center"}}>Non</div>)
                                                :
                                                (<div style={{textAlign: 'center'}}>{assoPaired[0].association.usageName}
                                                </div>)
                                                }
                                                    
                                                </TableCell>
                                                <TableCell>
                                                    {!row.closed && !row.invoice && !row.payout &&
                                                    <div style={{textAlign: 'center'}}>
                                                    <div onClick={() => openCampaignClosure(row)} className={styles.viewBtn} style={{textDecoration:"none",cursor: 'pointer'}} href={'/' + row?.linkParam} target="blank">
                                                    {t('BackOffice.Campaign.CloseCampaign')}
                                                    </div>
                                                    </div>
                                                        // <DownloadButton name={t('BackOffice.Campaign.CloseCampaign')} action={() => openCampaignClosure(row)} />
                                                    }
                                                    {row.closed && row.invoice && !row.payout &&
                                                        // <DownloadButton name={t('BackOffice.Campaign.MakeTransfer')} action={() => openCampaignClosure(row)} />
                                                        <div style={{textAlign: 'center'}}>
                                                        <div onClick={() => openCampaignClosure(row)} className={styles.viewBtn} style={{textDecoration:"none",cursor: 'pointer'}} href={'/' + row?.linkParam} target="blank">
                                                        {t('BackOffice.Campaign.MakeTransfer')}
                                                        </div>
                                                        </div>
                                                    }
                                                    {row.closed && row.invoice && row.payout &&
                                                        'Campain Closed'
                                                    }
                                                </TableCell>
                                                <TableCell>{row?.invoiceAmount ? row?.invoiceAmount + ' €' : '-'}</TableCell>
                                                <TableCell className={styles.tableLink}>
                                                    <div style={{textAlign: 'center'}}>
                                                    <a className={styles.viewBtn} style={{textDecoration:"none"}} href={'/' + row?.linkParam} target="blank">
                                                        View
                                                    </a>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {!row?.payout &&
                                                        t('BackOffice.Campaign.PaymentNoReleased')
                                                    }
                                                    {row?.payout &&
                                                        t('BackOffice.Campaign.PaymentReleased')
                                                    }
                                                </TableCell>
                                                {/* <TableCell>
                                                    {(row?.accepted && row?.linkParam) &&
                                                    <img style={{display: 'block', maxWidth:20, margin: '0 auto'}} src={checkSuccess} alt={''} />
                                                    }

                                                    {(!row?.accepted || !row?.linkParam) &&
                                                    <img style={{display: 'block', maxWidth:20, margin: '0 auto'}} src={checkError} alt={''} />
                                                    }
                                                </TableCell> */}
                                                {/* <TableCell>
                                                    {
                                                    row.affects.length && row.affects.length>1?row.affects.map((aff)=>{
                                                        return(
                                                            <p style={{textAlign:'center',borderBottom:"1px solid #333"}}>{aff.val}</p>
                                                        )
                                                    }):<p style={{textAlign: 'center'}}>-</p>}
                                                </TableCell> */}
                                                <TableCell>
                                                    <div className={styles.blockedStatus} style={{marginBottom:"10px", textAlign: 'center'}}>{campaignPageStatus}</div>
                                                    {row?.id && <>
                                                    {row.accepted ? (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-start',cursor: "pointer"}} onClick={()=>setCampaignPageRejected(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#1f8838",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    :
                                                    (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-end',cursor: "pointer"}} onClick={()=>setCampaignPageAccepted(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#bf3626",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    }
                                                    {/* <DownloadButton name={'Change status'} buttonStyle={'Link'} action={() => handleModal('change', row?.id)} /> */}
                                                    </>
                                                    }
                                                </TableCell>
                                                {/* <TableCell>{campaignStatus}</TableCell> */}
                                                {/* <TableCell>{campaignRising}</TableCell> */}
                                                {/* <TableCell>{row?.invoiceAmount ? row?.invoiceAmount + ' €' : '-'}</TableCell> */}
                                                {/* <TableCell>
                                                    {!row.closed && !row.invoice && !row.payout &&
                                                        <DownloadButton name={t('BackOffice.Campaign.CloseCampaign')} action={() => openCampaignClosure(row)} />
                                                    }
                                                    {row.closed && row.invoice && !row.payout &&
                                                        <DownloadButton name={t('BackOffice.Campaign.MakeTransfer')} action={() => openCampaignClosure(row)} />
                                                    }
                                                    {row.closed && row.invoice && row.payout &&
                                                        'Campain Closed'
                                                    }
                                                </TableCell> */}
                                                {/* <TableCell>
                                                    {!row?.payout &&
                                                        t('BackOffice.Campaign.PaymentNoReleased')
                                                    }
                                                    {row?.payout &&
                                                        t('BackOffice.Campaign.PaymentReleased')
                                                    }
                                                </TableCell> */}
                                                <TableCell>
                                                    <div className={styles.blockedStatus} style={{marginBottom:"10px", textAlign: 'center'}}>{!row.hiding || row.hiding === false ? "Affichée":"Cachée"}</div>
                                                    {row?.id && <>
                                                    {!row.hiding || row.hiding === false ? (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-start',cursor: "pointer"}} onClick={()=>setCampaignHidden(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#1f8838",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    :
                                                    (<>
                                                        <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-end',cursor: "pointer"}} onClick={()=>setCampaignShow(row?.id)}>
                                                        <div style={{width: '40px',height:"30.5px",backgroundColor:"#bf3626",borderRadius:"50px"}} ></div>
                                                        </div>
                                                    </>)
                                                    }
                                                    {/* <DownloadButton name={'Change status'} buttonStyle={'Link'} action={() => handleModal('change', row?.id)} /> */}
                                                    </>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        }
                    </div>

                    {showModal && modalStatus === 'change' &&
                        <Modal modalContent={modalContentChange(currentAssociationId)} modalClose={() => setModalShow(false)} />
                    }
                </>
            }

            { campaignPage === 2 &&
                <>
                    <DownloadButton name={t('Back')} action={() => setCampaignPage(1)} />
                    <CampaignClosing campaign={campaign} />
                </>
            }
        </>
    )

}

export default Campaigns;