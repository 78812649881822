import React from 'react'
import logo from '../assets/website_logo.svg'
import {Container,Nav} from 'react-bootstrap'
const Navbar = () => {
    return (
        <div>
             <Navbar collapseOnSelect expand="lg">
        <Container>
          <Navbar.Brand href="#home">{logo}</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Link href="#deets">Acceuil</Nav.Link>
              <Nav.Link href="#deets">Campagnes</Nav.Link>
              <Nav.Link href="#deets">Vos dons</Nav.Link>
              <Nav.Link href="#deets">Associations</Nav.Link>
              <Nav.Link href="#deets">Contactez-nous</Nav.Link>
              <Nav.Link href="#deets">Connexion</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        </div>
    )
}

export default Navbar

