import { useEffect, useState } from "react";
import "./QrCode.css";
import roseQr from "../../assets/New/roseQr.png";

function QrCode(props) {
  const [temp, setTemp] = useState("");
  const [word, setWord] = useState(props.url);
  const [size, setSize] = useState(400);
  const [bgColor, setBgColor] = useState("ffffff");
  const [qrCode, setQrCode] = useState("");

  // Changing the URL only when the user
  // changes the input
  useEffect(() => {
    setQrCode(
      `https://api.qrserver.com/v1/create-qr-code/?data=${word}?&size=${size}x${size}&bgcolor=${bgColor}`
    );
  }, [word, size, bgColor]);

  // Updating the input word when user
  // click on the generate button
  function handleClick() {
    setWord(temp);
  }

  const download = async () => {
    // const originalImage={qrCode};
    const image = await fetch(qrCode);

    //Split image name
    const nameSplit = qrCode.split("/");
    // const  duplicateName=nameSplit.pop();
    const duplicateName = "CharityClic";

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="App">
      {/* <h1>QR Code Generator</h1>
	<div className="input-box">
		<div className="gen">
		<input type="text" onChange={
			(e) => {setTemp(e.target.value)}}
			placeholder="Enter text to encode" />
		<button className="button"
			onClick={handleClick}>
			Generate
		</button>
		</div>
		<div className="extra">
		<h5>Background Color:</h5>
		<input type="color" onChange={(e) =>
		{ setBgColor(e.target.value.substring(1)) }} />
		<h5>Dimension:</h5>
		<input type="range" min="200" max="600"
		value={size} onChange={(e) =>
		{setSize(e.target.value)}} />
		</div>
	</div>
	<div className="output-box">
		<img src={qrCode} alt="" /> */}
      {/* <a href={qrCode} download> */}
      <button id="btnQr" onClick={download} style={{ marginTop: "-5px" }}>
        <img src={roseQr} width="32" height="32" />
      </button>
      {/* </a> */}
      {/* </div> */}
    </div>
  );
}

export default QrCode;
