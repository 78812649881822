import styles from "./index.css";
import { useRef, useState, useEffect, useContext } from "react";
import dynamicLoader from "react-dynamic-loader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
export default function AssocBilletterie({
    title,
    desc,
    img,
    campainName,
    mobile,
    // customStyle = {},
    customWidth
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorTemp,setEditorTemp] = useState("")
  const[readMore,setReadMore] = useState(false)
  const { t } = useTranslation();
  useEffect(() => {
    try {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(desc)))
      );
    } catch (error) {}
  }, []);
  useEffect(()=>{
    // console.log(customWidth);
    if(editorState){
      const ediTemp = editorState.getCurrentContent().getPlainText('\u0001').split(" ")
      const arrTemp = []
      // console.log(ediTemp);
      for(let i=0;i<50;i++){
        arrTemp.push(ediTemp[i])
      }
      var str = (arrTemp.join(" "));
      setEditorTemp(str+"...");
    }

  },[editorState])
  return (
    <div
      // style={{...customStyle}}
      style={{width:"90%", margin:"30px auto"}}
      className={"assoc-comp-container"}
    >
      <div className="assoc-comp-content">
        <div style={{display: "flex", alignItems: "center"}}>
          {mobile === false && <div>
            <img
              style={{
                width: "15em",
                height: "15em",
                borderRadius: 40,
                objectFit: "cover",
                marginLeft: "20px",
                marginRight: "20px",
              }}
              src={img}
            />
          </div>}
          <div style={{width: '100%',display: 'flex',flexDirection: 'column'}}>
            <div
              className={"assoc-comp-content-title-campName"}
              style={{ marginBottom: "-15px" }}
            >
              <div style={{textAlign: "left"}}>{campainName}</div>
            </div>
            <div className="assoc-comp-container">
              <div
                style={{ marginTop: 10, textAlign: "left" }}
                className="assoc-comp-content-title"
                
              >
                {title}
              </div>
              {/* <div>
                    <img style={{width: '4em', height: '4em', borderRadius: 40, objectFit: 'cover', marginLeft:"20px"}} src={img}/>
                </div> */}
            </div>
            <div className="assoc-comp-content-desc">
        {readMore == true ?<div className={"assocContainer-desc-billetterie"}>
                {/* {campain.content[0].content} */}
                <Editor readOnly toolbarHidden editorState={editorState} />
                <div onClick={()=>setReadMore(false)} style={{color:"#d12164", cursor: "pointer",fontSize:"20px", fontWeight:600, marginBottom:"40px", textAlign:"justify"}} >{t("Campain.close")}</div>
              </div>:
              <div className={"assocContainer-desc-billetterie"} style={{fontSize:"20px", fontWeight:600, marginBottom:"40px", textAlign: "justify", marginTop:"0px" }}>
                <div>{editorTemp}&nbsp; <span onClick={()=>setReadMore(true)} style={{color:"#d12164", cursor: "pointer"}}>{t("Campain.readMore")}</span></div>
                
              </div>}
        </div>
          </div>
        </div>

       
      </div>
    </div>
  );
}
