import styles from './index.css';
import { useState, useContext, useEffect } from 'react';
import { UserContext } from "../../Context/UserContext";
import { APIURL } from '../../Services/consts';
import { store } from 'react-notifications-component';
import {
    useParams
  } from "react-router-dom";
import { resetNewPassword } from '../../Services/ws';

export default function ResetPassword({history, navigation}) {
    var [password, setPassword] = useState("")
    var [passwordConf, setPasswordConf] = useState("")
    const [userState, setUserState] = useContext(UserContext);
    var { token } = useParams();

    return <div className={'loginContainer'}>
        <div className={'login-form'}>
        <div className={'login-inputCol'}>
            <input autocomplete="new-password" placeholder={'Nouveau mot de passe'} type="password" className={'login-bigInput'} value={password} onChange={(evt) => setPassword(evt.target.value)}></input>
            <input  autocomplete="new-password" placeholder={'Confirmation du mot de passe'} type="password" className={'login-bigInput'} value={passwordConf} onChange={(evt) => setPasswordConf(evt.target.value)}></input>
        </div>
        <div onClick={async() => {
            //console.log(history);
            //console.log(token);
            if (password.length < 8) {
                store.addNotification({
                    title: "Erreur",
                    message: "Mot de passe trop faible",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                  return ;
                }
            if (password != passwordConf) {
                store.addNotification({
                    title: "Erreur",
                    message: "Mot de passe différents",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
                  return ;
            }
            try {
                var result = await resetNewPassword(password, token)
                //console.log(result);
                store.addNotification({
                    title: "Confirmation",
                    message: "Mot de passe modifié avec succés",
                    type: "success",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
            } catch (error) {
                store.addNotification({
                    title: "Erreur",
                    message: "Mot de passe trop faible",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  });
            }
        
            history.push('/login')
        }} className={'login-signupBtn'}>Réinitialiser</div>

        </div>
    </div>
}
