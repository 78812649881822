import React, {useState, createRef, useEffect} from 'react';
import styles from './index.css';
import add from '../../assets/add.png'
import { createBilletterie, updateBilletterie, publishBilletterie, getLinkAvailability, getBackOffice } from '../../Services/ws';
import {uploadFile} from '../../Services/s3'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Store } from 'react-notifications-component';
import Modal from 'react-modal';
import dynamicLoader from 'react-dynamic-loader'
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import Loader from "react-loader-spinner";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from 'uuid'
import Select from 'react-select'
import Autosuggest from 'react-autosuggest';


const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      borderRadius: 20,
      zInd1ex: 20
    }
  };
Modal.setAppElement('#root')
export default function CreateBilletterie({campain, setPart, userState, setUserState}) {
    const [url, setUrl] = useState(campain ? campain.linkParam : '');
    const [campainName, setCampainName] = useState(campain ? campain.associationName : userState.association.usageName);
    const [campTitle, setCampTitle] = useState(campain ? campain.title[0].content : '');
    const [description, setDescription] = useState(campain ? campain.description[0].content : '');
    const [article, setArticle] = useState(campain ? campain.content[0].content : '');
    const [startDate, setStartDate] = useState(campain ? new Date(campain.startingAt) : '');
    const [endDate, setEndDate] = useState(campain ? new Date(campain.endingAt) : '');
    const inputOpenFileRef = createRef();
    const [pic, setPic] = useState(campain ? campain.poster[0].url : null);
    const [logo, setLogo] = useState(campain ? campain.associationLogoUrl : userState.association.logoUrl);
    const [sharingImage, setSharingImage] = useState(campain ? campain.sharingImage : null);
    const [videos, setVideos] = useState([]);
    const [pictures, setPictures] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPics, setLoadingPics] = useState(false);
    const [loadingAffiche, setLoadingAffiche] = useState(false);
    const [loadingSharingImage, setLoadingSharingImage] = useState(false);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [loadingVideos, setLoadingVideos] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [descEditorState, setDescEditorState] = useState(EditorState.createEmpty());
    const inputOpenFileRefLogo = createRef();
    const inputOpenFileRefPic = createRef();
    const inputOpenFileRefSharingImage = createRef();
    const inputOpenFileRefPhotos = createRef();
    const inputOpenFileRefVideos = createRef();
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [categoryBillet,setCategoryBillet] = useState("")
    const [prixBillet,setPrixBillet] = useState("")
    const [nbrPlacesBillet,setNbrPlacesBillet] = useState("")
    const [place,setPlace] = useState("")
    const [dateEvent,setDateEvent] = useState(null)
    const [errorBillet,setErrorBillet] = useState(null)
    const [billets,setBillets] = useState([])
    const [nomLieu,setNomLieu] = useState("")
    const [adresse,setAdresse] = useState("")
    const [codePostal,setCodePostal] = useState("")
    const [checkPairCampaign,setCheckPairCampaign] = useState(false)
    const [idCampaignPaired,setIdCampaignPaired] = useState("")
    const [allActiveCampaign,setAllActiveCampaign] = useState([])
    const { t } = useTranslation()

    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
    }
  
    function closeModal(){
      setIsOpen(false);
    }
    useEffect(() => {
        if (userState.collect) {
            // var edState = 
            setDescEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(userState.collect.description[0].content))))
            setDescription(userState.collect.description[0].content)

        }
        if (campain) {
            setPictures( campain.medias.filter((el) => el.type == 'PICTURE').map((el) => el.url))
            setVideos( campain.medias.filter((el) => el.type == 'VIDEO').map((el) => el.url))
            try {
                setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(campain.content[0].content))))
                setDescEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(campain.description[0].content))))
            } catch (error) {

                
            }
            if(campain.tickets){
                setBillets([...campain.tickets])
                setPlace(campain.tickets[0].place)
                setDateEvent(new Date(campain.tickets[0].dateEvent))
                // setErrorBillet()
                // setBillets()
                setNomLieu(campain.tickets[0].nomLieu)
                setAdresse(campain.tickets[0].adresse)
                setCodePostal(campain.tickets[0].codePostal)
                if(campain.idCampaignPaired !== ""){
                    setCheckPairCampaign(true)
                    setIdCampaignPaired(campain.idCampaignPaired)
                }
                
            };
        }
        
    }, [])

    useEffect(()=>{
        const getAllCamp = async() =>{

            const allCampaign = await getBackOffice(`/campaign/all-active-campaigns-assoc/${userState.association.id}`)
            allCampaign.unshift({_id:"-",title:"-"})
            setAllActiveCampaign(allCampaign);
        }
        getAllCamp()
    },[userState])

    const handleCheckPairing = (checkPairCampaign)=>{
        setCheckPairCampaign(!checkPairCampaign)
        if(!checkPairCampaign){
            setIdCampaignPaired("")
        }
    }
    console.log(idCampaignPaired);
    const resetFields = () => {
        setUrl('')
        setCampainName('')
        setCampTitle('')
        setDescription('')
        setArticle('')
        setStartDate('')
        setEndDate('')
        setPic(null)
        setSharingImage(null)
        setLogo(null)
        setVideos([])
        setPictures([])      
    }

    const handleAddBillet = ()=> {
        const currency = "eur"
        setErrorBillet(null);
        if(!prixBillet || !nbrPlacesBillet || !categoryBillet){
           return setErrorBillet("Veuillez remplir tous les champs")
        }
        setBillets([...billets,{id:uuidv4(),categoryBillet,prixBillet,nbrPlacesBillet,nbrPlacesBilletRestant:nbrPlacesBillet, place,dateEvent,currency,nomLieu,adresse,codePostal}])
        setPrixBillet("");
        setNbrPlacesBillet("")
        setCategoryBillet("")
    }

    const handleRemove = (id) => {
        setBillets(billets.filter((bi)=>{return bi.id!==id}))
    }


    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        setArticle(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    };
    
    const onDescEditorStateChange = (editorState) => {
        setDescEditorState(editorState)
        setDescription(JSON.stringify(convertToRaw(editorState.getCurrentContent())))
    };

    return (
        <div>
            <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            >

            <div className="dashFormTitle">{t("Dashboard.CreateCampaign.ConfirmChanges")}</div>

                <div style={{marginRight: 0}} className="smllRow">
                    <div className={'smbtn btnGeneral'} onClick={async () => {
                      var errorFields = []
                      !description && errorFields.push(`Description`)
                      !article && errorFields.push(`Article`)
                      !url && errorFields.push(`URL`)
                      !logo && errorFields.push(`Logo`)
                      !sharingImage && errorFields.push(t("Dashboard.CreateCampaign.ErrorPoster"))
                      !pic && errorFields.push(t("Dashboard.CreateCampaign.ErrorPoster"))
                      !campTitle && errorFields.push(t("Dashboard.CreateCampaign.ErrorPageTitle"))
                      !startDate && errorFields.push(t("Dashboard.CreateCampaign.ErrorStartDate"))
                      !endDate && errorFields.push(t("Dashboard.CreateCampaign.ErrorEndDate"))
                    //   !obj && errorFields.push(t("Dashboard.CreateCampaign.ErrorGoal"))
                      !campainName && errorFields.push("Dashboard.CreateCampaign.ErrorName")

                      if (errorFields.length > 0) {
                          Store.addNotification({
                              title: t("SingIn.Errors"),
                              message: t("Dashboard.CharityPage.FillFields") + errorFields.join(', '),
                              type: "danger",
                              insert: "top",
                              container: "top-center",
                              animationIn: ["animate__animated", "animate__fadeIn"],
                              animationOut: ["animate__animated", "animate__fadeOut"],
                              dismiss: {
                                duration: 10000,
                                onScreen: true
                              }
                            });
                      }
                      else {
                          if (loading == true) return ;
                          setLoading(true)
                          if (campain) {
                              if (url != campain.linkParam) {
                                  var available = await getLinkAvailability(url)
                                  if (!available.available) {
                                      Store.addNotification({
                                          title: t("SingIn.Errors"),
                                          message: t("SingIn.Errors.AssociationLinkError"),
                                          type: "danger",
                                          insert: "top",
                                          container: "top-center",
                                          animationIn: ["animate__animated", "animate__fadeIn"],
                                          animationOut: ["animate__animated", "animate__fadeOut"],
                                          dismiss: {
                                          duration: 10000,
                                          onScreen: true
                                          }
                                      });
                                      setLoading(false)
                                      return;
                                  }
                              }
                              try {
                                  var res = await updateBilletterie(campain.id, pic, sharingImage, campTitle, description, article, pictures, startDate, endDate, url, campainName, logo, videos,billets,userState.association.id, idCampaignPaired)
                                  var newCamp = []
                                  for (let index = 0; index < userState.campains.length; index++) {
                                      const element = userState.campains[index];
                                      if (res.id == element.id) {
                                          newCamp.push(res)
                                      } else {
                                        newCamp.push(element)
                                      }
                                      setUserState({...userState, campains: newCamp})
                                  }
                                  setPart(0)
                                  window.scrollTo(0, 0)

                                  Store.addNotification({
                                      title: t("Dashboard.CharityPage.Success"),
                                      message: t("Dashboard.CreateCampaign.UpdateCampaign"),
                                      type: "success",
                                      insert: "top",
                                      container: "top-center",
                                      animationIn: ["animate__animated", "animate__fadeIn"],
                                      animationOut: ["animate__animated", "animate__fadeOut"],
                                      dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                      }
                                    });
                                    setLoading(false)
                                    resetFields();
                              } catch (error) {
                                setLoading(false)

                              }
                          } else {
                              var available = await getLinkAvailability(url)
                              if (!available.available) {
                                  Store.addNotification({
                                      title: t("SingIn.Errors"),
                                      message: t("SingIn.Errors.AssociationLinkError"),
                                      type: "danger",
                                      insert: "top",
                                      container: "top-center",
                                      animationIn: ["animate__animated", "animate__fadeIn"],
                                      animationOut: ["animate__animated", "animate__fadeOut"],
                                      dismiss: {
                                      duration: 10000,
                                      onScreen: true
                                      }
                                  });
                                  setLoading(false)
                                  return;
                              }
                              try {
                        var res = await createBilletterie(pic, sharingImage, campTitle, description, article, pictures, startDate, endDate, url, campainName, logo, videos,billets,idCampaignPaired)
                                  if (userState.campains) {
                                      userState.campains.push(res)
                                  } else {
                                    userState.campains = [res]
                                  }
                                  setUserState({...userState})
                                  publishBilletterie(res.id)
                                  setPart(0)
                                  setLoading(false)

                                  window.scrollTo(0, 0)
                                  Store.addNotification({
                                      title: t("Dashboard.CharityPage.Success"),
                                      message: t("Dashboard.CreateCampaign.UpdateCampaign"),
                                      type: "success",
                                      insert: "top",
                                      container: "top-center",
                                      animationIn: ["animate__animated", "animate__fadeIn"],
                                      animationOut: ["animate__animated", "animate__fadeOut"],
                                      dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                      }
                                    });
                                    resetFields();
                              } catch (error) {
                                setLoading(false)

                            }
                      }
                  }
                    }}>{t("Dashboard.LogoutConfirm")}</div>
                    <div onClick={async () => {setIsOpen(false)}} className={'smbtn btnGeneral'}>{t("Dashboard.LogoutCancel")}</div>
                </div>
            </Modal>
            <p className="dashTitle">{t("Dashboard.CreateBilletterie.BilletterieCreation")}</p>
            <div className="dashInput2-new">
                <p className="dashFormTitle">{t("Dashboard.CreateBilletterie.InformationAbout")}</p>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.OfficialName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput-new" type="text" placeholder={t("Dashboard.CreateCampaign.OfficialNamePlaceholder")} value={campainName} onChange={(evt) => {setCampainName(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.ModifyLogo")}</p>
                    </div>
                    {loadingLogo ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefLogo?.current.click()}} />
                            )}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingLogo(true)
                            var fileUrl = await uploadFile(e.target.files[0])
                            setLogo(fileUrl)
                            setLoadingLogo(false)
                        }
                    }} type="file"  accept="image/*" ref={inputOpenFileRefLogo} style={{ display: 'none' }}/>
                    {logo && 
                        <img className="filePreview" src={logo}></img>
                    }
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.InsertPoster")}</p>
                    </div>
                    {loadingAffiche ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefPic?.current.click()}} />
                            )}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingAffiche(true)
                            try {
                                var fileUrl = await uploadFile(e.target.files[0])
                                setPic(fileUrl)
                            } catch (error) {
                                Store.addNotification({
                                    title: t("SingIn.Errors"),
                                    message: t("Dashboard.CharityPage.DownloadFailed"),
                                    type: "danger",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                    }
                                    });
                            }
                            setLoadingAffiche(false)
                        }
                    }} type="file"  accept="image/*, video/mp4" ref={inputOpenFileRefPic} style={{ display: 'none' }}/>
                    {pic &&
                        pic.indexOf('.mp4') > 0 ?
                            <video controls className="picturePreview" src={pic}></video> :
                            <img className="picturePreview" src={pic}></img>
                    }
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.InsertSharingImage")}</p>
                    </div>
                    {loadingSharingImage ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                                type="ThreeDots"
                                color="#507cab"
                                height={10}
                                width={30}/>
                        </div>
                    ) :(
                        <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefSharingImage?.current.click()}} />
                    )}
                    <input onChange={async (e) => {
                        if (e.target.files[0]) {
                            setLoadingSharingImage(true)
                            try {
                                var fileUrl = await uploadFile(e.target.files[0])
                                setSharingImage(fileUrl)
                            } catch (error) {
                                Store.addNotification({
                                    title: t("SingIn.Errors"),
                                    message: t("Dashboard.CharityPage.DownloadFailed"),
                                    type: "danger",
                                    insert: "top",
                                    container: "top-center",
                                    animationIn: ["animate__animated", "animate__fadeIn"],
                                    animationOut: ["animate__animated", "animate__fadeOut"],
                                    dismiss: {
                                        duration: 10000,
                                        onScreen: true
                                    }
                                });
                            }
                            setLoadingSharingImage(false)
                        }
                    }} type="file"  accept="image/*" ref={inputOpenFileRefSharingImage} style={{ display: 'none' }}/>
                    {sharingImage &&
                        <img className="picturePreview" src={sharingImage}></img>
                    }
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateBilletterie.BilletterieTitle")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput-new" type="text" placeholder={t("Dashboard.CreateBilletterie.BilletterieTitlePlaceholder")} value={campTitle} onChange={(evt) => {
                            setCampTitle(evt.target.value)
                            var link = evt.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "")
                            link = link.replace(' ', '-').replace(/ /g, '-');
                            setUrl(link)
                            }} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateBilletterie.BilletterieURL")}</p>
                    </div>
                    <div className="dashColInput">
                    <input className="dashInput-new" type="text" value={"https://charityclic.com/" + url}  placeholder={t("Dashboard.CreateCampaign.CampaignURLPlaceholder")} onChange={(evt) => {
                            var val = evt.target.value.slice('https://charityclic.com/'.length, evt.target.value.length)
                            
                            setUrl(val)

                            }} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateBilletterie.BilletterieStart")}</p>
                    </div>
                    <div className="dashColInput">
                        <DatePicker showTimeSelect dateFormat="dd/MM/yyyy HH:mm" timeFormat="HH:mm" className="dashInput-new" selected={startDate}  onChange={(date) => {setStartDate(date)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateBilletterie.BilletterieEnd")}</p>
                    </div>
                    <div className="dashColInput">
                    <DatePicker showTimeSelect dateFormat="dd/MM/yyyy HH:mm" timeFormat="HH:mm" className="dashInput-new" selected={endDate}  onChange={(date) => {setEndDate(date)}} />
                    </div>
                </div>
                <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.Lieu")}</p>
                        </div>
                        <div className="dashColInput">
                            <input 
                            className="dashInput-new" 
                            type="text" 
                            placeholder={t("Ex: Grand Rex")} 
                            value={nomLieu} 
                            onChange={(e) => {setNomLieu(e.target.value)}} 
                            />
                        </div>   
                    </div>
                <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.Address")}</p>
                        </div>
                        <div className="dashColInput">
                            <input 
                            className="dashInput-new" 
                            type="text" 
                            placeholder={t("BackOffice.Donation.Address")} 
                            value={adresse} 
                            onChange={(e) => {setAdresse(e.target.value)}} 
                            />
                        </div>   
                    </div>
                <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.Zip")}</p>
                        </div>
                        <div className="dashColInput">
                            <input 
                            className="dashInput-new" 
                            type="number" 
                            placeholder={t("Dashboard.Donation.ErrorZipCode")} 
                            value={codePostal} 
                            onChange={(e) => {setCodePostal(e.target.value)}} 
                            />
                        </div>   
                    </div>
                <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.City")}</p>
                        </div>
                        <div className="dashColInput">
                            <input 
                            className="dashInput-new" 
                            type="text" 
                            placeholder={t("BackOffice.Donation.City")} 
                            value={place} 
                            onChange={(e) => {setPlace(e.target.value)}} 
                            />
                        </div>   
                    </div>
                    <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Billetterie.Placeholder.Date")}</p>
                    </div>
                    <div className="dashColInput">
                        <DatePicker showTimeSelect dateFormat="dd/MM/yyyy HH:mm" timeFormat="HH:mm" className="dashInput-new" selected={dateEvent}  onChange={(date) => {setDateEvent(date)}} />
                    </div>
                </div>
                {billets.length>0 && <h2>{t("Billetterie.Placeholder.ActualTickets")}</h2>}
                {billets && billets.map((billet,index)=>{
                    return <div key={index} className="billetAdd" style={{textAlign:'center'}}>
                        <h4>Billet #{index+1}</h4>
                    <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.CategoryTickets")}</p>
                        </div>
                        <div className="dashColInput">
                            <div className="dashInput-new">{billet.categoryBillet}</div>
                        </div>   
                    </div>
                    <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.PriceTickets")}</p>
                        </div>
                        <div className="dashColInput">
                            <div className="dashInput-new">{billet.prixBillet}</div>
                        </div>   
                    </div>
                    <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.NbTickets")}</p>
                        </div>
                        <div className="dashColInput">
                            <div className="dashInput-new">{billet.nbrPlacesBillet}</div>
                        </div>   
                    </div>
                    {/* <img src={add} onClick={()=>handleRemove(billet.id)} alt="add" style={{width:"16px",height:"16px",padding: "10px",width: "16px",height: "16px",background: "#ffffff 0% 0% no-repeat padding-box",boxShadow: "0px 3px 6px #00000029",borderRadius: "24px",cursor: "pointer", transform:'rotate(45deg)'}} /> */}
                    <div className="newBtnNext2" onClick={()=>handleRemove(billet.id)} style={{margin:"auto"}}>{t("delete")}</div>
                    </div>
                })}
                <div className="billetAdd" style={{textAlign:'center'}}>
                        <h2>{t("Billetterie.Placeholder.AddCategory")}</h2>
                    <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.CategoryTickets")}</p>
                        </div>
                        <div className="dashColInput">
                            <input 
                            className="dashInput-new" 
                            type="text" 
                            placeholder="Ex: Premium" 
                            value={categoryBillet} 
                            onChange={(e) => {setCategoryBillet(e.target.value)}} 
                            />
                        </div>   
                    </div>
                    <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.PriceTickets")}</p>
                        </div>
                        <div className="dashColInput">
                            <input 
                            className="dashInput-new" 
                            type="number" 
                            placeholder="Ex: 150 euros" 
                            value={prixBillet} 
                            onChange={(e) => {setPrixBillet(e.target.value)}} 
                            />
                        </div>   
                    </div>
                    <div className="dashRow">
                        <div className="dashCol">
                            {/* <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignGoal")}</p> */}
                            <p className="dashLabel">{t("Billetterie.Placeholder.NbTickets")}</p>
                        </div>
                        <div className="dashColInput">
                            <input 
                            className="dashInput-new" 
                            type="number" 
                            placeholder="Ex: 10" 
                            value={nbrPlacesBillet} 
                            onChange={(e) => {setNbrPlacesBillet(e.target.value)}} 
                            />
                        </div>   
                    </div>
                    {/* <img src={add} onClick={handleAddBillet} alt="add" style={{width:"16px",height:"16px",padding: "10px",paddingLeft: "25px",paddingRight: "25px",width: "16px",height: "16px",background: "#ffffff 0% 0% no-repeat padding-box",boxShadow: "0px 3px 6px #00000029",borderRadius: "24px",marginRight: "20px",marginTop: "-20px",cursor: "pointer"}} /> */}
                    <div className="newBtnNext2" onClick={handleAddBillet} style={{margin:"auto"}}>{t("add")}</div>
                    {errorBillet && <p style={{color:"red"}}>{errorBillet}</p>}
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CharityPage.BilletterieArticle")}</p>
                        {/* <p className="smallTxt">{t("Dashboard.CreateCampaign.CampaignExplanation")}</p> */}
                    </div>
                    <div className="dashColInput">
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="dashInput-new"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                        />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignPhotos")}</p>
                    </div>
                    {loadingPics ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                        <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefPhotos?.current.click()}} />
                        )}
                    <input multiple onChange={async (e) => {
                     if (e.target.files[0]) {
                        const newPics =[...pictures]
                        setLoadingPics(true)
                        for (let i = 0; i < e.target.files.length; i++) {
                            const element = e.target.files[i];
                            var fileUrl = await uploadFile(element)
                            newPics.push(fileUrl)
                            setPictures(newPics)
                        }
                        setLoadingPics(false)
                    }
                    }} type="file" accept="image/*" ref={inputOpenFileRefPhotos} style={{ display: 'none' }}/>
                    <div style={{maxWidth: '55%'}} className="row">
                    {pictures.map((picture, idx) =>
                            <div>
                                <img className="picturePreview" src={picture}></img>
                                <div onClick={() => {
                                    const newPics =[...pictures]
                                    newPics.splice(idx, 1)
                                    setPictures(newPics)
                                }} className={'delBtn'}>{t("Dashboard.CharityPage.PhotosDelete")}</div>
                            </div>
                        )}
                    </div>

                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.CreateCampaign.CampaignVideos")}</p>
                    </div>
                    {loadingVideos ? (
                        <div style={{paddingLeft: 0, paddingRight: 0, width: 87, textAlign: 'center'}} className="dashAdd" >
                            <Loader
                            type="ThreeDots"
                            color="#507cab"
                            height={10}
                            width={30}/>
                        </div>
                        ) :(
                            <img className="dashAdd" src={add} alt="add" onClick={() => {inputOpenFileRefVideos?.current.click()}} />
                            )}
                    <input multiple type="file" onChange={async (e) => {
                        
                        if (e.target.files[0]) {
                            setLoadingVideos(true)
                            const newVids =[...videos]
                            for (let i = 0; i < e.target.files.length; i++) {
                                const element = e.target.files[i];
                                try {
                                    var fileUrl = await uploadFile(element)
                                    newVids.push(fileUrl)
                                    setVideos(newVids)
                                } catch (error) {
                                    Store.addNotification({
                                        title: t("SingIn.Errors"),
                                        message: t("Dashboard.CharityPage.DownloadFailed"),
                                        type: "danger",
                                        insert: "top",
                                        container: "top-center",
                                        animationIn: ["animate__animated", "animate__fadeIn"],
                                        animationOut: ["animate__animated", "animate__fadeOut"],
                                        dismiss: {
                                            duration: 10000,
                                            onScreen: true
                                        }
                                        });                                 
                                }
                            }
                            setLoadingVideos(false)
                        }
                    }} accept="video/mp4" ref={inputOpenFileRefVideos} style={{ display: 'none' }}/>
                    <div style={{maxWidth: '55%'}} className="row">
                        {videos.map((video, idx) =>
                            <div>
                            <video controls className="videoPreview"  src={video} />
                            <div onClick={() => {
                                const newVids =[...videos]
                                newVids.splice(idx, 1)
                                setVideos(newVids)
                            }} className={'delBtn'}>{t("Dashboard.CharityPage.PhotosDelete")}</div>
                        </div>
                        )}
                    </div>
                </div>
                <div className="dashRow" style={{display: 'flex', alignItems: 'center'}}>
                    <div className="dashCol">
                        <p className="dashLabel">{t("Billetterie.Create.LinkToCampaign")}</p>
                    </div>
                    <div className="dashColInput">
                        <input 
                        onChange={()=>handleCheckPairing(checkPairCampaign)} 
                        type="checkbox" id="checkPairing" style={{width: '18px',height: '18px',position: 'relative'}}/>
                        
                    </div>
                </div>
                {checkPairCampaign && (
                <div className="dashRow" style={{display: 'flex', alignItems: 'center'}}>
                    <div className="dashCol">
                        <p className="dashLabel">{t("Billetterie.Create.ChooseCampaign")}</p>
                    </div>
                    <div className="dashColInput">
                    <select
                className="dashInput-new"
                value={idCampaignPaired}
                // placeholder="EUR €"
                onChange={(e) => setIdCampaignPaired(e.target.value)}

              >
                {allActiveCampaign.map((opt, index) => {
                    if(index === 0){
                        return(
                            <option key={index} value={opt._id}>{opt.title}</option>
                        )
                    }else{

                        return (
                            <option key={index} value={opt._id}>{opt.title[0].content}</option>
                            // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                            );
                        }
                })}
              </select>
                    </div>
                </div>
                )}
                <div className="dashBtns">
                    <div onClick={async () => {
                       openModal()
                    }} className={'newBtnNext2'}>{t("Dashboard.CharityPage.Enter")}</div>
                    <div className={'newBtnNext2'} onClick={() => {
                        var medias = []
                        for (let i = 0; i < pictures.length; i++) {
                            const element = pictures[i];
                            medias.push({type: 'PICTURE', url: element})
                        }
                        for (let i = 0; i < videos.length; i++) {
                            const element = videos[i];
                            medias.push({type: 'VIDEO', url: element})
                        }
                        var assoc = {
                            // accepted: draft.acceptedVerif,
                            tickets:billets,
                            accepted: true,
                            closed: false,
                            associationLogoUrl: logo,
                            associationName: campainName,
                            sharingImage: sharingImage,
                            content: [{content: article}],
                            description: [{content: description}],
                            funding: [],
                            linkParam: "testassoc",
                            medias: medias ? medias : [],
                            poster: [{url: pic, type: pic && pic.indexOf('.mp4') > 0 ? 'VIDEO' : 'PICTURE'}],
                            published: true,
                            sponsors: [],
                            title: [{content: campTitle}],
                              endingAt: endDate,
                            association: {
                                block: false,
                            }
                        }
                        setUserState({...userState, campainVisualize:assoc})

                        window.open('/ticketvisualize', '_blank').focus();
                    //    history.push('/association/visualize?assoc=' + JSON.stringify(assoc))
                    }}>{t("Dashboard.CharityPage.Visualize")}</div>
                </div>
            </div>
        </div>
    )
}
