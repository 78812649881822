import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import {useEffect, useState} from "react";
import {
    getBackOffice,
    blockAssociation,
    unblockAssociation,
    acceptDonationPage,
    rejectDonationPage,
    getAllPersons,
    retrieveFile,
} from "../../Services/ws";
import { downloadExcel, downloadPDF, formatedDate, numberWithSpaces } from "../../Services/utils";
import DownloadButton from '../../Components/Elements/DownloadButton/DownloadButton'
import Search from "../../Components/Elements/Search/Search"
import Modal from "../../Components/Elements/Modal/Modal"
import { useTranslation } from "react-i18next";
import checkSuccess from  '../../assets/check.png';
import checkError from '../../assets/close.png'

import styles from './Association.module.css'


const Donations = () => {

  const [dataSource, setDataSource] = useState([])
  const [search, setSearch] = useState('')
  const [currentData, setCurrentData] = useState([])
  const [showModal, setModalShow] = useState(false)
  const [showManagersCardModal, setShowManagersCardModal] = useState(false)
  const [modalStatus, setModalStatus] = useState('')
  const [currentAssociationId, setAssociationId] = useState('')
  const [allDonations, setAllDonations] = useState([])
  const { t } = useTranslation()

  const getData = async () => {
    const data = await getBackOffice('/association/back-office')
    setDataSource(data)
    setCurrentData(data)
  }

  const handleSearch = (event) => {
    setSearch(event.target.value.toLowerCase());
    if(search.length > 1) {
      const result = currentData.filter(item => item.association.usageName.toLowerCase().includes(search))
      setDataSource(result)
    } else {
      setDataSource(currentData)
    }
  };

  const columns = [
    'BackOffice.Associations.Name',
    'BackOffice.Associations.createdAt',
    'BackOffice.Associations.Director',
    'BackOffice.Campaign.CollectedAmount',
    'BackOffice.Associations.Email',
    'BackOffice.Associations.Phone',
    'BackOffice.Associations.DonationPage',
    'BackOffice.Associations.DonationValidate',
    'BackOffice.Associations.Status',
    'BackOffice.Associations.ManagerIdentify',
    'BackOffice.Associations.Block',
    'BackOffice.Associations.View'
  ]

  // appFee: 0.05
  // businessDescription: "Association"
  // createdAt: "2021-07-20T05:04:18.224Z"
  // internalReference: "5"
  // linkParam: "generation-israel"
  // logoUrl: "https://charityclic-dev.s3-eu-west-3.amazonaws.com/f9a5117b-46bf-4cdb-b54e-4146a3806b5e.png"
  // stripeGateway: {_id: "60f65952426f1d006dd902a7", accountId: "acct_1JFB7pRCQxx57qpn", productId: "prod_Jsx1qgVomsAYcx"}
  // accountId: "acct_1JFB7pRCQxx57qpn"
  // productId: "prod_Jsx1qgVomsAYcx"
  // _id: "60f65952426f1d006dd902a7"
  // updatedAt: "2021-07-20T05:04:18.270Z"
  // usageName: "LES ENFANTS D'ISRAËL"
  // __v: 0
  // _id: "60f65952426f1d006dd902a8"

  useEffect(() => getData(), []);

  useEffect(()=>{
    const getAllDonations = async() =>{
      const getAll = await getBackOffice("/donation/back-office2")
      setAllDonations(getAll);
    }
    getAllDonations()
  },[])

  const CellRender = (cell,sizing=null) => (
    <p style={{fontSize: '12px',width:sizing===1?"150px":sizing===2?"175px":"100px"}}>{cell}</p>
  )

  const handleModal = (status, id) => {
    setAssociationId(id)
    setModalShow(true)
    setModalStatus(status)
  }

  const setAssociationBlock = associationId => {
      blockAssociation(`/association/block/${associationId}`)
      // setModalShow(false)
      window.location.reload(false);
  }

  const setAssociationUnblock = async (associationId) => {
      await unblockAssociation(`/association/unblock/${associationId}`)
      // setModalShow(false)
      window.location.reload(false);
  }

  const setDonationPageAccepted = campaignId => {
      acceptDonationPage(`/campaign/accept/${campaignId}`)
      setModalShow(false)
      window.location.reload(false);
  }

  const setDonationPageRejected = campaignId => {
      rejectDonationPage(`/campaign/reject/${campaignId}`)
      setModalShow(false)
      window.location.reload(false);
  }

  const modalContentChange = (campaignId) => {
      return(
          <>
              <div className="text">
                  {t("BackOffice.Associations.DonationValidateText")}
              </div>
              <div className="buttons">
                  <DownloadButton buttonStyle={'primary'} name={t("Charity.Accept")} action={()=>setDonationPageAccepted(campaignId)} />
                  <DownloadButton buttonStyle={'danger'} name={t("Charity.Reject")} action={()=>setDonationPageRejected(campaignId)} />
              </div>
          </>
      )
  }

  const setDonationPageModal = (campaignId) => {
      return(
          <>
              <div className="text">
                  {t("BackOffice.Associations.DonationValidateText")}
              </div>
              <div className="buttons">
              <div className={styles.viewBtn} onClick={()=>setDonationPageAccepted(campaignId)}>{t("Charity.Accept")}</div>
              <div className={styles.viewBtn} onClick={()=>setDonationPageRejected(campaignId)}>{t("Charity.Reject")}</div>
              </div>
          </>
      )
  }

  const setPageModal = (campaignId) => {
      return(
          <>
              <div className="text">
                  {t("BackOffice.Associations.AssociationBlock")}
              </div>
              <div className="buttons">
              <div className={styles.viewBtn} onClick={()=>setAssociationUnblock(campaignId)}>{t("BackOffice.Associations.UnBlock")}</div>
              <div className={styles.viewBtn} onClick={()=>setAssociationBlock(campaignId)}>{t("BackOffice.Associations.Block")}</div>
              </div>
          </>
      )
  }

  const setDonationPageRejectedModal = (campaignId) => {
      return(
          <>
              <div className="text">
                  {t("BackOffice.Associations.DonationValidateText")}
              </div>
              <div className="buttons">
              <div className={styles.viewBtn} action={()=>setDonationPageAccepted(campaignId)}>{t("Charity.Accept")}</div>
              <div className={styles.viewBtn} action={()=>setDonationPageRejected(campaignId)}>{t("Charity.Reject")}</div>
              </div>
          </>
      )
  }

  const modalContentBlock = (associationId) => {
    return(
        <>
          <div className="text">
              {t("BackOffice.Associations.AssociationBlock")}
          </div>
          <div className="buttons">
            <DownloadButton name={'Ok'} action={()=>setAssociationBlock(associationId)} />
            <DownloadButton buttonStyle={'Link'} name={t("Charity.Close")} action={() => setModalShow(false)}/>
          </div>
        </>
    )
  }

    const modalContentUnBlock = (associationId) => {
        return(
            <>
                <div className="text">
                    {t("BackOffice.Associations.AssociationUnblock")}
                </div>
                <div className="buttons">
                    <DownloadButton name={'Ok'} action={()=>setAssociationUnblock(associationId)} />
                    <DownloadButton buttonStyle={'Link'} name={t("Charity.Close")} action={() => setModalShow(false)}/>
                </div>
            </>
        )
    }

  return (
    <>
      <div>
        <div className={styles.pageHead}>
          <h1 style={{color:"#507CAB"}}>Association</h1>
          <div className={styles.tableNavbar}>
            <Search change={handleSearch} />
            <div className={styles.tableNavbarButtons}>
              <div className={styles.viewBtn} style={{marginRight:"10px"}} name={'PDF'} onClick={() => downloadPDF('associationTable')} >PDF</div>
              <div className={styles.viewBtn} name={'Excel'} onClick={() => downloadExcel('associationTable')} >Excel</div>
            </div>
          </div>
        </div>

        {
          dataSource.length && (
            <TableContainer component={Paper} className={styles.tableContainer} id={'associationTable'}>
              <Table className={styles.table} aria-label="simple table">
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    {
                      columns.map((cel,index) => (
                        <th key={index} size='small'>{t(cel)}</th>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody className={styles.tableBody}>
                  {dataSource.map((row) => {
                    let donationStatus = row?.campaignData?.accepted ? 'Accepted' : 'Rejected'
                    let blockStatus = row?.association.block ? 'Blocked' : 'Active'
                    const createdAt = new Date(row?.createdAt)
                    var count = 0;
                    const donationsFiltered = allDonations.filter(dons =>{
                      if(dons.status === "SUCCESS" && dons.association===row.association._id){
                        count = count + dons.originalAmount;
                      }
                    })
                    return (
                        <TableRow
                            key={row?.donation?.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{CellRender(row?.association.usageName)}</TableCell>
                          <TableCell>{CellRender(formatedDate(createdAt))}</TableCell>
                          <TableCell>{CellRender(row?.firstName + ' ' +  row?.lastName)}</TableCell>
                          <TableCell style={{textAlign: 'center'}}>{CellRender(numberWithSpaces(count),1)}</TableCell>
                          <TableCell>{CellRender(row?.email,2)}</TableCell>
                          <TableCell>{CellRender(row?.phone ? '+' + row?.phone : '')}</TableCell>
                          <TableCell>
                              {(row?.campaignData?.accepted && row?.campaignData.linkParam) &&
                                CellRender(<img style={{display: 'block', maxWidth:20, margin: '0 auto'}} src={checkSuccess} alt={''} />)
                              }

                              {(!row?.campaignData?.accepted || !row?.campaignData.linkParam) &&
                              CellRender(<img style={{display: 'block', maxWidth:20, margin: '0 auto'}} src={checkError} alt={''} />)
                              }
                          </TableCell>
                          <TableCell>
                            {CellRender(<><div className={styles.blockedStatus} style={{marginBottom:"10px",textAlign: 'center'}}>{donationStatus}</div>
                              {row?.campaignData?._id &&<>
                              {/* {donationStatus &&<> */}
                                {/* <DownloadButton name={'Change status'} buttonStyle={'Link'} action={() => handleModal('change', row?.campaignData._id)} /> */}
                                <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:donationStatus=="Accepted"?'flex-start':"flex-end",cursor: "pointer"}} onClick={()=>handleModal("donationPage",row?.campaignData._id)}>
                                <div style={{width: '40px',height:"30.5px",backgroundColor:donationStatus=="Accepted"?"#1f8838":"#bf3626",borderRadius:"50px"}} ></div>
                                </div>
                                </>
                              }</>)}
                          </TableCell>
                          <TableCell>
                              {!row?.association.documents &&
                                <p>There is no information yet</p>
                              }

                              {row?.association.documents && row?.association.documents?.verificationStatus === 'verified' && (
                                  <div className={styles.managerVerificationSuccess}>
                                      <img style={{display: 'block', maxWidth:20 }} src={checkSuccess} alt={''} />
                                      {row?.association.documents?.verificationStatus}
                                  </div>
                              )}

                              {row?.association.documents && row?.association.documents?.verificationStatus !== 'verified' && (
                                  <div className={styles.managerVerificationError}>
                                      <img style={{display: 'block', maxWidth:20}} src={checkError} alt={''} />
                                      {row?.association.documents?.verificationStatus}

                                      {row?.association.documents?.verificationErrorAdditionalDocuments &&
                                      <div className={styles.managerVerificationErrorMsg}>
                                          <span>?</span>
                                          <div className={styles.managerVerificationErrorMsgText}>
                                              {row?.association.documents?.verificationErrorAdditionalDocuments}
                                          </div>
                                      </div>
                                      }
                                  </div>
                              )}
                          </TableCell>
                          <TableCell>
                              {!row?.association.documents &&
                                <p>There is no information yet</p>
                              }

                              {row?.association.documents && row?.association.documents.verificationStatus === 'verified' && (
                                  <div className={styles.managerVerificationSuccess}>
                                      <img style={{display: 'block', maxWidth:20 }} src={checkSuccess} alt={''} />
                                      {row?.association.documents.verificationStatus}
                                  </div>
                              )}

                              {row?.association.documents && row?.association.documents.verificationStatus !== 'verified' && (
                                  <div className={styles.managerVerificationError}>
                                      <img style={{display: 'block', maxWidth:20}} src={checkError} alt={''} />
                                      {row?.association.documents.verificationStatus}

                                      {row?.association.documents.verificationErrorDocuments &&
                                          <div className={styles.managerVerificationErrorMsg}>
                                              <span>?</span>
                                              <div className={styles.managerVerificationErrorMsgText}>
                                                  {row?.association.documents.verificationErrorDocuments}
                                              </div>
                                          </div>
                                      }
                                  </div>
                              )}
                          </TableCell>
                          <TableCell>
                            <div className={styles.blockedStatus} style={{marginBottom:"10px", textAlign: 'center'}}>{blockStatus}</div>
                            {!row?.association.block &&<>
                              {/* <DownloadButton name={'Block'} buttonStyle={'Link'} action={() => handleModal('block', row?.association._id)} /> */}
                              <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:'flex-start',cursor: "pointer"}} onClick={()=>handleModal("associationPage",row?.association._id)}>
                                <div style={{width: '40px',height:"30.5px",backgroundColor:"#1f8838",borderRadius:"50px"}} ></div>
                                </div>
                              </>
                            }
                            {row?.association.block &&<>
                              {/* <DownloadButton name={'Unblock'} buttonStyle={'Link'} action={() => handleModal('unblock', row?.association._id)} /> */}
                              <div style={{width: '75px',height:"30px",border:"1px solid rgb(20, 90, 137)", borderRadius:"50px",margin:"auto",display: 'flex',alignItems: 'center',justifyContent:"flex-end",cursor: "pointer"}} onClick={()=>handleModal("associationPage",row?.association._id)}>
                                <div style={{width: '40px',height:"30.5px",backgroundColor:"#bf3626",borderRadius:"50px"}} ></div>
                                </div>
                              </>
                            }
                          </TableCell>
                          <TableCell className={styles.tableLink} style={{textAlign:"center"}}>
                              {row?.campaignData?.linkParam &&
                              <a className={styles.viewBtn} style={{textDecoration:"none"}} href={'/' + row?.campaignData?.linkParam} target="blank">
                                  View
                              </a>
                              }
                          </TableCell>
                        </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }

        {/* {showModal && modalStatus === 'change' &&
          <Modal modalContent={modalContentChange(currentAssociationId)} modalClose={() => setModalShow(false)} />
        }
        {showModal && modalStatus === 'block' &&
          <Modal modalContent={modalContentBlock(currentAssociationId)} modalClose={() => setModalShow(false)} />
        }
        {showModal && modalStatus === 'unblock' &&
          <Modal modalContent={modalContentUnBlock(currentAssociationId)} modalClose={() => setModalShow(false)} />
        } */}
        {showModal && modalStatus === "donationPage" &&
        <Modal modalContent={setDonationPageModal(currentAssociationId)} modalClose={() => setModalShow(false)} />
        }
        {showModal && modalStatus === "associationPage" &&
        <Modal modalContent={setPageModal(currentAssociationId)} modalClose={() => setModalShow(false)} />
        }
      </div>
    </>
  );
}

export default Donations;
