import React from 'react';

export default function Facture({userState}) {


    return (
        <div>
            <p className="dashTitle">Factures de CharityClic</p>
            <div className="dashForm">
                <p className="dashFormTitle">Février 2021</p>
                <div className="factRow">
                    <div className="fact-first">
                        <p className="dashFact noMar fact-txt">Facture numéo</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                    </div>
                    <div className="fact-sec">
                        <p className="dashFact noMar fact-txt">Origine</p>
                        <p className="dashFact fact-row">Don</p>
                        <p className="dashFact fact-row">Campagne</p>
                        <p className="dashFact fact-row">Don</p>
                        <p className="dashFact fact-row">Campagne</p>
                        <p className="dashFact fact-row">Don</p>
                        <p className="dashFact fact-row">Campagne</p>
                    </div>
                    <div className="fact-third">
                        <p className="dashFact noMar fact-txt">Montant</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                    </div>
                </div>
            </div>
            <div className="rowBtn">
                <div className="btnLeftDash btnGeneral">Télécharger</div>
                <div className="btnRightDash btnGeneral">Visualiser</div>
            </div>
            <div className="dashForm">
                <p className="dashFormTitle">Janvier 2021</p>
                <div className="factRow">
                    <div className="fact-first">
                        <p className="dashFact noMar fact-txt">Facture numéo</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                        <p className="dashFact fact-row" style={{borderLeft: '2px solid#B5B5B5'}}>N° 111111</p>
                    </div>
                    <div className="fact-sec">
                        <p className="dashFact noMar fact-txt">Origine</p>
                        <p className="dashFact fact-row">Don</p>
                        <p className="dashFact fact-row">Campagne</p>
                        <p className="dashFact fact-row">Don</p>
                        <p className="dashFact fact-row">Campagne</p>
                        <p className="dashFact fact-row">Don</p>
                        <p className="dashFact fact-row">Campagne</p>
                    </div>
                    <div className="fact-third">
                        <p className="dashFact noMar fact-txt">Montant</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                        <p className="dashFact fact-row" style={{borderRight: '2px solid#B5B5B5'}}>450 euro</p>
                    </div>
                </div>
            </div>
            <div className="rowBtn">
                <div className="btnLeftDash btnGeneral">Télécharger</div>
                <div className="btnRightDash btnGeneral">Visualiser</div>
            </div>
        </div>
    )
}