import Paper from '@material-ui/core/Paper';
// import {
//   ArgumentAxis,
//   LineSeries,
//   ValueAxis,
//   Chart,
// } from '@devexpress/dx-react-chart-material-ui';

import { getBackOffice } from '../../Services/ws';
import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { numberWithSpaces } from "../../Services/utils";

const StatisticItemRender = ({value,value2,eur}) => {
  return (
    <div style={{width:"150px",display: 'flex',flexDirection:'column',alignItems: 'space-between'}}>
      <div style={{color:"rgb(20, 90, 137)",textAlign:'center',marginBottom:"10px",fontWeight:"bold"}}>
       {value2} 
      </div>
      <div style={{
        border: '2px solid rgb(20, 90, 137)',
        backgroundColor:"rgb(20, 90, 137)",
        padding: '15px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color:"#fff",
        fontWeight:"bold"
      }}>
        {eur?numberWithSpaces(value):value}
      </div>
    </div>
  )
}

const ChartRender = ({ data, name, valueField }) => (
  <>
    {
      data.length &&
      (
        <div style={{
          marginTop: '30px'
        }}>
          <span>{name}</span>
          <Paper>
            {/*<Chart*/}
            {/*  data={data}*/}
            {/*  width={500}*/}
            {/*  height={200}*/}
            {/*>*/}
            {/*  <ArgumentAxis />*/}
            {/*  <ValueAxis />*/}
            {/*  <LineSeries valueField={valueField} argumentField="month" />*/}
            {/*</Chart>*/}
          </Paper>
        </div>
      )
    }
  </>
)

const Statistics = () => {

  const [statistics, setStatistics] = useState();
  const [charts, setCharts] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [apiData, setApiData] = useState();
  const { t } = useTranslation()

  const getStatistics = async () => {
    const data = await getBackOffice('/statistics/back-office');
    setApiData(data);
    let eurosCollected = 0;
    data.donations.forEach(d => {eurosCollected += (d?.campaign?.goal?.amount || 0 )})
    setStatistics({
      associations: `${data.associations.length}`,
      donations: `${data.donations.length}`,
      eurosCollected: `${eurosCollected}`,
      earnings: '? euros '
    })
  }

  const calculate = () => {
    const arrayByDateDonations = [];
    const arrayByDateAssociations = [];
    if (!apiData && !selectedYear) return

    ///Donate

    apiData?.donations.forEach((d) => {
      const month = moment(d.createdAt).month();
      const year = moment(d.createdAt).year();
      if(year === selectedYear) {
        const index = arrayByDateDonations.findIndex(elem => elem.month === month);
        if (index !== -1) {
          arrayByDateDonations[index].amount += (+d.campaign?.goal?.amount || 0 );
        }
        else {
          arrayByDateDonations.push({ month: moment(d.createdAt).month(), amount: d.campaign?.goal?.amount })
        }
      }
    })

    ///Associations
    apiData?.associations.forEach((d) => {
      const month = moment(d.createdAt).month();
      const year = moment(d.createdAt).year();
      if(year === selectedYear) {
        const index = arrayByDateAssociations.findIndex(elem => elem.month === month);
        if (index !== -1) {
          arrayByDateAssociations[index].amount += 1;
        }
        else {
          arrayByDateAssociations.push({ month: moment(d.createdAt).month(), amount: 1 })
        }
      }
    })

    setCharts([
      { name: 'Donations', data: arrayByDateDonations, valueField: 'amount' },
      { name: 'Associations', data: arrayByDateAssociations, valueField: 'amount' }
    ])
  }


  useEffect(() => getStatistics(), []);

  useEffect(() => {
      if (selectedYear && apiData)
      calculate()
  }, [selectedYear, apiData]);

  return (
    <>
      <div style={{
        width: '1100px',
        margin: 'auto',
      }}>
        <DatePicker
          views={['year']}
          label="Year only"
          value={new Date()}
          onChange={(newValue) => {
            setSelectedYear(moment(newValue).year())
          }}
          renderInput={(params) => <TextField {...params} helperText={null} />}
        />
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
          marginTop:"50px"
        }}>
          <StatisticItemRender value={statistics?.associations} value2="Associations" eur={false} />
          <StatisticItemRender value={statistics?.donations} value2="Donateurs" eur={false}/>
          <StatisticItemRender value={statistics?.eurosCollected} value2="Euros récolté" eur={true} /> 
          <StatisticItemRender value={statistics?.earnings} value2="Gains Charityclic" eur={false}/>
          <StatisticItemRender value={apiData?.campaign.length} value2="Campagnes" eur={false}/>
          <StatisticItemRender value={apiData?.billetterie.length} value2="Billetteries" eur={false}/>
        </div>
        {/* <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}>
          {
            charts.length &&
            charts.map(({ data, name, valueField }) => <ChartRender data={data} name={name} valueField={valueField} />)
          }
        </div> */}
      </div>

    </>
  )
}

export default Statistics;
