import TableToExcel from "@stanlystark/table-to-excel";
import { jsPDF } from "jspdf";

const currentDate = (new Date()).toISOString().split('T')[0]

export const numberWithSpaces = (x, currency="eur") => {
    
    x = Math.round(x)
    var cur = "€"
    switch (currency) {
        case "usd":
            cur = "$"
            break;
        case "ils":
            cur = "₪"
            break;
        case "gbp":
            cur = "£"
            break;
        default:
            break;
    }
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return `${parts.join(".")} ${cur}`;
}

export const calcPercentage = (partialValue, totalValue) => {
    return Math.round(((100 * partialValue) / totalValue));
 } 

 String.prototype.isEmpty = function() {
    return (this.length === 0 || !this.trim());
};

export const downloadPDF = (id, format = "a0", width = 1600, height = 960, orientation = "landscape") => {
    const block = document.getElementById(id)

    let pdf = new jsPDF({
        orientation: orientation,
        unit: "px",
        hotfixes: "px_scaling",
        format: format
    });

    pdf.html(block, {
        callback: function (doc) {
            doc.save(`association_${currentDate}.pdf`);
        }, x: 0, y: 0, width: width, height: height
    });
}

export const downloadExcel = id => {
    const table = document.getElementById(id)
    TableToExcel.convert(table, { name: `associations_${currentDate}.xlsx`});
}

export const formatedDate = (date) => {
    if(!date) {
        return '--.--.----'
    }

    date = new Date(date)

    let day = date.getDate();
    if(day < 10) {
        day = '0' + day
    } else if(isNaN(day)){
        day = '--'
    }

    let month = date.getMonth() + 1
    if(month < 10) {
        month = '0' + month
    } else if(isNaN(month)) {
        month = '--'
    }

    let year = date.getFullYear()

    if(isNaN(year)) {
        year = '----'
    }

    return day + '.' + month + '.' + year
}

