import './Header.css';
import React from 'react';
import logoFull from '../../assets/website_logo.svg'
import search from '../../assets/search-solid.svg'
import Autosuggest from 'react-autosuggest';
import { withRouter } from 'react-router-dom'
import { useState, useContext, useEffect } from 'react';
import { UserContext } from "../../Context/UserContext";
import { getAssocByName } from '../../Services/ws';
import { HashLink as Link } from 'react-router-hash-link';
import Modal from 'react-modal';
import { slide as Menu } from 'react-burger-menu'
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import usFlag from '../../assets/usa.png'
import frFlag from '../../assets/france.png'
import flagFr from '../../assets/New/flagFr.png'
import flagUs from '../../assets/New/flagUs.png'
import LogoNavbar from '../../assets/New/LogoNavbar.png'
import Search from '../../assets/New/search.svg'
import xIcon from '../../assets/New/xIcon.svg'

const customStyles = {
  content : {
    top                   : '0px',
    left                  : '0px',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '0px',
    width             : '100%',
    height: '100%',
    zInd1ex: 20,
    display: 'flex',
    flexDirection: 'column'
  }
};
Modal.setAppElement('#root')
  // Imagine you have a list of languages that you'd like to autosuggest.
const languages = [];

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = async value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    var res = await getAssocByName(inputValue, 0)

    return res;
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion.name;

  const renderInputComponent = inputProps => (
    <div className="inputContainer">
      <img className="icon" src={search} />
      <input {...inputProps} />
    </div>
  );

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => (
    <div>
        {suggestion.type==1?suggestion.name:suggestion.title[0].content}
    </div>
)

const renderSectionTitle = (section) => (
    <strong>{section.title}</strong>
)

function Header({construction, history}) {
    const [userState, setUserState] = useContext(UserContext);
    const [suggestions, setSuggestions] = useState([])
    const [value, setValue] = useState('')
    const [giftModalIsOpen,setIsGiftModalOpen] = useState(false);
    const [isMenuOpen,setMenuOpen] = useState(false);
    const [suggestionsAssociations, setSuggestionsAssociations] = useState([]);
    const [suggestionsCampaigns, setSuggestionsCampaigns] = useState([]);
    const [suggestionsResults, setSuggestionResults] = useState([suggestionsAssociations, suggestionsCampaigns]);
    const { i18n, t } = useTranslation();

    const [showLang,setShowLang] = useState(false);
    const [showSearch,setShowSearch] = useState(false);

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
    }

    const isAdmin = userState?.user?.roles.find(r=> r.role === 'admin');

    const onChange = (event, { newValue }) => {
        setValue(newValue)
      };
    const onSuggestionsFetchRequested = ({ value }) => {
      getSuggestions(value).then((res) => {
        const sectionAssociations = {
            title: 'Associations',
            suggestions: []
        }

        const sectionCampaigns = {
            title: 'Campaigns',
            suggestions: []
        }

        res.map(item => {
            if(item.type === 0) {
                sectionCampaigns.suggestions.push(item)
            }

            if(item.type === 1) {
                sectionAssociations.suggestions.push(item)
            }
        })

          setSuggestions([sectionAssociations, sectionCampaigns])
      });
    };

      // Autosuggest will call this function every time you need to clear suggestions.
      const onSuggestionsClearRequested = () => {
        setSuggestions([])
        setSuggestionsAssociations([])
        setSuggestionsCampaigns([])
        setSuggestionResults([])
      };

    const getSectionSuggestions = (section) => {
        return section.suggestions;
    }

      function afterOpenGiftModal() {
      }

      function closeGiftModal(){
        setIsGiftModalOpen(false);
      }

      const handleChangeLanguage = (lang) => {
        changeLanguage(lang)
        setShowLang(false)
      }

    return (

    <div className={"container"}>
        <Modal
          isOpen={giftModalIsOpen}
          onAfterOpen={afterOpenGiftModal}
          onRequestClose={closeGiftModal}
          style={customStyles}
          >
        </Modal>
        <div className={'headerLeft'}>
            <Link to="/" >
                {/* <img className="logo" src={logoFull}></img> */}
                <img className="logo" src={LogoNavbar}></img>
            </Link>
            
        </div>


        {isMobile && <Menu style={{color:"#000"}} isOpen={isMenuOpen} onStateChange={(state) => setMenuOpen(state.isOpen)} right width={'100%'}>

          <img className="logo" src={LogoNavbar}></img>
          <div style={{display: 'flex', flexDirection: 'column'}}>
          {window.location.pathname == '/dashboard' ? (
            <>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/dashboard?menu=0">{t("Charity.DonationPage")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/dashboard?menu=1">{t("Charity.CampaignPage")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/dashboard?menu=2">{t("Dashboard.Billetterie")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/dashboard?menu=3">{t("Charity.Donors")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/dashboard?menu=4">{t("Charity.Invoices")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/dashboard?menu=5">{t("Charity.Treasury")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/dashboard?menu=6">{t("Charity.Settings")}</Link>
            <Link onClick={() => {setMenuOpen(false)
            setUserState({association: null, user: null, accessToken: null});
//            history.push('/login')
            }} className={'link'} to="/login">Déconnexion</Link>
            </>
          ) : (
            <>
             <Autosuggest
                // theme={{container: {
                //     boxShadow: '0px 3px 6px #FFFFFF66', border: '3px solid #507CAB', borderRadius: 23, width: 300, height: 60
                // },
                // input: {backgroundColor: 'transparent', outline: 'none', width: 300, height: 60, paddingLeft: 30}}}
                suggestions={suggestions}
                onSuggestionSelected={(event, {suggestion, suggestionValue}) => {
                  // console.log(suggestion);

                  setMenuOpen(false)
                  history.push('/'+suggestion.linkParam)
                //  window.open('/'+suggestion.linkParam)
                }}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{placeholder:t("Charity.Search"), value, onChange}}
                renderInputComponent={renderInputComponent}
                multiSection={true}
                renderSectionTitle={renderSectionTitle}
                getSectionSuggestions={getSectionSuggestions}
            />
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/#campains">{t("Charity.Campaigns")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/tickets">{t("Dashboard.Billetterie")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/donations">{t("Charity.Donations")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/association">{t("Charity.Charities")}</Link>
            <Link onClick={() => setMenuOpen(false)} className={'link'} to="/contact">{t("Charity.Contact")}</Link>
            <Link style={{marginTop: 20, textAlign: 'center', width: '60%', marginLeft: 'auto', marginRight: 'auto'}}  onClick={() => setMenuOpen(false)} className={'connection'} to="/login">{t("Charity.Connection")}</Link>

            </>
          )}

          </div>
            <div className={'langMobile'}>
                  <span onClick={() => changeLanguage('en')}>
                      <img src={flagUs} />
                  </span>
                <span onClick={() => changeLanguage('fr')}>
                      <img src={flagFr} />
                  </span>
            </div>
        </Menu>
         }
        <div className={'navBar'}>
            
            <div className="linkGroup">
            <Link className={'link'} to="/#campains">{t("Charity.Campaigns")}</Link>
            <Link className={'link'} to="/donations">{t("Charity.Donations")}</Link>
            <Link className={'link'} to="/association">{t("Charity.Charities")}</Link>
            <Link className={'link'} to="/tickets">{t("Dashboard.BilletteriePage.Billetteries")}</Link>
            <Link className={'link'} to="/contact">{t("Charity.Contact")}</Link>
            {
              isAdmin && <Link className={'link'} to="/back-office/association">{t('Back Office')}</Link>
            }
            </div>
            <div className={'searchBarContainer'}>
              <div className={'searchbarHidden'} style={{opacity:showSearch?1:0}}>
            <Autosuggest
                // theme={{container: {
                //     boxShadow: '0px 3px 6px #FFFFFF66', border: '3px solid #507CAB', borderRadius: 23, width: 300, height: 60
                // },
                // input: {backgroundColor: 'transparent', outline: 'none', width: 300, height: 60, paddingLeft: 30}}}
                suggestions={suggestions}
                onSuggestionSelected={(event, {suggestion, suggestionValue}) => {
                  // console.log(suggestion);

                  history.push('/'+suggestion.linkParam)
                //  window.open('/'+suggestion.linkParam)
                }}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={{placeholder:t("Charity.Search"), value, onChange}}
                renderInputComponent={renderInputComponent}
                multiSection={true}
                renderSectionTitle={renderSectionTitle}
                getSectionSuggestions={getSectionSuggestions}
            />
              </div>
              <div className="searchToggle" onClick={()=>setShowSearch(!showSearch)}>
            <img src={showSearch?xIcon:Search} alt="" />
              </div>
            </div>
            {!isMobile &&
              <div className={'langHeader'} onClick={()=>setShowLang(!showLang)} style={{display:"flex", alignItems: 'center', marginTop:"0px",zInd1ex:100}}>
                  <div style={{transition: '0.2s all ease-in-out', opacity: showLang ? 1 : "0", display: "flex", flexDirection: "row", position: "relative",right:"-62px",top:"-26px"}}>
                    <span title="EN" onClick={() => handleChangeLanguage('en')}>
                      <img src={flagUs} style={{borderRadius:"50px"}} alt='en' />
                    </span>
                    <span title="FR" onClick={() => handleChangeLanguage('fr')}>
                      <img src={flagFr} style={{borderRadius:"50px"}} alt='fr' />
                    </span>
                  </div>
                <div className={'langHeader'} style={{marginRight:"0px"}}>
                  <span>
                    <div>{i18n.language == "fr" && <img src={flagFr} style={{borderRadius:"50px", cursor: "pointer"}} alt='fr' />}</div>
                    <div>{i18n.language == "en" && <img src={flagUs} style={{borderRadius:"50px", cursor:"pointer"}} alt='en' />}</div>
                  </span>
                </div>
              </div>
            }
            {userState && userState.association ? (
              <Link  className={'link'} to="/dashboard">
              <img style={{width: 50, height: 50, borderRadius: 15, marginRight: 20, objectFit: 'cover'}} src={userState.association.logoUrl} />
              {/* {userState.association.usageName} */}
              </Link>
              ): (
              <Link className={'connection'} to="/login">{t("Charity.Connection")}</Link>
            )}
        </div>
    </div>);
}

export default withRouter(Header)

