import React, {useEffect, useState} from 'react';
import styles from './index.css';
import { getTreasury, getPayoutsForAssociation } from '../../Services/ws';
import { numberWithSpaces, formatedDate } from '../../Services/utils';
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";

export default function Treasury({userState}) {
    const association = userState.association;
    const [treasury, setTreasury] = useState(null)
    const [payouts, setPayouts] = useState(null)
    const { t } = useTranslation()

    const getData = async () => {
        getTreasury().then((res) => {
            setTreasury(res)
        })
    }

    const getPayouts = async () => {
        const data = await getPayoutsForAssociation(`/payouts/${userState.association.id}`)
        if(data) {
            setPayouts(data)
        }
    }

    useEffect(() => {
        getData();
        getPayouts();
    }, [])
    return (
            <div>
                <p className="dashTitle">{t("Dashboard.Donation.TreasuryTitle")}</p>
                <div className="priceRow">
                    <p style={{marginLeft: 'auto'}} className="priceTxt">{t("Dashboard.Donation.AtCheckout")}</p>
                    <div className="line"/>
                    <p className="priceTxt" style={{fontWeight: '600'}}>{numberWithSpaces(treasury ? treasury.available[0].amount : 0)}</p>
                    <p style={{fontSize: isMobile ? 14 : 20, marginLeft: 'auto'}} className="priceTxt">{t("Dashboard.Donation.Waiting")}</p>
                    <div style={{height: isMobile ? 14 : 22}} className="line"/>
                    <p  className="priceTxt" style={{fontWeight: '600', fontSize: isMobile ? 14 : 20}}>{numberWithSpaces(treasury ? treasury.pending[0].amount : 0)}</p>
                </div>
                <div className="dashForm">
                    <p className="dashFormTitle">{t("Dashboard.Donation.Payments")}</p>
                    <div className="rowSub">
                        <p className="dashSub">Date</p>
                        <p className="dashSub">{t("Dashboard.Donation.Amount")}</p>
                        <p className="dashSub">{t("Dashboard.Donation.Bank")}</p>
                        <p className="dashSub">{t("Dashboard.Donation.BankDetails")}</p>
                    </div>
                    {payouts && payouts.map(payout => (
                        <div className="rowTable">
                            <p className="tableCell">{formatedDate(payout.date)}</p>
                            <p className="tableCell">{payout.amount}</p>
                            <p className="tableCell">{payout.bank}</p>
                            <p className="tableCell">{payout.bankDetails}</p>
                        </div>
                    ))}
                </div>
            </div>

    )
}
