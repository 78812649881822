import React, { useState } from 'react'

import styles from './Search.module.css'

const Search = props => {
    let { change } = props
    return (
        <div className={styles.searchBlock}>
            <div className={styles.searchIcon}></div>
            <input type="text" placeholder="Search ..." onKeyUp={change} />
        </div>
    )
}

export default Search