import React, {useState, createRef, useEffect, useContext} from 'react';
import styles from './index.css';
import searchIcon from '../../assets/search-solid.svg'
import { Divider } from '@material-ui/core';
import { UserContext } from "../../Context/UserContext";
import { getAssocDonations, createManualPayment, createManualPayment2, getAssocDonationsByName, getExcel, sendingBillet, removeBillets } from '../../Services/ws';
import Select from 'react-select'
import { Store } from 'react-notifications-component';
import validator from 'validator';
import { numberWithSpaces } from '../../Services/utils';
import PhoneInput from 'react-phone-input-2'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const options = [
    { label: 'EUR €', value: 'eur' },
    { label: 'USD $', value: 'usd' },
    { label: 'ILS ₪', value: 'ils' },
    { label: 'GBP £', value: 'gbp' },
  ]

var fx = require("money");

fx.base = "EUR";
fx.rates = {
    "USD" : 1.19, // eg. 1 USD === 0.745101 EUR
    "GBP" : 0.86, // etc...
    "ILS" : 3.89,
    "EUR" : 1,        // always include the base rate (1:1)
    /* etc */
}

export default function Donations({}) {
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState('');
    const [postal, setPostal] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState(null);
    const [phone, setPhone] = useState('');
    const [search, setSearch] = useState('');
    const inputOpenFileRef = createRef();
    const [userState, setUserState] = useContext(UserContext);
    const [amount, setAmount] = useState(null)
    const [currency, setCurrency] = useState(options[0])
    const [campain, setCampain] = useState(null)
    const [isCash, setIsCash] = useState(true)
    const [isAnonymous, setIsAnonymous] = useState(null)
    const [campainOptions, setCampainOptions] = useState([])
    const [campainOptionsFiltered, setCampainOptionsFiltered] = useState([])
    const [donations, setDonations] = useState([])
    const [company, setCompany] = useState(false)
    const [companyName, setCompanyName] = useState('')
    const [pageNb, setPageNb] = useState(0)
    const { t } = useTranslation()
    const [arrParrains,setArrParrains] = useState([])
    const [parrain,setParrain] = useState([])
    const [idParrain,setIdParrain] = useState('')
    const [arrBilletterie,setArrBilletterie] = useState([])
    const [billetterie,setBilletterie] = useState([])
    const [idBilletterie,setIdBilletterie] = useState('')
    const [target,setTarget] = useState(null)
    const [infoBillet,setInfoBillet] = useState([])

    const customStyles = {
        option: (provided, state) => ({
        //   ...provided,
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
        //   width: "100%",
        }),
        singleValue: (provided, state) => {
        //   const opacity = state.isDisabled ? 0.5 : 1;
        //   const transition = 'opacity 300ms';
      
          return { };
        }
      }


    useEffect(() => {
        getAssocDonations(0).then((res) => {
            setDonations(res || [])
        })
        var campOpts = []
        var parOptsTemp = []
        var parOpts = []
        const now = new Date()
        if (userState.collect && userState.collect.id) {
            campOpts.push({label: userState.collect.title[0].content, value: userState.collect.id,type:"asso"})
        }
        if (userState.campains) {
            // console.log(userState.campains);
            // console.log(new Date(userState.campains[15].endingAt)< new Date());
            for (let i = 0; i < userState.campains.length; i++) {
                // console.log(new Date(userState.campains[i].startingAt).getTime());
                // console.log(new Date(userState.campains[i].endingAt).getTime());
                // console.log(now.getTime());
                if(new Date(userState.campains[i].startingAt) < new Date() && new Date(userState.campains[i].endingAt)> new Date()) {
                    const element = userState.campains[i];
                    campOpts.push({label: element.title[0].content, value: element.id,type:"campain"})
                }
                // console.log(parOpts);
            }
        }
        if (userState.billetteries) {
            for (let i = 0; i < userState.billetteries.length; i++) {
                if(new Date(userState.billetteries[i].startingAt) < new Date() && new Date(userState.billetteries[i].endingAt)> new Date()) {
                const element = userState.billetteries[i];
                campOpts.push({label: element.title[0].content, value: element.id,type:"billetterie"})
                }
                // console.log(parOpts);
            }
        }
        setCampainOptions(campOpts)

            // var parrOpts = []
            // const camTemp = userState.campains.filter((cam)=>cam.id===campain)
            // if(camTemp[0]?.sponsors.length){
            //     for(let i=0;i<camTemp[0].sponsors.length;i++) {
            //         parrOpts.push({label:camTemp[0].sponsors[i].name,value:camTemp[0].sponsors[i].id})
            //     }
            // }
            // setArrParrains(parrOpts)
        
    }, [])

    
useEffect(()=>{
    if(campain && target == 2){
        var parrOpts = []
        const camTemp = userState.campains.filter((cam)=>cam.id===campain)
        if(camTemp[0]?.sponsors.length){
            parrOpts.push({label:"-",value:null})
            for(let i=0;i<camTemp[0].sponsors.length;i++) {
                parrOpts.push({label:camTemp[0].sponsors[i].name,value:camTemp[0].sponsors[i].id})
            }
        }
        setArrParrains(parrOpts)
    }

},[campain,userState])

useEffect(()=>{
    if(campain && target == 3){
        var billOpts = []
        const billTemp = userState.billetteries.filter((cam)=>cam.id===campain)
        billOpts.push({label:"Veuillez choisir",value:"-"})
        // if(camTemp[0]?.sponsors.length){
            // billOpts.push({label:"-",value:null})
            for(let i=0;i<billTemp[0].tickets.length;i++) {
                billOpts.push({label:billTemp[0].tickets[i].categoryBillet,value:billTemp[0].tickets[i].id,infoBillet:billTemp[0].tickets[i]})
            }
        // }
        setArrBilletterie(billOpts)
    }

},[campain,userState])

useEffect(()=>{

    if(arrParrains.length && parrain.value && target == 2){
        setIdParrain(parrain.value);
    }else{
        setIdParrain(null)
    }

},[parrain,arrParrains])

useEffect(()=>{

    if(userState.billetteries){

        const billTemp = userState.billetteries.filter((cam)=>cam.id===campain)
        if(billTemp[0]?.tickets){
            
            const billetChoosen = billTemp[0].tickets.filter((bill)=>bill.id===billetterie)
            
            if(billetChoosen[0] && target == 3){
                setAmount(Number(billetChoosen[0].prixBillet))
                billetChoosen[0]=[{...billetChoosen[0],title:billTemp[0].title[0].content,dateTemp:`${billetChoosen[0].dateEvent.slice(8,10)}/${billetChoosen[0].dateEvent.slice(5,7)}/${billetChoosen[0].dateEvent.slice(0,4)}`,heureTemp:billetChoosen[0].dateEvent.slice(11,16),id2:billTemp[0].id,nbrPlace:1}]
                setInfoBillet(billetChoosen[0])
            }else if (target !=3){
                setAmount(0)
                setInfoBillet([])
            }
        }
    }
    },[billetterie])
    
useEffect(()=>{

    if(arrBilletterie.length && billetterie.value && target == 3){
        setIdBilletterie(billetterie.value);
    }else{
        setIdBilletterie(null)
    }

},[billetterie,arrBilletterie])

    useEffect(() => {
        
        if (search.length >= 1) {
            getAssocDonationsByName(pageNb, search).then((res) => {
                setDonations(res || [])
            })
        } else {
            //console.log('pageNb', pageNb*5);
            
            getAssocDonations(pageNb).then((res) => {
                // //console.log(res);
                setDonations(res || [])
            })
        }
    }, [search, pageNb])

    useEffect(()=>{
        const arrTemp = [...campainOptions]
        let arrRes = []
        if(target == 1){
            arrRes = arrTemp.filter((arr)=>arr.type == "asso")
            arrRes.unshift({label:"Veuillez choisir", value:"-"})
        }
        else if(target == 2){
            arrRes = arrTemp.filter((arr)=>arr.type == "campain")
            arrRes.unshift({label:"Veuillez choisir", value:"-"})
        }
        else if(target == 3){
            arrRes = arrTemp.filter((arr)=>arr.type == "billetterie")
            arrRes.unshift({label:"Veuillez choisir", value:"-"})
        }

        setCampainOptionsFiltered(arrRes)
    },[target])


    return (
        <div>
            <p className="dashTitle">{t("Dashboard.Donation.AddDonation")}</p>
            <div className="dashForm">
            <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonationDestination")}</p>
                    </div>
                    <div className="dashColInput" style={{width:"90%",display: 'flex',flexDirection: 'row',justifyContent: 'space-between',marginTop:"14px"}}>
                        

                    <div className="assoc-donation-block-amount-checkbox-line">
                <input
                  onChange={() => setTarget(1)}
                  checked={target==1}
                  style={{ width: 25, height: 25, backgroundColor: "#f1f0ec" }}
                  type="radio"
                  className="radio-toolbar"
                />
                <div className="assoc-donation-block-amount-checkbox-text">
                  {t("BackOffice.Sidebar.Association")}
                </div>
              </div>

              <div className="assoc-donation-block-amount-checkbox-line">
                <input
                  onChange={() => setTarget(2)}
                  checked={target==2}
                  style={{ width: 25, height: 25, backgroundColor: "#f1f0ec" }}
                  type="radio"
                  className="radio-toolbar"
                />
                <div className="assoc-donation-block-amount-checkbox-text">
                  {t("BackOffice.Campaign.Title")}
                </div>
              </div>

              <div className="assoc-donation-block-amount-checkbox-line">
                <input
                  onChange={() => setTarget(3)}
                  checked={target==3}
                  style={{ width: 25, height: 25, backgroundColor: "#f1f0ec" }}
                  type="radio"
                  className="radio-toolbar"
                />
                <div className="assoc-donation-block-amount-checkbox-text">
                  {t("Billeterie.title")}
                </div>
              </div>


                    </div>
                    </div>
                        {target && (
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonationDestination")}</p>
                    </div>
                    <div className="dashColInput">
                    <select
                className="dashInput"
                value={campain}
                placeholder="EUR €"
                onChange={(e) => setCampain(e.target.value)}
              >
                {campainOptionsFiltered.map((opt, index) => {

                  return (
                    <option key={index} value={opt.value}>{opt.label}</option>
                    // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                  );
                })}
              </select>
                        {/* <Select value={campain} onChange={(val) => {
                            setCampain(val)
                        }} placeholder={t("Dashboard.Donation.DonationDestinationPlaceholder")} classNamePrefix="" className="selectCampain" options={campainOptions}
                    /> */}
                    </div>
                </div>
                    )}
                {arrParrains.length && target == 2 ?
                <div className="dashRow">
                <div className="dashCol">
                    <p className="dashLabel">{t("Dashboard.Donation.DonationDestination")}</p>
                </div>
                <div className="dashColInput">
                <select
                className="dashInput"
                value={parrain}
                placeholder="EUR €"
                onChange={(e) => setParrain(e.target.value)}
              >
                {arrParrains.map((opt, index) => {
                  return (
                    <option key={index} value={opt.value}>{opt.label}</option>
                    // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                  );
                })}
              </select>
                    {/* <Select value={parrain} onChange={(val) => {
                        setParrain(val)
                    }} placeholder={t("Dashboard.Donation.DonationDestinationPlaceholder")} classNamePrefix="" className="selectCampain" options={arrParrains}
                     /> */}
                </div>
            </div>: 
                null}
                {arrBilletterie.length && target == 3 ?
                <div className="dashRow">
                <div className="dashCol">
                    <p className="dashLabel">{t("Dashboard.Donations.Billet")}</p>
                </div>
                <div className="dashColInput">
                <select
                className="dashInput"
                value={billetterie}
                placeholder="EUR €"
                onChange={(e) => setBilletterie(e.target.value)}
              >
                {arrBilletterie.map((opt, index) => {
                  return (
                    <option key={index} value={opt.value}>{opt.label}</option>
                    // <div className="affects-choices" id={aff.idAffect} key={aff.idAffect} onClick={()=>handleVal(aff.idAffect,campain.affects)}>{aff.val}</div>
                  );
                })}
              </select>
                    {/* <Select value={parrain} onChange={(val) => {
                        setParrain(val)
                    }} placeholder={t("Dashboard.Donation.DonationDestinationPlaceholder")} classNamePrefix="" className="selectCampain" options={arrParrains}
                     /> */}
                </div>
            </div>: 
                null}
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorType")}</p>
                    </div>
                    <div className="dashColInput">
                        <div className="donateRadioRow radioContainer">
                            <div><input type="radio" className="radio-toolbar" value="particulier" name="don" checked={!company} onChange={() => {setCompany(false)}} /> {t("Dashboard.Donate.Particular")}</div>
                            <div><input type="radio" className="radio-toolbar" value="company" name="don" checked={company}  onChange={() => {setCompany(true)}}/> {t("Dashboard.Donate.Business")}</div>
                        </div>
                    </div>
                </div>
                { company &&
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donate.CompanyName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder={t("Dashboard.Donate.CompanyName")} value={companyName} onChange={(evt) => {setCompanyName(evt.target.value)}} />
                    </div>
                </div>
                }
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder={t("Dashboard.Donation.DonorName")} value={firstname} onChange={(evt) => {setFirstname(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorFirstName")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder={t("Dashboard.Donation.DonorFirstName")} value={lastname} onChange={(evt) => {setLastname(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorPhone")}</p>
                    </div>
                    <div className="dashColInput">
                        <PhoneInput
                        country={'fr'}
                        containerClass="dashInput"
                        inputClass="phoneInput"
                        dropdownClass="phoneDropdown"
                        placeholder={t("Dashboard.Donation.DonorPhone")}
                        value={phone}
                        onChange={(phoneNb) => {
                            if (phoneNb.length == 3 && phoneNb[2] == '0') {
                                setPhone(phoneNb.substring(0, 2))
                                return ;
                            }
                            setPhone(phoneNb)
                        }}                                    />
                        {/* <input className="dashInput" type="number" placeholder="06 54 98 56 78" value={phone} onChange={(evt) => {setPhone(evt.target.value)}} /> */}
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">Email *</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder={t("Dashboard.Donation.DonorEmailPlaceholder")} value={email} onChange={(evt) => {setEmail(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorAddress")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder="32 rue des bouleaux" value={address} onChange={(evt) => {setAddress(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorZipCode")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder="75008" value={postal} onChange={(evt) => {setPostal(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorCity")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder="Paris" value={city} onChange={(evt) => {setCity(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorCountry")}</p>
                    </div>
                    <div className="dashColInput">
                        <input className="dashInput" type="text" placeholder="France" value={country} onChange={(evt) => {setCountry(evt.target.value)}} />
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorPaymentMethod")}</p>
                    </div>
                    <div className="radioContainer">
                        <div style={{flexDirection: 'row', alignSelf: 'center'}}>
                            <input onChange={() => setIsCash(true)} checked={isCash} className="radio-toolbar" type="radio" value="cash" name="payment"/> {t("Dashboard.Donation.DonorPaymentCash")}
                        </div>
                        <div style={{flexDirection: 'row', alignSelf: 'center'}}>
                            <input onChange={() => setIsCash(false)} checked={!isCash} className="radio-toolbar" type="radio" value="check" name="payment" /> {t("Dashboard.Donation.DonorPaymentCheck")}
                        </div>
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorPaymentAmount")}</p>
                    </div>
                        <input disabled={target==3?true:false} style={{marginRight: 20}} onChange={(e) => {
                            setAmount(e.target.value.replace(/[^0-9]/g, ''))
                            //console.log(e.target.value.replace(/[^0-9]/g, ''));
                            
                            //console.log(e.target.value);

                        }} value={amount} placeholder={t("Dashboard.Donation.DonorAmount")} className="assoc-donation-block-amount"></input>
                        {/* <Select value={currency} onChange={(val) => {
                            //console.log(val);
                            setCurrency(val)
                        }} placeholder="EUR €" classNamePrefix="assoc-select" className="react-select-container" options={options} /> */}
                </div>
                <div className="dashRow">
                <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorAnonime")}</p>
                    </div>
                    <div className="radioContainer">
                        <div style={{flexDirection: 'row', alignSelf: 'center'}}>
                            <input onChange={() => setIsAnonymous(true)} checked={isAnonymous == true} type="radio" className="radio-toolbar" value="ano" name="anonymous"/> {t("Dashboard.Donation.Yes")}
                        </div>
                        <div style={{flexDirection: 'row', alignSelf: 'center'}}>
                            <input onChange={() => setIsAnonymous(false)} checked={isAnonymous == false} type="radio" className="radio-toolbar" value="notano" name="anonymous" /> {t("Dashboard.Donation.No")}
                        </div>
                    </div>
                </div>
                <div className="dashRow">
                    <div className="dashCol">
                        <p className="dashLabel">{t("Dashboard.Donation.DonorMessage")}</p>
                    </div>
                    <div className="dashColInput">
                        <textarea className="dashInput" type="text" placeholder="Message" value={message} onChange={(e) => { setMessage(e.target.value)}} />
                    </div>
                </div>
               
                <div className="dashBtns">
                    <div onClick={async () => {
                        // createManualPayment()

                        const errorFields = [];
                        (!campain) && errorFields.push(t("Dashboard.Donation.DonorDestination"));
                        !amount && errorFields.push(t("Dashboard.Donation.ErrorAmount"));
                        // (!currency || !currency.value) && errorFields.push(`Devise`);
                        !firstname && errorFields.push(t("Dashboard.Donation.ErrorFirstName"));
                        !lastname && errorFields.push(t("Dashboard.Donation.ErrorName"));
                        !phone && errorFields.push(t("Dashboard.Donation.ErrorPhone"));
                        !email && errorFields.push(`Email`);
                        !address && errorFields.push(t("Dashboard.Donation.ErrorAddress"));
                        !postal && errorFields.push(t("Dashboard.Donation.ErrorZipCode"));
                        !city && errorFields.push(t("Dashboard.Donation.ErrorCity"));
                        !country && errorFields.push(t("Dashboard.Donation.ErrorCountry"));
                        isAnonymous == null && errorFields.push(t("Dashboard.Donation.ErrorAnonime"));
                        (company && !companyName) && errorFields.push(t("Dashboard.Donate.CompanyName"));


                        if (errorFields.length > 0) {
                            Store.addNotification({
                                title: t("SingIn.Errors"),
                                message: t("Dashboard.CharityPage.FillFields") + errorFields.join(', '),
                                type: "danger",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate_animated", "animate_fadeIn"],
                                animationOut: ["animate_animated", "animate_fadeOut"],
                                dismiss: {
                                    duration: 10000,
                                    onScreen: true
                                }
                                });
                            return ;
                        }

                        if (!validator.isEmail(email)) {
                            Store.addNotification({
                                title: t("SingIn.Errors"),
                                message: t("Dashboard.Donation.ErrorEmail"),
                                type: "danger",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate_animated", "animate_fadeIn"],
                                animationOut: ["animate_animated", "animate_fadeOut"],
                                dismiss: {
                                duration: 10000,
                                onScreen: true
                                }
                            });
                            return false
                        }
                        if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test('+' + phone)) {
                            Store.addNotification({
                                title: t("SingIn.Errors"),
                                message: t("Dashboard.Donation.ErrorPhoneNumber"),
                                type: "danger",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate_animated", "animate_fadeIn"],
                                animationOut: ["animate_animated", "animate_fadeOut"],
                                dismiss: {
                                duration: 10000,
                                onScreen: true
                                }
                            });
                            return false
                        }
                        if (loading == true) {
                            //console.log(loading);
                            return;
                        }
                        // return ;
                        setLoading(true)
                        if(target === 3){
                            var res = await createManualPayment2(campain, amount, 'eur', companyName, firstname, lastname, '+' + phone, email, address, postal, city, country, idParrain, isAnonymous, message, isCash ? "CASH" : "DRAFT")
                        }else{
                            var res = await createManualPayment(campain, amount, 'eur', companyName, firstname, lastname, '+' + phone, email, address, postal, city, country, idParrain, isAnonymous, message, isCash ? "CASH" : "DRAFT")
                        }
                        setLoading(false)
                        if (res.status == 'SUCCESS') {
                            Store.addNotification({
                                title: t("Dashboard.CharityPage.Success"),
                                message: t("Dashboard.Donation.DonationAdded"),
                                type: "success",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate_animated", "animate_fadeIn"],
                                animationOut: ["animate_animated", "animate_fadeOut"],
                                dismiss: {
                                    duration: 10000,
                                    onScreen: true
                                }
                                });
                                setFirstname('')
                                setLastname('')
                                setAddress('')
                                setCity('')
                                setCountry('')
                                setPostal('')
                                setEmail('')
                                setPhone('')
                                setSearch('')
                                setAmount('')
                                setMessage('')
                                setIsAnonymous(null)
                                // setCurrency(options[0])
                                setCampain(null)
                                setIsCash(true)
                                getAssocDonations(0).then((res) => {
                                    //console.log(res);
                                    setDonations(res || [])
                                })                        
                                // var newDons = [res, ...donations]
                                // setDonations(newDons)

                            window.scrollTo(0, 0)
                            if(target === 3){
                                await sendingBillet(firstname,lastname,infoBillet.prixBillet,infoBillet.categoryBillet,infoBillet.title,infoBillet.dateTemp,infoBillet.heureTemp,infoBillet.nomLieu,infoBillet.adresse,infoBillet.codePostal,infoBillet.place,email)
                                await removeBillets(infoBillet.id2,infoBillet)
                            }
                            } else {
                            Store.addNotification({
                                title: t("SingIn.Errors"),
                                message: t("Dashboard.Donation.AnError"),
                                type: "danger",
                                insert: "top",
                                container: "top-center",
                                animationIn: ["animate_animated", "animate_fadeIn"],
                                animationOut: ["animate_animated", "animate_fadeOut"],
                                dismiss: {
                                    duration: 10000,
                                    onScreen: true
                                }
                                });
                         }
                         //console.log(res);

                    }} className={'newBtnNext2'}>{t("Dashboard.CharityPage.Enter")}</div>
                </div>
            </div>
        </div>
    )
}