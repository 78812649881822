import styles from './Footer.css';
import logo from '../../assets/website_logo.svg'
import facebook from '../../assets/facebook.svg'
import instagram from '../../assets/instagram.svg'
import twitter from '../../assets/twitter.svg'
import whatsapp from '../../assets/whatsapp.svg'
import linkedin from '../../assets/linkedin.svg'
import React from 'react';
import { TextareaAutosize } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import logoNavbar from '../../assets/New/LogoNavbar.png'

export default function Footer() {
    const { t } = useTranslation();

    return <div className={'footerContainer'}>
        <div className={'footerContainerRow'} style={{flexDirection: 'row', display: 'flex', alignItems: 'flex-end'}}>
            {/* <div className={'footerLogoContainer'} style={{flexDirection: 'row', display: 'flex', flexDirection: 'row', marginTop: 20, marginLeft: 45}}> */}
                {/* <img className={'logo-footer'} src={logo}></img> */}
                <img  className={'logo-footer'} src={logoNavbar}></img>
                <div style={{marginLeft: 'auto', marginRight: 'auto', marginTop: 10}}>
                    <img onClick={() => {
                        window.open('https://www.facebook.com/CharityClic', '_blank').focus();
                    }} className={'socialIcon'}  src={facebook}/>
                    {/* <img className={'socialIcon'}  src={twitter}/>
                    <img className={'socialIcon'}  src={instagram}/>
                    <img className={'socialIcon'}  src={whatsapp}/> */}
                    <img onClick={() => {
                        if (isMobile) {
                            var ww = window.open('https://www.linkedin.com/company/charityclic/')
                        } else {
                            var ww = window.open('https://www.linkedin.com/company/charityclic/', '_blank')
                            if (ww) ww.focus();
                        }
                    }} className={'socialIcon'}  src={linkedin}/>
                </div>
            {/* </div> */}
            <div className={'footerColContainer'} style={{flexDirection:'row',justifyContent:"space-evenly", display: 'flex', width: '70%', marginLeft: 'auto', marginRight: 'auto', paddingTop: 25}}>
             {/* <div className={'footerCol'}> */}
                <Link to="/about-us" className={'footerLink'}>{t("Charity.About")}</Link>
                <Link  to="/contact" className={'footerLink'}>{t("Charity.Contact")}</Link>
             {/* </div> */}
             {/* <div className={'footerCol'}> */}
                <Link to="/rgpd" className={'footerLink'}>{t("Charity.RGPD")}</Link>
                <Link to="/cgu" className={'footerLink'}>{t("Charity.TermsOfService")}</Link>
             {/* </div> */}
             {/* <div className={'footerCol'}>
             </div> */}
            </div>
        </div>
        <div className={'footerCopyright'} style={{marginTop: 'auto', marginBottom: 15, fontSize: 14, textAlign: 'center'}}>2021 © Charityclic. Tous droits réservés.</div>
    </div>
}
