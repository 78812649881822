import React from "react";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Donations from "./Pages/Donations";
import Associations from "./Pages/Associations";
import Dashboard from "./Pages/Dashboard";
import CRM from "./Pages/CRM";
import Signup from "./Pages/Signup";
import Donate from "./Pages/Donate";
import Association from "./Pages/Association";
import Campain from "./Pages/Campain";
import Contact from "./Pages/Contact";
import Login from "./Pages/Login";
import AssocOrCampain from "./Pages/AssocOrCampain";
import Parain from "./Pages/Parain";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { UserProvider } from "./Context/UserContext";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ResetPassword from "./Pages/ResetPassword";
import RGPD from "./Pages/RGPD";
import CGU from "./Pages/CGU";
import AboutUs from "./Pages/AboutUs";
import BackOffice from "./Pages/BackOffice";
import { StyledEngineProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Stack from "@mui/material/Stack";
import "@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css";
import Navbar from "./Components/Navbar";
import Pairing from "./Components/Pairing/Pairing";
import Billetterie from "./Pages/Billetterie/Billetterie";
import DonnateBillet from "./Pages/BuyTickets";
import HomeBilletterie from "./Pages/homeBilletterie/index.js";
import { HelmetProvider } from "react-helmet-async";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
function App({ history }) {
  const helmetContext = {};
  return (
    // <HelmetProvider>
    <Elements stripe={stripePromise}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <UserProvider>
            <Router>
              <Header history={history} />
              <ReactNotifications />
              <div style={{ flex: 1 }}>
                {/* <ReactNotification /> */}
                <Switch>
                  <Route
                    path="/pairing/:campaignId/:response"
                    component={Pairing}
                  />
                  <Route path="/tickets" component={HomeBilletterie} />
                  <Route path="/donations" component={Donations} />
                  <Route
                    path="/resetPassword/:token"
                    component={ResetPassword}
                  />
                  <Route path="/back-office" component={BackOffice} />
                  <Route path="/association/:name" component={Association} />
                  <Route path="/campain/:name/:parain" component={Parain} />
                  <Route path="/billetterie/:name" component={Billetterie} />
                  <Route path="/ticketvisualize" component={Billetterie} />
                  <Route path="/campain/:name" component={Campain} />
                  <Route path="/visualize" component={Campain} />
                  <Route path="/association" component={Associations} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/about-us" component={AboutUs} />
                  <Route path="/crm" component={CRM} />
                  <Route path="/signup" component={Signup} />
                  <Route path="/donate" component={Donate} />
                  <Route path="/buytickets" component={DonnateBillet} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/login" component={Login} />
                  <Route path="/rgpd" component={RGPD} />
                  <Route path="/navbar" component={Navbar} />
                  <Route path="/cgu" component={CGU} />
                  <Route path="/:name" component={AssocOrCampain} />
                  <Route path="/" component={Home} />
                </Switch>
              </div>
              <Footer />
            </Router>
          </UserProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </Elements>
    // </HelmetProvider>
  );
}

export default App;
