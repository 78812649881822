import {ListItem, ListItemText} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Sidebar.module.css"

const actions = [
  {
    title: 'BackOffice.Sidebar.Association',
    link: '/back-office/association',
    description: 'BackOffice.Sidebar.Association',
  },
  {
    title: 'BackOffice.Sidebar.Campaign',
    link: '/back-office/campaigns',
    description: 'BackOffice.Sidebar.Campaign',
  },
  {
    title: 'BackOffice.Sidebar.Billetteries',
    link: '/back-office/billetteries',
    description: 'BackOffice.Sidebar.Billetteries',
  },
  {
    title: 'BackOffice.Sidebar.Donor',
    link: '/back-office/donations',
    description: 'BackOffice.Sidebar.Donor',
  },
  {
    title: 'BackOffice.Sidebar.Treasury',
    link: '/back-office/treasury',
    description: 'BackOffice.Sidebar.Treasury',
  },
  {
    title: 'BackOffice.Sidebar.MyBills',
    link: '/back-office/my-bills',
    description: 'BackOffice.Sidebar.MyBills',
  },
  {
    title: 'BackOffice.Sidebar.Statistics',
    link: '/back-office/statistics',
    description: 'BackOffice.Sidebar.Statistics',
  },
  // {
  //   title: 'BackOffice.Sidebar.Disconnection',
  //   link: '/back-office/disconnection',
  //   description: 'BackOffice.Sidebar.Disconnection',
  // },
]


const Sidebar = () => {
  //const history = useHistory();
  const currentUrl = window.location.href;
  const { t } = useTranslation()

  return (
    <div className={styles.sidebar}>
      <div>
        <h3 style={{color:"#145a89"}}>Back-office de Charityclic</h3>
        <div style={{backgroundColor:"#145a89", borderRadius:"15px",padding:"20px 0px"}}>
        {actions.map((action, index) => (
          <div
          onClick={() => window.location.assign(action.link)}
          style={{color:"#ffffff",fontSize:"20px",padding:"10px 20px"}}
          
          >
            <span className={currentUrl.split("/")[currentUrl.split("/").length-1] == action.link.split("/")[2]? styles.linked:styles.links}>{t(action.title)}</span>
          </div>
          // <ListItem
          //   button
          //   key={index.toString()}
          //   className={currentUrl.indexOf(action.link) !== -1 ? styles.activeLink : ''}
          //   onClick={() => window.location.assign(action.link)}
          // >
          // <span>
          //   <ListItemText primary={t(action.title)} />
          //   {/*<span>{t(action.description)}</span>*/}
          // </span>
          // </ListItem>
        ))}
        </div>
      </div>
    </div>
  )
}

export default Sidebar;