import React, {useState, createRef, useEffect} from 'react';
import styles from './index.css';
import plus from '../../assets/plus.png'
import valid_tick from '../../assets/valid_tick.png'
import {useStripe} from '@stripe/react-stripe-js';
import validator from 'validator';
import { Store } from 'react-notifications-component';
import {uploadFile} from '../../Services/s3'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Select from 'react-select'
import options from '../../Services/countries'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { APIURL } from '../../Services/consts';
import { getLinkAvailability } from '../../Services/ws';
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";
import Plus3 from '../../assets/New/plus3.png'


  export default function Signup({history}) {
    const [step, setStep] = useState(0);
    const [loading, setLoading] = useState(false)
    const [loadingLogo, setLoadingLogo] = useState(false)
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [loadingID, setLoadingID] = useState(false)
    const [loadingRIB, setLoadingRIB] = useState(false)
    const [loadingJustif, setLoadingJustif] = useState(false)
    const [assoName, setAssoName] = useState('');
    const [usage, setUsage] = useState('');
    const [phone, setPhone] = useState('');
    const [link, setLink] = useState('');
    const [assoAddress, setAssoAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState({name: 'France', value: 'FR'});
    const [taxId, setTaxId] = useState('000000001')
    const [postCode, setPostCode] = useState('');
    const [coord, setCoord] = useState('');
    const [directorFunct, setDirectorFunct] = useState('');
    const [directorFirstname, setDirectorFirstname] = useState('');
    const [directorLastname, setDirectorLastname] = useState('');
    const [directorAddress, setDirectorAddress] = useState('');
    const [directorPhone, setDirectorPhone] = useState('');
    const [directorCity, setDirectorCity] = useState('');
    const [directorCountry, setDirectorCountry] = useState('');
    const [directorEmail, setDirectorEmail] = useState('');
    const [directorPass, setDirectorPass] = useState('');
    const [directorConf, setDirectorConf] = useState('')
    const [conditionsChecked, setConditionsChecked] = useState(false)
    const [bic, setBic] = useState('');
    const [iban, setIban] = useState('');
    const [bank, setBank] = useState('');
    const [rib, setRib] = useState(null)
    const [pic, setPic] = useState(null)
    const [ID, setID] = useState(null)
    const [Justif, setJustif] = useState(null)
    const [ID_BACK, setID_BACK] = useState(null)
    const [status, setStatus] = useState(null)
    const [bankAddress, setBankAddress] = useState('');
    const inputOpenFileIDRef = createRef();
    const inputOpenFileStatusRef = createRef();
    const inputOpenFileRIBRef = createRef();
    const inputOpenFileJustifRef = createRef();
    const inputOpenFileRef = createRef();
    const stripe = useStripe();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0,0);
    }, [step])

    const validateStep = async (step) => {
        switch (step) {
            case 0:
                // if (pic && pic.url && !assoName.isEmpty() && !usage.isEmpty() && !phone.isEmpty() && !link.isEmpty() && !assoAddress.isEmpty() && !city.isEmpty()&& !postCode.isEmpty() && country.value) {
                //     setStep(1)
                //     return true
                // }

                var errorFields = []
                !(pic && pic.url) && errorFields.push(t("SingIn.Errors.AssociationLogo"))
                assoName.isEmpty() && errorFields.push(t("SingIn.Errors.AssociationName"))
                usage.isEmpty() && errorFields.push(t("SingIn.Errors.AssociationUsage"))
                phone.isEmpty() && errorFields.push(t("SingIn.Errors.AssociationPhone"))
                link.isEmpty() && errorFields.push(t("SingIn.Errors.AssociationLink"))
                assoAddress.isEmpty() && errorFields.push(t("SingIn.Errors.AssociationAddress"))
                city.isEmpty() && errorFields.push(t("SingIn.Errors.AssociationCity"))
                postCode.isEmpty() && errorFields.push(t("SingIn.Errors.AssociationPostal"))
                !(country.value) && errorFields.push(t("SingIn.Errors.AssociationCountry"))
                if (errorFields.length == 0) {
                    var available = await getLinkAvailability(link)
                    if (!available.available) {
                        Store.addNotification({
                            title: t("SingIn.Errors"),
                            message: t("SingIn.Errors.AssociationLinkError"),
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                            duration: 10000,
                            onScreen: true
                            }
                        });
                        // console.log(available);
                        // setLoading(false)
                        return;
                    }
                    setStep(1)
                    return true
                }
                Store.addNotification({
                    title: t("SingIn.Errors"),
                    message: t("SingIn.Errors.AssociationFieldsError") + errorFields.join(', '),
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 10000,
                      onScreen: true
                    }
                  });
                return false
                break;
            case 1:
                // if (!directorLastname.isEmpty() && !directorFirstname.isEmpty() && !directorFunct.isEmpty() && !directorPhone.isEmpty() && !directorAddress.isEmpty() && !directorCity.isEmpty() && !directorCountry.isEmpty() && !directorPass.isEmpty() && !directorConf.isEmpty() && directorPass == directorConf) {
                //     setStep(2)
                //     return true
                // }

                var errorFields = []
                directorLastname.isEmpty() && errorFields.push(t("SingIn.Errors.DirectorLastName"))
                directorFirstname.isEmpty() && errorFields.push(t("SingIn.Errors.DirectorFirstName"))
                directorFunct.isEmpty() && errorFields.push(t("SingIn.Errors.DirectorFunction"))
                directorPhone.isEmpty() && errorFields.push(t("SingIn.Errors.DirectorTelephone"))
                directorAddress.isEmpty() && errorFields.push(t("SingIn.Errors.DirectorAddress"))
                directorCity.isEmpty() && errorFields.push(t("SingIn.Errors.DirectorCity"))
                directorCountry.isEmpty() && errorFields.push(t("SingIn.Errors.DirectorCountry"))
                directorPass.isEmpty() && errorFields.push(t("SingIn.Errors.DirectorPassword"))
                directorConf.isEmpty() && errorFields.push(t("SingIn.Errors.DirectorPasswordConfirmation"))
                if (errorFields.length == 0) {
                    if (directorPass != directorConf) {
                        Store.addNotification({
                            title: t("SingIn.Errors"),
                            message: t("SingIn.Errors.DirectorPasswordDifferent"),
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                              duration: 10000,
                              onScreen: true
                            }
                          });
                        return false
                    }


                    //console.log('checking pwd');
                    //console.log(directorPass);
                    //console.log(/^(?=.[a-z])(?=.[A-Z])(?=.*\d).{8,15}$/.test(directorPass));
                    const expression = /^(?=.[a-z])(?=.[A-Z])(?=.*\d).{8,15}$/;
                      const test = expression.test(directorPass);
                      //console.log(test);


                    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(directorPass) == false) {
                        Store.addNotification({
                            title: t("SingIn.Errors"),
                            message: t("SingIn.Errors.DirectorPasswordWeak"),
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                              duration: 10000,
                              onScreen: true
                            }
                          });
                        return false
                    }
                    if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test('+' + directorPhone)) {
                        Store.addNotification({
                            title: t("SingIn.Errors"),
                            message: t("SingIn.Errors.DirectorPhoneError"),
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                            duration: 10000,
                            onScreen: true
                            }
                        });
                        return false
                    }
                    setStep(2)
                    return true
                }
                Store.addNotification({
                    title: t("SingIn.Errors"),
                    message: t("SingIn.Errors.AssociationFieldsError") + errorFields.join(', '),
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 10000,
                      onScreen: true
                    }
                  });
                return false
            case 2:
            //console.log(conditionsChecked);

                if (conditionsChecked == true) {
                    setStep(3)
                    return true
                }
                Store.addNotification({
                    title: t("SingIn.Errors"),
                    message: t("SingIn.Errors.Conditions"),
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 10000,
                    onScreen: true
                    }
                });
                return false
            case 3:
                if (!iban.isEmpty() && !bic.isEmpty() && !bank.isEmpty()) {
                    // setStep(3)
                    createAccount();
                    return true
                }
                // Store.addNotification({
                //     title: "Erreur",
                //     message: "Merci d'accepter les conditions générales.",
                //     type: "danger",
                //     insert: "top",
                //     container: "top-center",
                //     animationIn: ["animate__animated", "animate__fadeIn"],
                //     animationOut: ["animate__animated", "animate__fadeOut"],
                //     dismiss: {
                //     duration: 10000,
                //     onScreen: true
                //     }
                // });
                return false
            default:
                break;
        }
    }

    const handleError = (err) => {
        //console.log(err);

        switch (err.param) {
            case "person[phone]":
                Store.addNotification({
                    title: t("SingIn.Errors"),
                    message: t("SingIn.Errors.PhoneError"),
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 10000,
                    onScreen: true
                    }
                });
                break;
            case "bank_account[account_number]":
                Store.addNotification({
                    title: t("SingIn.Errors"),
                    message: t("SingIn.Errors.BankAccount"),
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 10000,
                    onScreen: true
                    }
                });
                break;
            default:
                Store.addNotification({
                    title: t("SingIn.Errors"),
                    message: err.message,
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                    duration: 10000,
                    onScreen: true
                    }
                });
                break;
        }
    }

    const createAccount = async () => {
        setLoading(true)
            const accountResult = await stripe.createToken('account', {
                business_type: 'non_profit',
                tos_shown_and_accepted: true,
                company: {
                    name: assoName,
                    tax_id: taxId,
                    address: {
                    city: city,
                    line1: assoAddress,
                    postal_code: postCode,
                    },
                    phone: '+' + phone,
                    owners_provided: true,
                    directors_provided: true,
                    executives_provided: true,
                }
            });

            const personResult = await stripe.createToken('person', {
                first_name: directorFirstname,
                last_name: directorLastname,
                email: directorEmail,
                dob: {
                year: 1995,
                month: 9,
                day: 15,
                },
                address: {
                line1: directorAddress,
                city: directorCity,
                postal_code: directorCountry,
                },
                phone: '+' + directorPhone,
                relationship: {
                executive: true,
                owner: true,
                representative: true,
                title: directorFunct,
                },
                "verification": {
                "additional_document": {
                    "front": Justif ? Justif.id : ''
                },
                "document": {
                    "back": ID_BACK ? ID_BACK.id: '',
                    "front": ID ? ID.id: ''
                }
            }
            });
            //console.log('personResult', personResult);

            if (personResult.error) {
                handleError(personResult.error)
                setLoading(false)
                return ;
            }

            const bankAccountResult = await stripe.createToken('bank_account', {
                country: 'FR',
                currency: 'eur',
                account_holder_name: directorFirstname + ' ' + directorLastname,
                account_holder_type: 'company',
                // routing_number: bic,
                account_number: iban.trim()
            });
            if (bankAccountResult.error) {
                handleError(bankAccountResult.error)
                setLoading(false)
                return ;
            }

                //console.log(rib);
                //console.log(status);
                //console.log(ID);
                //console.log(pic);

            //   return ;
            var docs = [];
            if (rib) {
                docs.push({
                    documentId: rib ? rib.id: '',
                    type: "BANK"
                })
            }
            if (status) {
                docs.push({
                    documentId: status ? status.id: '',
                    type: "OWNERSHIP"
                })
            }
            if (ID) {
                docs.push({
                    documentId: ID ? ID.id: '',
                    type: "ID_FRONT"
                })
                docs.push({
                    documentId: ID ? ID.id: '',
                    type: "ID_BACK"
                })
            }

                const res = await fetch(APIURL + '/auth/signUp', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "representative": {
                        "email": directorEmail,
                        "phone": directorPhone,
                        "password": directorPass,
                        "firstName": directorFirstname,
                        "lastName": directorLastname,
                        "token": personResult.token.id
                    },
                    "association": {
                        "usageName": usage,
                        "linkParam": link,
                        "logoUrl": pic && pic.url,
                        "token": accountResult.token.id,
                        "businessDescription": "Association",
                        "countryISO": country.value,
                        "ip": accountResult.token.client_ip
                    },
                    "documents" : docs,
                    "bankAccount": {
                        token: bankAccountResult.token && bankAccountResult.token.id
                    }
                    })
                });
                setLoading(false)
                //console.log(res);
                if (res.ok) {
                    //console.log(await res.json());
                    setStep(4)
                } else {
                    var jsonRes = await res.json();
                    //console.log(jsonRes);
                    if (jsonRes.message == 'USER_EMAIL_EXISTS') {
                        Store.addNotification({
                            title: t("SingIn.Errors"),
                            message: t("SingIn.Errors.EmailAddress"),
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 10000,
                                onScreen: true
                            }
                            });
                        return ;
                    }
                    if (typeof(jsonRes.message) == 'string' ) {
                        Store.addNotification({
                            title: t("SingIn.Errors"),
                            message: jsonRes.message,
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 10000,
                                onScreen: true
                            }
                            });
                        return ;
                    }
                    for (let i = 0; i < jsonRes.message.length; i++) {
                        const element = jsonRes.message[i];
                        Store.addNotification({
                            title: t("SingIn.Errors"),
                            message: element,
                            type: "danger",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 10000,
                                onScreen: true
                            }
                            });
                    }
                }
    }

    return (
        <>
            <div style={{justifyContent: 'space-evenly', flexDirection: 'row', width: '95%', marginLeft: 'auto', marginRight: 'auto'}} className="signUpRow">
                <p onClick={() => setStep(0)} className="stepTxt">{t("SingIn.Step1.AssociationInfo")}</p>
                <p onClick={() => setStep(step < 1 ? step : 1)} className="stepTxt">{t("SingIn.Step2.DirectorInfo")}</p>
                <p onClick={() => setStep(step < 2 ? step : 2)} className="stepTxt">{t("SingIn.Step3.Documents")}</p>
                <p onClick={() => setStep(step < 3 ? step : 3)} className="stepTxt">{t("SingIn.Step4.Bank")}</p>
            </div>
            <div style={{flexDirection: 'row', width: '95%', marginLeft: 'auto', marginRight: 'auto', alignItems:"center"}} className="signUpRow">
                <div onClick={() => setStep(0)} className={'stepDot'} />
                <div className={step >= 1 ? "stepLineFull" : 'stepLine'} />
                <div onClick={() => setStep(step < 1 ? step : 1)} className={step >= 1 ? "stepDot" : 'stepDotEmpty'} />
                <div className={step >= 2 ? "stepLineFull" : 'stepLine'} />
                <div onClick={() => setStep(step < 2 ? step : 2)} className={step >= 2 ? "stepDot" : 'stepDotEmpty'} />
                <div className={step >= 3 ? "stepLineFull" : 'stepLine'} />
                <div onClick={() => setStep(step < 3 ? step : 3)} className={step >= 3 ? "stepDot" : 'stepDotEmpty'} />
            </div>
            {
                step !== 4 &&
                    <div className="signupContainer" style={{marginBottom: '100px'}}>
                        <p className="newSignupTitle">{step == 2 ? t("SingIn.Step3.Documents") : t("SingIn.FillTheFields")}</p>
                        {/* <div className="signupUnderline" /> */}

                        {
                            step === 0 &&
                            <>
                                <div onClick={() => {inputOpenFileRef?.current.click()}} className="newSignupPlus">
                                {loadingLogo == true ?(
                                     <Loader
                                     type="ThreeDots"
                                     color="#507cab"
                                     height={30}
                                     width={30}/>
                                    ) :(
                                        <img style={{objectFit: 'cover', maxWidth:pic ? 100:50, maxHeight: pic ? 100:50, borderRadius: 40}} src={pic ? pic.url: Plus3} alt="plus"  />
                                    )}
                                    {<p className="newBtnTxt">{!pic ?  t("SingIn.Step1.Logo") : t("SingIn.Step1.ModifyLogo")} </p>}
                                </div>
                                {/* <div style={{textAlign: 'center'}}>{pic && pic.filename}</div> */}
                                <input onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        //console.log(e.target.files[0]);
                                        setLoadingLogo(true)
                                        try {
                                            var fileUrl = await uploadFile(e.target.files[0])
                                            setLoadingLogo(false)
                                            //console.log(fileUrl);
                                            setPic({filename: e.target.files[0].name, url: fileUrl})
                                        } catch (error) {
                                            Store.addNotification({
                                                title: t("SingIn.Errors"),
                                                message: t("SingIn.Errors.UploadPhoto"),
                                                type: "danger",
                                                insert: "top",
                                                container: "top-center",
                                                animationIn: ["animate__animated", "animate__fadeIn"],
                                                animationOut: ["animate__animated", "animate__fadeOut"],
                                                dismiss: {
                                                  duration: 10000,
                                                  onScreen: true
                                                }
                                              });
                                            setLoadingLogo(false)
                                        }
                                    }
                                }} type="file"  accept="image/*" ref={inputOpenFileRef} style={{ display: 'none' }}/>
                                <div className="signuUpForm">
                                    <input  className="inputSignup" type="text" value={assoName}  placeholder={t("SingIn.Step1.AssociationName")} onChange={(evt) => {
                                        setAssoName(evt.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ ']/g, ""))
                                        var link = evt.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z ]/g, "")
                                        link = link.replace(' ', '-').replace(/ /g, '-');

                                        setLink(link)
                                        }} />
                                    <input className="inputSignup" type="text" value={usage}  placeholder={t("SingIn.Step1.AssociationUsage")} onChange={(evt) => {setUsage(evt.target.value.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ ']/g, ""))}} />
                                    <PhoneInput
                                        country={'fr'}
                                        containerClass="inputSignup"
                                        inputClass="phoneInput"
                                        dropdownClass="phoneDropdown"
                                        placeholder={t("SingIn.Step1.AssociationPhone")}
                                        value={phone}
                                        onChange={(phoneNb) => {
                                            if (phoneNb.length == 3 && phoneNb[2] == '0') {
                                                setPhone(phoneNb.substring(0, 2))
                                                return ;
                                            }
                                            setPhone(phoneNb)
                                        }} />
                                    {/* <input className="inputSignup" type="text" value={phone}  placeholder="Téléphone de l'association *" onChange={(evt) => {setPhone(evt.target.value)}} /> */}
                                    <input className="inputSignup" type="text" value={"https://charityclic.com/" + link}  placeholder="www.com/nom voulu à remplir" onChange={(evt) => {
                                        var val = evt.target.value.slice('https://charityclic.com/'.length, evt.target.value.length)
                                        //console.log(val);

                                        setLink(val)
                                    }} />

                                    {/* <input className="inputSignup" type="text" value={link}  placeholder="Lien de votre page d'association *" onChange={(evt) => {setLink(evt.target.value)}} /> */}
                                    <input className="inputSignup" type="text" value={assoAddress}  placeholder={t("SingIn.Step1.AssociationAddress")} onChange={(evt) => {setAssoAddress(evt.target.value)}} />
                                    <input className="inputSignup" style={{marginRight: '20px'}} type="text" value={city}  placeholder={t("SingIn.Step1.City")} onChange={(evt) => {setCity(evt.target.value.replace(/[^a-z \-\_]/gi, ''))}} />
                                    <div className="signUpRow">
                                        <Select onChange={(val) => {
                                            //console.log(val);
                                            setCountry(val)
                                        }}  isDisabled placeholder="France"  classNamePrefix="country-select" className="react-select-country" options={options} />
                                        <input className="inputSignupHalf" type="text" value={postCode}  placeholder={t("SingIn.Step1.Postal")} onChange={(evt) => {setPostCode(evt.target.value)}} />
                                    </div>
                                </div>
                                <div className="newBtn newBtnNext2" style={{fontSize: 20}} onClick={async () => {
                                    validateStep(0)
                                }}>{t("SingIn.Following")}</div>
                            </>
                        }
                        {
                            step === 1 &&
                            <>
                                <div className="signuUpForm">
                                    <div className="signUpRow">
                                        <input className="inputSignupHalf" style={{marginRight: '20px'}} type="text" value={directorFirstname}  placeholder={t("SingIn.Step2.DirectorFistName")} onChange={(evt) => {setDirectorFirstname(evt.target.value)}} />
                                        <input className="inputSignupHalf" type="text" value={directorLastname}  placeholder={t("SingIn.Step2.DirectorLastName")} onChange={(evt) => {setDirectorLastname(evt.target.value)}} />
                                    </div>
                                    <input className="inputSignup" type="text" value={directorFunct}  placeholder={t("SingIn.Step2.DirectorFunction")} onChange={(evt) => {setDirectorFunct(evt.target.value)}} />
                                    <PhoneInput
                                        country={'fr'}
                                        containerClass="inputSignup"
                                        inputClass="phoneInput"
                                        dropdownClass="phoneDropdown"
                                        placeholder={t("SingIn.Step2.DirectorPhone")}
                                        value={directorPhone}
                                        onChange={(phoneNb) => {
                                            if (phoneNb.length == 3 && phoneNb[2] == '0') {
                                                setDirectorPhone(phoneNb.substring(0, 2))
                                                return ;
                                            }
                                            setDirectorPhone(phoneNb)
                                        }} />
                                    <input className="inputSignup" type="text" value={directorAddress}  placeholder={t("SingIn.Step2.DirectorAddress")} onChange={(evt) => {setDirectorAddress(evt.target.value)}} />
                                    <div className="signUpRow">
                                        <input className="inputSignupHalf" style={{marginRight: '20px'}} type="text" value={directorCity}  placeholder={t("SingIn.Step1.City")} onChange={(evt) => {setDirectorCity(evt.target.value)}} />
                                        <input className="inputSignupHalf" type="text" value={directorCountry}  placeholder={t("SingIn.Step1.Postal")} onChange={(evt) => {setDirectorCountry(evt.target.value)}} />
                                    </div>
                                    <input className="inputSignup" type="email" value={directorEmail}  placeholder={t("SingIn.Step2.DirectorUsername")} onChange={(evt) => {setDirectorEmail(evt.target.value)}} />
                                    <input className="inputSignup" type="password" value={directorPass}  placeholder={isMobile ? t("SingIn.Step2.DirectorPasswordMobile") : t("SingIn.Step2.DirectorPassword")} onChange={(evt) => {setDirectorPass(evt.target.value)}} />
                                    <input className="inputSignup" type="password" value={directorConf}  placeholder={t("SingIn.Step2.DirectorPasswordConfirmation")} onChange={(evt) => {setDirectorConf(evt.target.value)}} />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div style={{marginRight: 10}} className="newBtn newBtnNext2" onClick={() => { setStep(0)}}>{t("SingIn.Previous")}</div>
                                    <div style={{marginLeft: 10}} className="newBtn newBtnNext2" onClick={() => { validateStep(1)}}>{t("SingIn.Following")}</div>
                                </div>
                            </>
                        }
                        {
                            step === 2 &&
                            <>
                                <div className="signuUpForm">
                                    {/* <div className="newBlockSignUp"> */}
                                    <div style={{display: 'flex', flexDirection: 'row',justifyContent:"space-between",alignItems: 'center'}}>
                                    <p className="inputTitle">{t("SingIn.Step3.DocumentsAssocStatuses")}</p>
                                    <div onClick={() => {inputOpenFileStatusRef.current.click()}} className="newSignupPlus2">
                                        {loadingStatus == true ?(
                                            
                                        <Loader
                                        type="ThreeDots"
                                        color="#507cab"
                                        height={30}
                                        width={30}/>
                                        ) :(
                                            <img style={{width: 40, height: 40}} src={Plus3} alt="plus"  />
                                            )}
                                        {status && <p style={{paddingTop: 10}} className="btnTxt">{t("SingIn.Modify")} </p>}
                                    </div>
                                    </div>
                                    <div style={{textAlign: 'center'}}>{status && status.filename}</div>
                                    <input onChange={async (e) => {
                                            //console.log(e.target);
                                        if (e.target.files[0]) {
                                            var myHeaders = new Headers();
                                            myHeaders.append("Authorization", process.env.REACT_APP_STRIPE_PUBLIC_KEY_2);

                                            var formdata = new FormData();
                                            formdata.append("file", e.target.files[0]);
                                            formdata.append("purpose", "additional_verification");

                                            var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                            };

                                            setLoadingStatus(true)
                                            fetch("https://files.stripe.com/v1/files", requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                //console.log(result);
                                                setStatus(result)
                                                setLoadingStatus(false)

                                            })
                                            .catch(error => {
                                                setLoadingStatus(false)
                                                //console.log('error', error)
                                            });
                                        }
                                    }} type="file" accept="application/pdf, image/png" ref={inputOpenFileStatusRef} style={{ display: 'none' }}/>
                                    {/* </div> */}
                                    <div style={{display: 'flex', flexDirection: 'row',justifyContent:"space-between",alignItems: 'center'}}>
                                    <p className="inputTitle">{t("SingIn.Step3.DocumentsManagerId")}</p>
                                    <div onClick={() => {inputOpenFileIDRef.current.click()}} className="newSignupPlus2">
                                    {loadingID == true ?(
                                        <Loader
                                        type="ThreeDots"
                                        color="#507cab"
                                        height={30}
                                        width={30}/>
                                        ) :(
                                            <img style={{width: 40, height: 40}} src={Plus3}  alt="plus" />
                                            )}
                                        {ID && <p style={{paddingTop: 10}} className="btnTxt">{t("SingIn.Modify")} </p>}
                                    </div>
                                    </div>
                                    <div style={{textAlign: 'center'}}>{ID && ID.filename}</div>
                                    <input  onChange={async (e) => {
                                            //console.log(e.target);
                                        if (e.target.files[0]) {
                                            var myHeaders = new Headers();
                                            myHeaders.append("Authorization", process.env.REACT_APP_STRIPE_PUBLIC_KEY_2);

                                            var formdata = new FormData();
                                            formdata.append("file", e.target.files[0]);
                                            formdata.append("purpose", "identity_document");

                                            var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                            };

                                            setLoadingID(true)

                                            fetch("https://files.stripe.com/v1/files", requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                //console.log(result);
                                                setID(result)
                                            })
                                            .catch(error => console.log('error', error));
                                            fetch("https://files.stripe.com/v1/files", requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                //console.log(result);
                                                setID_BACK(result)
                                                setLoadingID(false)
                                            })
                                            .catch(error => {
                                                setLoadingID(false)
                                            });
                                        }
                                    }} type="file" accept="application/pdf, image/png" ref={inputOpenFileIDRef} style={{ display: 'none' }}/>
                                    <div style={{display: 'flex', flexDirection: 'row',justifyContent:"space-between",alignItems: 'center'}}>
                                    <p className="inputTitle">{t("SingIn.Step3.DocumentsAddressProof")}</p>
                                    <div onClick={() => {inputOpenFileJustifRef.current.click()}} className="newSignupPlus2">
                                    {loadingJustif == true ?(
                                        <Loader
                                        type="ThreeDots"
                                        color="#507cab"
                                        height={30}
                                        width={30}/>
                                        ) :(
                                            <img style={{width: 40, height: 40}} src={Plus3}  alt="plus" />
                                            )}
                                        {Justif && <p style={{paddingTop: 10}} className="btnTxt">{t("SingIn.Modify")} </p>}
                                    </div>
                                    </div>
                                    <div style={{textAlign: 'center'}}>{Justif && Justif.filename}</div>
                                    <input  onChange={async (e) => {
                                            //console.log(e.target);
                                        if (e.target.files[0]) {
                                            var myHeaders = new Headers();
                                            myHeaders.append("Authorization", process.env.REACT_APP_STRIPE_PUBLIC_KEY_2);

                                            var formdata = new FormData();
                                            formdata.append("file", e.target.files[0]);
                                            formdata.append("purpose", "additional_verification");

                                            var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                            };

                                            setLoadingJustif(true)

                                            fetch("https://files.stripe.com/v1/files", requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                //console.log(result);
                                                setJustif(result)
                                                setLoadingJustif(false)
                                            })
                                            .catch(error => {
                                                setLoadingJustif(false)
                                            });
                                        }
                                    }} type="file" accept="application/pdf, image/png" ref={inputOpenFileJustifRef} style={{ display: 'none' }}/>
                                    <div style={{display: 'flex', flexDirection: 'row',justifyContent:"space-between",alignItems: 'center'}}>
                                    <p className="inputTitle">{t("SingIn.Step3.DocumentsRIB")}</p>
                                    <div onClick={() => {inputOpenFileRIBRef.current.click()}} className="newSignupPlus2">
                                    {loadingRIB == true ?(
                                        <Loader
                                        type="ThreeDots"
                                        color="#507cab"
                                        height={30}
                                        width={30}/>
                                        ) :(
                                            <img style={{width: 40, height: 40}} src={Plus3}  alt="plus"  />
                                            )}
                                        {rib && <p style={{paddingTop: 10}} className="btnTxt">{t("SingIn.Modify")} </p>}
                                    </div>
                                    </div>
                                    <div style={{textAlign: 'center'}}>{rib && rib.filename}</div>
                                    <input  onChange={async (e) => {
                                            //console.log(e.target);
                                        if (e.target.files[0]) {
                                            var myHeaders = new Headers();
                                            myHeaders.append("Authorization", process.env.REACT_APP_STRIPE_PUBLIC_KEY_2);

                                            var formdata = new FormData();
                                            formdata.append("file", e.target.files[0]);
                                            formdata.append("purpose", "additional_verification");

                                            var requestOptions = {
                                            method: 'POST',
                                            headers: myHeaders,
                                            body: formdata,
                                            redirect: 'follow'
                                            };
                                            setLoadingRIB(true)

                                            fetch("https://files.stripe.com/v1/files", requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                setLoadingRIB(false)
                                                //console.log(result);
                                                setRib(result)
                                            })
                                            .catch(error => {
                                                setLoadingRIB(false)
                                                //console.log('error', error)
                                            });
                                        }
                                    }} type="file" accept="application/pdf, image/png" ref={inputOpenFileRIBRef} style={{ display: 'none' }}/>
                                    <p className="inputTitle">{t("SingIn.Step3.DocumentsSendEmail")}<br />
                                        <a href="mailto:contact@charityclic.com" style={{textDecoration: 'underline', color: 'black'}}>contact@charityclic.com</a>
                                    </p>
                                    <div className="signUpRow" style={{marginTop: '30px', fontSize: '18px'}}>
                                        <input checked={conditionsChecked} onClick={() => {
                                            //console.log('here');

                                            setConditionsChecked(!conditionsChecked)
                                        }} type="radio" className="radio-toolbar" value="check" name="payment" /> {t("SingIn.Step3.DocumentsConditions")}
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div style={{marginRight: 10}} className="newBtn newBtnNext2" onClick={() => { setStep(1)}}>{t("SingIn.Previous")}</div>
                                    <div style={{marginLeft: 10}} className="newBtn newBtnNext2" onClick={() => { validateStep(2)}}>{t("SingIn.Following")}</div>
                                </div>
                            </>
                        }
                        {
                            step === 3 &&
                            <>
                                <div className="signuUpForm">
                                    <input className="inputSignup" type="text" value={bank}  placeholder={t("SingIn.Step4.BankName")} onChange={(evt) => {setBank(evt.target.value)}} />
                                    {/* <input className="inputSignup" type="text" value={bankAddress}  placeholder="Adresse de la banque" onChange={(evt) => {setBankAddress(evt.target.value)}} /> */}
                                    <input className="inputSignup" type="text" value={iban}  placeholder={t("SingIn.Step4.BankIBAN")} onChange={(evt) => {setIban(evt.target.value)}} />
                                    <input className="inputSignup" type="text" value={bic}  placeholder={t("SingIn.Step4.BankBIC")} onChange={(evt) => {setBic(evt.target.value)}} />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div style={{marginRight: 10}} className="newBtn newBtnNext2" onClick={() => { setStep(2)}}>{t("SingIn.Previous")}</div>
                                    <div style={{marginLeft: 10}} className="newBtn newBtnNext2" onClick={async () => {
                                    validateStep(3)

                                    }}>
                                    {loading == true ?(
                                     <Loader
                                     type="ThreeDots"
                                     color="#507cab"
                                     height={30}
                                     width={30}/>
                                    ) :(
                                        t("SingIn.Step4.Validate")
                                    )}
                                    </div>
                                </div>

                            </>
                        }
                    </div>
            }
            {
                step === 4 &&
                <>
                    <div className="signupContainer">
                        <p className="signUpEnd">{t("SingIn.Step4.EndText1")} < br/>{t("SingIn.Step4.EndText2")} < br/>{t("SingIn.Step4.EndText3")}
                        </p>
                    </div>
                    <div className="btn" style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '100px'}} onClick={async () => {
                        history.push('/');

                    }}>{t("SingIn.Step4.Back")}</div>
                </>
            }
        </>
    )
}
