import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import {useEffect, useState} from "react";
import {getBackOffice} from "../../Services/ws";
import { downloadExcel, downloadPDF } from "../../Services/utils";
import DownloadButton from '../../Components/Elements/DownloadButton/DownloadButton'
import Search from "../../Components/Elements/Search/Search"
import { useTranslation } from "react-i18next"

import styles from './Donation.module.css';

var fx = require("money");

fx.base = "EUR";
fx.rates = {
  "USD" : 1.19, // eg. 1 USD === 0.745101 EUR
  "GBP" : 0.86, // etc...
  "ILS" : 3.89,
  "EUR" : 1,        // always include the base rate (1:1)
  /* etc */
}


const Donations = () => {
  const [dataSource, setDataSource] = useState([]);
  const [search, setSearch] = useState('')
  const [currentData, setCurrentData] = useState([])
  const [allCampaign,setAllCampaign] = useState([])
  const { t } = useTranslation()

  const getData = async () => {
    try {
      
      const data = await getBackOffice('/donation/back-office');
      const data2 = await getBackOffice('/billetterie/back-office');
      const data3 = await getBackOffice('/billetterie/back-office2');
      console.log(data);
      console.log(data2);
      console.log(data3);
      const allNamesCampaignTemp = await getBackOffice('/campaign/back-office-names')
      setAllCampaign(allNamesCampaignTemp);
      setDataSource(data)
      setCurrentData(data)
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (event) => {
    setSearch(event.target.value.toLowerCase());
    if(search.length > 1) {
      const result = currentData.filter(item => item.donation?.campaign?.associationName.toLowerCase().includes(search) || item.donation?.donor?.firstName.toLowerCase().includes(search) || item.donation?.donor?.lastName.toLowerCase().includes(search))
      setDataSource(result)
    } else {
      setDataSource(currentData)
    }
  };


  const columns = [
    'BackOffice.Donation.Association',
    'BackOffice.Donation.Origin',
    'Dashboard.Donation.ColName',
    'BackOffice.Donation.Parrain',
    'BackOffice.Donation.FirstName',
    'BackOffice.Donation.Name',
    'BackOffice.Donation.Email',
    'BackOffice.Donation.Address',
    'BackOffice.Donation.Postal',
    'BackOffice.Donation.City',
    'BackOffice.Donation.Country',
    'BackOffice.Donation.Phone',
    'BackOffice.Donation.Payment',
    'BackOffice.Donation.Times',
    'BackOffice.Donation.NameCerfa',
    'BackOffice.Donation.NumberCerfa',
    'BackOffice.Donation.Date',
    'BackOffice.Donation.Amount',
    'BackOffice.Donation.Currency',
    'BackOffice.Donation.Amount'
  ]

  useEffect(() => getData(), []);

  const CellRender = (cell) => (
    <p style={{fontSize: '12px'}}>{cell}</p>
  )

  return (
    <>
    <div>
      <div className={styles.pageHead}>
        <h1 style={{color:"#145a89"}}>{t("BackOffice.Donation.Title")}</h1>
        <div className={styles.tableNavbar}>
          <Search change={handleSearch} />
          <div className={styles.tableNavbarButtons}>
          <div className={styles.viewBtn} style={{marginRight:"10px"}} name={'PDF'} onClick={() => downloadPDF('associationTable')} >PDF</div>
          <div className={styles.viewBtn} name={'Excel'} onClick={() => downloadExcel('associationTable')} >Excel</div>
          </div>
        </div>
      </div>

        {
          dataSource.length && (
            <TableContainer component={Paper} className={styles.tableContainer} id={'associationTable'}>
              <Table className={styles.table} aria-label="simple table">
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    {
                      columns.map(cel => (
                        <TableCell size='small'>{t(cel)}</TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody className={styles.tableBody}>
                  {dataSource.map((row,index) => {
                    let createdAt = new Date(row?.donation?.createdAt)
                    let createdAtDay = createdAt.getDate() && createdAt.getDate().length === 1 ? '0' + createdAt.getDate() : createdAt.getDate()
                    let createdAtMonth = createdAt.getMonth() + 1
                    let createdYear = createdAt.getFullYear()
                    let createdHours = createdAt.getHours()
                    let newCreatedDate = createdAtDay + '.' + createdAtMonth + '.' + createdYear + '/' + createdHours

                    if(!row.donation) {
                      return
                    }

                    let convertedMoney = fx(row?.donation?.amount).from(row?.originalCurrency.toUpperCase()).to('EUR');
                    convertedMoney = Number(convertedMoney.toFixed(2))
                    const campaignName = allCampaign.filter(camp=>camp._id === row.donation.campaign.id)
                    console.log(row);
                    return (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{CellRender(row?.donation?.campaign?.associationName)}</TableCell>
                          <TableCell>{campaignName?"Campagne":"Page de don"}</TableCell>
                          <TableCell>{CellRender(campaignName[0]?.title[0].content)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.sponsor?.name ? row?.donation?.sponsor?.name : '-')}</TableCell>
                          <TableCell>{CellRender(row?.donation?.donor?.firstName)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.donor?.lastName)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.donor?.email)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.donor?.address?.lineOne)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.donor?.address?.zipCode)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.donor?.address?.city)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.donor?.address?.country)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.donor.phone)}</TableCell>
                          <TableCell>{CellRender(row?.type)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.intervals)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.donor?.firstName + ' ' + row?.donation?.donor?.lastName)}</TableCell>
                          <TableCell>{CellRender(row?.internalReference ? row?.internalReference : '-')}</TableCell>
                          <TableCell>{CellRender(newCreatedDate)}</TableCell>
                          <TableCell>{CellRender(row?.donation?.amount)}</TableCell>
                          <TableCell>{CellRender(row?.originalCurrency)}</TableCell>
                          <TableCell>{CellRender(convertedMoney)}</TableCell>
                        </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }
      </div>
    </>
  );
}

export default Donations;