import React from "react";
import styles from "./Home.css";
import logo from "../../assets/website_logo.svg";
import arrowDown from "../../assets/angle-down-solid.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";
import whatsapp from "../../assets/whatsapp.svg";
import linkedin from "../../assets/linkedin.svg";
import iconDonate from "../../assets/icon_donate.svg";
import iconAssoc from "../../assets/icon_association.svg";
import iconSecu from "../../assets/icon_securite.svg";
import iconConfiance from "../../assets/icon_confiance.svg";
import videoPlaceholder from "../../assets/video_placeholder.png";
import videoPres from "../../assets/video_presentation.mp4";
import videoPresEn from "../../assets/video_presentation_en.mp4";
import value1 from "../../assets/ico-plateforme.svg";
import value2 from "../../assets/ico-experts-asso.svg";
import value3 from "../../assets/ico-experts-marketing.svg";
import value4 from "../../assets/ico-accompagnement.svg";
import iconCerfa from "../../assets/cerfaNew.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useRef, useEffect, useState } from "react";
import {
  getCampains,
  getData,
  getUpcomingCampains,
  getMyBilletteries,
} from "../../Services/ws";
import { numberWithSpaces, calcPercentage } from "../../Services/utils";
import { isMobile } from "react-device-detect";
import Countdown from "react-countdown";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
} from "react-share";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Helmet } from "react-helmet";
import LogoShare from "../../assets/logo-share.jpeg";
import check from "../../assets/New/check-circle.svg";
import CountUp from "react-countup";
import {
  Waypoint,
  previousPosition,
  currentPosition,
  event,
} from "react-waypoint";
import { useSprings, animated } from "react-spring";
// import SEO from "../../Components/SEO/SEO";

var fx = require("money");

fx.base = "EUR";
fx.rates = {
  USD: 1.19, // eg. 1 USD === 0.745101 EUR
  GBP: 0.86, // etc...
  ILS: 3.89,
  EUR: 1, // always include the base rate (1:1)
  /* etc */
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown
    return (
      <span>{`${days} J : ${hours} H : ${minutes} M : ${seconds} S`}</span>
    );
  }
};
const renderer2 = ({ days, hours, minutes, seconds, completed }) => {
  // console.log(completed);
  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown
    return (
      <span>{`${days} J : ${hours} H : ${minutes} M : ${seconds} S`}</span>
    );
  }
};

const SliderBlock = ({ history, campain }) => {
  // console.log(campain)
  // var total = 0;
  // var currency = 'eur';
  var isFuture = new Date(campain.startingAt) > new Date();
  const { t } = useTranslation();

  // if (campain.funding && isFuture == false) {
  //     for (let i = 0; i < campain.funding.length; i++) {
  //         const element = campain.funding[i];
  //         total += fx(element.amount).from(element.currency.toUpperCase()).to('EUR');
  //     }
  // }

  // var percentage = calcPercentage(total, campain.goal.amount)
  // var percentagePx = ((percentage/100)*246)
  // var percentagePxBonus = 0
  // 0%   => 0px
  // 25%  => 61.5px
  // 50%  => 123px
  // 75%  => 184.5px
  // 100% => 246px
  // if(percentage>100){
  //     percentagePxBonus = percentagePx - 246
  //     percentagePx=246 - percentagePxBonus - 15
  //     percentagePxBonus = 246
  // }
  // console.log(percentagePx);
  // console.log(percentagePxBonus);

  return (
    <div
      onClick={() => history.push(`/${campain.linkParam}`, { camp: campain })}
      className={"slider-block"}
    >
      <div className={"sb-container"}>
        {campain.poster[0].type == "VIDEO" ? (
          <video
            preload={"auto"}
            autoPlay={true}
            muted={true}
            style={{
              height: 150,
              borderRadius: 20,
              width: "100%",
              objectFit: "cover",
              background: "#f8f8f8",
            }}
          >
            <source src={campain.poster[0].url} />
          </video>
        ) : (
          <img
            style={{
              height: 150,
              borderRadius: 20,
              width: "100%",
              objectFit: "cover",
            }}
            src={campain.poster[0].url}
          ></img>
        )}
        <div
          className={"home-slider-title"}
          style={{ marginTop: 10, textAlign: "left" }}
        >
          {" "}
          {campain.title[0].content}
        </div>
        <div
          className={"home-slider-sub-title"}
          style={{ marginBottom: "20px" }}
        >
          {campain.associationName}
        </div>
        <div style={{ flexDirection: "column", display: "flex", padding: 5 }}>
          {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10, opacity: isFuture? 0 : 1}}>
                    <div className={'home-slider-price'}>{numberWithSpaces(campain.goal.amount, currency)}</div>
                    <div className={'home-slider-price'}>{percentage}%</div>
                </div> */}
          {/* <div style={{background: '#f8f8f8', border:"0.2px solid #471d30", width: '246px', height: 15, borderRadius: 4, opacity: isFuture? 0 : 1}}> */}
          {/* barre % */}
          {/* <div style={{background: new Date(campain.startingAt) > new Date() ? '#471d30' : new Date(campain.endingAt) <= new Date() ? 'linear-gradient(90deg, rgba(71,29,48,1) 0%, rgba(224,3,101,1) 100%)': '#471d30', width: `${percentage > 100 ? 100 : percentage}%`, height: '100%',  borderRadius: 4}}></div>
                <div style={{position: 'relative', top: '-15px', left: percentage>100?`${percentagePx}px`:'0'}}><img style={{display: percentage>100?"block":"none"}} src={check}/></div> */}
          {/* barre % */}
          {/* </div> */}
          {/* <div style={{position: 'relative', top: '-12px', left: percentage>100?`${percentagePx - 40}px`:'0',display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10, opacity: isFuture? 0 : 1}}>
                    <div style={{opacity: percentage>100?"1":"0"}} className={'home-slider-price'}>{numberWithSpaces(total, currency)}</div>
                </div> */}
          {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 5, alignItems: 'center'}}>
                <div  style={{fontSize: 14}} className={'home-slider-subtitle'}>{t("Campaign.Goal")}</div>
                <div style={{fontSize: 16}}  className={'home-slider-subtitle'}>{numberWithSpaces(campain.goal.amount, campain.goal.currency)}</div>
             </div> */}
        </div>

        <div
          className={"home-slider-subtitle"}
          style={{
            width: "85%",
            marginLeft: "auto",
            marginRight: "auto",
            minHeight: 16,
          }}
        >
          {new Date(campain.startingAt) > new Date()
            ? t("Campaign.Starts")
            : new Date(campain.endingAt) <= new Date()
            ? t("Campaign.Completed")
            : t("Billetterie.Current")}
        </div>
        <div className="infoCampagneContainer">
          <div className="statusCampagne">
            {new Date(campain.startingAt) > new Date()
              ? null
              : new Date(campain.endingAt) <= new Date()
              ? t("Home.Merci")
              : t("Home.IlReste")}
          </div>

          <div className="tempsCampagne">
            {new Date(campain.startingAt) > new Date() ? (
              <Countdown
                date={new Date(campain.startingAt)}
                renderer={renderer}
              />
            ) : new Date(campain.endingAt) <= new Date() ? null : (
              <Countdown
                date={new Date(campain.endingAt)}
                renderer={renderer2}
              />
            )}
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: 5,
            marginBottom: 0,
            backgroundColor:
              new Date(campain.startingAt) > new Date()
                ? "#471d30"
                : new Date(campain.endingAt) <= new Date()
                ? "#471d30"
                : "#471d30",
            color: "white",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: "0px 0px 20px 20px",
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          {t("Billetterie.ButtonSee")}
        </div>
      </div>
    </div>
  );
};

export default function HomeBilletterie({ history }) {
  const [campains, setCampains] = useState([]);
  const [trigger1, setTrigger1] = useState(false);
  const [trigger2, setTrigger2] = useState(false);
  const [trigger3, setTrigger3] = useState(false);
  const [trigger4, setTrigger4] = useState(false);
  const [billetterie, setBilletterie] = useState([]);
  // const animation1 = useSprings({
  //     opacity:trigger1?1:0,
  //     transform:trigger1?"translateX(0)":"translateX(50%)"
  // })
  // const animation2 = useSprings({
  //     opacity:trigger2?1:0,
  //     transform:trigger2?"translateX(0)":"translateX(-50%)"
  // })
  // const animation3 = useSprings({
  //     opacity:trigger3?1:0,
  //     transform:trigger3?"translateX(0)":"translateX(50%)"
  // })
  // const animation4 = useSprings({
  //     opacity:trigger4?1:0,
  //     transform:trigger4?"translateX(0)":"translateX(-50%)"
  // })

  useEffect(() => {
    const getAllbilletterie = async () => {
      const billTemp = await getMyBilletteries();
      // console.log(billTemp);
      const allBilletterieValide = billTemp.filter(
        (bil) => new Date(bil.endingAt) > new Date()
      );
      const arrFinal = [];
      for (let i = 0; i < allBilletterieValide.length; i++) {
        if (
          !allBilletterieValide[i].hiding ||
          allBilletterieValide[i].hiding === false
        ) {
          arrFinal.push(allBilletterieValide[i]);
        }
      }
      // console.log(allBilletterieValide);
      setBilletterie(arrFinal);
    };
    getAllbilletterie();
  }, []);

  const [data, setData] = useState({
    associations: 0,
    nbDonation: 0,
    amount: 0,
  });
  const { t } = useTranslation();
  useEffect(() => {
    getCampains(0).then((res) => {
      //console.log('home campains', res);
      // getUpcomingCampains(0).then((res2) => {
      //     //console.log('home campains', res2);
      //     setCampains([...res2, ...res])
      // })
      setCampains(res);
      getCampains(1).then((res2) => {
        //console.log('home campains 2', res2);
        // getUpcomingCampains(0).then((res2) => {
        //     //console.log('home campains', res2);
        //     setCampains([...res2, ...res])
        // })
        setCampains([...res, ...res2]);
      });
    });
    getData().then((res) => {
      //console.log(res);
      setData({
        associations: res.associations,
        nbDonation:
          res.donations.length > 0
            ? res.donations
                .map((item) => item.count)
                .reduce((prev, next) => prev + next)
            : 0,
        amount:
          res.donations.length > 0
            ? res.donations
                .map((item) => item.total)
                .reduce((prev, next) => prev + next)
            : 0,
      });
    });
  }, []);

  // useEffect(() => {
  //     if (history.location.search.indexOf('?campagnes') != -1) {
  //         scrollTo()
  //     }

  // }, [history.location.pathname]);
  const TopHome = () => (
    <div
      className="home-top-block"
      style={{ flex: 1, display: "flex", flexDirection: "row" }}
    >
      <div className={"home-top-left-block"}>
        <div className={"th-title"}>
          <span style={{ color: "#e00365" }}>Charityclic</span>
          {`${t("Charity.title")}`}
        </div>
        {isMobile && (
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={"video-cont"}
          >
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/8sLeOcFq4A0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            <video
              preload={"none"}
              poster={videoPlaceholder}
              className={"homeVideo"}
              src={i18n.language === "fr" ? videoPres : videoPresEn}
              controls
            >
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        <div className={"th-desc"}>{t("Charity.text")}</div>
        <div className={"home-top-left-block-button"}>
          <div
            onClick={() => history.push("/association")}
            className={"btnHome"}
          >
            {t("Charity.buttonAssociation")}
          </div>
          <div onClick={() => history.push("/donations")} className={"btnHome"}>
            {t("Charity.buttonDonor")}
          </div>
        </div>
      </div>
      {!isMobile && (
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={"video-cont"}
        >
          {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/8sLeOcFq4A0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          <video
            preload={"none"}
            poster={videoPlaceholder}
            className={"homeVideo"}
            src={i18n.language === "fr" ? videoPres : videoPresEn}
            controls
          >
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {/* <div className="social-cont" style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 30}}>
                <FacebookShareButton url={(typeof window !== "undefined") ? window.location.href : ""} >
                    <img className={'h-socialIcon'}  src={facebook}/>
                </FacebookShareButton>
                <LinkedinShareButton url={(typeof window !== "undefined") ? window.location.href : ""} >
                <img className={'h-socialIcon'}  src={linkedin}/>
                </LinkedinShareButton>
                <TwitterShareButton url={(typeof window !== "undefined") ? window.location.href : ""} >
                <img className={'h-socialIcon'}  src={twitter}/>
                </TwitterShareButton>
                <WhatsappShareButton url={(typeof window !== "undefined") ? window.location.href : ""} >
                <img className={'h-socialIcon'}  src={whatsapp}/>
                </WhatsappShareButton>
            </div> */}
    </div>
  );

  const MarcheBlock = () => (
    <div
      className="home-top-block"
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#f9f9f9",
        marginTop: "40px",
      }}
    >
      <div className={"home-top-left-block"}>
        <div className={"marche-desc"}>
          <span style={{ color: "#e00365", fontSize: "25px" }}>
            {t("Donateur")}
          </span>{" "}
          {t("Charity.DonorText")}
        </div>
        {isMobile && (
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={"video-cont"}
          >
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/8sLeOcFq4A0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            <video
              preload={"none"}
              poster={videoPlaceholder}
              className={"homeVideo"}
              src={i18n.language === "fr" ? videoPres : videoPresEn}
              controls
            >
              Your browser does not support the video tag.
            </video>
          </div>
        )}
        <div className={"marche-desc"}>
          <span style={{ color: "#e00365", fontSize: "25px" }}>
            {t("Association")}
          </span>{" "}
          {t("Charity.AssociationText")}
        </div>
      </div>
      {!isMobile && (
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className={"video-cont"}
        >
          {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/8sLeOcFq4A0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
          <video
            preload={"none"}
            poster={videoPlaceholder}
            className={"homeVideo"}
            src={i18n.language === "fr" ? videoPres : videoPresEn}
            controls
          >
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {/* <div className="social-cont" style={{display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 30}}>
                <FacebookShareButton url={(typeof window !== "undefined") ? window.location.href : ""} >
                    <img className={'h-socialIcon'}  src={facebook}/>
                </FacebookShareButton>
                <LinkedinShareButton url={(typeof window !== "undefined") ? window.location.href : ""} >
                <img className={'h-socialIcon'}  src={linkedin}/>
                </LinkedinShareButton>
                <TwitterShareButton url={(typeof window !== "undefined") ? window.location.href : ""} >
                <img className={'h-socialIcon'}  src={twitter}/>
                </TwitterShareButton>
                <WhatsappShareButton url={(typeof window !== "undefined") ? window.location.href : ""} >
                <img className={'h-socialIcon'}  src={whatsapp}/>
                </WhatsappShareButton>
            </div> */}
    </div>
  );

  const StatsBlock = () => (
    <div className={"stats-container"}>
      <div
        className={"stats-block"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "400px",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <div className={"stats-title"}>
          <CountUp duration={3} end={data.nbDonation} />
        </div>
        <div className={"stats-desc"}>{t("Charity.Dons")}</div>
      </div>
      <div
        className={"stats-block"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "400px",
          justifyContent: "center",
          alignItems: "center",
          height: "80%",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <div className={"stats-title"}>
          <CountUp duration={3} end={data.associations} />
        </div>
        <div className={"stats-desc"}>{t("Charity.Charities")}</div>
      </div>
      <div
        className={"stats-block"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "400px",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div className={"stats-title"}>
          <CountUp duration={3} separator=" " end={data.amount} />€
        </div>
        <div className={"stats-desc"}>{t("Charity.Funds")}</div>
      </div>
    </div>
  );

  const ValuesBlock = () => {
    return (
      <div className={"values-container"}>
        <Waypoint key={1} />
        <div className={"values-col2"}>
          <div className={"values"}>
            <img
              style={{
                width: "300px",
                height: "100%",
                objectFit: "contain",
                display: "flex",
                flexDirection: "column",
                padding: "20px 0px",
                position: isMobile ? "initial" : "relative",
                left: isMobile ? "auto" : "-100px",
              }}
              src={value4}
            />
          </div>
          <div
            class
            style={{ minWidth: "310px", width: isMobile ? "300px" : "30%" }}
          >
            <div className={"values-title"}>
              {t("Charity.featureFourTitle")}
            </div>
            <div className={"values-desc"}>{t("Charity.featureFourText")}</div>
          </div>
        </div>
        <Waypoint key={2} />
        <div className={"values-col"}>
          <div className={"values"}>
            <img
              style={{
                width: "300px",
                height: "100%",
                objectFit: "contain",
                display: "flex",
                flexDirection: "column",
                padding: "20px 0px",
                position: isMobile ? "initial" : "relative",
                left: isMobile ? "auto" : "100px",
              }}
              src={value3}
            />
          </div>
          <div
            class
            style={{ minWidth: "310px", width: isMobile ? "300px" : "30%" }}
          >
            <div className={"values-title"}>{t("Charity.featureTwoTitle")}</div>
            <div className={"values-desc"}>{t("Charity.featureTwoText")}</div>
          </div>
        </div>
        <Waypoint key={3} />
        <div className={"values-col2"}>
          <div className={"values"}>
            <img
              style={{
                width: "300px",
                height: "100%",
                objectFit: "contain",
                display: "flex",
                flexDirection: "column",
                padding: "20px 0px",
                position: isMobile ? "initial" : "relative",
                left: isMobile ? "auto" : "-100px",
              }}
              src={value1}
            />
          </div>
          <div
            class
            style={{ minWidth: "310px", width: isMobile ? "300px" : "30%" }}
          >
            <div className={"values-title"}>
              {t("Charity.featureThreeTitle")}
            </div>
            <div className={"values-desc"}>{t("Charity.featureThreeText")}</div>
          </div>
        </div>
        <Waypoint key={4} />
        <div className={"values-col"}>
          <div className={"values"}>
            <img
              style={{
                width: "300px",
                height: "100%",
                objectFit: "contain",
                display: "flex",
                flexDirection: "column",
                padding: "20px 0px",
                position: isMobile ? "initial" : "relative",
                left: isMobile ? "auto" : "100px",
              }}
              src={value2}
            />
          </div>
          <div
            class
            style={{ minWidth: "310px", width: isMobile ? "300px" : "30%" }}
          >
            <div className={"values-title"}>{t("Charity.featureOneTitle")}</div>
            <div className={"values-desc"}>{t("Charity.featureOneText")}</div>
          </div>
        </div>
      </div>
    );
  };

  const YouAreBlock = ({ title, title2, desc, btnText, img, action }) => {
    return (
      <div className={"yrb-container"}>
        <div className={"yrb-title"}>{t(title)}</div>
        <div className={"yrb-title"}>{t(title2)}</div>
        <img src={img} className={"yrb-image"} />
        <div className={"yrb-desc"}>{t(desc)}</div>
        <div
          onClick={action}
          style={{ fontSize: "22px" }}
          className={"yrb-button btnHome"}
        >
          {t(btnText)}
        </div>
      </div>
    );
  };

  const PlusBlock = ({ title, desc, img }) => {
    return (
      <div className={"pb-container"}>
        <img src={img} className={"pb-img"} />
        <div className={"pb-title"}>{t(title)}</div>
        <div className={"pb-desc"}>{t(desc)}</div>
      </div>
    );
  };

  const sliderContainerRef = useRef(null);
  const Carousel = ({ history }) => {
    const sliderRef = useRef(null);

    var calcSize =
      sliderContainerRef && sliderContainerRef.current
        ? Math.trunc(sliderContainerRef.current.offsetWidth / 350)
        : billetterie.length;
    calcSize = calcSize > billetterie.length ? billetterie.length : calcSize;
    var settings = {
      dots: true,
      infinite: true,
      arrows: true,
      speed: 500,
      // speed: 50000000,
      autoplaySpeed: 1500,
      slidesToShow: isMobile ? 1 : calcSize,
      slidesToScroll: 1,
      variableWidth: !isMobile,
      autoplay: true,
    };
    var campainsTemp = [...campains];
    var resultCampains = [];
    campainsTemp.forEach((camp) => {
      console.log(camp);
      if (
        (camp.draft == undefined || camp.draft == "no") &&
        (!camp.hiding || camp.hiding === false)
      ) {
        resultCampains.push(camp);
      }
    });
    //console.log(resultCampains)
    return (
      <div
        ref={sliderContainerRef}
        style={{ marginTop: 60, position: "relative" }}
      >
        <Slider ref={sliderRef} {...settings}>
          {billetterie.map((campain) => {
            if (campain.accepted) {
              return (
                <div>
                  <SliderBlock history={history} campain={campain} />
                </div>
              );
            }
          })}
        </Slider>
        {/* <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 370, top: 0, display: 'flex'}}>
            <img onClick={() => {
                //console.log(sliderRef);
                sliderRef.current.slickPrev()
            }} style={{width: 35, marginLeft: 'auto', marginRight: 'auto', marginTop: 50,  zIndex: 30, transform: 'rotate(90deg)'}} src={arrowDown}></img>
            </div>
            <div className={'yrb-slidercorner'} style={{position:'absolute', width: 30, height: 370, top: 0, right: 0, display: 'flex'}}>
            <img onClick={() => {
                //console.log(sliderRef);
                sliderRef.current.slickNext()
            }}  style={{width: 35, marginLeft: 'auto', marginRight: 'auto', marginTop: 50,  zIndex: 30, transform: 'rotate(270deg)'}} src={arrowDown}></img>
            </div> */}
      </div>
    );
  };
  return (
    <div className={"homeContainer"}>
      {/* <SEO
        title={`CharityClick`}
        description={t("Charity.text")}
        name={`CharityClick`}
        type="website"
        image={LogoShare}
      /> */}
      <Helmet>
        <title>CharityClick</title>
        <meta property="og:title" content={`CharityClick`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={t("Charity.text")} />
        <meta property="og:site_name" content={"CharityClic"} />
        <meta property="og:image" itemProp={"image"} content={LogoShare} />
      </Helmet>
      <div className="topContainer">
        <div className="topContainerFilter">
          <TopHome />
        </div>
      </div>

      <StatsBlock />

      {/* <img id="campains" style={{width: isMobile ? 35 : 55, marginLeft: 'auto', marginRight: 'auto', marginTop: 35}} src={arrowDown}></img> */}
      <div className={"h-arrowTitle"}>{t("Charity.billetterieTitle")}</div>
      <div className={"carousel-cont"}>
        <Carousel history={history} />
      </div>
      {/* <StatsBlock /> */}
      {/* <img style={{width: isMobile ? 35 : 55, marginLeft: 'auto', marginRight: 'auto', marginTop: 50}} src={arrowDown}></img> */}

      <div className={"h-arrowTitle"}>{t("CommentCaMarche")}</div>

      <MarcheBlock />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        <div className={"yrb-container"}>
          <div style={{ fontSize: "25px" }} className={"yrb-title"}>
            {t("Charity.youAre")} {t("Charity.Donor")}
          </div>
          <div
            onClick={() => history.push("/donations")}
            className={"yrb-button btnHome"}
          >
            {t("Charity.LearnMore")}
          </div>
        </div>

        <div className={"yrb-container"}>
          <div style={{ fontSize: "25px" }} className={"yrb-title"}>
            {t("Charity.youAre2")} {t("Charity.Association")}
          </div>
          <div
            onClick={() => history.push("/association")}
            className={"yrb-button btnHome"}
          >
            {t("Charity.LearnMore")}
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }} className={"containerValue"}>
        <div className={"titleValues"}>{t("Charity.featuresHead")}</div>
        {/* <ValuesBlock /> */}
        <div className={"values-container"}>
          <Waypoint
            // debug={true}
            key={1}
            bottomOffset="30%"
            scrollableAncestor={window}
            onEnter={(previousPosition, currentPosition, event) => {
              setTrigger1(true);
            }}
            // onLeave={(previousPosition, currentPosition, event) =>{setTrigger1(false)}}
          />
          <div
            style={{
              transition: "1s all ease-in-out",
              opacity: trigger1 ? 1 : 0,
              transform: trigger1 ? "translateX(0)" : "translateX(50%)",
            }}
            className={"values-col2"}
          >
            <div className={"values"}>
              <img
                style={{
                  width: "300px",
                  height: "100%",
                  objectFit: "contain",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 0px",
                  position: isMobile ? "initial" : "relative",
                  left: isMobile ? "auto" : "-100px",
                }}
                src={value4}
              />
            </div>
            <div
              class
              style={{ minWidth: "310px", width: isMobile ? "300px" : "30%" }}
            >
              <div className={"values-title"}>
                {t("Charity.featureFourTitle")}
              </div>
              <div className={"values-desc"}>
                {t("Charity.featureFourText")}
              </div>
            </div>
          </div>
          <Waypoint
            key={2}
            bottomOffset="30%"
            scrollableAncestor={window}
            onEnter={(previousPosition, currentPosition, event) => {
              setTrigger2(true);
            }}
          />
          <div
            style={{
              transition: "1s all ease-in-out",
              opacity: trigger2 ? 1 : 0,
              transform: trigger2 ? "translateX(0)" : "translateX(-50%)",
            }}
            className={"values-col"}
          >
            <div className={"values"}>
              <img
                style={{
                  width: "300px",
                  height: "100%",
                  objectFit: "contain",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 0px",
                  position: isMobile ? "initial" : "relative",
                  left: isMobile ? "auto" : "100px",
                }}
                src={value3}
              />
            </div>
            <div
              class
              style={{ minWidth: "310px", width: isMobile ? "300px" : "30%" }}
            >
              <div className={"values-title"}>
                {t("Charity.featureTwoTitle")}
              </div>
              <div className={"values-desc"}>{t("Charity.featureTwoText")}</div>
            </div>
          </div>
          <Waypoint
            key={3}
            bottomOffset="30%"
            scrollableAncestor={window}
            onEnter={(previousPosition, currentPosition, event) => {
              setTrigger3(true);
            }}
          />
          <div
            style={{
              transition: "1s all ease-in-out",
              opacity: trigger3 ? 1 : 0,
              transform: trigger3 ? "translateX(0)" : "translateX(50%)",
            }}
            className={"values-col2"}
          >
            <div className={"values"}>
              <img
                style={{
                  width: "300px",
                  height: "100%",
                  objectFit: "contain",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 0px",
                  position: isMobile ? "initial" : "relative",
                  left: isMobile ? "auto" : "-100px",
                }}
                src={value1}
              />
            </div>
            <div
              class
              style={{ minWidth: "310px", width: isMobile ? "300px" : "30%" }}
            >
              <div className={"values-title"}>
                {t("Charity.featureThreeTitle")}
              </div>
              <div className={"values-desc"}>
                {t("Charity.featureThreeText")}
              </div>
            </div>
          </div>
          <Waypoint
            key={4}
            bottomOffset="30%"
            scrollableAncestor={window}
            onEnter={(previousPosition, currentPosition, event) => {
              setTrigger4(true);
            }}
          />
          <div
            style={{
              transition: "1s all ease-in-out",
              opacity: trigger4 ? 1 : 0,
              transform: trigger4 ? "translateX(0)" : "translateX(-50%)",
            }}
            className={"values-col"}
          >
            <div className={"values"}>
              <img
                style={{
                  width: "300px",
                  height: "100%",
                  objectFit: "contain",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px 0px",
                  position: isMobile ? "initial" : "relative",
                  left: isMobile ? "auto" : "100px",
                }}
                src={value2}
              />
            </div>
            <div
              class
              style={{ minWidth: "310px", width: isMobile ? "300px" : "30%" }}
            >
              <div className={"values-title"}>
                {t("Charity.featureOneTitle")}
              </div>
              <div className={"values-desc"}>{t("Charity.featureOneText")}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={'yrb-cont'} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
            <YouAreBlock action={() => history.push('/donations')} img={iconDonate} title={"Charity.youAre"} title2={"Charity.Donor"} desc={"Charity.DonorText"} btnText={"Charity.LearnMore"} />
            <YouAreBlock action={() => history.push('/association')} img={iconAssoc} title={"Charity.youAre"} title2={"Charity.Association"} desc={"Charity.AssociationText"} btnText={"Charity.LearnMore"} />
        </div> */}
      <div
        className="plus-block-container"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          marginTop: "80px",
        }}
      >
        <PlusBlock
          img={iconConfiance}
          title={"Charity.HomeAdditionalTrust"}
          desc={"Charity.HomeAdditionalTrustText"}
        />
        <PlusBlock
          img={iconSecu}
          title={"Charity.HomeAdditionalSecurity"}
          desc={"Charity.HomeAdditionalSecurityText"}
        />
        <PlusBlock
          img={iconCerfa}
          title={"Charity.HomeAdditionalTax"}
          desc={"Charity.HomeAdditionalTaxText"}
        />
      </div>
    </div>
  );
}
