import React, {useState, useEffect} from 'react';
import styles from './index.css';
import add from '../../assets/add.png'
import CreateBilletterie from './CreateBilletterie.js'
import AddParrains from './AddParrains.js'
import { numberWithSpaces } from '../../Services/utils';
import {getCollect,getMyCampains, getMyBilletteries,getPaired} from '../../Services/ws'
import { isMobile } from 'react-device-detect';
import { useTranslation } from "react-i18next";

const moment = require('moment');

var fx = require("money");

fx.base = "EUR";
fx.rates = {
    "USD" : 1.19, // eg. 1 USD === 0.745101 EUR
    "GBP" : 0.86, // etc...
    "ILS" : 3.89,
    "EUR" : 1,        // always include the base rate (1:1)
    /* etc */
}

export default function Billetterie({userState, history, setUserState, reload}) {
    const [part, setPart] = useState(0);
    const [selectCampain, setSelectCampain] = useState(null);
    const [selectBilletterie, setSelectBilletterie] = useState(null);
    const [ locationKeys, setLocationKeys ] = useState([])
    const { t } = useTranslation()

    useEffect(() => {
        setPart(0)
        getMyBilletteries().then((result) => {
            setUserState({...userState, billetteries: result})
        })
    }, [reload])
    
    useEffect(()=>{

    },[])

    return (
        <>
        {
            part === 0 &&
            <div>
                {/* <p className="dashTitle">{t("Dashboard.Billetterie")}</p> */}
                <div className="row" onClick={() => {
                    setSelectBilletterie(null)
                    setPart(1)
                    }}>
                    <img className="newDashAddNo" src={add} alt="add"  />
                    <p style={{fontSize: isMobile ? '16px' : '30px'}}>{t("Dashboard.BilletteriePage.AddNew")}</p>
                    <div className={'newBtnNext2'}>{t("Dashboard.CampaignPage.Access")}</div>
                </div>


                <div className="dashInput2-new">
                    <p className="newDashFormTitle">{t("Dashboard.BilletteriePage.IncomingBilletterie")}</p>
                    {/* <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.BilletteriePage.Billetteries")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>
                        
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div> */}
                    {userState.billetteries && userState.billetteries.filter((camp) => new Date(camp.startingAt) > new Date()).slice(0).reverse().map((camp,index) => {
                        var total = 0;
                        var currency = 'eur';
                        if (camp.funding) {
                            for (let i = 0; i < camp.funding.length; i++) {
                                const element = camp.funding[i];
                                total += fx(element.amount).from(element.currency.toUpperCase()).to('EUR');
                            }
                        }
                        return (
                            <div key={index} style={{borderTop:index!=0?"1px solid #5c3748":null, margin:index!=0?"50px 0px":"0px 0px"}}>
                             <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.BilletteriePage.Billetteries")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>
                        
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div>
                                <div className="newRowCamp">
                                    <p className="newDashCamptxt">{camp.title[0].content} {camp.draft === "yes"?t("Dashboard.CampaignPage.Draft"):null}</p>
                                    <p className="newDashCamptxt">{numberWithSpaces(total)}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.startingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.endingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{new Date() >= new Date(camp.startingAt) ? t("Dashboard.CampaignPage.CampaignInProgress") : t("Dashboard.CampaignPage.CampaignFuture") }</p>
                                </div>
                                <div className="newRowBtn">
                                    {/* <div className="btnLeftDash btnGeneral" onClick={() => {
                                        setPart(2)
                                        setSelectCampain(camp)
                                        }}>{t("Dashboard.CampaignPage.AddSponsors")}</div> */}
                                    <div className="newBtnNext2" onClick={() => {
                                        setSelectBilletterie(camp)
                                        setPart(1)
                                    }}>Modifier</div>
                                    <div onClick={() => {
                                        window.open(`/${camp.linkParam}`).focus();
                                    }} className="newBtnNext2">{t("Dashboard.CharityPage.Visualize")}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>



                <div className="dashInput2-new">
                    <p className="newDashFormTitle">{t("Dashboard.BilletteriePage.CurrentBilletterie")}</p>
                    {/* <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.BilletteriePage.Billetteries")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>
                        
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div> */}
                    {userState.billetteries && userState.billetteries.filter((camp) => new Date(camp.endingAt) > new Date() && new Date(camp.startingAt)< new Date()).slice(0).reverse().map((camp,index) => {
                        var total = 0;
                        var currency = 'eur';
                        if (camp.funding) {
                            for (let i = 0; i < camp.funding.length; i++) {
                                const element = camp.funding[i];
                                total += fx(element.amount).from(element.currency.toUpperCase()).to('EUR');
                            }
                        }
                        return (
                            <div key={index} style={{borderTop:index!=0?"1px solid #5c3748":null, margin:index!=0?"50px 0px":"0px 0px"}}>
                             <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.BilletteriePage.Billetteries")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>
                        
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div>
                                <div className="newRowCamp">
                                    <p className="newDashCamptxt">{camp.title[0].content} {camp.draft === "yes"?t("Dashboard.CampaignPage.Draft"):null}</p>
                                    <p className="newDashCamptxt">{numberWithSpaces(total)}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.startingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.endingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{new Date() >= new Date(camp.startingAt) ? t("Dashboard.CampaignPage.CampaignInProgress") : t("Dashboard.CampaignPage.CampaignFuture") }</p>
                                </div>
                                <div className="newRowBtn">
                                    {/* <div className="btnLeftDash btnGeneral" onClick={() => {
                                        setPart(2)
                                        setSelectCampain(camp)
                                        }}>{t("Dashboard.CampaignPage.AddSponsors")}</div> */}
                                    <div className="newBtnNext2" onClick={() => {
                                        setSelectBilletterie(camp)
                                        setPart(1)
                                    }}>Modifier</div>
                                    <div onClick={() => {
                                        window.open(`/${camp.linkParam}`).focus();
                                    }} className="newBtnNext2">{t("Dashboard.CharityPage.Visualize")}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="dashInput2-new">
                    <p className="newDashFormTitle">{t( "Dashboard.BilletteriePage.CompletedBilletterie")}</p>
                    {/* <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.CampaignPage.Campaigns")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>

                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div> */}
                    {userState.billetteries && userState.billetteries.filter((camp) => new Date(camp.endingAt) <= new Date()).slice(0).reverse().map((camp,index) => {
                        var total = 0;
                        var currency = 'eur';
                        if (camp.funding) {
                            for (let i = 0; i < camp.funding.length; i++) {
                                const element = camp.funding[i];
                                total += fx(element.amount).from(element.currency.toUpperCase()).to('EUR');
                            }
                        }
                        return (
                            <div key={index} style={{borderTop:index!=0?"1px solid #5c3748":null, margin:index!=0?"50px 0px":"0px 0px"}}>
                            <div className="newRowSub">
                        <p className="newDashSub">{t("Dashboard.CampaignPage.Campaigns")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.AmountCollected")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDate")}</p>
                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsDateEnd")}</p>

                        <p className="newDashSub">{t("Dashboard.CampaignPage.CampaignsStatus")}</p>
                    </div>
                                <div className="newRowCamp">
                                    <p className="newDashCamptxt">{camp.title[0].content}</p>
                                    <p className="newDashCamptxt">{numberWithSpaces(total)}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.startingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{moment(new Date(camp.endingAt)).format('DD/MM/YYYY')}</p>
                                    <p className="newDashCamptxt">{t("Dashboard.CampaignPage.CampaignEnded")}</p>
                                </div>
                                <div className="newRowBtn">
                                    {/* <div className="btnLeftDash btnGeneral" onClick={() => {
                                        setPart(2)
                                        setSelectCampain(camp)
                                        }}>Ajouter Parrains</div>
                                    <div className="btnCenterDash btnGeneral" onClick={() => {
                                        setSelectCampain(camp)
                                        setPart(1)
                                    }}>Modifier</div> */}
                                    <div onClick={() => {
                                        window.open(`/${camp.linkParam}`).focus();
                                    }} className="newBtnNext2">{t("Dashboard.CharityPage.Visualize")}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        }
        {
            part === 1 &&
            <CreateBilletterie campain={selectBilletterie} userState={userState} setPart={setPart} setUserState={setUserState} />
        }
        {/* {
            part === 2 &&
            <AddParrains campain={selectCampain} userState={userState} setUserState={setUserState} updateCampain={(camp) => setSelectCampain(camp)} />
        } */}
        </>
    )
}
